<script>
import {
  case_page,
  case_page_draft,
  case_statistic,
  caseMessagePage,
  clinicList,
  PublicLogisticsCasePage,
  PublicUserCasePage,
  queryCasesPage,
  removeCase,
  removeTempCase
} from "common/api/cases";
import { getUserId, notifyMsg } from "common/js/util";
import { getDictList } from "common/api/public";
import { extend, pickBy } from "lodash";
import MsgPage from "@/pages/home/publicChildren/msgPage.vue";
import ExBirdie from "components/expressage_birdie/index.vue";
import { querySmsList } from "common/api/system";
import FullLoading from "components/full-loading/full-loading.vue";
import { getUser } from "common/api/user";
import Confirm from "components/confirm-modal/confirm.vue";
import { mapState } from "vuex";
import TYPES from "store/mutationTypes";

const toObj = {
  to_submit: "/cases/create",
  to_resubmit: "/cases/create",
  to_deliver_model: "/cases/detail",
  to_deliver_model_remain: "/cases/detail",
  to_confirm_text: "/cases/view3d",
  to_pay_design: "/cases/paymentScheme",
  to_confirm_plan: "/cases/view3d",
  to_pay_production: "/cases/paymentScheme",
  to_deliver: "/cases/detail",
  to_done: "/cases/maintain",
  to_archive: "/cases/casesDone",
  archived: "/cases/restartCases",
  to_receive: "/cases/allLogistics",
  to_receive_remain: "/cases/allLogistics",
  to_receive_done: "/cases/allLogistics"
};
export default {
  components: { Confirm, FullLoading, ExBirdie, MsgPage },
  data() {
    return {
      noticeList: [
        {
          key: 1,
          title: "草稿",
          contents: [
            "首页增加新病例草稿，阶段调整草稿，重启病例待提交，完成病例草稿，方便医生快速定位待提交资料"
          ]
        },
        {
          key: 2,
          title: "产品变更",
          contents: [
            "1.首次病例已提交，且未批准3D设计方案支持产品变更",
            "2.仅支持同类型产品变更"
          ]
        },
        {
          key: 3,
          title: "其他",
          contents: [
            "1.病例详情增加服务截止日期，归档，关注。",
            "2.服务到期90天和30天内会弹窗提醒"
          ]
        }
      ],
      noticeOpen: false,
      noticeKey: 1,
      userName: localStorage.getItem("userName"),
      modalLoading: false,
      isLoading: false,
      clinicList: [],
      clinicId: "所有机构",
      toSubmitTotal: 0,
      handleList: [],
      approveList: { list: [] },
      nextStatusObj: {},
      deliverTab: "0",
      toDeliverData: {},
      deliverData: {},
      deliverList: [],
      msgObj: {
        list: [],
        total: ""
      },
      announcementList: [],
      announcementTotal: "",
      stageList: [
        { id: "1", key: "newCase", label: "新病例" },
        { id: "2", key: "phaseAdjustment", label: "阶段调整" },
        { id: "3", key: "reopen", label: "重启病例" },
        { id: "4", key: "completion", label: "完成病例" }
      ],
      statusList: ["to_receive_done", "to_receive", "to_receive_remain"],
      tableData: { list: [] },
      countDict: {},
      modalTab: "1",
      caseOpen: false,
      currentPage: 1,
      currentPageSize: 10,
      receivedPageSize: 10,
      caseRow: {},
      caseId: "",
      isShowConfirm: false,
      confirmObj: {
        tipText: "确定删除？",
        backText: this.$t("common.message.qx"),
        okText: this.$t("common.message.qd")
      },
      urlObj: {
        "1": "/cases/create",
        "2": "/stage-adjustment",
        "3": "/cases/create",
        "4": "/cases/maintain"
      },
      stageDict: {
        "1": "新病例",
        "2": "重启病例",
        "3": "阶段调整",
        "4": "完成阶段"
      },
      statisticDict: {}
    };
  },
  computed: {
    ...mapState({
      userMsg: state => state.userMsg,
      isHandleMsg: state => state.isHandleMsg,
      isShowNews: state => state.isShowNews,
      language: state => state.language
    })
  },
  watch: {
    noticeOpen(val) {
      if (!val) {
        const key = JSON.parse(localStorage.getItem("home_notice_key"));
        if (key) {
          key.push(getUserId());
          localStorage.setItem(
            "home_notice_key",
            JSON.stringify([...new Set(key)])
          );
        } else {
          localStorage.setItem(
            "home_notice_key",
            JSON.stringify([getUserId()])
          );
        }
      }
    },
    isHandleMsg() {
      this.caseMessagePage();
    },
    isShowNewCase(val) {
      if (!val) {
        this.productId = "";
        this.productParticularId = "";
      }
    }
  },
  methods: {
    goPage(url) {
      if (url) {
        this.$router.push({
          path: url,
          query: extend({}, this.$route.query)
        });
      }
    },
    prevStep() {
      this.noticeKey -= 1;
    },
    nextStep() {
      if (this.noticeKey !== 3) {
        this.noticeKey += 1;
      } else {
        this.noticeOpen = false;
      }
    },
    showConfirm(row) {
      this.caseId = row.id;
      this.caseRow = row;
      this.isShowConfirm = true;
    },
    confirmHide() {
      this.isShowConfirm = false;
    },
    confirmSubmit() {
      this.caseRemove();
    },
    async getNewCaseList() {
      this.isLoading = true;
      const data = await case_page({
        clinicIdList: this.clinicId === "所有机构" ? [] : [this.clinicId],
        doctorId: getUserId() || "",
        pageNum: 1,
        pageSize: 5,
        tab: "4",
        typeList: ["1", "2", "3", "4"]
      });
      this.isLoading = false;
      const list = [];
      data.data.list.forEach(v => {
        v?.caseList.forEach(v1 => {
          list.push({ ...v, ...v1 });
        });
      });
      this.toSubmitTotal = data.statistic.toSubmitTotal;
      this.handleList = list;
    },
    caseRemove() {
      if (this.caseId) {
        this.isLoading = true;
        const removeRun = this.modalTab === "1" ? removeCase : removeTempCase;
        removeRun(this.modalTab === "1" ? this.caseId : this.caseRow.deleteId)
          .then(async () => {
            await notifyMsg(this, "success", "操作成功");
            await this.getStatistic();

            await this.getNewCaseList();
            await this.getList();
            await this.getPageDraft();
            this.isLoading = false;
            this.isShowConfirm = false;
          })
          .catch(() => {
            this.isLoading = false;
          });
      }
    },
    async changeClinic(val) {
      this.clinicId = val || "所有机构";
      await this.getStatistic();
      await this.getNewCaseList();
      await this.getList();
      PublicLogisticsCasePage(
        pickBy({
          pageNum: 1,
          pageSize: 10,
          clinicId: this.clinicId === "所有机构" ? "" : this.clinicId,
          doctorId: getUserId() || "",
          generateFlag: 1,
          status: "0",
          pageType: "index",
          refType: 2
        })
      ).then(data => {
        this.deliverList = data.list;
        this.toDeliverData = data;
      });
      PublicLogisticsCasePage(
        pickBy({
          pageNum: 1,
          pageSize: 10,
          clinicId: this.clinicId === "所有机构" ? "" : this.clinicId,
          doctorId: getUserId() || "",
          generateFlag: 1,
          status: "1",
          pageType: "index",
          refType: 2
        })
      ).then(data => {
        this.deliverData = data;
      });
    },
    changeModalTab(val) {
      this.modalTab = val;
      this.currentPage = 1;
      this.getPageDraft();
    },
    changeCaseOpen(val) {
      this.modalTab = val;
      this.currentPage = 1;
      this.caseOpen = true;
      this.getPageDraft();
    },
    async getList() {
      this.tableLoading = true;
      const data = await case_page({
        clinicIdList: this.clinicId === "所有机构" ? [] : [this.clinicId],
        doctorId: getUserId() || "",
        pageNum: 1,
        pageSize: 5,
        tab: "2"
      });
      this.isLoading = false;
      const list = [];
      data.data.list.forEach(v => {
        v?.caseList.forEach(v1 => {
          list.push({ ...v, ...v1 });
        });
      });
      this.statisticDict = data.statistic;
      this.approveList = list;
    },
    async getClinicList() {
      const list = await clinicList();
      this.clinicList = list;
    },
    changeTab(val) {
      this.deliverTab = val;
      this.receivedPageSize = 10;
      this.deliverList =
        val === "0" ? this.toDeliverData.list : this.deliverData.list;
    },
    goCaseList(val) {
      this.$router.replace({
        path: "/cases",
        query: { tab: val }
      });
    },

    /**
     * 跳转到审核页面
     * @param {Object} item - 包含病例信息的对象
     * 该函数根据病例的当前状态和处理标志，生成相应的URL并打开新页面
     * 主要处理逻辑包括：
     * 1. 根据不同的状态和标志，拼接查询参数
     * 2. 设置localStorage项，以传递额外信息到新页面
     * 3. 根据状态和标志，决定是否打开新页面以及打开哪个页面
     */
    goRatify(item) {
      // 解构赋值从item中提取所需信息
      const {
        id,
        userInfo,
        cureNoteId,
        currentCurePlanId: curePlanId = "",
        nextStatus,
        nextProcessingFlag,
        receiveNotifyFlag,
        phaseAdjustmentNumber = 0,
        cureType,
        productId
      } = item;
      // 根据nextStatus获取目标URL的基础路径
      const url = toObj[nextStatus] || "";
      // 初始化查询字符串
      let query = `?caseId=${id}&curePlanId=${item.curePlanId}`;
      if (nextStatus === "to_submit") {
        if (item.type === "1") {
          query += `&submitTabKey=0`;
        }
      }
      // 配置化的 URL 参数
      const payTypeMap = { to_pay_design: 0, to_pay_production: 1 };
      const doneTypeMap = { to_archive: "archive" };

      // 处理不同状态下的查询参数
      if (["to_pay_design", "to_pay_production"].includes(url)) {
        query += `&payType=${payTypeMap[url]}`;
      }

      if (url === "/cases/maintain") {
        query += `&type=${
          nextStatus === "to_done" ? "done" : "archive"
        }&productId=${productId}`;
      }

      // 在交付模型状态时，将窗口名称保存到localStorage
      if (
        nextStatus === "to_deliver_model" ||
        nextStatus === "to_deliver_model_remain"
      ) {
        localStorage.setItem(
          "windowName",
          `${item.name}${this.$t("common.common.blxq")}`
        );
      }

      // 在支付生产状态时，将支付类型保存到localStorage
      if (nextStatus === "to_pay_production") {
        localStorage.setItem("payType", "1");
      }

      // 在归档状态时，将完成类型保存到localStorage
      if (nextStatus === "to_archive") {
        localStorage.setItem("doneType", doneTypeMap[nextStatus]);
      }

      // 如果设置了接收通知标志，则将病例详情保存到localStorage并打开新页面
      if (receiveNotifyFlag === "1") {
        localStorage.setItem("casesDetail", JSON.stringify(item));
        window.open(`/cases/allLogistics${query}`, "_blank");
      } else if (
        nextProcessingFlag === "1" ||
        this.statusList.includes(nextStatus)
      ) {
        // 处理确认文本和确认计划状态
        if (
          nextStatus === "to_confirm_text" ||
          nextStatus === "to_confirm_plan"
        ) {
          const type = nextStatus === "to_confirm_text" ? "target" : "cure";
          query += `&schemeType=${type}&cureNoteId=${cureNoteId}`;
          if (cureType === "F") {
            window.open(`/exocad${query}`);
            return;
          }
        }

        // 在交付状态时，添加交付查询参数
        if (nextStatus === "to_deliver") {
          query += "&delivery=1";
        }

        // 如果是创建病例且阶段调整次数不为0，则打开阶段调整页面
        if (url === "/cases/create" && phaseAdjustmentNumber !== 0) {
          window.open(
            `/stage-adjustment?caseId=${id}&curePlanId=${curePlanId}`
          );
          return;
        }
        // 打开目标URL页面
        window.open(`${url}${query}`);
      }
    },
    jumpCreate() {
      sessionStorage.removeItem("createUserForm");
      window.open(`/cases/create?submitTabKey=5`);
    },
    handleSizeChange(val) {
      this.currentPageSize = val;
      this.getPageDraft();
    },
    handleCurrentChange(val) {
      this.currentPage = val;
      this.getPageDraft();
    },
    async getStatistic() {
      const data = await case_statistic({
        clinicId: this.clinicId === "所有机构" ? "" : this.clinicId,
        doctorId: getUserId() || ""
      });
      this.countDict = data;
    },
    async getPageDraft() {
      this.modalLoading = true;
      const { data } = await case_page_draft({
        pageNum: this.currentPage,
        pageSize: this.currentPageSize,
        clinicId: this.clinicId === "所有机构" ? "" : this.clinicId,
        doctorId: getUserId() || "",
        tab: this.modalTab
      });
      this.modalLoading = false;
      this.tableData = data;
    },
    load() {
      this.receivedPageSize += 10;
      this.getLogisticsCasePage(this.receivedPageSize, this.deliverTab);
    },
    getLogisticsCasePage(pageSize = 10, status = "0") {
      PublicLogisticsCasePage(
        pickBy({
          pageNum: 1,
          pageSize,
          clinicId: this.clinicId === "所有机构" ? "" : this.clinicId,
          doctorId: getUserId() || "",
          generateFlag: 1,
          status,
          pageType: "index",
          refType: 2
        })
      ).then(data => {
        this.deliverList = data.list;
        if (status === "0") {
          this.toDeliverData = data;
        } else {
          this.deliverData = data;
        }
      });
    },
    caseMessagePage() {
      caseMessagePage({
        toUserId: getUserId(),
        status: "to_handle",
        pageNum: 1,
        pageSize: 5
      }).then(data => {
        this.msgObj.list = data.list || [];
        this.msgObj.total = data.total || "";
      });
    },
    toSubmitJumpCreate(item) {
      const _obj = {
        "1": "/cases/create",
        "3": "/stage-adjustment",
        "2": "/cases/create",
        "4": "/cases/maintain"
      };
      const url = _obj[item.type];
      let query = `?caseId=${item.id}&curePlanId=${item.curePlanId}`;
      if (item.nextStatus === "to_submit") {
        if (item.type === "1") {
          query += `&submitTabKey=0`;
        }
      }
      window.open(`${url}?${query}`);
    },
    modalJumpCreate(item) {
      const url = this.urlObj[this.modalTab];
      let query = `?caseId=${item.id}&curePlanId=${item.curePlanId}`;
      if (this.modalTab === "1") {
        query += `&submitTabKey=0`;
      }
      window.open(`${url}?${query}`);
    },
    jumpGoDetail(item) {
      let { caseId, curePlanId } = item;
      this.openPage(`/cases/detail?caseId=${caseId}&curePlanId=${curePlanId}`);
    },
    jumpDetail(item) {
      let { caseId, id } = item;
      window.open(`/cases/viewLogistics?logisticsId=${id}&caseId=${caseId}`);
    },
    reload() {
      this.queryCasesList();
    },
    queryCasesList(type = "0") {
      // this.isLoading = true;
      // if (type === "0") {
      //   this.casesStatistical();
      // }
      // const conditionForQuery =
      //   sessionStorage.getItem("conditionForQuery") || "";
      // if (conditionForQuery) {
      //   this.casesForm.conditionForQuery = conditionForQuery;
      // } else {
      //   delete this.casesForm.conditionForQuery;
      // }
      // this.casesForm.status === "all"
      //   ? (this.casesForm.status = "")
      //   : this.casesForm.status;
      // if (this.casesForm.status === "curing") {
      //   this.casesForm["statusList"] = ["to_handle", "curing"];
      //   this.casesForm.status = "";
      // }
      // PublicUserCasePage(pickBy(extend({}, this.casesForm)))
      //   .then(data => {
      //     this.isLoading = false;
      //     this.casesList = data.list;
      //     this.caseTotal = data.total;
      //   })
      //   .catch(() => {
      //     this.isLoading = false;
      //   });
      // sessionStorage.removeItem("conditionForQuery");
    },
    openDia() {
      this.$refs["exBirdie"].openDia();
    },
    announcementDetail(ev) {
      const target = ev.target;
      const list = Array.from(target.classList);
      if (list.includes("click_box")) {
        const key = target.getAttribute("data-key");
        sessionStorage.setItem("announcementId", key);
        this.goPage("/home/msgDetail");
      }
    },
    openPage(url) {
      window.open(url, "_blank");
    }
  },
  async created() {
    const _homeNoticeKey = JSON.parse(localStorage.getItem("home_notice_key"));
    if (!_homeNoticeKey) {
      this.noticeOpen = true;
    } else {
      const _id = getUserId();
      this.noticeOpen = !_homeNoticeKey.includes(_id);
    }
    document.title = this.$t("home.home.title");
    document.addEventListener("visibilitychange", () => {
      if (document.visibilityState !== "hidden") {
        this.getPageDraft();
        this.getStatistic();
      }
    });
    getUser().then(data => {
      this.userName = data.realName;
    });
    await this.getStatistic();
    await this.getClinicList();
    await this.getNewCaseList();
    await this.getList();
    getDictList("case.next_status").then(data => {
      const obj = {};
      data.forEach(item => {
        obj[item.key] = item.value;
      });
      this.nextStatusObj = obj;
    });
    PublicLogisticsCasePage(
      pickBy({
        pageNum: 1,
        pageSize: 10,
        clinicId: this.clinicId === "所有机构" ? "" : this.clinicId,
        doctorId: getUserId() || "",
        generateFlag: 1,
        status: "0",
        pageType: "index",
        refType: 2
      })
    ).then(data => {
      this.deliverList = data.list;
      this.toDeliverData = data;
    });
    PublicLogisticsCasePage(
      pickBy({
        pageNum: 1,
        pageSize: 10,
        clinicId: this.clinicId === "所有机构" ? "" : this.clinicId,
        doctorId: getUserId() || "",
        generateFlag: 1,
        status: "1",
        pageType: "index",
        refType: 2
      })
    ).then(data => {
      this.deliverData = data;
    });
    this.caseMessagePage();
    querySmsList({
      status: "1",
      target: "DOCTOR",
      type: "SYS",
      readFlag: "0",
      language: localStorage.getItem("user_lang") || "zh_CN"
    }).then(data => {
      if (data.length && !this.isShowNews) {
        this.$store.commit(TYPES.GET_IS_SHOW_NEWS, false);
      }
      if (!data.length) {
        this.$store.commit(TYPES.GET_IS_SHOW_NEWS, false);
      }
      this.announcementTotal = data.length;
      this.announcementList = data.splice(0, 3);
    });
  }
};
</script>

<template>
  <div class="home_page" :class="{ home_page_news: isShowNews }">
    <div class="title-box">
      <div class="title-box-left">
        <h3 style="margin-right: 40px; font-size: 30px">{{ userName }}医生</h3>
        <el-select
          popper-class="custom_select"
          @change="changeClinic"
          clearable
          v-model="clinicId"
          style="width: 236px;height: 108px!important;"
        >
          <el-option
            style="width: 300px;"
            v-for="item in clinicList"
            :key="item.clinicId"
            :label="item.clinicInfo.name"
            :value="item.clinicId"
            class="txt-ellipsis"
          />
        </el-select>
      </div>
      <div class="title-box-right">
        <div
          style="border: 1px solid #c0c4cc;margin-right: 20px;"
          @click="openDia"
        >
          <div class="right_icon1" />
          免费邮寄模型
        </div>
        <div style="background-color: #FCC80E;" @click="jumpCreate">
          <div class="right_icon2" />
          新建病例
        </div>
      </div>
    </div>
    <div class="status-card">
      <div class="card-top">
        <div class="card-box card-box-bg6" @click="changeCaseOpen('2')">
          <div>
            <div class="card-title-icon icon6"></div>
            <span>阶段调整草稿</span>
          </div>
          <h3>{{ countDict.phaseAdjustment }}</h3>
        </div>
        <div class="card-box card-box-bg1" @click="changeCaseOpen('3')">
          <div>
            <div class="card-title-icon icon1"></div>
            <span>重启病例待提交</span>
          </div>
          <h3>{{ countDict.reopen }}</h3>
        </div>
        <div class="card-box card-box-bg2" @click="changeCaseOpen('4')">
          <div>
            <div class="card-title-icon icon2"></div>
            <span>完成病例草稿</span>
          </div>
          <h3>{{ countDict.completion }}</h3>
        </div>
      </div>
      <div class="card-bot">
        <div class="card-box card-box-bg3" @click="changeCaseOpen('1')">
          <div>
            <div class="card-title-icon icon3"></div>
            <span>新病例草稿</span>
          </div>
          <h3>{{ countDict.newCase }}</h3>
        </div>
        <div class="card-box card-box-bg4" @click="goCaseList('5')">
          <div>
            <div class="card-title-icon icon4"></div>
            <span>治疗中病例</span>
          </div>
          <h3>{{ countDict.curing }}</h3>
        </div>
        <div class="card-box card-box-bg5" @click="goCaseList('6')">
          <div>
            <div class="card-title-icon icon5"></div>
            <span>已完成病例</span>
          </div>
          <h3>{{ countDict.done }}</h3>
        </div>
      </div>
    </div>
    <div class="case_content">
      <div class="case_card">
        <div class="case_card_title">
          <div class="case_card_title_left">
            <div class="title_icon" />
            待提交病例<span class="num_text"> ({{ toSubmitTotal || 0 }})</span>
          </div>
          <div class="look_all" @click="goCaseList('4')">查看全部</div>
        </div>
        <div
          v-if="!handleList.length"
          class="case_card_content"
          style="padding-top: 100px;"
        >
          <div class="no_case_data">
            <div class="no_case_text">暂无数据</div>
          </div>
        </div>
        <div v-if="handleList.length" class="case_card_content">
          <div
            class="case_card_item"
            v-for="(item, index) in handleList"
            :key="item.id + index"
            @click="toSubmitJumpCreate(item)"
            v-if="index < 5"
          >
            <div class="case_card_item_left">
              <div style="margin-right: 10px;width: 44px;">
                <el-avatar
                  shape="circle"
                  style="width: 0.44rem;height: 0.44rem;"
                  :src="$PicPrefix + item.photo"
                />
              </div>
              <div class="item_left_text">
                <p class="txt-ellipsis" style="max-width: 80px;">
                  {{ item.name }}
                </p>
                <p>{{ item.caseNumber }}</p>
              </div>
            </div>

            <div class="case_card_item_right1">
              <div class="item_stage">
                {{ stageDict[item.type] }}
              </div>
              <div class="txt-ellipsis product_item_name">
                {{ item.productName }}
              </div>
              <i class="el-icon-arrow-right" />
            </div>
          </div>
        </div>
      </div>
      <div class="case_card">
        <div class="case_card_title">
          <div class="case_card_title_left">
            <div class="title_icon_2" />
            待批准方案
            <span class="num_text">
              ({{ statisticDict.toConfirmPlan || 0 }})</span
            >
          </div>
          <div class="look_all" @click="goCaseList('2')">查看全部</div>
        </div>
        <div
          v-if="!approveList.length"
          class="case_card_content"
          style="padding-top: 100px;"
        >
          <div class="no_case_data">
            <div class="no_case_text">暂无数据</div>
          </div>
        </div>

        <div class="case_card_content" v-if="approveList.length">
          <div
            class="case_card_item"
            v-for="item in approveList"
            :key="item.id"
            @click="goRatify(item)"
          >
            <div class="case_card_item_left">
              <el-avatar
                shape="circle"
                :src="$PicPrefix + item.photo"
                style="margin-right: 10px;width: 0.44rem;height: 0.44rem;"
              />
              <div class="item_left_text">
                <p>{{ item.name }}</p>
                <p>{{ item.caseNumber }}</p>
              </div>
            </div>
            <div class="case_card_item_right">
              <div>
                {{
                  item.cureType === "F"
                    ? $t("casesDetail.casesDetail.mxfa")
                    : $t("casesDetail.casesDetail.threedsj")
                }}{{ item.cureNoteNum }}
              </div>
              <div><i class="el-icon-arrow-right" /></div>
            </div>
          </div>
        </div>
      </div>
      <div class="case_card">
        <div class="logistics_case_title">
          <div
            :class="`title_tab ${deliverTab === '0' ? 'tab_active' : ''}`"
            @click="changeTab('0')"
          >
            待收货({{ toDeliverData.total }})
          </div>
          <div
            :class="`title_tab ${deliverTab === '1' ? 'tab_active' : ''}`"
            @click="changeTab('1')"
          >
            已收货({{ deliverData.total }})
          </div>
        </div>
        <div
          v-if="!deliverList.length"
          class="case_card_content"
          style="padding-top: 100px;"
        >
          <div class="no_case_data">
            <div class="no_case_text">暂无数据</div>
          </div>
        </div>
        <div
          v-if="deliverList.length"
          class="logistics_card_content"
          v-infinite-scroll="load"
        >
          <div
            class="logistics_item infinite-list"
            v-for="item in deliverList"
            :key="item.id"
            @click.prevent="jumpGoDetail(item)"
          >
            <el-avatar
              shape="circle"
              style="width: 0.44rem;height: 0.44rem;"
              :src="$PicPrefix + item.userPhoto"
            ></el-avatar>
            <div class="logistics_item_text">
              <div class="item_text_top">
                <span>{{ item.userName }}</span>
                <div @click.stop="jumpDetail(item)">快递追踪</div>
              </div>
              <div style="line-height: 20px;">{{ item.logisticsName }}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="message_content">
      <div class="message_title">
        <div class="message_title_left">
          <div class="title_icon" />
          消息中心
          <div
            :class="{
              expressage_header_tabs_total: +msgObj.total < 10,
              expressage_header_tabs_total_two:
                +msgObj.total <= 99 && +msgObj.total > 9,
              expressage_header_tabs_total_three: +msgObj.total > 99
            }"
            class="top_message_total expressage_header_tabs_total_main flex-x-y-c"
          >
            {{ +msgObj.total > 99 ? "99+" : msgObj.total || 0 }}
          </div>
        </div>
        <div
          class="look_all"
          @click="$router.push({ path: '/home/moreHomePage' })"
        >
          查看全部
        </div>
      </div>
      <msg-page :msgList="msgObj.list" />
    </div>
    <div class="notice_content">
      <div class="message_title">
        <div class="message_title_left">
          <div class="title_icon" />
          <div
            :class="{
              expressage_header_tabs_total: +announcementTotal < 10,
              expressage_header_tabs_total_two:
                +announcementTotal <= 99 && +announcementTotal > 9,
              expressage_header_tabs_total_three: +announcementTotal > 99
            }"
            class="top_message_total expressage_header_tabs_total_main flex-x-y-c"
          >
            {{ +announcementTotal > 99 ? "99+" : announcementTotal || 0 }}
          </div>
          系统公告
        </div>
        <div class="look_all" @click="$router.push({ path: '/home/Notice' })">
          查看全部
        </div>
      </div>
      <div class="home_container">
        <div class="announcement_box">
          <div class="announcement_title">
            <p class="title_01">{{ $t("home.home.ggbt") }}</p>
            <p class="title_02 tac">{{ $t("home.home.sj") }}</p>
            <p></p>
          </div>
          <ul
            v-if="announcementList.length"
            class="announcement_ul"
            @click="announcementDetail"
          >
            <li
              v-for="(item, index) in announcementList"
              :key="index"
              class="an_single"
            >
              <div :data-key="item.id" class="click_box"></div>
              <p
                :title="item.title"
                class="announcement_ul_title_01 txt-ellipsis"
              >
                {{ item.title }}
              </p>
              <p class="announcement_ul_title_02 txt-ellipsis">
                {{ item.updateDatetime }}
              </p>
            </li>
          </ul>
          <div
            v-else
            :class="{ ann_no_empty: !announcementList.length }"
            class="ann_no"
          >
            <img
              alt=""
              src="../../../common/imgs/home/empty_announcement.png"
            />
            <span class="fz14 main_theme_color_666"
              >{{ $t("home.home.nzshmygg") }}～</span
            >
          </div>
        </div>
      </div>
    </div>
    <el-dialog
      :show-close="false"
      :visible.sync="noticeOpen"
      class="notice_modal"
      width="8.52rem"
    >
      <div class="notice_content">
        <div
          class="close-notice el-icon-close"
          @click="noticeOpen = false"
        ></div>
        <div class="left-navigation">
          <div class="navigation-title">
            {{ `${$t("notice.caseDetail.gxnr")}` }}
          </div>
          <div
            :class="
              noticeKey === item.key
                ? 'navigation-item-active'
                : 'navigation-item'
            "
            :key="item.key"
            v-for="item in noticeList"
            @click="noticeKey = item.key"
          >
            {{ item.title }}
          </div>
        </div>
        <div class="right-content">
          <div
            v-for="(item, index) in noticeList"
            class="content-item"
            :key="index"
            v-if="noticeKey === item.key"
          >
            <div :class="`img${item.key}`"></div>
            <div class="message-content">
              <div class="title">
                <span class="title_01">
                  {{ item.title }}
                </span>
                <div class="step-wrap">{{ item.key }}/{{ 3 }}</div>
              </div>
              <div class="message-item">
                <p :key="index" v-for="(cItem, index) in item.contents">
                  {{ cItem }}
                </p>
              </div>
            </div>
          </div>
          <div class="message_footer">
            <div @click="prevStep" v-show="noticeKey !== 1">上一个</div>
            <div @click="nextStep" class="next_btn">
              {{ noticeKey === 3 ? "知道了" : "下一个" }}
            </div>
          </div>
        </div>
      </div>
    </el-dialog>
    <el-dialog
      :show-close="false"
      class="status_modal"
      :visible.sync="caseOpen"
      width="830px"
    >
      <div slot="title" class="modal_header">
        <div class="case_title_box">
          <div
            @click="changeModalTab(item.id)"
            :class="`tab_box ${item.id === modalTab ? 'tab_box_active' : ''}`"
            v-for="item in stageList"
          >
            {{ item.label }} {{ countDict[item.key] }}
          </div>
        </div>
        <div @click="caseOpen = false" class="el-icon-close" />
      </div>
      <div style="height: 470px;">
        <el-table
          :show-header="false"
          header-row-class-name="modal_table"
          :data="tableData.list"
          v-if="tableData.list.length"
          v-loading="modalLoading"
          element-loading-spinner="el-icon-loading"
          @row-click="modalJumpCreate"
          row-class-name="modal-row-class"
        >
          <el-table-column width="180">
            <template slot-scope="scope">
              <div class="case_table_title">
                <div>
                  <el-avatar
                    :src="$PicPrefix + scope.row.photo"
                    style="margin-right: 10px;display: inline-block;width: 0.44rem;height: 0.44rem;"
                  />
                </div>
                <div>
                  <el-tooltip
                    class="item"
                    effect="dark"
                    :content="scope.row.name"
                    placement="top"
                  >
                    <div class="txt-ellipsis" style="max-width: 1rem;">
                      {{ scope.row.name }}
                    </div>
                  </el-tooltip>
                  <div>{{ scope.row.caseNumber }}</div>
                </div>
              </div>
            </template>
          </el-table-column>
          <el-table-column prop="productName" width="260">
            <template slot-scope="scope">
              <div class="txt-ellipsis2">
                {{ scope.row.productName }}
              </div>
            </template>
          </el-table-column>
          <el-table-column prop="clinicName" width="220">
            <template slot-scope="scope">
              <div class="txt-ellipsis2">
                {{ scope.row.clinicName }}
              </div>
            </template>
          </el-table-column>
          <el-table-column prop="date" width="110" />
          <el-table-column prop="操作" width="45">
            <template slot-scope="scope" v-if="modalTab !== '3'">
              <i
                @click.stop="showConfirm(scope.row)"
                class="el-icon-delete"
                style="font-size: 20px;rgb(168 168 168);cursor: pointer;
"
              ></i>
            </template>
          </el-table-column>
        </el-table>
        <div v-if="!tableData.list.length" class="default_case_page">
          <div class="default_case_icon">
            <div>
              暂无数据
            </div>
          </div>
        </div>
      </div>

      <div slot="footer" class="dialog-footer">
        <el-pagination
          v-if="tableData.list.length"
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :total="tableData.total"
          class="pagination_bot"
          :current-page.sync="currentPage"
          :page-sizes="[10, 20, 30]"
          layout="total, sizes, prev, pager, next, jumper"
        />
      </div>
    </el-dialog>
    <Confirm
      :confirmObj="confirmObj"
      @confirmHide="confirmHide"
      @confirmSubmit="confirmSubmit"
      v-show="isShowConfirm"
    />
    <ExBirdie @reload="reload" ref="exBirdie" />
    <FullLoading v-show="isLoading" />
  </div>
</template>

<style>
.custom_select {
  z-index: 800 !important;
}
</style>

<style scoped lang="scss">
.home_page {
  width: 16rem;
  margin: 0 auto;
  color: #333333;
  font-size: 0.16rem;
  transition: all 0.3s;
  //height: 100%;
  .title-box {
    height: 48px;
    display: flex;
    justify-content: space-between;
    .title-box-left {
      line-height: 48px;
      display: flex;
    }
    .title-box-right {
      display: flex;
      align-items: center;
      line-height: 48px;
      > div {
        display: flex;
        text-align: center;
        width: 206px;
        height: 48px;
        font-size: 16px;
        border-radius: 6px;
        cursor: pointer;
        background-color: #fff;
        align-items: center;
        justify-content: center;
        .right_icon1 {
          width: 20px;
          height: 20px;
          background-image: url(../../../common/imgs/case-list/SF_case.png);
          background-size: cover;
          background-repeat: no-repeat;
          margin-right: 10px;
        }
        .right_icon2 {
          width: 20px;
          height: 20px;
          background-image: url(../../../common/imgs/case-list/create_case.png);
          background-size: cover;
          background-repeat: no-repeat;
          margin-right: 10px;
        }
      }
    }
  }
  .status-card {
    margin-top: 20px;
    background-color: #fff;
    padding: 52px;
    border-radius: 6px;
    height: 368px;
    background-image: url("../../../common/imgs/home-page/home-banner.png");
    background-size: cover;
    background-repeat: no-repeat;
    color: #666666;

    .card-top {
      display: flex;
      width: 740px;
      margin-bottom: 32px;
    }
    .card-bot {
      display: flex;
      width: 740px;
    }
    .card-box-bg6 {
      background-image: url("../../../common/imgs/home-page/stage_phaseAdjustment_bg.png");
      background-size: cover;
      background-repeat: no-repeat;
    }
    .card-box-bg5 {
      background-image: url("../../../common/imgs/home-page/done_bg.png");
      background-size: cover;
      background-repeat: no-repeat;
    }
    .card-box-bg4 {
      background-image: url("../../../common/imgs/home-page/curing_bg.png");
      background-size: cover;
      background-repeat: no-repeat;
    }
    .card-box-bg3 {
      background-image: url("../../../common/imgs/home-page/new_case_bg.png");
      background-size: cover;
      background-repeat: no-repeat;
    }
    .card-box-bg2 {
      background-image: url("../../../common/imgs/home-page/complet_bg.png");
      background-size: cover;
      background-repeat: no-repeat;
    }
    .card-box-bg1 {
      background-image: url("../../../common/imgs/home-page/reopen_bg.png");
      background-size: cover;
      background-repeat: no-repeat;
    }
    .card-box {
      cursor: pointer;
      width: 220px;
      height: 116px;
      margin-right: 20px;
      background-color: #fff;
      border-radius: 4px;
      padding: 15px 20px 20px;
      box-sizing: border-box !important;
      border: 1px solid #fff;
      box-shadow: 0px 0px 12px 0px rgba(0, 0, 0, 0.12);
      transition: 0.2s all; /* 过渡效果应用到所有属性 */

      > div {
        margin-bottom: 12px;
        font-size: 16px;
        display: flex;
        //justify-content: space-between;
        box-sizing: border-box !important;
        align-items: center;

        .card-title-icon {
          width: 32px;
          height: 32px;
          //background-color: #fcc80e;
          background-size: cover;
          background-repeat: no-repeat;
          border-radius: 50%;
          margin-right: 8px;
          overflow: hidden;
        }
        .icon1 {
          background-image: url("../../../common/imgs/home-page/reopen.png");
        }
        .icon2 {
          background-image: url("../../../common/imgs/home-page/complet.png");
        }
        .icon3 {
          background-image: url("../../../common/imgs/home-page/new_case.png");
        }
        .icon4 {
          background-image: url("../../../common/imgs/home-page/curing.png");
        }
        .icon5 {
          background-image: url("../../../common/imgs/home-page/done.png");
        }
        .icon6 {
          background-image: url("../../../common/imgs/home-page/stage_phaseAdjustment.png");
        }
      }
      h3 {
        font-size: 32px;
        color: #333333;
      }
    }
    .card-box:hover {
      transition: 0.2s all;
      box-shadow: 0px 0px 16px 0px rgba(0, 0, 0, 0.2);
      border: 1px solid #fcc80e;
      margin-top: -5px;
    }
  }

  .case_content {
    margin-top: 20px;
    display: flex;
    justify-content: space-between;
    .case_card {
      //padding: 20px;
      background-color: #fff;
      border-radius: 6px;
      width: 520px;
      .case_card_title {
        display: flex;
        justify-content: space-between;
        height: 54px;
        border-bottom: 1px solid #e5e5e5;
        padding: 20px;
        align-items: center;
        .case_card_title_left {
          display: flex;
          align-items: center;
          .title_icon {
            width: 24px;
            height: 24px;
            //background-color: #fcc80e;
            margin-right: 10px;
            background-image: url("../../../common/imgs/home-page/handle.png");
            background-size: cover;
            background-repeat: no-repeat;
          }
          .title_icon_2 {
            width: 24px;
            height: 24px;
            //background-color: #fcc80e;
            margin-right: 10px;
            background-image: url("../../../common/imgs/home-page/to_confirm.png");
            background-size: cover;
            background-repeat: no-repeat;
          }
        }

        .look_all {
          color: #fcc80e;
          cursor: pointer;
          font-size: 14px;
        }
      }
      .case_card_content {
        height: 436px;

        .no_case_data {
          margin: auto;
          width: 207px;
          height: 187px;
          background-image: url("../../../common/imgs/home-page/no_case.png");
          background-size: cover;
          background-repeat: no-repeat;
          position: relative;
          .no_case_text {
            text-align: center;
            width: 207px;
            font-size: 14px;
            color: #999999;
            position: absolute;
            bottom: -30px;
          }
        }
        .case_card_item {
          padding: 0px 20px;
          height: 84px;
          cursor: pointer;
          display: flex;
          align-items: center;
          .case_card_item_left {
            width: 160px;
            display: flex;
            align-items: center;
            justify-items: center;
            .item_left_text {
              > p {
                font-size: 14px;
                margin: 5px;
              }
            }
          }
          .case_card_item_right {
            display: flex;
            flex: 1;
            justify-content: space-between;
            margin-left: 40px;
            align-items: center;
            justify-items: center;
            color: #666;
          }
          .case_card_item_right1 {
            flex: 1;
            justify-content: space-between;
            color: #666;
            display: flex;
            //width: 100%;
            height: 100%;
            align-items: center;
            justify-items: center;
            line-height: 84px;
            .item_stage {
              width: 70px;
            }
            .product_item_name {
              width: 180px;
            }
            .el-icon-arrow-right {
              width: 20px;
            }
          }
        }
        .case_card_item:hover {
          background-color: #f9f9f9;
          transition: 0.2s all;
        }
      }

      .logistics_case_title {
        padding-left: 20px;
        height: 54px;
        display: flex;
        border-bottom: 1px solid #e5e5e5;
        .title_tab {
          width: 100px;
          font-size: 16px;
          line-height: 54px;
          margin-right: 20px;
          cursor: pointer;
          height: 54px;
        }
        .tab_active {
          border-bottom: 2px solid #fcc80e;
          //color: #fcc80e;
          //transition: .2s all;
        }
      }
      .logistics_card_content {
        padding: 3px 20px 10px;
        height: 419px;
        overflow-y: auto;
        .logistics_item {
          height: 84px;
          background: #f4f4f4;
          border-radius: 4px;
          margin-top: 20px;
          padding: 20px;
          display: flex;
        }
        .logistics_item_text {
          margin-left: 10px;
          font-size: 14px;
          width: 400px;
          .item_text_top {
            display: flex;
            justify-content: space-between;
            margin-bottom: 4px;
            > div {
              cursor: pointer;
              color: red;
            }
          }
        }
      }
    }
  }

  .message_content {
    //margin-top: 10px;
    border-radius: 6px;
    .message_title {
      display: flex;
      justify-content: space-between;
      padding: 25px 0 10px;
      border-radius: 6px;
      justify-items: center;
      align-items: center;
      .message_title_left {
        display: flex;
        align-items: center;
        font-size: 18px;
        position: relative;
        .title_icon {
          width: 32px;
          height: 32px;
          background-image: url(../../../common/imgs/home-page/message.png);
          background-size: cover;
          background-repeat: no-repeat;
          margin-right: 20px;
        }
      }
      .look_all {
        font-size: 14px;
        cursor: pointer;
        color: #fcc80e;
      }
    }
  }
  .notice_content {
    //margin-top: 10px;
    border-radius: 6px;
    .message_title {
      display: flex;
      justify-content: space-between;
      padding: 25px 0 10px;
      border-radius: 6px;
      justify-items: center;
      align-items: center;
      .message_title_left {
        display: flex;
        align-items: center;
        font-size: 18px;
        position: relative;
        .title_icon {
          width: 32px;
          height: 32px;
          background-image: url(../../../common/imgs/home-page/notice.png);
          background-size: cover;
          background-repeat: no-repeat;
          margin-right: 10px;
        }
      }
      .look_all {
        font-size: 14px;
        cursor: pointer;
        color: #fcc80e;
      }
    }
  }
  .home_container {
    width: 100%;
    margin: 0 auto;
    background-color: white;

    .con_head {
      margin-bottom: 0.2rem;
      display: flex;
      align-items: center;
      justify-content: space-between;

      .con_head_left {
        display: flex;
        align-items: center;
        position: relative;

        .h_left_h5 {
          margin-right: 0.68rem;
        }

        .h5_strong {
          font-size: 0.23rem;
          margin: 0 0.04rem;
        }

        .h_left_select {
          border: none;
          outline: none;
          padding: 0.1rem;
        }

        .l_select_icon {
          position: absolute;
          right: -1.5%;
          bottom: 38%;
          display: inline-block;
          width: 0.1rem;
          height: 0.05rem;
          background-size: 100% 100%;
          background-image: url("/static/images/home/web_hp_arrow.png");
        }
      }

      .con_head_right {
        width: 1.48rem;
        height: 0.44rem;
        background: rgb(81, 30, 122);
        color: #fff;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 1rem;
        cursor: pointer;

        .add_icon {
          display: inline-block;
          font-size: 0.18rem;
          font-weight: bold;
          margin-right: 0.06rem;
        }

        .h_r_txt {
          padding-top: 0.02rem;
        }
      }
    }

    .cases_ul {
      margin-bottom: 0.3rem;
      display: flex;
      justify-content: space-between;

      .cases_single {
        width: 32%;
        height: 2.92rem;
        box-sizing: border-box;
        padding: 0.2rem 0.18rem 0.3rem;
        background-color: #fff;
        border-radius: 0.06rem;
        display: flex;
        flex-direction: column;

        .single_h5 {
          display: flex;
          justify-content: space-between;
          padding-bottom: 0.1rem;

          .single_h5_l {
            font-size: 0.15rem;
          }

          .single_h5_r {
            color: #511e7a;
            font-size: 0.14rem;
            cursor: pointer;
          }
        }

        .c_single_li {
          display: flex;
          align-items: center;
          padding: 0.2rem 0;
          border-bottom: 0.01rem solid #ebebeb;
          cursor: pointer;
          position: relative;

          &:last-child {
            border: none;
          }

          .c_single_li_pic {
            width: 0.38rem;
            height: 0.38rem;
            margin-right: 0.07rem;
            border-radius: 100%;
            background-repeat: no-repeat;
            background-size: cover;
            background-position: center;
          }

          .c_single_li_r {
            flex: 1;

            .li_r_left_h5 {
              font-size: 0.15rem;
              margin-bottom: 0.08rem;
            }

            .li_r_left_p {
              color: #999;
              font-size: 0.14rem;
              display: flex;
              align-items: center;
              justify-content: space-between;
            }
          }
        }

        .cases_no {
          flex: 1;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          color: #999999;
          font-size: 0.15rem;

          .cases_no_icon {
            width: 0.48rem;
            height: 0.54rem;
            margin-bottom: 0.13rem;
            background-size: 100% 100%;
          }

          .cases_no_icon01 {
            background-image: url("/static/images/home/web_hp_disposal.png");
          }

          .cases_no_icon02 {
            background-image: url("/static/images/home/web_hp_treatment.png");
          }

          .cases_no_icon03 {
            background-image: url("/static/images/home/web_hp_completed.png");
          }
        }
      }
    }

    .page_h5 {
      display: flex;
      align-items: center;
      font-size: 0.22rem;
      font-family: Microsoft YaHei;
      margin-bottom: 0.29rem;

      .page_h5_line {
        display: inline-block;
        width: 0.03rem;
        height: 0.22rem;
        margin-right: 0.1rem;
        background-color: #ff8e30;
      }
    }

    .announcement_box {
      margin-bottom: 0.87rem;
      color: #9b9b9b;

      .announcement_title {
        display: flex;
        align-items: center;
        height: 0.54rem;
        font-size: 0.18rem;
        border-bottom: 0.01rem solid #dcdfe6;
      }

      .announcement_ul {
        background-color: #fff;

        .an_single {
          display: flex;
          align-items: center;
          justify-content: space-between;
          height: 0.6rem;
          font-size: 0.14rem;
          color: $main_theme_color_333;
          border-bottom: 0.01rem solid #e9e9e9;
          position: relative;

          .announcement_ul_title_01 {
            width: 600px;
            padding-left: 60px;
          }

          .announcement_ul_title_02 {
            padding-right: 60px;
          }
        }

        .an_single:hover {
          transition: 0.3s all;
          background: #f9f9f9;
          color: #555657;
        }
      }

      .announcement_ul_empty {
        height: 0.6rem;
      }

      .ann_no {
        color: #666666;
        font-size: 0.16rem;
        height: 0.88rem;
        line-height: 1.1rem;
        padding-left: 0.78rem;
        background-color: #fff;
      }

      .ann_no_empty {
        height: 3.38rem !important;
        display: flex;
        flex-flow: column;
        justify-content: center;
        align-items: center;

        img {
          display: block;
          width: 198px;
          height: 142px;
        }
      }

      .title_01 {
        flex: 4;
        padding-left: 0.6rem;
        font-size: 16px;
        color: $main_theme_color_333;
      }

      .title_02 {
        flex: 1;
        font-size: 16px;
        color: $main_theme_color_333;
        display: flex;
        flex-flow: row-reverse;
        margin-right: 60px;
      }
    }
  }
}

.notice_modal {
  .notice_content {
    height: 560px;
    position: relative;
    display: flex;
    padding: 16px 0;
    .close-notice {
      cursor: pointer;
      position: absolute;
      right: 10px;
      top: 5px;
      font-size: 24px;
    }
    .close-notice:hover {
      color: $main_theme_color;
    }

    .left-navigation {
      width: 192px;
      .navigation-title {
        padding-left: 20px;
        font-size: 20px;
        line-height: 28px;
        font-weight: bold;
        margin-bottom: 16px;
        //color: #FCC80E;
        color: var(--textColor);
      }
      .navigation-item,
      .navigation-item-active {
        //color: #fcc80e;
        //color: var(--textColor);
        cursor: pointer;
        position: relative;
        padding-left: 32px;
        height: 48px;
        display: flex;
        align-items: center;
        font-size: 16px;
      }
      .navigation-item-active {
        background: #f9f9f9;
        //background: var(--view3d-init-notice-left-active-item);
        //color: var(--mainColor);
        color: #fcc80e;

        &::before {
          content: "";
          position: absolute;
          width: 2px;
          height: 24px;
          top: 50%;
          left: 0;
          transform: translateY(-50%);
          background-color: #fcc80e;
          //background-color: var(--mainColor);
        }
      }
    }
    .right-content {
      flex: 1;
      .content-item {
        width: 100%;
      }
      .message-content {
        padding: 14px;
        box-sizing: border-box;

        .title {
          display: flex;
          justify-content: space-between;
          .title_01 {
            font-size: 18px;
            font-weight: bold;
            color: #333333;
          }
          .step-wrap {
            width: 48px;
            height: 24px;
            border-radius: 12px;
            background: rgba(0, 0, 0, 0.04);
            font-size: 14px;
            display: flex;
            justify-content: center;
            align-items: center;
          }
        }
        .message-item {
          margin-top: 10px;
          font-size: 14px;
          color: #333333;
          line-height: 28px;
        }
      }
      .message_footer {
        width: 660px;
        height: 36px;
        font-size: 14px;
        padding: 0 32px;
        color: #999999;
        display: flex;
        justify-content: space-between;
        align-items: center;
        justify-items: center;
        position: absolute;
        bottom: 16px;
        right: 16px;
        > div {
          cursor: pointer;
          position: absolute;
        }
        .next_btn {
          width: 96px;
          height: 36px;
          background-color: $main_theme_color;
          border-radius: 4px;
          text-align: center;
          line-height: 36px;
          color: #333;
          right: 0px;
        }
      }
    }
  }
}
.img1,
.img2,
.img3,
.img4,
.img5 {
  width: 660px;
  height: 370px;
}
.img1 {
  background: url("../../../common/imgs/home-page/img1.png") no-repeat center;
  background-size: 100% 100%;
}

.img2 {
  background: url("../../../common/imgs/home-page/img2.png") no-repeat center;
  background-size: 100% 100%;
}

.img3 {
  background: url("../../../common/imgs/home-page/img3.png") no-repeat center;
  background-size: 100% 100%;
}

::v-deep .notice_modal .el-dialog__header {
  padding: 0 !important
;
}

::v-deep .notice_modal .el-dialog__body {
  padding: 0 !important;
}
::v-deep .notice_modal .el-dialog__footer {
  padding: 0;
}

::v-deep .status_modal .el-dialog__header {
  padding-left: 0 !important;
  padding-right: 0 !important;
}

::v-deep .status_modal .el-dialog__body {
  padding: 0;
  max-height: 470px;
  overflow-y: auto;
}
::v-deep .status_modal .el-dialog__footer {
  padding: 0;
}
.modal_header {
  width: 100%;
  display: flex;
  position: relative;
  .el-icon-close {
    position: absolute;
    right: 10px;
    top: -5px;
    font-size: 24px;
    font-weight: 800;
    cursor: pointer;
    color: #9e9999;
    transition: 0.2s all;
  }
  .el-icon-close:hover {
    color: #fcc80e;
    transition: 0.2s all;
  }
}
.case_title_box {
  width: 100%;
  display: flex;
  border-bottom: 1px solid #ebebeb;
  padding-left: 30px;
  .tab_box {
    cursor: pointer;
    //width: 106px;
    height: 30px;
    font-size: 16px;
    line-height: 22px;
    margin-right: 20px;
    transition: 0.2s;
  }
  .tab_box_active {
    color: #fcc80e;
    border-bottom: 2px solid #fcc80e;
    transition: 0.2s;
  }
}
.status_modal .dialog-footer {
  height: 72px;
  box-shadow: 0px -2px 8px 0px rgba(51, 51, 51, 0.08);
  padding-top: 24px;
  padding-bottom: 24px;
  padding-right: 40px;
}

.case_table_title {
  display: flex;
  justify-items: center;
  align-items: center;
  margin-left: 20px;
}

::v-deep.pagination_bot .el-pager li.active {
  color: #fcc80e !important;
}

::v-deep.pagination_bot .el-pager li:hover {
  color: #fcc80e !important;
}
::v-deep.pagination_bot .el-pager li.active:hover {
  color: #fcc80e !important;
}
::v-deep.el-pagination .el-select .el-input__inner:hover {
  border-color: #fcc80e !important;
}
::v-deep .el-pagination .el-icon:hover {
  color: #fcc80e !important;
}

::v-deep .el-dialog .el-dialog__body .el-table .el-table__row {
  height: 84px !important;
  color: #333333 !important;
  font-size: 14px;
  cursor: pointer !important;

  > td {
    padding: 0 !important;
  }
}

.top_message_total {
  position: absolute;
  left: 21px;
  top: 5px;
}
.expressage_header_tabs_total_main {
  height: 14px;
  background: #ed4027;
  border-radius: 7px;
  font-size: 12px;
  color: white;
  padding-left: 4px;
  padding-right: 4px;
  text-align: center;
}
.default_case_page {
  padding-top: 150px;
  width: 100%;
  .default_case_icon {
    margin: auto;
    width: 204px;
    height: 154px;
    background-image: url("../../../common/imgs/case-list/default_case_icon.png");
    background-size: cover;
    background-repeat: no-repeat;
    position: relative;
    > div {
      width: 100%;
      text-align: center;
      position: absolute;
      bottom: -20px;
      color: #999999;
      font-size: 14px;
    }
  }
}
::v-deep .el-icon-loading {
  color: #606266 !important;
  font-size: 30px;
}
/* 全局样式文件 */
::v-deep.el-select .el-input__inner {
  height: 48px !important; /* 您想要的高度 */
  line-height: 48px !important; /* 保持垂直居中 */
}

::v-deep.el-select .el-input__icon {
  line-height: 48px !important; /* 调整图标的行高以垂直居中 */
}
.modal-row-class {
  cursor: pointer !important;
}
.home_page_news {
  margin-top: 0.38rem;
}
::v-deep .el-avatar {
  img {
    display: inline-block;
    margin: auto;
    width: 44px !important;
  }
}
</style>
