import Vue from "vue";
import Router from "vue-router";

Vue.use(Router);

const Login = () => import("@/pages/login/login");
const RetrievePaw = () => import("@/pages/login/retrievePaw");
const DownloadApp = () => import("@/pages/login/downloadApp");

// 病例相关
// const Cases = () => import("@/pages/cases/cases");
const Cases = () => import("@/pages/cases/case-list");
const CasesCreate = () => import("@/pages/cases/cases-create");
const PaymentScheme = () =>
  import("@/pages/cases/cases-detail/casesChildren/paymentScheme");
const CasesDetail = () => import("@/pages/cases/cases-detail/casesDetail");
const ReviewPlan = () =>
  import("@/pages/cases/cases-detail/casesChildren/reviewPlan");
const Amendments = () =>
  import("@/pages/cases/cases-detail/casesChildren/amendments");
const NextProduction = () =>
  import("@/pages/cases/cases-detail/casesChildren/nextProduction");
const LookWearingRecord = () =>
  import("@/pages/cases/cases-detail/casesChildren/lookWearingRecord");
const ViewLogistics = () =>
  import("@/pages/cases/cases-detail/casesChildren/viewLogistics");
const OperationView = () =>
  import("@/pages/cases/cases-detail/casesChildren/operationView");
const NewCaseData = () =>
  import("@/pages/cases/cases-detail/casesChildren/newCaseData");

const SendAdvice = () =>
  import("@/pages/cases/cases-detail/casesChildren/sendAdvice");
const HisEditOpinion = () =>
  import("@/pages/cases/cases-detail/casesChildren/hisEditOpinion");
const CasesDoKeep = () =>
  import("@/pages/cases/cases-detail/casesChildren/casesDoKeep");
const CasesDone = () =>
  import("@/pages/cases/cases-detail/casesChildren/casesDone");
const BracesReissue = () =>
  import("@/pages/cases/cases-detail/casesChildren/bracesReissue");
const RestartCases = () =>
  import("@/pages/cases/cases-detail/casesChildren/restartCases");
const CasesTransfer = () =>
  import("@/pages/cases/cases-detail/casesChildren/casesTransfer");
const CasesToScan = () =>
  import("@/pages/cases/cases-detail/casesChildren/casesToScan");
const ReviewTextPlan = () =>
  import("@/pages/cases/cases-detail/casesChildren/reviewTextPlan");
const HisTextPlan = () =>
  import("@/pages/cases/cases-detail/casesChildren/hisTextPlan");
const AllLogistics = () =>
  import("@/pages/cases/cases-detail/casesChildren/allLogistics");
const InviteFriends = () => import("@/pages/cases/inviteFriends");

const Maintain = () =>
  import("@/pages/cases/cases-detail/casesChildren/maintain.vue");

// 主页
// const Home = () => import("@/pages/home/home");
const Home = () => import("@/pages/home/home-page/index.vue");
const MoreHomePage = () => import("@/pages/home/homeChildren/moreHomePage");
const MsgDetail = () => import("@/pages/home/homeChildren/msgDetail");
// 个人中心
const Personal = () => import("@/pages/home/personal/personal");
const ExitEmail = () =>
  import("@/pages/home/personal/personalChildren/exitEmail");
const BandEmail = () =>
  import("@/pages/home/personal/personalChildren/bandEmail");
const BandPhone = () =>
  import("@/pages/home/personal/personalChildren/bandPhone");
const EditPhone = () =>
  import("@/pages/home/personal/personalChildren/editPhone");
const BandLoginPaw = () =>
  import("@/pages/home/personal/personalChildren/bandLoginPaw");
const BandTradingPaw = () =>
  import("@/pages/home/personal/personalChildren/bandTradingPaw");
const BandWeChat = () =>
  import("@/pages/home/personal/personalChildren/bandWeChat");

// 帮助中心
const HelpCenter = () => import("@/pages/helpCenter/index");

// 临床偏好
const ClinicalPreferences = () =>
  import("@/pages/cases/cases-detail/clinicalPreferences");

// 查看3d
const View3d = () => import("@/pages/cases/cases-detail/view3d/view3d");

//查看全部公共
const Notice = () => import("@/pages/home/homeChildren/notice");

// exocad
const Exocad = () => import("@/pages/cases/cases-detail/exocad/exocad");

//switch-irdt
const SwitchIrdt = () => import("@/pages/cases/cases-detail/switch-irdt/index");

const StageAdjustment = () =>
  import("@/pages/cases/cases-detail/stageAdjustment/index.vue");

// 方案咨询
const Consultation = () => import("@/pages/consultation/index.vue");
const CreateConsultation = () =>
  import("@/pages/consultation/addModify-plan.vue");
const ConsultationDetail = () => import("@/pages/consultation/plan-detail.vue");

const caseList = () => import("@/pages/cases/case-list");
// 测试页面
const Test = () => import("@/pages/test");

const route = new Router({
  mode: "history",
  routes: [
    {
      path: "/login",
      component: Login
    },
      {
      path: "/case-list",
      component:caseList
    },
    {
      path: "/downloadApp",
      component: DownloadApp
    },
    {
      path: "/retrievePaw",
      component: RetrievePaw
    },
    {
      path: "/home",
      component: Home
    },
    {
      path: "/home/moreHomePage",
      component: MoreHomePage
    },
    {
      path: "/home/msgDetail",
      component: MsgDetail
    },
    {
      path: "/cases/paymentScheme",
      component: PaymentScheme
    },
    {
      path: "/home/personal",
      component: Personal
    },
    {
      path: "/home/exitEmail",
      component: ExitEmail
    },
    {
      path: "/home/bandEmail",
      component: BandEmail
    },
    {
      path: "/home/bandPhone",
      component: BandPhone
    },
    {
      path: "/home/editPhone",
      component: EditPhone
    },
    {
      path: "/home/bandLoginPaw",
      component: BandLoginPaw
    },
    {
      path: "/home/bandTradingPaw",
      component: BandTradingPaw
    },
    {
      path: "/home/bandWeChat",
      component: BandWeChat
    },
    {
      path: "/cases",
      component: Cases
    },
    {
      path: "/cases/create",
      component: CasesCreate
    },
    {
      path: "/cases/detail",
      component: CasesDetail
    },
    {
      path: "/cases/reviewPlan",
      component: ReviewPlan
    },
    {
      path: "/cases/amendments",
      component: Amendments
    },
    {
      path: "/cases/nextProduction",
      component: NextProduction
    },
    {
      path: "/cases/lookWearingRecord",
      component: LookWearingRecord
    },
    {
      path: "/cases/viewLogistics",
      name: "ViewLogistics",
      component: ViewLogistics
    },
    {
      path: "/cases/operationView",
      component: OperationView
    },
    {
      path: "/new/case/data",
      name: "new_case_data",
      component: NewCaseData
    },
    //发送医嘱
    {
      path: "/cases/sendAdvice",
      component: SendAdvice
    },
    {
      path: "/cases/hisEditOpinion",
      component: HisEditOpinion
    },
    {
      path: "/cases/casesDoKeep",
      name: "CasesDoKeep",
      component: CasesDoKeep
    },
    {
      path: "/cases/casesDone",
      component: CasesDone
    },
    {
      path: "/cases/bracesReissue",
      component: BracesReissue
    },
    {
      path: "/switch-irdt",
      component: SwitchIrdt
    },
    {
      path: "/stage-adjustment",
      component: StageAdjustment
    },
    {
      path: "/cases/restartCases",
      component: RestartCases
    },
    //病例转让
    {
      path: "/cases/casesTransfer",
      component: CasesTransfer
    },
    {
      path: "/cases/casesToScan",
      component: CasesToScan
    },
    {
      path: "/cases/reviewTextPlan",
      component: ReviewTextPlan
    },
    {
      path: "/cases/hisTextPlan",
      component: HisTextPlan
    },
    {
      path: "/cases/allLogistics",
      name: "AllLogistics",
      component: AllLogistics
    },
    {
      path: "/cases/inviteFriends",
      component: InviteFriends
    },
    {
      path: "/helpCenter",
      name: "helpCenter",
      meta: {
        title: "帮助中心"
      },
      component: HelpCenter
    },
    {
      path: "/cases/clinicalPreferences",
      name: "clinicalPreferences",
      component: ClinicalPreferences
    },
    {
      path: "/cases/view3d",
      component: View3d
    },
    {
      path: "/home/Notice",
      component: Notice,
      name: "homeNotice"
    },
    {
      path: "/exocad",
      component: Exocad,
      name: "exocad"
    },
    {
      path: "/cases/maintain",
      component: Maintain,
      name: "maintain"
    },
    { path: "/", redirect: "/home" },
    {
      path: "/consultation",
      name: "consultation",
      component: Consultation,
      meta: {
        title: "病例咨询"
      }
    },
    {
      path: "/consultation/create",
      name: "createConsultation",
      component: CreateConsultation
    },
    {
      path: "/consultation/detail",
      name: "consultationDetail",
      component: ConsultationDetail
    },
    {
      path: "/test",
      component: Test
    }
  ],
  scrollBehavior(to, from, savedPosition) {
    // vue滚动行为
    if (savedPosition) {
      return savedPosition;
    } else {
      return { x: 0, y: 0 };
    }
  }
});
export default route;
