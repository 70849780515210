<script>
import {
  case_archive,
  case_page,
  clinic_relevant,
  clinicList,
  follow_case,
  product_relevant,
  PublicProductTypeListByDoctor,
  PublicUserCasePage,
  removeCase,
  removeTempCase
} from "common/api/cases";
import { getUserId, notifyMsg } from "common/js/util";
import { getDictList } from "common/api/public";
import FullLoading from "components/full-loading/full-loading.vue";
import Confirm from "components/confirm-modal/confirm.vue";
import { extend, pickBy } from "lodash";
import ExBirdie from "components/expressage_birdie/index.vue";
import moment from "moment";
import {mapActions, mapMutations, mapState} from "vuex";

const toObj = {
  to_submit: "/cases/create",
  to_resubmit: "/cases/create",
  to_deliver_model: "/cases/detail",
  to_deliver_model_remain: "/cases/detail",
  to_confirm_text: "/cases/view3d",
  to_pay_design: "/cases/paymentScheme",
  to_confirm_plan: "/cases/view3d",
  to_pay_production: "/cases/paymentScheme",
  to_deliver: "/cases/detail",
  to_done: "/cases/maintain",
  to_archive: "/cases/casesDone",
  archived: "/cases/restartCases",
  to_receive: "/cases/allLogistics",
  to_receive_remain: "/cases/allLogistics",
  to_receive_done: "/cases/allLogistics"
};
export default {
  components: { ExBirdie, Confirm, FullLoading },
  data() {
    return {
      productCount: 0,
      clinicCount: 0,
      caseRow: {},
      checked: true,
      date: null, // 用来存储选择的日期
      showDatePicker: false, // 控制日期选择框的显示与隐藏
      tableLoading: false,
      currentPage: 1,
      currentPageSize: 20,
      statusBtnList: [
        { label: "待批准方案", key: "2" },
        { label: "待处理", key: "3" },
        { label: "全部", key: "0" },
        { label: "待提交", key: "4" },
        { label: "治疗中", key: "5" },
        { label: "已完成", key: "6" },
        { label: "已归档", key: "7" }
      ],
      clinicList: [],
      statusBtnKey: "0",
      countDict: {
        "0": "0",
        "1": "0",
        "2": "0",
        "3": "0",
        "4": "0",
        "5": "0",
        "6": "0",
        "7": "0"
      },
      statisticCount: {},
      statusKeyDict: {
        total: "0",
        follow: "1",
        toConfirmPlan: "2",
        toHandle: "3",
        toSubmitTotal: "4",
        curing: "5",
        done: "6",
        archive: "7"
      },
      stageDict: {
        "1": "新病例",
        "2": "重启病例",
        "3": "阶段调整",
        "4": "完成阶段"
      },
      // 类型 1-新病例 2-重启病例 3-阶段调整（草稿） 4-完成阶段（草稿）
      stageList: [
        { key: "1", label: "新病例", checked: true, countKey: "toSubmitNew" },
        {
          key: "2",
          label: "重启病例",
          checked: true,
          countKey: "toSubmitReopen"
        },
        { key: "3", label: "阶段调整", checked: true, countKey: "toSubmitPA" },
        {
          key: "4",
          label: "完成阶段",
          checked: true,
          countKey: "toSubmitCompletion"
        }
      ],
      stageCheckAll: true,
      tableData: {
        list: []
      },
      nextStatusObj: {},
      casesNextObj: {},
      statusParams: {
        "1": { status: "to_handle" },
        "2": { nextStatus: "to_confirm_plan" },
        "3": {},
        "4": { status: "precuring" },
        "5": { stageList: ["C", "D"] },
        "6": { status: "done" },
        "7": { status: "abandon" }
      },
      formData: {
        patientNameForQuery: "",
        caseNumberForQuery: ""
      },
      confirmObj: {
        tipText: this.$t("cases.cases.qrsc"),
        backText: this.$t("common.message.qx"),
        okText: this.$t("common.message.qd")
      },
      archiveConfirmObj: {
        tipText: "确定取消归档?",
        backText: this.$t("common.message.qx"),
        okText: this.$t("common.message.qd")
      },
      isShowArchive: false,
      caseId: "",
      isIndeterminate: false,
      isStageIndeterminate: false,
      checkAll: true,
      checkObj: {},
      clinicDoctorList: [],
      statusList: ["to_receive_done", "to_receive", "to_receive_remain"],
      sort: "",
      orderDirect: "",
      createTime: "",
      stopDataTab: "0",
      isProductOpen: true,
      isDatePickerVisible: false, // 控制 dropdown 显示状态
      typeList: ["1", "2", "3", "4"],
      productList: [],
      showProductList: [],
      productIdList: [],
      serviceDeadlineBegin: "",
      serviceDeadlineEnd: "",
      urlObj: {
        "1": "/cases/create",
        "3": "/stage-adjustment",
        "2": "/cases/create",
        "4": "/cases/maintain"
      },
      isShowConfirm: false,
      // 阶段点击会触发两次
      isStageProcessing: false,
      // 机构点击会触发两次
      isClinicProcessing: false,
      isLoading: false
    };
  },
  computed: {
    ...mapState({
      conditionForQuery: state => state.conditionForQuery,
      isShowNews: state => state.isShowNews
    })
  },
  watch: {
    conditionForQuery: {
      handler() {
        this.queryCasesList();
      }
    }
  },
  beforeDestroy() {
    // this.clickFn();
    sessionStorage.removeItem("conditionForQuery");
    window.removeEventListener("scroll", this.handleScroll, false);
    document.body.style.overflowY = "auto";
  },
  methods: {
    ...mapMutations(["HANDLE_MSG"]),
    ...mapActions({
      getMsgList: "actionGetMsgList" //病例详情
    }),
    jumpCaseCreate(item) {
      const url = this.urlObj[item.type];
      console.log(1111);
      window.open(`${url}?caseId=${item.id}&curePlanId=${item.curePlanId}`);
    },
    toggleDatePicker() {
      // 切换 isDatePickerVisible 状态
      // this.$refs.datePickerRef.$el.click();
      this.isDatePickerVisible = !this.isDatePickerVisible;
    },
    handleDateChange() {
      this.currentPage = 1;
      this.getList();
      this.$refs.popoverRef.doClose();
      // 在选择日期后关闭 dropdown
      this.isDatePickerVisible = false;
    },
    changeSort() {
      if (!this.sort) {
        this.sort = "ascend";
        this.orderDirect = "createDatetime";
      } else if (this.sort === "ascend") {
        this.sort = "descend";
        this.orderDirect = "-createDatetime";
      } else if (this.sort === "descend") {
        this.sort = "";
        this.orderDirect = "";
      }
      this.getList();
    },
    changeFollow(item) {
      follow_case({
        id: item.id,
        follow: item.follow === "1" ? "0" : "1"
      }).then(() => {
        notifyMsg(
          this,
          "success",
          this.$t(
            item.follow === "1" ? "cases.cases.gdqxgz" : "cases.cases.qdgz"
          )
        );
        this.getList();
      });
    },
    toEditCases(obj, item) {
      const {
        id,
        userInfo,
        cureNoteId,
        curePlanId,
        nextStatus,
        nextProcessingFlag,
        receiveNotifyFlag,
        phaseAdjustmentNumber,
        cureType,
        productId
      } = obj;
      const url = toObj[nextStatus];
      let query = `?caseId=${id}&curePlanId=${curePlanId || ""}`;
      if (url === "/cases/create" && nextStatus === "to_submit") {
        if (obj.type === "3") {
          window.open(
            `/stage-adjustment?caseId=${id}&curePlanId=${curePlanId}`
          );
          return;
        }
        if (obj.type === "4") {
          window.open(`/cases/maintain?caseId=${id}&curePlanId=${curePlanId}`);
          return;
        }
        query += `&submitTabKey=0`;
      }
      if (["to_pay_design", "to_pay_production"].includes(url)) {
        query += `&payType=${{ to_pay_design: 0, to_pay_production: 1 }[url]}`;
      }
      if (url === "/cases/maintain") {
        query += `&type=${
          nextStatus === "to_done" ? "done" : "archive"
        }&productId=${productId}`;
      }
      if (
        nextStatus === "to_deliver_model" ||
        nextStatus === "to_deliver_model_remain"
      ) {
        localStorage.setItem(
          "windowName",
          item.name + this.$t("common.common.blxq")
        );
      }
      if (nextStatus === "to_pay_production") {
        localStorage.setItem("payType", "1");
      }
      if (nextStatus === "to_archive") {
        localStorage.setItem("doneType", "archive");
      }
      if (receiveNotifyFlag === "1") {
        localStorage.setItem("casesDetail", JSON.stringify(obj));
        window.open("/cases/allLogistics" + query, "_blank");
      } else if (
        nextProcessingFlag === "1" ||
        this.statusList.includes(nextStatus)
      ) {
        if (
          (nextStatus === "to_confirm_text" ||
            nextStatus === "to_confirm_plan") &&
          cureType == "F"
        ) {
          let type = nextStatus === "to_confirm_text" ? "target" : "cure";
          query += `&schemeType=${type}&cureNoteId=${cureNoteId}`;
          window.open("/exocad?" + query);
          return;
        }
        if (
          nextStatus === "to_confirm_text" ||
          nextStatus === "to_confirm_plan"
        ) {
          let type = nextStatus === "to_confirm_text" ? "target" : "cure";
          query += `&schemeType=${type}&cureNoteId=${cureNoteId}`;
        }
        if (nextStatus === "to_deliver") query += "&delivery=1";
        if (url === "/cases/create" && phaseAdjustmentNumber !== 0) {
          return window.open(
            `/stage-adjustment?caseId=${id}&curePlanId=${curePlanId}`
          );
        }
        window.open(url + query);
      }
    },
    statusImportant() {
      this.statusBtnKey = "1";
      this.typeList = "";
      this.currentPage = 1;
      this.getList();
    },
    confirmSubmit() {
      this.caseRemove();
    },
    caseRemove() {
      if (this.caseId) {
        this.isLoading = true;
        const removeRun =
          this.caseRow.type === "1" ? removeCase : removeTempCase;
        removeRun(
          this.caseRow.type === "1" ? this.caseId : this.caseRow.deleteId
        ).then(() => {
          notifyMsg(this, "success", this.$t("cases.cases.gblysc"));
          this.getList();
          this.getMsgList({
            toUserId: getUserId(),
            status: "to_handle"
          });
          this.isShowConfirm = false;
          this.isLoading = false;
        });
      }
    },
    handleChangeStopData(val) {
      this.stopDataTab = val;
      // 获取当前时间
      const today = moment().format("YYYY-MM-DD");
      // 获取一个月前的时间
      const oneMonthBefore = moment()
        .clone()
        .add(1, "months")
        .format("YYYY-MM-DD");
      // 获取三个月前的时间
      const threeMonthsBefore = moment()
        .clone()
        .add(3, "months")
        .format("YYYY-MM-DD");
      // 获取半年前的时间switch-irdt
      const halfYearBefore = moment()
        .clone()
        .add(6, "months")
        .format("YYYY-MM-DD");

      switch (val) {
        case "0":
          this.serviceDeadlineBegin = "";
          this.serviceDeadlineEnd = "";
          break;
        case "1":
          this.serviceDeadlineBegin = today;
          this.serviceDeadlineEnd = oneMonthBefore;
          break;
        case "2":
          this.serviceDeadlineBegin = today;
          this.serviceDeadlineEnd = threeMonthsBefore;
          break;
        case "3":
          this.serviceDeadlineBegin = today;
          this.serviceDeadlineEnd = halfYearBefore;
      }
      this.currentPage = 1;
      this.getList();
    },
    handleChangeStage(val) {
      if (this.isStageProcessing) return; // 防止重复触发
      this.isStageProcessing = true;
      if (val === "0") {
        this.stageList.forEach(item => {
          item.checked = !this.stageCheckAll;
        });
        this.stageCheckAll = !this.stageCheckAll;
      } else {
        let length = 0;
        this.stageList = this.stageList.map(item => {
          if (item.key === val) {
            item.checked = !item.checked;
          }
          length += item.checked ? 1 : 0;
          return item;
        });

        this.isStageIndeterminate =
          length > 0 && length < this.stageList.length;
        this.stageCheckAll = length === this.stageList.length;
      }
      this.typeList = this.stageList
        .filter(item => item.checked)
        .map(item => item.key);
      this.currentPage = 1;
      this.getList();
      setTimeout(() => {
        this.isStageProcessing = false; // 处理完毕，重置标识
      }, 100); // 假设是100ms后可以再点击
    },
    handleCheckClinic(val) {
      if (this.isClinicProcessing) return; // 防止重复触发
      this.isClinicProcessing = true;
      if (val === "all") {
        this.clinicDoctorList.forEach(item => {
          item.checkbox = !this.checkAll;
        });
        this.checkAll = !this.checkAll;
      } else {
        let length = 0;
        this.clinicDoctorList = this.clinicDoctorList.map(item => {
          if (item.id === val) {
            item.checkbox = !item.checkbox;
          }
          length += item.checkbox ? 1 : 0;
          return item;
        });
        this.isIndeterminate =
          length > 0 && length < this.clinicDoctorList.length;
        this.checkAll = length === this.clinicDoctorList.length;
      }
      this.clinicList = this.clinicDoctorList
        .map(item => {
          if (item.checkbox) {
            return item.id;
          }
        })
        .filter(Boolean);
      this.currentPage = 1;
      this.getList();
      setTimeout(() => {
        this.isClinicProcessing = false; // 处理完毕，重置标识
      }, 100); // 假设是100ms后可以再点击
    },
    handleSizeChange(val) {
      this.currentPageSize = val;
      this.currentPage = 1;
      window.scrollTo({
        top: 0, // 滚动到页面顶部
        behavior: 'smooth' // 平滑滚动
      });
      this.getList();
    },
    handleCurrentChange(val) {
      console.log(111)
      window.scrollTo({
        top: 0, // 滚动到页面顶部
        behavior: 'smooth' // 平滑滚动
      });
      this.currentPage = val;
      this.getList();
    },
    changeStatus(val) {
      this.statusBtnKey = val;
      if (val === "4") {
        this.typeList = ["1", "2", "3", "4"];
        this.stageList = [
          { key: "1", label: "新病例", checked: true, countKey: "toSubmitNew" },
          {
            key: "2",
            label: "重启病例",
            checked: true,
            countKey: "toSubmitReopen"
          },
          {
            key: "3",
            label: "阶段调整",
            checked: true,
            countKey: "toSubmitPA"
          },
          {
            key: "4",
            label: "完成阶段",
            checked: true,
            countKey: "toSubmitCompletion"
          }
        ];
        this.stageCheckAll = true;
      } else {
        this.typeList = "";
      }
      this.currentPage = 1;
      this.getList();
    },
    handleRowClick(obj) {
      const { id, name, curePlanId, nextStatus } = obj;
      let query = `?caseId=${id}&curePlanId=${curePlanId}`;
      nextStatus === "to_deliver_model" ||
      nextStatus === "to_deliver_model_remain"
        ? (query += "")
        : "";
      localStorage.setItem("windowName", name + this.$t("common.common.blxq"));
      window.open("/cases/detail" + query, "_blank");
    },
    async getList(params = {}) {
      this.isLoading = true;
      let createDateBegin;
      let createDateEnd;
      if (this.createTime) {
        createDateBegin = this.createTime[0];
        createDateEnd = this.createTime[1];
      }
      const { data, statistic } = await case_page(
        pickBy({
          doctorId: getUserId() || "",
          sort: this.orderDirect,
          pageNum: this.currentPage,
          pageSize: this.currentPageSize,
          tab: this.statusBtnKey,
          typeList: this.statusBtnKey === "4" ? this.typeList : "",
          productIdList: this.productIdList,
          createDateBegin,
          createDateEnd,
          clinicIdList: this.clinicList,
          serviceDeadlineBegin: this.serviceDeadlineBegin,
          serviceDeadlineEnd: this.serviceDeadlineEnd,
          ...params
        })
      );
      if (!this.clinicList.length) {
        this.tableData = { list: [] };
        this.statisticCount = {};
        this.countDict = {
          "1": "0",
          "2": "0",
          "3": "0",
          "4": "0",
          "5": "0",
          "6": "0",
          "7": "0"
        };
      } else if (!this.productIdList.length) {
        this.tableData = { list: [] };
        this.statisticCount = {};
        this.countDict = {
          "1": "0",
          "2": "0",
          "3": "0",
          "4": "0",
          "5": "0",
          "6": "0",
          "7": "0"
        };
      } else {
        if (this.statusBtnKey !== "4") {
          this.tableData = data;
          this.statisticCount = statistic;
          for (const _key in statistic) {
            this.countDict[this.statusKeyDict[_key]] = statistic[
              _key
            ].toString();
          }
        } else {
          if (!this.typeList.length) {
            this.tableData = { list: [] };
            this.statisticCount = {};
            this.countDict = {
              "1": "0",
              "2": "0",
              "3": "0",
              "4": "0",
              "5": "0",
              "6": "0",
              "7": "0"
            };
          } else {
            this.tableData = data;
            this.statisticCount = statistic;
            for (const _key in statistic) {
              this.countDict[this.statusKeyDict[_key]] = statistic[
                _key
              ].toString();
            }
          }
        }
      }
      this.isLoading = false;
    },
    showConfirm(item) {
      this.caseId = item.id;
      this.caseRow = item;
      this.isShowConfirm = true;
    },
    showArchive(item) {
      this.caseId = item.id;
      this.caseRow = item;
      this.isShowArchive = true;
    },
    confirmHide() {
      this.isShowConfirm = false;
    },
    archiveHide() {
      this.isShowArchive = false;
    },
    archiveSubmit() {
      case_archive({
        id: this.caseId,
        archive: "0"
      })
        .then(() => {
          this.isShowArchive = false;
          notifyMsg(this, "success", "取消成功", 3000);
          this.getList();
        })
        .catch(() => {});
    },
    async getClinicList() {
      // const list = await clinicList();
      const list = await clinic_relevant({ doctorId: getUserId() || "" });
      const obj = {};
      const _idList = [];
      list.forEach(v => {
        obj[v.id] = true;
        v.checkbox = true;
        _idList.push(v.id);
      });
      this.clinicList = _idList;
      this.clinicDoctorList = list;
      this.clinicCount = _idList.length;
      this.checkObj = obj;
    },
    async getProductList() {
      const data = await product_relevant({ doctorId: getUserId() || "" });
      // const data = await PublicProductTypeListByDoctor();
      const list = [];
      const _idList = [];
      data.forEach(v => {
        v.productList.forEach(v1 => {
          list.push([v.id, v1.id]);
          _idList.push(v1.id);
        });
      });
      this.productCount = _idList.length;
      this.productIdList = _idList;
      this.showProductList = list;
      this.productList = data;
    },
    clickNode($event, node) {
      event.stopPropagation();
      event.preventDefault();
      $event.target.parentElement.parentElement.firstElementChild.click();
    },
    toggleDropdown() {
      // this.$refs.dropdown.show()
      this.$refs.dropdown.showPopper = true;
    },
    toggleDropdownFn(list) {
      const productList = [];
      if (list?.length) {
        list.forEach(v => {
          productList.push(v[1]);
        });
      }
      this.showProductList = list;
      this.productIdList = productList;
      this.currentPage = 1;
      this.getList();
      this.$refs.dropdown.show();
    },
    jumpCreate() {
      sessionStorage.removeItem('createUserForm')
      window.open(`/cases/create?submitTabKey=5`);
    },
    reload() {
      this.queryCasesList();
    },
    async queryCasesList() {
      this.isLoading = true;

      const conditionForQuery =
        sessionStorage.getItem("conditionForQuery") || "";
      await this.getList({ conditionForQuery });
      this.isLoading = false;
      sessionStorage.removeItem("conditionForQuery");
    },
    openDia() {
      this.$refs["exBirdie"].openDia();
    }
  },
  // beforeUpdate() {
  //   this.$nextTick(() => {
  //     this.$refs.tableRef.doLayout();
  //   });
  // },
  // updated() {
  //   // tableRef是表格的ref属性值
  //   if (this.$refs.tableRef && this.$refs.tableRef.doLayout) {
  //     this.$refs.tableRef.doLayout();
  //   }
  // },
  async created() {
    this.HANDLE_MSG();

    let query = this.$route.query;
    this.statusBtnKey = query.tab || "0";
    document.title = this.$t("cases.cases.title");
    await this.getClinicList();
    await this.getProductList();
    getDictList("case.next_status").then(data => {
      const obj = {};
      data.forEach(item => {
        obj[item.key] = item.value;
      });
      this.nextStatusObj = obj;
    });
    getDictList("case.next_status_button").then(data => {
      const obj = {};
      data.forEach(item => {
        obj[item.key] = item;
      });
      this.casesNextObj = obj;
    });
    this.queryCasesList();
    // document.addEventListener("visibilitychange", () => {
    //   if (document.visibilityState !== "hidden") {
    //     this.queryCasesList();
    //   }
    // });
  }
};
</script>

<template>
  <div class="cases_box">
    <div class="cases_con posr">
      <div class="case_table_content">
        <div class="status_list_box">
          <div class="status_list_left">
            <div
              :class="
                `status_box ${statusBtnKey === '1' ? 'status_box_active' : ''}`
              "
              @click="statusImportant"
            >
              关注 {{ countDict["1"] }}
            </div>
            <div style="padding: 4px 0;">
              <div class="status_split" />
            </div>
            <div
              v-for="item in statusBtnList"
              :key="item.key"
              :class="
                `status_box ${
                  item.key === statusBtnKey ? 'status_box_active' : ''
                }`
              "
              @click="changeStatus(item.key)"
            >
              {{ item.label }} {{ countDict[item.key] }}
            </div>
          </div>
          <div class="status_list_right">
            <div class="right_box" style="margin-right: 20px;" @click="openDia">
              <div class="right_icon1" />
              待寄送
              <span
                v-if="statisticCount['toDeliver']"
                style="margin-left: 2px;"
              >
                {{ statisticCount["toDeliver"] }}</span
              >
            </div>
            <div class="right_box create_case" @click="jumpCreate">
              <div class="right_icon2" />
              新建病例
            </div>
          </div>
        </div>
        <div class="case_table">
          <el-table
            :key="statusBtnKey"
            ref="tableRef"
            :data="tableData.list"
            row-class-name="case-row-class"
          >
            <el-table-column fixed="left" width="220">
              <template #header>
                <div class="table-head-text" style="padding-left: 0.05rem;">
<!--                  <div class="case_list_icon" />-->
                  患者信息
                </div>
              </template>
              <template slot-scope="scope">
                <div
                  class="case_list_title"
                  @click="handleRowClick(scope.row.caseList[0])"
                >
                  <div
                    :class="
                      scope.row.follow === '1'
                        ? 'case_important'
                        : 'case_no_important'
                    "
                    @click.stop="changeFollow(scope.row)"
                  />
                  <el-avatar
                    :src="$PicPrefix + scope.row.photo"
                    style="margin-right: 10px;width: 0.44rem;height: 0.44rem;"
                  ></el-avatar>
                  <div>
                    <div class="case_name">
                      <el-tooltip
                        :content="scope.row.name"
                        class="item"
                        effect="dark"
                        placement="top"
                      >
                        <span
                          class="txt-ellipsis"
                          style="max-width: 100px;display: inline-block"
                        >
                          {{ scope.row.name }}
                        </span>
                      </el-tooltip>
                    </div>

                    <div class="case_name" style="color: #666666">
                      {{ scope.row.age }}岁/{{
                        scope.row.sex === "1" ? "男" : "女"
                      }}
                    </div>
                  </div>
                </div>
              </template>
            </el-table-column>
            <el-table-column label="病例号" width="120">
              <template slot="header" slot-scope="scope">
                <div class="table-head-text">病例号</div>
              </template>
              <template slot-scope="scope">
                <div>
                  <div
                    v-for="(item, index) in scope.row.caseList"
                    :key="item.id + index"
                    class="case-column-box"
                    @click="handleRowClick(item)"
                  >
                    {{ item.caseNumber }}
                  </div>
                </div>
              </template>
            </el-table-column>
            <el-table-column label="产品" width="280">
              <template #header>
                <div class="table-head-text" style="position:relative;">
                  产品
                  <el-popover placement="bottom-start">
                    <el-cascader
                      :options="productList"
                      :props="{
                        multiple: true,
                        expandTrigger: 'hover',
                        // checkStrictly: true,
                        label: 'name',
                        value: 'id',
                        children: 'productList'
                      }"
                      :value="showProductList"
                      class="select_product"
                      clearable
                      collapse-tags
                      style="width: 230px;"
                      @change="toggleDropdownFn"
                      ><span
                        slot-scope="{ node, data }"
                        style="margin-left: -10px;padding-left:10px;display:block;"
                        @click="clickNode($event, node)"
                        >{{ data.name }}</span
                      ></el-cascader
                    >
                    <div
                      slot="reference"
                      class="el-dropdown-link "
                      :class="
                        productIdList.length !== productCount
                          ? 'filter-icon_active'
                          : 'filter-icon'
                      "
                    />
                  </el-popover>
                </div>
              </template>
              <template slot-scope="scope">
                <div>
                  <div
                    v-for="(item, index) in scope.row.caseList"
                    :key="item.id + index"
                    class="case-column-box "
                    style="max-width: 280px;"
                    @click="handleRowClick(item)"
                  >
                    <span class="txt-ellipsis2" style="max-width: 280px;">{{
                      item.productName
                    }}</span>
                  </div>
                </div>
              </template>
            </el-table-column>
            <el-table-column width="130">
              <template #header>
                <div class="title-header-box table-head-text">
                  创建日期
                  <el-popover
                    ref="popoverRef"
                    :popper-class="'custom-popover'"
                    placement="bottom-start"
                  >
                    <el-date-picker
                      ref="datePickerRef"
                      v-model="createTime"
                      class="date_style"
                      end-placeholder="结束日期"
                      format="yyyy-MM-dd"
                      range-separator="至"
                      start-placeholder="开始日期"
                      type="daterange"
                      value-format="yyyy-MM-dd"
                      @change="handleDateChange"
                    >
                    </el-date-picker>
                    <span slot="reference" class="el-dropdown-link">
                      <i
                        class="el-icon-date"
                        @click="toggleDatePicker"
                        :style="createTime ? { color: '#fcc80e' } : {}"
                      />
                    </span>
                  </el-popover>
                  <div class="header-box" @click="changeSort">
                    <i
                      :class="
                        `el-icon-caret-top ${
                          sort === 'ascend' ? 'sort_active' : ''
                        }`
                      "
                    />
                    <i
                      :class="
                        `el-icon-caret-bottom ${
                          sort === 'descend' ? 'sort_active' : ''
                        }`
                      "
                    />
                  </div>
                </div>
              </template>
              <template slot-scope="scope">
                <div>
                  <div
                    v-for="(item, index) in scope.row.caseList"
                    :key="item.id + index"
                    class="case-column-box"
                    @click="handleRowClick(item)"
                  >
                    {{ item.createDatetime }}
                  </div>
                </div>
              </template>
            </el-table-column>

            <el-table-column
              v-if="statusBtnKey === '4'"
              label="阶段"
              width="100"
            >
              <template #header>
                <div class="table-head-text">
                  阶段
                  <el-dropdown
                    :hide-on-click="false"
                    placement="bottom-start"
                    trigger="click"
                    @command="handleChangeStage"
                  >
                    <div
                      class="el-dropdown-link"
                      :class="
                        typeList.length < 4 && typeList.length > 0
                          ? 'filter-icon_active'
                          : 'filter-icon'
                      "
                    />
                    <el-dropdown-menu slot="dropdown">
                      <el-dropdown-item command="0">
                        <el-checkbox
                          :indeterminate="isStageIndeterminate"
                          :value="stageCheckAll"
                        >
                          <div
                            style="display: flex;justify-content: space-between;width: 85px;"
                          >
                            <span>全部</span>
                            <span>{{ statisticCount["toSubmitTotal"] }}</span>
                          </div>
                        </el-checkbox>
                      </el-dropdown-item>
                      <el-dropdown-item
                        v-for="item in stageList"
                        :key="item.key"
                        :command="item.key"
                      >
                        <el-checkbox :value="item.checked">
                          <div
                            style="display: flex;justify-content: space-between;width: 85px;"
                          >
                            <span>{{ item.label }}</span>
                            <span>{{ statisticCount[item.countKey] }}</span>
                          </div>
                        </el-checkbox>
                      </el-dropdown-item>
                    </el-dropdown-menu>
                  </el-dropdown>
                </div>
              </template>

              <template slot-scope="scope">
                <div>
                  <div
                    v-for="(item, index) in scope.row.caseList"
                    :key="item.id + index"
                    class="case-column-box txt-ellipsis"
                    style="width: 160px;"
                    @click="handleRowClick(item)"
                  >
                    {{ stageDict[item.type] }}
                  </div>
                </div>
              </template>
            </el-table-column>
            <el-table-column label="状态" width="150">
              <template slot="header" slot-scope="scope">
                <div class="table-head-text">状态</div>
              </template>
              <template slot-scope="scope">
                <div>
                  <div
                    v-for="(item, index) in scope.row.caseList"
                    :key="item.id + index"
                    class="case-column-box"
                    style="color: #FCC80E"
                    @click="handleRowClick(item)"
                  >
                    {{ nextStatusObj[item.nextStatus] }}
                  </div>
                </div>
              </template>
            </el-table-column>
            <el-table-column label="机构" width="300">
              <template #header>
                <div class="table-head-text">
                  机构
                  <el-dropdown
                    :hide-on-click="false"
                    placement="bottom-start"
                    trigger="click"
                    @command="handleCheckClinic"
                  >
                    <div
                      class="el-dropdown-link "
                      :class="
                        clinicList.length === 0 ||
                        clinicList.length !== clinicCount
                          ? 'filter-icon_active'
                          : 'filter-icon'
                      "
                    />
                    <el-dropdown-menu slot="dropdown">
                      <el-dropdown-item command="all">
                        <el-checkbox
                          :indeterminate="isIndeterminate"
                          :value="checkAll"
                        >
                          全部
                        </el-checkbox>
                      </el-dropdown-item>
                      <el-dropdown-item
                        v-for="item in clinicDoctorList"
                        :key="item.id"
                        :command="item.id"
                      >
                        <el-checkbox :value="item.checkbox">
                          <div class="txt-ellipsis" style="width: 300px;">
                            {{ item.name }}
                          </div>
                        </el-checkbox>
                      </el-dropdown-item>
                    </el-dropdown-menu>
                  </el-dropdown>
                </div>
              </template>
              <template slot-scope="scope">
                <div>
                  <div
                    v-for="(item, index) in scope.row.caseList"
                    :key="item.id + index"
                    class="case-column-box txt-ellipsis2"
                    style="max-width: 300px"
                    @click="handleRowClick(item)"
                  >
                    <span class="txt-ellipsis2" style="max-width: 300px">{{
                      item.clinicName
                    }}</span>
                  </div>
                </div>
              </template>
            </el-table-column>
            <el-table-column width="150">
              <template #header>
                <div class="table-head-text">
                  服务截止日期
                  <el-dropdown
                    :hide-on-click="false"
                    placement="bottom-start"
                    trigger="click"
                    @command="handleChangeStopData"
                  >
                    <div
                      class="el-dropdown-link"
                      :class="
                        stopDataTab !== '0'
                          ? 'filter-icon_active'
                          : 'filter-icon'
                      "
                    />
                    <el-dropdown-menu slot="dropdown">
                      <el-dropdown-item
                        :class="stopDataTab === '0' ? 'stop_data_active' : ''"
                        command="0"
                        >全部</el-dropdown-item
                      >
                      <el-dropdown-item
                        :class="stopDataTab === '1' ? 'stop_data_active' : ''"
                        command="1"
                        >一个月内到期</el-dropdown-item
                      >
                      <el-dropdown-item
                        :class="stopDataTab === '2' ? 'stop_data_active' : ''"
                        command="2"
                        >三个月内到期</el-dropdown-item
                      >
                      <el-dropdown-item
                        :class="stopDataTab === '3' ? 'stop_data_active' : ''"
                        command="3"
                        >半年内到期</el-dropdown-item
                      >
                    </el-dropdown-menu>
                  </el-dropdown>
                </div>
              </template>
              <template slot-scope="scope">
                <div>
                  <div
                    v-for="(item, index) in scope.row.caseList"
                    :key="item.id + index"
                    class="case-column-box "
                    @click="handleRowClick(item)"
                  >
                    <span class="txt-ellipsis2" style="max-width: 300px">{{
                      item.serviceDeadline
                    }}</span>
                  </div>
                </div>
              </template>
            </el-table-column>
            <el-table-column label="iRDT协助" width="100">
              <template slot="header" slot-scope="scope">
                <div class="table-head-text">iRDT协助</div>
              </template>
              <template slot-scope="scope">
                <div>
                  <div
                    v-for="(item, index) in scope.row.caseList"
                    :key="item.id + index"
                    class="case-column-box"
                    @click="handleRowClick(item)"
                  >
                    <div
                      v-if="item.helpFlag === '1'"
                      :class="item.helpFlag ? 'irdt-text' : ''"
                    >
                      iRDT
                    </div>
                  </div>
                </div>
              </template>
            </el-table-column>
            <el-table-column label="备注" width="300">
              <template slot="header" slot-scope="scope">
                <div class="table-head-text">备注</div>
              </template>
              <template slot-scope="scope">
                <div>
                  <div
                    v-for="(item, index) in scope.row.caseList"
                    :key="item.id + index"
                    class="case-column-box"
                    style="width: 280px;"
                    @click="handleRowClick(item)"
                  >
                    <span
                      :title="item.remark"
                      class="txt-ellipsis2"
                      style="width: 280px"
                    >
                      {{ item.remark }}
                    </span>
                    <!--                    </el-tooltip>-->
                  </div>
                </div>
              </template>
            </el-table-column>
            <el-table-column
              v-if="statusBtnKey === '7'"
              label="归档原因"
              width="300"
            >
              <template slot="header" slot-scope="scope">
                <div class="table-head-text">归档原因</div>
              </template>
              <template slot-scope="scope">
                <div>
                  <div
                    v-for="(item, index) in scope.row.caseList"
                    :key="item.id + index"
                    :title="item.archiveReason"
                    class="case-column-box "
                    @click="handleRowClick(item)"
                    style="width: 280px;"
                  >
                    <span
                      :title="item.remark"
                      class="txt-ellipsis2"
                      style="width: 280px"
                    >
                      {{ item.archiveReason }}
                    </span>
                  </div>
                </div>
              </template>
            </el-table-column>
            <el-table-column fixed="right" width="190">
              <template slot="header" slot-scope="scope">
                <div class="table-head-text" style="margin-left: 12px;">
                  操作
                </div>
              </template>
              <template slot-scope="scope">
                <div>
                  <div
                    v-for="(item, index) in scope.row.caseList"
                    :key="item.id + index"
                    class="case_operate"
                    @click="handleRowClick(item)"
                  >
                    <div
                      v-if="
                        statusBtnKey === '7' ||
                          (statusBtnKey === '0' && item.archive === '1')
                      "
                      class="btn-box"
                      @click.stop="showArchive(item)"
                    >
                      取消归档
                    </div>
                    <div
                      v-if="
                        item.archive !== '1' &&
                          !item.nextStatus &&
                          statusBtnKey === '4'
                      "
                      class="btn-box"
                      @click.stop="jumpCaseCreate(item)"
                    >
                      提交病例
                    </div>
                    <div
                      v-if="
                        item.archive !== '1' &&
                          casesNextObj[item.nextStatus] &&
                          statusBtnKey !== '7'
                      "
                      class="btn-box"
                      @click.stop="toEditCases(item, scope.row)"
                    >
                      {{ casesNextObj[item.nextStatus].value }}
                    </div>
                    <div
                      v-if="
                        item.archive !== '1' &&
                          statusList.includes(item.nextStatus) &&
                          statusBtnKey !== '7'
                      "
                      class="btn-box"
                      @click.stop="toEditCases(item, scope.row)"
                    >
                      {{ $t("cases.cases.qsh") }}
                    </div>
                    <i
                      v-if="
                        item.archive !== '1' &&
                          (item.nextStatus === 'to_submit' ||
                            !item.nextStatus) &&
                          statusBtnKey !== '7' &&
                          item.type !== '2'
                      "
                      class="el-icon-delete"
                      @click.stop="showConfirm(item)"
                    />
                  </div>
                </div>
              </template>
            </el-table-column>
          </el-table>
          <div v-if="!tableData.list.length" class="default_case_page">
            <div class="default_case_icon">
              <div>
                暂无数据
              </div>
            </div>
          </div>
        </div>

        <el-pagination
          v-if="tableData.list.length"
          :current-page.sync="currentPage"
          :page-size="currentPageSize"
          :page-sizes="[20, 40, 60, 80, 100]"
          :total="tableData.total"
          background
          class="pagination_bot"
          layout="total, sizes, prev, pager, next, jumper"
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
        />
      </div>
    </div>
    <ExBirdie ref="exBirdie" @reload="reload" />
    <Confirm
      v-show="isShowConfirm"
      :confirmObj="confirmObj"
      @confirmHide="confirmHide"
      @confirmSubmit="confirmSubmit"
    />
    <FullLoading v-show="isLoading" />
    <Confirm
      v-show="isShowArchive"
      :confirmObj="archiveConfirmObj"
      @confirmHide="archiveHide"
      @confirmSubmit="archiveSubmit"
    />
  </div>
</template>

<style lang="scss" scoped>
.cases_con {
  width: 16rem;
  margin: 0 auto;
  //position: relative;
  //top: 30px;
  //padding-top: 25px;

  .case_table_content {
    background-color: #fff;
    border-radius: 6px;
    padding: 24px;
    .case_table {
      //max-height: 600px;
      font-size: 14px;
      overflow-y: auto;
      .default_case_page {
        overflow: hidden;
        height: 500px;
        width: 100%;
        .default_case_icon {
          margin: 150px auto auto;
          width: 207px;
          height: 187px;
          background-image: url("../../../common/imgs/home-page/no_case.png");
          background-size: cover;
          background-repeat: no-repeat;
          position: relative;
          > div {
            width: 100%;
            text-align: center;
            color: #999999;
            font-size: 14px;
            position: absolute;
            bottom: -30px;
          }
        }
      }
    }
    .table-head-text {
      color: #333333;
      font-size: 16px;
      display: flex;
      justify-items: center;
      align-items: center;
    }
    .status_list_box {
      display: flex;
      height: 54px;
      font-size: 16px;
      text-align: center;
      color: #333333;
      justify-content: space-between;
      margin-bottom: 20px;

      .status_list_left {
        display: flex;
        margin-right: 31px;

        .status_split {
          width: 1px;
          height: 32px;
          background: #d8d8d8;
        }
        .status_box {
          line-height: 40px;
          padding-left: 15px;
          padding-right: 15px;
          height: 40px;
          margin-right: 4px;
          cursor: pointer;
          font-size: 16px;
          color: #333333;
        }
        .status_box_active {
          border-radius: 4px;
          //background-color: #fcc80e;
          color: #fcc80e;
          //transition: 0.3s all;
          font-weight: bold;
        }
      }
      .status_list_right {
        display: flex;
        .right_box {
          width: 158px;
          height: 48px;
          background: #ffffff;
          border-radius: 4px;
          border: 1px solid #bbbbbb;
          cursor: pointer;
          display: flex;
          padding: 13px;
          padding-left: 30px;
          align-items: center;
          .right_icon1 {
            width: 20px;
            height: 20px;
            background-image: url(../../../common/imgs/case-list/SF_case.png);
            background-size: cover;
            background-repeat: no-repeat;
            margin-right: 10px;
          }
          .right_icon2 {
            width: 20px;
            height: 20px;
            background-image: url(../../../common/imgs/case-list/create_case.png);
            background-size: cover;
            background-repeat: no-repeat;
            margin-right: 10px;
          }
        }
        .create_case {
          border: none;
          background-color: #fcc80e;
        }
      }
    }
    ::v-deep .case_list_title {
      display: flex;
      align-items: center;
      padding-left: 10px;
      padding-right: 10px;
      height: 104px;
      .case_no_important {
        width: 32px;
        height: 32px;
        background-image: url("../../../../src/common/imgs/case-list/case_no_important.png");
        background-size: cover;
        background-repeat: no-repeat;
        margin-right: 12px;
        cursor: pointer;
      }
      .case_important {
        width: 32px;
        height: 32px;
        background-image: url("../../../../src/common/imgs/case-list/case_important.png");
        background-size: cover;
        background-repeat: no-repeat;
        margin-right: 12px;
        cursor: pointer;
      }
      .el-avatar {
        img {
          display: inline-block !important;
          margin: auto !important;
          width: 44px !important;
          height: 44px !important;
        }
      }
    }
    .irdt-text {
      width: 72px;
      height: 24px;
      background: #38383a;
      color: #fff;
      text-align: center;
      border-radius: 15px;
      line-height: 26px;
      font-size: 16px;
    }
    .case_operate {
      height: 104px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      justify-items: center;
      padding-left: 20px;
      padding-right: 5px;
      //width: 190px;
      .btn-box {
        width: 124px;
        height: 36px;
        line-height: 36px;
        text-align: center;
        background-color: #fcc80e;
        border-radius: 4px;
        color: #333333;
        cursor: pointer;
        font-size: 14px;
        //margin-right: 20px;
      }
      i {
        cursor: pointer;
        font-size: 20px;
      }
    }

    .pagination_bot {
      margin-top: 20px;
      text-align: center;
    }
  }
}

.title-header-box {
  display: flex;
  align-items: center;
  ::v-deep .el-dropdown {
    margin-top: 4px;
  }

  .header-box {
    display: flex;
    flex-direction: column;
    position: relative;
    //width: 24px;
    height: 34px;
    cursor: pointer;
    color: #adadad !important;
    .el-icon-caret-top {
      position: absolute;
      top: 3px;
    }
    .el-icon-caret-bottom {
      position: absolute;
      bottom: 3px;
    }
  }
}
.sort_active {
  color: #fcc80e !important;
  transition: 0.2s all;
}
.case-column-box {
  height: 104px;
  display: flex;
  align-items: center;
  word-break: break-word;
  padding-left: 10px;
  padding-right: 10px;
}

.stop_data_active {
  color: #fcc80e !important;
  background-color: #f9f9f9 !important;
  transition: 0.2s all;
}

::v-deep.pagination_bot .el-pager li.active {
  color: #fff !important;
}

::v-deep.pagination_bot .el-pager li:hover {
  color: #fcc80e !important;
}
::v-deep.pagination_bot .el-pager li.active:hover {
  color: #fff !important;
}
::v-deep.el-pagination .el-select .el-input__inner:hover {
  border-color: #fcc80e !important;
}

/* scoped 样式修改 v-loading 的颜色 */
::v-deep .el-loading-mask .el-loading-spinner {
  border-top-color: #fcc80e !important;
}
::v-deep .case_table .el-table__row {
  height: 104px !important;
  color: #333333 !important;
  font-size: 16px;
  cursor: pointer;
  ::v-deep .el-table__cell {
    ::v-deep.cell {
      padding-left: 0 !important;
      padding-right: 0 !important;
    }
  }
}
::v-deep .case_table .el-table__row td {
  padding-bottom: 0 !important;
  padding-top: 0 !important;
}
::v-deep .case_table .el-table__header th {
  background: #f4f4f4; /* 自定义背景色 */
  height: 56px;
}
::v-deep .el-dropdown-menu__item:hover {
  color: #fcc80e;
  background-color: #f9f9f9;
  transition: 0.2s all;
}
::v-deep .el-dropdown-menu__item:focus {
  background-color: #f9f9f9;
  color: #fcc80e;
  transition: 0.2s all;
}

/* 设置选中行的背景颜色 */
::v-deep .el-table .el-table__body .el-table__row.selected:hover {
  background-color: #fcc80e !important; /* 选中行悬停时的背景色 */
}

/* 修改半选时的勾选框颜色 */
::v-deep .el-dropdown-menu__item .is-indeterminate .el-checkbox__inner {
  background-color: #fcc80e !important; /* 半选时勾选框的背景颜色 */
  border-color: #fcc80e !important; /* 半选时勾选框的边框颜色 */
}
::v-deep .el-dropdown-menu__item {
  height: 35px !important;
  place-content: center;
}
::v-deep .el-dropdown-menu__item .el-checkbox {
  display: flex !important;
  align-items: center !important;
}
::v-deep .el-cascader__dropdown .is-indeterminate .el-checkbox__inner {
  background-color: #fcc80e !important; /* 半选时勾选框的背景颜色 */
  border-color: #fcc80e !important; /* 半选时勾选框的边框颜色 */
}
::v-deep
  .el-cascader__dropdown
  .el-cascader-panel
  .el-cascader-menu
  .el-cascader-menu__wrap
  .el-cascader-menu__list
  .el-cascader-node
  .is-indeterminate
  .el-checkbox__inner {
  background-color: #fcc80e !important; /* 设置背景颜色 */
  border-color: #fcc80e !important; /* 设置边框颜色 */
}
::v-deep
  .el-date-range-picker.el-picker-panel__body-wrapper.el-picker-panel__body
  .el-date-range-picker__time-header {
  display: none !important;
}
.case_list_icon {
  cursor: pointer;
  width: 24px;
  height: 24px;
  background-image: url("../../../common/imgs/case-list/case_list.png");
  background-size: cover;
  background-repeat: no-repeat;
}
.filter-icon {
  cursor: pointer;
  width: 24px;
  height: 24px;
  background-image: url("../../../common/imgs/case-list/case_filter_icon.png");
  background-size: cover;
  background-repeat: no-repeat;
}

.filter-icon_active {
  cursor: pointer;
  width: 24px;
  height: 24px;
  background-image: url("../../../common/imgs/case-list/filter-icon_active.png");
  background-size: cover;
  background-repeat: no-repeat;
}

::v-deep
  .el-cascader-node.is-checked
  .el-checkbox__input.is-indeterminate
  .el-checkbox__inner {
  background-color: #fcc80e !important;
  border-color: #fcc80e !important;
}

::v-deep .el-dropdown-menu {
  padding: 0 !important;
}

/* 全局样式文件 */
::v-deep .select_product .el-cascader .el-input__inner {
  height: 60px !important; /* 您想要的高度 */
  line-height: 60px !important; /* 保持垂直居中 */
}

::v-deep .select_product .el-cascader .el-input__icon {
  line-height: 60px !important; /* 调整图标的行高以垂直居中 */
}

::v-deep .title-header-box .el-popover .el-popper.custom-popover {
  z-index: 15000 !important; /* 您想要的 z-index 值 */
}

::v-deep .case-column-box .remark_text {
  max-width: 400px !important;
  white-space: normal !important;
}
.el-icon-date {
  cursor: pointer;
  margin-left: 4px;
  margin-right: 4px;
  font-size: 24px;
  color: #bbbbbb;
}
::v-deep .el-table .cell{
  padding-left: 0;
  padding-right: 0;
}


::v-deep .el-table__empty-block {
  height: 0!important;
  min-height: 0 !important;
}
</style>
