<template>
  <div
      :style="`padding: ${isDatabase ? '0 0.24rem' : '0.3rem'}`"
      class="preview"
  >
    <div v-if="!isLoading" class="preview_tip">
      <template v-if="isCompleteData.groupCount > 0">
        <h5 class="tip_h5">
          <span class="tip_icon"></span>{{ $t("cases.createPreview.wwcts") }}
        </h5>
        <ul class="p_tip_ul">
          <li
              v-if="isCompleteData.groupOneNameList.length > 0"
              class="p_tip_li"
          >
            <h5 class="p_tip_l_h">
              {{ $t("cases.createPreview.jzzl") }}
            </h5>
            <div class="p_tip_l_box">
              <span
                  v-for="item in isCompleteData.groupOneNameList"
                  :key="`${$t('cases.createPreview.jzzl')}_${item}`"
                  @click="groupClick(item, 1)"
              >{{ item }}</span
              >
            </div>
          </li>
          <li class="p_tip_li">
            <h5 class="p_tip_l_h">
              {{ $t("cases.createPreview.yxzlsm") }}
            </h5>
            <div class="p_tip_l_box">
              <span
                  v-for="item in isCompleteData.groupTwoNameList"
                  :key="`${$t('cases.createPreview.yxzlsm')}_${item}`"
                  :data-tit="item"
                  data-type="2"
                  @click="groupClick(item, 2)"
              >{{ item }}</span
              >
            </div>
          </li>
        </ul>
      </template>
      <template v-else>
        <div class="complete-success">
          <div v-if="!isDatabase" class="complete-success-title">
            <img
                alt=""
                src="../../../../../common/imgs/case/case_preview_success.png"
            />
            {{ $t("cases.createPreview.bltxwz") }}
          </div>
          <div v-if="!isDatabase" class="preview-patient-info">
            <div class="patient-avatar">
              <img
                  :src="
                  'photo' in userInfo ? $PicPrefix + userInfo.photo : defPhoto
                "
                  alt=""
              />
            </div>
            <div class="patient-information">
              <div class="patient-name">{{ caseDetail.userName }}</div>
              <div class="case-number">
                {{
                  `${$t("casesDetail.casesChildren.casesDoKeep.blh")}：` +
                  caseDetail.caseNumber
                }}
              </div>
              <div class="clinical-preferences">
                {{
                  `${$t("casesDetail.casesDetail.lcqk")}：`
                }}<span>{{ caseDetail.clinicInfo }}</span>
              </div>
            </div>
          </div>
          <div v-if="previewCom.includes('table')" class="preview-table">
            <div class="result-subtitle result-title">
              <div class="label">1.{{ $t("stageAdjust.dqjzqthqk") }}</div>
              <div class="value">{{ diaKeyObj[columnData["fitStatus"]] }}</div>
            </div>
            <div class="result-title">
              <div>2.{{ $t("stageAdjust.lcjc") }}</div>
              <div class="result-subtitle">
                <div class="label">2.1 {{ $t("stageAdjust.bcfkspdbs") }}</div>
                <div class="value">
                  <p>
                    {{
                      `${$t("stageAdjust.shd")}${columnData["maxillaryStep"] ||
                      ""}${$t("stageAdjust.bs")}`
                    }}
                  </p>
                  <p>
                    {{
                      `${$t("stageAdjust.xhd")}${columnData["mandibularStep"] ||
                      ""}${$t("stageAdjust.bs")}`
                    }}
                  </p>
                </div>
              </div>
              <div class="result-subtitle">
                <div class="label">2.2 {{ $t("stageAdjust.fjtz") }}</div>
                <div class="value">
                  {{ diaKeyObj[columnData["accessoryAdjustment"]] }}
                </div>
              </div>
              <ChildrenTeeth
                  v-if="
                  columnData['accessoryAdjustment'] ===
                    'reserved_specified_attachment'
                "
                  :commonTeeth="commonTeeth"
                  :patientType="patientType[caseDetail['cureType']]"
                  :readOnly="true"
                  :teethType="6"
                  class="mb40 mt40"
              />
              <div class="result-subtitle">
                <div class="label">2.3 {{ $t("stageAdjust.yljx") }}</div>
                <div class="value">
                  {{ diaKeyObj[columnData["dentitionSpace"]] }}
                </div>
              </div>
              <DentitionSpace
                  v-if="columnData['dentitionSpace'] === 'have'"
                  :commonTeeth="commonTeeth"
                  :cureType="caseDetail['cureType']"
                  :readOnly="true"
                  :uploadIntervalList="uploadIntervalList"
              />
              <div v-if="!['C'].includes(caseDetail['cureType'])" class="result-subtitle">
                <div class="label">2.4 {{ $t("stageAdjust.nxhgjsfyc") }}</div>
                <div class="value">
                  {{ diaKeyObj[columnData["tmjIssues"]] }}
                </div>
              </div>
              <div
                  v-if="columnData['tmjIssues'] === 'true'"
                  class="tmj-issues-note"
                  style="padding-left: 2.5rem;margin: 0.24rem 0;"
              >
                {{ columnData["tmjIssuesNote"] }}
              </div>
              <div class="result-subtitle">
                <div class="label">{{ !['C'].includes(caseDetail['cureType']) ? '2.5' : '2.4' }}
                  {{ $t("stageAdjust.qtjc") }}
                </div>
                <div class="value">
                  {{ columnData["otherTests"] }}
                </div>
              </div>
            </div>
            <div class="result-title">
              <div>3.{{ $t("stageAdjust.qygjddsjyq") }}</div>
              <div class="result-subtitle">
                <div class="label">3.1 {{ $t("stageAdjust.bkydyc") }}</div>
                <div v-if="'nonMoveableTeeth' in columnData" class="value">
                  {{
                    dictObj["non_moveable_teeth"].find(
                        (item) => item.key === columnData["nonMoveableTeeth"]
                    ).value
                  }}
                </div>
              </div>
              <ChildrenTeeth
                  v-if="columnData['nonMoveableTeeth'] === 'true'"
                  :commonTeeth="commonTeeth"
                  :patientType="patientType[caseDetail['cureType']]"
                  :readOnly="true"
                  :teethType="2"
                  class="mb40 mt40"
              />
              <div class="result-subtitle">
                <div class="label">3.2 {{ $t("stageAdjust.bksjfjyc") }}</div>
                <div
                    v-if="'notAvailableAttachments' in columnData"
                    class="value"
                >
                  {{
                    dictObj["not_available_for_attachments"].find(
                        (item) =>
                            item.key === columnData["notAvailableAttachments"]
                    ).value
                  }}
                </div>
              </div>
              <ChildrenTeeth
                  v-if="columnData['notAvailableAttachments'] === 'true'"
                  :commonTeeth="commonTeeth"
                  :patientType="patientType[caseDetail['cureType']]"
                  :readOnly="true"
                  :teethType="3"
                  class="mb40 mt40"
              />
            </div>
            <div class="result-subtitle result-title">
              <div class="label">4.{{ $t("stageAdjust.hxjzyq") }}</div>
              <div class="value">
                {{ columnData["subsequentOrthodonticRequirements"] }}
              </div>
            </div>
          </div>
          <div v-if="previewCom.includes('image')" class="preview-img-modal">
            <div class="pic-title">{{ $t("stageAdjust.pdjzqbthbwzp") }}</div>
            <div v-if="echoNotFitPicture.length" class="notfit-pictures">
              <div
                  v-for="(item, index) in echoNotFitPicture"
                  v-if="item.nanoId"
                  :key="index"
                  class="pic-item"
              >
                <div
                    class="after-upload"
                    @click="
                    showCarousel({
                      filed: item.filed,
                      text: `notFitPicture${index}`,
                      index: getPicIndex(item.filed),
                    })
                  "
                >
                  <img
                      v-if="item.nanoId"
                      :src="
                      `${$PicPrefix}${item.nanoId}?imageView2/0/w/360/h/250`
                    "
                      :style="{
                      transform: `rotate(${item['degree']}deg) scaleY(${
                        item['vertical'] ? -1 : 1
                      }) scaleX(${item['horizontal'] ? -1 : 1})`,
                    }"
                      alt=""
                      class="after-upload-img"
                  />
                  <p class="pic_look">
                    <img
                        alt=""
                        src="/static/images/createCases/check_icon.png"
                    />
                    <span>{{ $t("cases.createImage.ck") }}</span>
                  </p>
                </div>
              </div>
            </div>
            <div class="pic-title">{{ $t("stageAdjust.kqnzp") }}</div>
            <div class="face-pic-container">
              <div
                  v-for="(item, index) in facePic"
                  v-if="columnData[item.id] && columnData[item.id].nanoId"
                  :key="index"
                  class="pic-item"
              >
                <div
                    class="after-upload"
                    @click="
                    showCarousel({
                      filed: item.id,
                      text: item.name,
                      index: getPicIndex(item.id),
                    })
                  "
                >
                  <img
                      v-if="columnData[item.id] && columnData[item.id].nanoId"
                      :src="
                      `${$PicPrefix}${
                        columnData[item.id].nanoId
                      }?imageView2/0/w/360/h/250`
                    "
                      :style="{
                      transform: `rotate(${
                        columnData[item.id]['degree']
                      }deg) scaleY(${
                        columnData[item.id]['vertical'] ? -1 : 1
                      }) scaleX(${columnData[item.id]['horizontal'] ? -1 : 1})`,
                    }"
                      alt=""
                      class="after-upload-img"
                  />
                  <p class="pic_look">
                    <img
                        alt=""
                        src="/static/images/createCases/check_icon.png"
                    />
                    <span>{{ $t("cases.createImage.ck") }}</span>
                  </p>
                </div>
                <div class="img-name">{{ item.name }}</div>
              </div>
            </div>
            <div class="pic-title">{{ $t("cases.createImage.xgp") }}</div>
            <div class="xray-pic-container">
              <div
                  v-for="(item, index) in XRayPic"
                  v-if="columnData[item.id] && columnData[item.id].nanoId"
                  :key="index"
                  class="pic-item"
              >
                <div
                    class="after-upload"
                    @click="
                    showCarousel({
                      filed: item.id,
                      text: item.name,
                      index: getPicIndex(item.id),
                    })
                  "
                >
                  <img
                      v-if="columnData[item.id] && columnData[item.id].nanoId"
                      :src="
                      `${$PicPrefix}${
                        columnData[item.id].nanoId
                      }?imageView2/0/w/360/h/250`
                    "
                      :style="{
                      transform: `rotate(${
                        columnData[item.id]['degree']
                      }deg) scaleY(${
                        columnData[item.id]['vertical'] ? -1 : 1
                      }) scaleX(${columnData[item.id]['horizontal'] ? -1 : 1})`,
                    }"
                      alt=""
                      class="after-upload-img"
                  />
                  <p class="pic_look">
                    <img
                        alt=""
                        src="/static/images/createCases/check_icon.png"
                    />
                    <span>{{ $t("cases.createImage.ck") }}</span>
                  </p>
                </div>
                <div class="img-name">{{ item.name }}</div>
              </div>
            </div>
            <div class="pic-title">{{ $t("cases.createImage.yxzl") }}</div>
            <div v-if="echoOtherPictures" class="other-pictures">
              <div
                  v-for="(item, index) in echoOtherPictures"
                  v-if="item.nanoId"
                  :key="index"
                  class="pic-item"
              >
                <div
                    class="after-upload"
                    @click="
                    showCarousel({
                      filed: item.filed,
                      text: `otherPicture${index}`,
                      index: getPicIndex(item.filed),
                    })
                  "
                >
                  <img
                      v-if="item.nanoId"
                      :src="
                      `${$PicPrefix}${item.nanoId}?imageView2/0/w/360/h/250`
                    "
                      :style="{
                      transform: `rotate(${item['degree']}deg) scaleY(${
                        item['vertical'] ? -1 : 1
                      }) scaleX(${item['horizontal'] ? -1 : 1})`,
                    }"
                      alt=""
                      class="after-upload-img"
                  />
                  <p class="pic_look">
                    <img
                        alt=""
                        src="/static/images/createCases/check_icon.png"
                    />
                    <span>{{ $t("cases.createImage.ck") }}</span>
                  </p>
                </div>
              </div>
            </div>
          </div>
          <PublicTeethModel
              v-if="previewCom.includes('image')"
              :details="columnData"
              :fileList="columnData.attachmentList"
              renderFiled="stlFile"
              renderModelType="siliconeImpression"
          />
        </div>
      </template>
    </div>
    <PreviewImg
        ref="previewImg"
        :carouselList="carouselList"
        @imgChange="imgChange"
    />
  </div>
</template>

<script>
import {
  toCompleteCurePlan,
  columnDataTempRecordDetail,
} from "@/common/api/cases";
import PublicTeethModel from "components/treatment-table/preview-teeth-model/index";
import {adultTeethList} from "@/components/treatment-table/common";
import DentitionSpace from "./dentition-space.vue";
import ChildrenTeeth from "@/components/treatment-table/children-teeth-copy/index";
import PreviewImg from "components/preview-image/preview-image.vue";
import {extend, each, values, get, map, filter, keys, find} from "lodash";
import {defPhoto} from "common/js/requireImage";

export default {
  props: {
    caseDetail: Object,
    diaKeyObj: Object,
    dictObj: Object,
    previewData: Object,
    isDatabase: Boolean,
    previewCom: {
      type: Array,
      default: () => ["table", "image"],
    },
  },
  components: {
    PublicTeethModel,
    PreviewImg,
    DentitionSpace,
    ChildrenTeeth,
  },
  data() {
    return {
      defPhoto,
      isCompleteData: {},
      recordDetail: {},
      columnData: {},
      isLoading: false,
      echoFacePic: [],
      echoXRayPic: [],
      echoOtherPictures: [],
      echoNotFitPicture: [],
      carouselList: [],
      commonTeeth: [],
      uploadIntervalList: [],
      patientType: {
        A: "adult",
        B: "youth",
        C: "children",
        E: "adult",
        I: 'gap'
      },
      facePic: [
        {
          id: "sideProfile",
          name: this.$t("cases.createImage.cmx"),
          label: "cmx",
        },
        {
          id: "frontal",
          name: this.$t("cases.createImage.zmx"),
          label: "zmx",
        },
        {
          id: "frontalSmile",
          name: this.$t("cases.createImage.wxx"),
          label: "wxx",
        },
        {
          id: "upperArch",
          name: this.$t("cases.createImage.sylx"),
          label: "sylx",
        },
        {
          id: "anteriorOverjet",
          name: this.$t("cases.createImage.qyfhfgx"),
          label: "qyfhfgx",
        },
        {
          id: "lowerArch",
          name: this.$t("cases.createImage.xylx"),
          label: "xylx",
        },
        {
          id: "intraOralRight",
          name: this.$t("cases.createImage.knzcyx"),
          label: "knzcyx",
        },
        {
          id: "intraOralFrontal",
          name: this.$t("cases.createImage.knzwx"),
          label: "knzwx",
        },
        {
          id: "intraOralLeft",
          name: this.$t("cases.createImage.knycyx"),
          label: "knycyx",
        },
      ],
      XRayPic: [
        {
          id: "panoramicRadiograph",
          name: this.$t("cases.createImage.dcp"),
          label: "dcp",
        },
        {
          id: "lateralCephalogram",
          name: this.$t("cases.createImage.dwp"),
          label: "dwp",
        },
      ],
    };
  },
  methods: {
    values,
    get,
    map,
    find,
    keys,
    filter,
    imgChange(obj) {
      this.$forceUpdate();
      if (
          obj.filed.includes("notFitPicture") ||
          obj.filed.includes("otherPicture")
      ) {
        let currentList = obj.filed.includes("notFitPicture")
            ? this.echoNotFitPicture
            : this.echoOtherPictures;
        currentList.map((item) => {
          if (item.filed === obj.filed) {
            item.degree = obj.degree;
            item.horizontal = obj.horizontal;
            item.vertical = obj.vertical;
          }
        });
      } else {
        for (const key in this.columnData) {
          if (obj.filed === key) {
            const current = this.columnData[key];
            this.$set(current, "degree", obj.degree);
            this.$set(current, "horizontal", obj.horizontal);
            this.$set(current, "vertical", obj.vertical);
          }
        }
      }
    },
    showCarousel(item) {
      this.$nextTick(() => {
        this.$refs["previewImg"] &&
        this.$refs["previewImg"].show(item.text, item.index);
      });
    },
    getPicIndex(filed) {
      return filter(this.carouselList, (item) => item.filed === filed)[0]?.index;
    },
    initObj(str, filed, name) {
      if (!str) {
        return "";
      }
      if (typeof str === "string") {
        let obj = JSON.parse(str);
        !obj.nanoId && (obj.nanoId = obj.ETag);
        !obj.degree && (obj.degree = 0);
        !obj.horizontal && (obj.horizontal = false);
        !obj.vertical && (obj.vertical = false);
        obj.filed = filed;
        obj.name = name;
        return obj;
      } else if (typeof str === 'object') {
        return str;
      }
    },
    init(data) {
      const {
        otherPicture = [],
        notFitPicture = [],
        toothInfoList = [],
      } = data;
      const {cureType} = this.caseDetail;
      // 牙位信息
      this.uploadIntervalList = filter(
          toothInfoList,
          (item) => item.type === "15"
      );
      let nearList = filter(toothInfoList, (item) => item.type === "6");
      let noMoveList = filter(toothInfoList, (item) => item.type === "2");
      let noDesignList = filter(toothInfoList, (item) => item.type === "3");
      let deficiencyList = map(
          filter(toothInfoList, (item) => item.type === "1"),
          (it) => extend({}, it, {deficiency: "deficiency"})
      );
      let removeList = filter(toothInfoList, (item) => item.type === "4");
      let mainList = ["A", "E", 'I'].includes(cureType)
          ? filter(toothInfoList, (item) => item.type === "7").concat(
              adultTeethList
          )
          : filter(toothInfoList, (item) => item.type === "8");
      let untreatedList = [
        ...nearList,
        ...noMoveList,
        ...noDesignList,
        ...removeList,
        ...deficiencyList,
      ];
      if (untreatedList.length || ["A", "E", 'I'].includes(cureType)) {
        let teethTypeObj = {
          2: "noMove",
          3: "noDesign",
          4: "remove",
          1: "deficiency",
          6: "near",
        };
        this.commonTeeth = map(mainList, (item) => {
          let obj;
          each(untreatedList, (it) => {
            if (item.number === it.number && !item.nextNumber) {
              obj = extend({}, item, obj, {
                [teethTypeObj[it.type]]: teethTypeObj[it.type],
              });
            }
          });
          return obj ? obj : item;
        });
      } else {
        this.commonTeeth =
            filter(toothInfoList, (item) => !item.nextNumber) || [];
      }
      // 影像资料
      this.columnData["sideProfile"] = this.initObj(
          this.columnData["sideProfile"],
          "sideProfile",
          this.$t("cases.createImage.cmx")
      );
      this.columnData["frontal"] = this.initObj(
          this.columnData["frontal"],
          "frontal",
          this.$t("cases.createImage.zmx")
      );
      this.columnData["frontalSmile"] = this.initObj(
          this.columnData["frontalSmile"],
          "frontalSmile",
          this.$t("cases.createImage.wxx")
      );
      this.columnData["upperArch"] = this.initObj(
          this.columnData["upperArch"],
          "upperArch",
          this.$t("cases.createImage.sylx")
      );
      this.columnData["anteriorOverjet"] = this.initObj(
          this.columnData["anteriorOverjet"],
          "anteriorOverjet",
          this.$t("cases.createImage.qyfhfgx")
      );
      this.columnData["lowerArch"] = this.initObj(
          this.columnData["lowerArch"],
          "lowerArch",
          this.$t("cases.createImage.xylx")
      );
      this.columnData["intraOralRight"] = this.initObj(
          this.columnData["intraOralRight"],
          "intraOralRight",
          this.$t("cases.createImage.knzcyx")
      );
      this.columnData["intraOralFrontal"] = this.initObj(
          this.columnData["intraOralFrontal"],
          "intraOralFrontal",
          this.$t("cases.createImage.knzwx")
      );
      this.columnData["intraOralLeft"] = this.initObj(
          this.columnData["intraOralLeft"],
          "intraOralLeft",
          this.$t("cases.createImage.knzcyx")
      );
      this.columnData["panoramicRadiograph"] = this.initObj(
          this.columnData["panoramicRadiograph"],
          "panoramicRadiograph",
          this.$t("cases.createImage.dcp")
      );
      this.columnData["lateralCephalogram"] = this.initObj(
          this.columnData["lateralCephalogram"],
          "lateralCephalogram",
          this.$t("cases.createImage.dwp")
      );
      let echoFacePic = [
        this.columnData["sideProfile"],
        this.columnData["frontal"],
        this.columnData["frontalSmile"],
        this.columnData["upperArch"],
        this.columnData["anteriorOverjet"],
        this.columnData["lowerArch"],
        this.columnData["intraOralRight"],
        this.columnData["intraOralFrontal"],
        this.columnData["intraOralLeft"],
      ];
      let echoXRayPic = [
        this.columnData["panoramicRadiograph"],
        this.columnData["lateralCephalogram"],
      ];
      let otherPic = otherPicture.length
          ? JSON.parse(otherPicture).map((item, index) => {
            return this.initObj(
                JSON.stringify(item),
                `otherPicture${index}`,
                this.$t("cases.createImage.yxzl")
            );
          })
          : [];
      this.echoOtherPictures = otherPic;
      let notFitPic = notFitPicture.length
          ? JSON.parse(notFitPicture).map((item, index) => {
            return this.initObj(
                JSON.stringify(item),
                `notFitPicture${index}`,
                this.$t("stageAdjust.bthbwzp")
            );
          })
          : [];
      this.echoNotFitPicture = notFitPic;
      let picList = [...notFitPic, ...echoFacePic, ...echoXRayPic, ...otherPic];
      picList = picList.filter((item) => item.nanoId);
      this.carouselList = map(picList, (item, index) => {
        return extend({}, item, {index: index + ""});
      });
    },
    groupClick(val) {
      const pageOne = [
        this.$t("stageAdjust.dqjzqthqk"),
        this.$t("stageAdjust.bcfkspdbs"),
        this.$t("stageAdjust.yljx"),
        this.$t("stageAdjust.hxjzyq"),
      ];
      const pageTwo = [
        this.$t("stageAdjust.kqnzp"),
        this.$t("stageAdjust.yhmx"),
      ];
      let fixIdx = 0;
      if (pageOne.includes(val)) fixIdx = 0;
      if (pageTwo.includes(val)) fixIdx = 1;
      this.$emit("jumpQuestion", fixIdx, val);
    },
  },
  computed: {
    userInfo() {
      return this.caseDetail.userInfo || {};
    },
  },
  watch: {
    previewData:{
      handler(val) {
        this.columnData = val;
        this.init(val);
      },
      deep: true
    },
  },
  created() {
    this.columnData = this.previewData;
  },
  mounted() {
    const {curePlanId} = this.$route.query;
    if (document.querySelector(".stage-adjustment")) {
      const parent = document.querySelector(".stage-adjustment");
      parent.scrollTo({
        top: 0,
      });
    }
    this.isLoading = true;
    if (this.isDatabase) {
      this.columnData = this.previewData;
      this.init(this.previewData);
      this.isLoading = false;
      return;
    }
    toCompleteCurePlan({
      id: curePlanId,
      type: "phase_adjustment",
    }).then((res) => {
      const {groupOneNameList = [], groupTwoNameList = [], groupCount} = res;
      this.isCompleteData = {
        groupOneNameList,
        groupTwoNameList,
        groupCount,
      };
      if (groupCount <= 0) {
        this.$emit("handleCanSubmit", true);
        const {caseId, curePlanId} = this.$route.query;
        columnDataTempRecordDetail({
          caseId,
          curePlanId,
          type: "phase_adjustment",
        }).then((recordDetail) => {
          this.recordDetail = recordDetail;
          this.columnData = recordDetail["columnData"];
          this.init(recordDetail["columnData"]);
          this.isLoading = false;
        });
      } else {
        this.$emit("handleCanSubmit", true);
        this.isLoading = false;
      }
    });
  },
};
</script>

<style lang="scss" scoped>
.preview {
  background-color: #fff;
  padding: 0.33rem 0.3rem 0.3rem 0.3rem;

  .preview_tip {
    .tip_h5 {
      display: flex;
      align-items: center;
      font-size: 0.22rem;
      color: $main_theme_color_333;

      .tip_icon {
        display: inline-block;
        width: 0.24rem;
        height: 0.24rem;
        background-image: url("/static/images/createCases/web_preview_tips.png");
        background-size: 100% 100%;
        border-radius: 100%;
        margin-right: 0.1rem;
      }

      .tip_icon_ok {
        background-image: url("/common/imgs/case/case_preview_success.png");
      }
    }

    .p_tip_ul {
      width: 80%;
      margin-top: 0.67rem;

      .p_tip_li {
        padding-left: 30px;
        margin-bottom: 60px;

        .p_tip_l_h {
          display: flex;
          align-items: center;
          position: relative;
          font-size: 0.18rem;
          color: $main_theme_color_333;
        }

        .l_h_sp {
          color: $main_theme_color;
          font-size: 0.3rem;
          position: absolute;
          left: -0.06rem;
          top: 50%;
          transform: translateY(-50%);
        }

        .p_tip_l_box {
          display: flex;
          align-items: center;
          flex-wrap: wrap;
          box-sizing: border-box;
          margin-top: 10px;

          span {
            margin-bottom: 0.1rem;
            border-bottom: 0.01rem solid $main_theme_color_333;
            color: $main_theme_color_333;
            cursor: pointer;
            margin-right: 0.2rem;
            font-size: 0.16rem;
          }

          span:hover {
            color: $main_theme_color;
            border-bottom: 0.01rem solid $main_theme_color;
          }
        }

        &:last-child {
          .p_tip_l_box {
            padding-bottom: 0;
          }
        }
      }
    }
  }

  .complete-success {
    color: #333333;

    &-title {
      display: flex;
      align-items: center;
      font-size: 22px;
      line-height: 30px;
      margin-bottom: 30px;

      img {
        width: 24px;
        height: 24px;
        margin-right: 10px;
      }
    }

    .preview-patient-info {
      display: flex;
      padding: 30px;
      border-radius: 6px;
      background-color: #f4f4f4;

      .patient-avatar {
        width: 120px;
        height: 120px;
        margin-right: 20px;

        img {
          width: 100%;
          height: 100%;
          border-radius: 50%;
        }
      }

      .patient-information {
        flex: 1;
        height: 100%;

        .patient-name {
          font-size: 20px;
          line-height: 28px;
        }

        .case-number {
          font-size: 14px;
          line-height: 20px;
          margin: 10px 0;
        }

        .clinical-preferences {
          font-size: 16px;
          line-height: 22px;

          span {
            font-size: 14px;
            color: #666666;
          }
        }
      }
    }

    .result-title {
      font-size: 16px;
      line-height: 22px;
      color: #333;
      margin: 10px 0;

      > div {
        margin: 10px 0;
      }

      > .label {
        font-size: 16px;
        line-height: 22px;
        color: #333;
      }
    }

    .result-value {
      font-size: 16px;
      line-height: 22px;
      color: #333;
      margin-bottom: 10px;
    }

    .result-subtitle {
      display: flex;
      align-items: flex-start;
      margin-bottom: 10px;
      font-size: 14px;
      line-height: 20px;
      color: #666;

      .label {
        flex-shrink: 0;
        width: 220px;
        text-align: left;
        margin-right: 30px;
      }

      .value {
        color: #333;
        display: flex;
        align-items: center;
        word-break: break-all;

        p:last-child {
          margin-left: 30px;
        }
      }
    }

    .tmj-issues-note {
      word-break: break-all;
    }

    .pic-title {
      font-size: 16px;
      line-height: 22 p;
      margin-bottom: 10px;
    }

    .notfit-pictures,
    .face-pic-container,
    .xray-pic-container,
    .other-pictures {
      display: grid;
      grid-template-columns: 1fr 1fr 1fr;

      .pic-item {
        min-width: 280px;
        max-width: 340px;
        min-height: 240px;
        max-height: 280px;
        margin: 0 43px 20px 0;
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        &:nth-child(3n + 3) {
          margin-right: 0px;
        }
      }

      .after-upload {
        cursor: pointer;
        display: flex;
        justify-content: center;
        align-items: center;
        height: 240px;
        //border: 1px dashed #bbb;
        border-radius: 8px;
        background-color: #f0f0f0;
        position: relative;
        overflow: hidden;

        &:hover .pic_look {
          opacity: 1;
        }

        .pic_look {
          position: absolute;
          bottom: 0;
          left: 0;
          right: 0;
          height: 0.5rem;
          background-color: rgba(0, 0, 0, 0.6);
          color: #fff;
          display: flex;
          align-items: center;
          justify-content: center;
          font-size: 0.16rem;
          opacity: 0;
          transition: opacity 0.6s;
          z-index: 1;

          img {
            width: 0.24rem;
            height: 0.16rem;
            margin-right: 0.1rem;
          }
        }

        img {
          max-width: 100%;
          max-height: 100%;
        }
      }

      .img-name {
        font-size: 16px;
        line-height: 22px;
        color: #333;
        text-align: center;
        margin-top: 10px;
      }
    }
  }
}
</style>
