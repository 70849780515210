<template>
  <div class="switch-irdt-container">
    <div class="create_head" :class="{ top068: !isShowNews }">
      <h5 class="create_head_h5">{{ $t("casesDetail.casesDetail.kqrdt") }}</h5>
      <div class="create_head_ul">
        <p class="h_line"></p>
        <div class="h_single">
          <h5 class="h_single_h5">
            {{ $t("cases.maintain.zsmc") }}: {{ caseDetail.clinicName }}
          </h5>
        </div>
        <p class="h_line"></p>
        <div class="h_single">
          <h5 class="h_single_h5">
            {{ $t("cases.maintain.cpmc") }}: {{ caseDetail.productName }}
          </h5>
        </div>
      </div>
    </div>
    <div class="create_tabs" :class="{ top18: !isShowNews }">
      <div class="header-left">
        <div
            :class="[activeKey === 1 ? 'action-item-active' : 'action-item']"
            @click="changeActiveKey(1)"
        >
          <span
              :class="activeKey === 1 ? 'diagnosis_icon_active' : ''"
              class="diagnosis_icon"
          ></span>
          {{ $t("cases.casesCreate.zdsm") }}
        </div>
        <div
            :class="[activeKey === 2 ? 'action-item-active' : 'action-item']"
            @click="changeActiveKey(2)"
        >
          <span
              :class="activeKey === 2 ? 'image_icon_active' : ''"
              class="image_icon"
          ></span>
          {{ $t("cases.casesCreate.yxsm") }}
        </div>
        <div
            :class="[activeKey === 3 ? 'action-item-active' : 'action-item']"
            @click="changeActiveKey(3)"
        >
          <span
              :class="activeKey === 3 ? 'preview_icon_active' : ''"
              class="preview_icon"
          ></span>
          {{ $t("cases.casesCreate.lltj") }}
        </div>
      </div>
      <div class="header-right">
        <div
            class="step-option"
            @click="
            changeActiveKey(activeKey === 3 ? activeKey - 1 : activeKey + 1)
          "
        >
          {{
            activeKey === 3
                ? $t("cases.casesCreate.syy")
                : $t("cases.casesCreate.xyy")
          }}
        </div>
        <div class="submit-switch-irdt" @click="modifyOrSubmit">
          {{
            activeKey === 3
                ? $t("common.message.tj")
                : $t("cases.casesCreate.zc")
          }}
        </div>
      </div>
      <div class="transverse_line"></div>
    </div>
    <div :class="{ mt28: !isShowNews }" class="create_con">
      <div v-if="activeKey === 1">
        <Adult v-if="caseDetail['cureType'] === 'A'" ref="adult" />
        <Kid v-if="caseDetail['cureType'] === 'B'" ref="kid" />
        <Baby v-if="caseDetail['cureType'] === 'C'" ref="baby" />
        <Veneer v-if="caseDetail['cureType'] === 'E'" ref="veneer" />
        <Gap v-if="caseDetail['cureType'] === 'I'" ref="gap" />
      </div>
      <div class="video-content">
        <createImage
            v-if="activeKey === 2"
            ref="image"
            :caseDetail="caseDetail"
        />
      </div>
      <div v-if="activeKey === 3" class="preview-content">
        <createPreview
            ref="preview"
            :caseDetail="caseDetail"
            :recordId="recordId"
            @saveDiagnosis="saveDiagnosis"
        />
      </div>
    </div>
    <confirm-modal
        v-show="isShowConfirm"
        :confirmObj="confirmObj"
        @confirmHide="isShowConfirm = false"
        @confirmSubmit="confirmSubmit()"
    >
    </confirm-modal>
    <full-loading v-show="isLoading" />
  </div>
</template>

<script>
import {
  casesDetail,
  columnDataTempRecordSave,
  curePlanToCompleteNs,
  curePlanOpenIrdt
} from "common/api/cases";

import { notifyMsg } from "common/js/util";
import { mapState } from "vuex";
import Adult from "./components/adult.vue";
import Kid from "./components/kid.vue";
import Baby from "./components/baby.vue";
import Veneer from "./components/veneer.vue";
import Gap from "./components/gap.vue";
import createImage from "./components/image.vue";
import createPreview from "./components/preview.vue";
import ConfirmModal from "components/confirm-modal/confirm.vue";
import FullLoading from "components/full-loading/full-loading.vue";

export default {
  data() {
    return {
      caseDetail: {},
      isShowConfirm: false,
      confirmObj: {
        tipText: this.$t("cases.casesCreate.sftj"),
        backText: this.$t("cases.theDelivery.qx"),
        okText: this.$t("cases.casesCreate.qdtj"),
        initText: this.$t("casesDetail.casesDetail.kqrdth")
      },
      isLoading: false,
      isSubmit: false,
      diagnosisData: {},
      activeKey: 1,
      recordId: ""
    };
  },
  components: {
    Adult,
    Kid,
    Baby,
    Veneer,
    Gap,
    createImage,
    createPreview,
    ConfirmModal,
    FullLoading
  },
  computed: {
    ...mapState({
      isShowNews: state => state.isShowNews,
      language: state => state.language
    })
  },
  methods: {
    saveDiagnosis(key) {
      this.activeKey = key;
    },
    changeActiveKey(val) {
      if ([1, 2].includes(this.activeKey)) {
        this.modifyTempData(val);
      } else {
        this.activeKey = val;
      }
    },
    confirmSubmit() {
      this.isLoading = true;
      curePlanOpenIrdt({
        id: this.$route.query.curePlanId,
        recordId: this.recordId
      })
          .then(res => {
            this.isLoading = false;
            notifyMsg(this, "success", this.$t("cases.createPreview.tjcg"));
            this.isShowConfirm = false;
            this.$router.replace(
                `/cases/detail?caseId=${this.$route.query.caseId}`
            );
          })
          .catch(() => {
            this.isLoading = false;
          });
    },
    queryCaseDetail() {
      const caseId = this.$route.query.caseId;
      casesDetail(caseId).then(data => {
        this.caseDetail = data;
      });
    },
    modifyTempData(val) {
      const caseId = this.$route.query.caseId;
      const curePlanId = this.$route.query.curePlanId;
      let dom;
      if (this.activeKey === 1) {
        dom = this.$refs[
            {
              A: "adult",
              B: "kid",
              C: "baby",
              E: "veneer",
              I: "gap"
            }[this.caseDetail["cureType"]]
            ];
      } else {
        dom = this.$refs["image"];
      }
      columnDataTempRecordSave({
        columnData: {
          ...dom.saveData()
        },
        caseId,
        curePlanId,
        type: "open_rdt"
      }).then(res => {
        this.recordId = res;
        if (val) {
          this.activeKey = val;
        }
        notifyMsg(this, "success", this.$t("common.message.yzc"), 2500);
      });
    },
    modifyOrSubmit() {
      if (this.activeKey === 3) {
        const dom = this.$refs["preview"];
        dom.saveData();
        if (!dom.saveData()) {
          notifyMsg(this, "warning", this.$t("cases.casesCreate.qjc"));
        } else {
          this.recordId = dom.saveData();
          this.isSubmit = true;
          this.isShowConfirm = true;
        }
      } else {
        this.modifyTempData();
      }
    }
  },
  mounted() {
    this.queryCaseDetail();
  }
};
</script>

<style lang="scss" scoped>
.switch-irdt-container {
  width: 16rem;
  margin: 0 auto;

  .create_head {
    width: 16rem;
    display: flex;
    align-items: center;
    padding-top: 35px;
    padding-bottom: 11px;
    position: fixed;
    top: 1.03rem;
    transition: top 0.3s;
    background-color: #efeff4;
    z-index: 1000;
    .create_head_h5 {
      color: $main_theme_color_333;
      font-size: 0.2rem;
      margin-right: 0.1rem;
      position: relative;
      top: -10px;
      white-space: nowrap;
    }
    .create_head_ul {
      display: flex;
      align-items: center;
      position: relative;
      top: -10px;

      .h_line {
        width: 0.01rem;
        height: 0.16rem;
        background-color: $main_theme_color_666;
      }

      .h_single {
        padding: 0 0.1rem;
        display: flex;
        flex-direction: column;
        align-items: center;

        .h_single_pic {
          width: 0.76rem;
          height: 0.4rem;
          margin-bottom: 0.08rem;
          background-repeat: no-repeat;
          background-size: cover;
          background-position: center;
        }

        .h_single_h5 {
          max-width: 400px;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          font-size: 0.16rem;
          color: $main_theme_color_333;
        }
      }
    }
  }

  .create_tabs {
    width: 16rem;
    padding: 0 0.3rem 0 0.3rem;
    height: 0.72rem;
    display: flex;
    justify-content: space-between;
    box-sizing: border-box;
    background-color: #fff;
    position: fixed;
    top: 1.63rem;
    z-index: 1000;
    transition: top 0.3s;

    .header-left,
    .header-right {
      height: 100%;
      display: flex;
      align-items: center;
    }

    .action-item,
    .action-item-active {
      cursor: pointer;
      margin-right: 40px;
      display: flex;
      align-items: center;
      height: 100%;
      position: relative;
      font-size: 0.18rem;

      span {
        display: inline-block;
        width: 0.24rem;
        height: 0.24rem;
        margin-right: 0.04rem;
        background-size: 100% 100%;
        border-radius: 100%;
      }

      .user_icon {
        background-image: url("../../../../common/imgs/case/case_tabs_user.png");
      }

      .user_icon_active {
        background-image: url("../../../../common/imgs/case/case_tabs_user_y.png");
      }

      .diagnosis_icon {
        background-image: url("../../../../common/imgs/case/case_tabs_cure.png");
      }

      .diagnosis_icon_active {
        background-image: url("../../../../common/imgs/case/case_tabs_cure_y.png");
      }

      .image_icon {
        background-image: url("../../../../common/imgs/case/case_tabs_img.png");
      }

      .image_icon_active {
        background-image: url("../../../../common/imgs/case/case_tabs_img_y.png");
      }

      .preview_icon {
        background-image: url("../../../../common/imgs/case/case_tabs_preview.png");
      }

      .preview_icon_active {
        background-image: url("../../../../common/imgs/case/case_tabs_preview_y.png");
      }
    }

    .action-item-active {
      &::after {
        content: "";
        position: absolute;
        bottom: -2px;
        left: 0;
        width: 100%;
        height: 0.05rem;
        background-color: #fcc80e;
        z-index: 100;
      }
    }

    .submit-switch-irdt,
    .step-option {
      cursor: pointer;
      width: 96px;
      height: 36px;
      border-radius: 4px;
      display: flex;
      justify-content: center;
      align-items: center;
      color: #333;
      justify-self: flex-end;
      background-color: #fcc80e;
    }

    .submit-switch-irdt {
      background-color: #fff;
      border: 1px solid #b5b5b5;
      margin-left: 20px;
    }
  }
  .transverse_line {
    width: 16rem;
    height: 0.06rem;
    background-color: #efeff4;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    bottom: -6px;
    z-index: 99;
  }
  .create_con {
    margin-top: 2.4rem;
    transition: margin-top 0.3s;
  }
}

.top068 {
  top: 0.67rem !important;
  transition: all 0.3s;
}
.top18 {
  top: 1.26rem !important;
  transition: all 0.3s;
}
.mt28 {
  margin-top: 2.04rem !important;
}
</style>
