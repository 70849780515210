<template>
  <div class="amend_step">
    <div class="step_left" @click="toPage">
      <span
        class="main_theme_color_999"
        v-for="(item, index) in goList"
        :key="`${item.url}_${index}`"
        :data-url="item.url"
      >
        {{ item.name }} &gt;
      </span>
      <p class="main_theme_color_333">{{ currentTxt }}</p>
    </div>
    <p class="step_right" @click="goBack">
      <img src="../../../../common/imgs/app/back_icon.png" alt="" />
      {{ $t("casesDetail.childrenPublic.backStep.fhsyy") }}
    </p>
  </div>
</template>

<script>
import { extend } from "lodash";

export default {
  data() {
    return {
      history: 0,
    };
  },
  props: {
    goList: {
      type: Array,
      default: () => [],
    },
    currentTxt: {
      type: String,
      default: "",
    },
  },
  methods: {
    goPage(url) {
      // this.$router.push(url);
      let route = this.$route;
      this.$router.push({
        path: url,
        query: extend({}, route.query),
      });
    },
    goBack() {
      if (window.history.state.key < 500) {
        this.$router.push("/cases");
      } else {
        this.$router.back(-1);
      }
    },
    toPage(ev) {
      const target = ev.target;
      if (target.tagName.toLocaleLowerCase() === "span") {
        const url = target.getAttribute("data-url");
        url && this.goPage(url);
      }
    },
  },
  created() {
    this.$setgoindex();
  },
};
</script>

<style scoped lang="scss">
.amend_step {
  display: flex;
  align-items: center;
  justify-content: space-between;
  //margin-bottom: 0.1rem;
  height: 30px;
  background-color: #efeff4;
  padding-bottom: 2px;
  .step_left {
    display: flex;
    align-items: center;
    font-size: 16px;
    span {
      cursor: pointer;
      &:first-child {
        margin-right: 0.08rem;
        color: $main_theme_color_999;
      }
      &:last-child {
        margin-right: 0.04rem;
      }
      &:nth-of-type(2) {
        margin: 0 0.04rem;
      }
    }
  }
  .step_right {
    color: $main_theme_color;
    cursor: pointer;
    font-size: 16px;
  }
}
</style>
