<template>
  <div class="invite_friends">
    <back-step
      :goList="[{
        url: '/cases',
        name: `${$t('cases.inviteFriends.bl')}`
      }, {
        url: '/cases/detail',
        name: `${$t('cases.inviteFriends.blxq')}`
      }]"
      :currentTxt="$t('cases.inviteFriends.yqsy')"
    />
    <div class="invite_box" ref="copyImg">
      <h5 class="invite_title">{{doctorName}}{{$t('cases.inviteFriends.ysyq')}}</h5>
      <p class="invite_p">{{$t('cases.inviteFriends.gdxx')}}</p>
      <div class="invite_con">
        <div class="in_left"></div>
        <div class="in_right"></div>
      </div>
    </div>
    <div class="btn_box">
      <span class="main_theme_color_btn main_big_btn mr20" @click="downPic">{{$t('cases.inviteFriends.xztp')}}</span>
      <span class="main_theme_color_btn_white main_big_btn" @click="goBack">{{$t('common.message.fh')}}</span>
    </div>
  </div>
</template>

<script>
  import BackStep from './cases-detail/childrenPublic/backStep';
  import html2canvas from 'html2canvas';
  import {getDictList} from 'common/api/public';
  export default {
    data() {
      return {
        doctorName: '',
        copyImg: null,
        picUrl: ''
      }
    },
    created() {
      document.title = this.$t('cases.inviteFriends.yqsy');
      const casesDetail = sessionStorage.getItem('casesDetail');
      getDictList('h5_download_ios').then(d => {
      });
      if(casesDetail) {
        this.doctorName = JSON.parse(casesDetail).doctorName;
      }
    },
    mounted() {
      html2canvas(this.$refs.copyImg, {
        useCORS: true
      }).then((canvas) => {
        this.picUrl = canvas.toDataURL('image/png');
      });
    },
    methods: {
      downPic() {
        const a = document.createElement('a');
        a.download = `${this.doctorName}${this.$t('cases.inviteFriends.ysyq')}`;
        a.href = this.picUrl;
        a.click();
      },
      goBack() {
        window.history.go(-1);
      }
    },
    components: {
      BackStep
    }
  }
</script>

<style scoped lang="scss">
  .invite_friends{
    width: 16rem;
    margin: 0 auto;
    color: $main_theme_color_333;
    font-size: 0.16rem;
    position: relative;
    .invite_box{
      width: 100%;
      height: 5.28rem;
      background-size: 100% 100%;
      background-image: url("/static/images/web_invitation_bg.png");
      padding-top: 0.52rem;
      display: flex;
      flex-direction: column;
      align-items: center;
      box-sizing: border-box;
      .invite_title{
        font-size: 0.3rem;
        font-weight:bold;
        margin-bottom: 0.22rem;
      }
      .invite_p{
        color: #666666;
        font-size: 0.2rem;
        margin-bottom: 0.18rem;
      }
      .invite_con{
        display: flex;
        align-items: center;
        .in_left{
          width: 2.32rem;
          height: 2.68rem;
          margin-right: 0.31rem;
          background-size: 100% 100%;
          background-image: url("/static/images/web_doc_download_phone.png");
        }
        .in_right{
          width: 1.62rem;
          height: 1.62rem;
          background-image: url("/static/images/download.png");
          background-size: 100% 100%;
        }
      }
    }
    .btn_box{
      position: absolute;
      bottom: 0.5rem;
      left: 50%;
      transform: translateX(-50%);
      display: flex;
      align-items: center;
    }
  }
</style>
