import "babel-polyfill";
import Vue from "vue";
import App from "./App";
import store from "./store";
import "common/js/validate";
import VueI18n from "vue-i18n";
import ElementUI from "element-ui";
import eleZh from "element-ui/lib/locale/lang/zh-CN";
import eleEn from "element-ui/lib/locale/lang/en";
import "element-ui/lib/theme-chalk/index.css";
import router from "./router";
import zh from "./common/lang/zh";
import en from "./common/lang/en";
import { isLogin } from "./common/js/util";
import "lib-flexible/flexible.js";
import "./common/js/dialogDrag";

import "common/scss/reset.scss";
import "common/scss/theme.scss";
import "common/scss/global.scss";
import "common/scss/font/iconfont";
import "viewerjs/dist/viewer.css";

Vue.use(ElementUI, {
  locale: { zh_CN: eleZh, en_US: eleEn }[localStorage.getItem("user_lang")]
});

Vue.use(VueI18n);

const i18n = new VueI18n({
  locale: localStorage.getItem("user_lang") || "zh_CN",
  messages: {
    zh_CN: zh,
    en_US: en
  }
});
Vue.prototype.$setgoindex = function() {
  if (window.history.length <= 1) {
    if (location.href.indexOf("?") === -1) {
      window.location.href = location.href + "?goindex=true";
    } else if (
      location.href.indexOf("?") !== -1 &&
      location.href.indexOf("goindex") === -1
    ) {
      window.location.href = location.href + "&goindex=true";
    }
  }
};

router.beforeEach((to, from, next) => {
  const lo = isLogin();
  sessionStorage.setItem("menuUrl", to.path);
  const YZ_LIST = [
    "/login",
    "/retrievePaw",
    "/downloadApp",
    "/cases/view3d",
    "/exocad"
  ];
  if (YZ_LIST.includes(to.path)) {
    sessionStorage.setItem("isShowHead", 1);
  } else {
    sessionStorage.setItem("isShowHead", 2);
  }
  document.body.scrollTop = 0;
  if (to.meta.title) {
    document.title = to.meta.title;
  }
  document.body.scrollTop = 0;
  if (to.path === "/cases/view3d" && !lo) {
    return next();
  }
  if (to.path === "/exocad" && !lo) {
    return next();
  }
  if (
    to.path !== "/login" &&
    to.path !== "/retrievePaw" &&
    to.path !== "/downloadApp" &&
    !lo
  ) {
    next("login");
  } else if (to.path === "/login" && lo) {
    next("/");
  } else if (to.path === "/retrievePaw" && !lo) {
    next();
  } else if (to.path === "/downloadApp" && !lo) {
    next();
  } else {
    next();
  }
});
const VUE_APP_VERSION = require('../package.json').version
const vers = window.localStorage.getItem("appVersion");
if (VUE_APP_VERSION != vers) {
  localStorage.clear()
  window.localStorage.setItem("appVersion", VUE_APP_VERSION);
  location.reload()
}
/* eslint-disable no-new */
new Vue({
  el: "#app",
  router,
  store,
  i18n,
  render: h => h(App)
});
