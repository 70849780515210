export default {
  GET_LOGIN_STATUS: 'GET_LOGIN_STATUS', // 获取登录状态
  GET_MENU_URL: 'GET_MENU_URL', // 获取选择的菜单地址
  USER_MSG: 'USER_MSG', // 用户基本信息
  HANDLE_MSG: 'HANDLE_MSG', // 阅读消息
  HEAD_SEARCH: 'HEAD_SEARCH', // 头部搜索
  GET_IS_SHOW_NEWS: 'GET_IS_SHOW_NEWS',//是否显示新闻
  GET_language: 'GET_language',//中英文
  MODIFY_USER_PHOTO: 'MODIFY_USER_PHOTO', //修改用户头像
  CITY_LIST: 'CITY_LIST', //修改用户头像
}
