<template>
  <div class="table_wrap">
    <div :style="{ height: rightHeight }" class="left_option">
      <div style="height: 6.3rem">
        <el-scrollbar ref="scrollbar" style="height: 100%">
          <template>
            <div
              v-for="(item, index) in leftList"
              class="left_option_item"
              :class="{ left_active: selectLeftKey === `d${index + 1}` }"
              @click="selectLeft(index, 'left')"
              :key="index"
            >
              <div
                v-show="selectLeftKey === `d${index + 1}`"
                class="left_wire"
              ></div>
              {{ item.title }}
            </div>
          </template>
        </el-scrollbar>
      </div>
    </div>
    <div ref="rightTable" class="right_table">
<!--      <div class="switch-irdt-tips">-->
<!--        <img src="../../../../../common/imgs/icon_warn.png" alt="" />-->
<!--        {{ $t("casesDetail.casesDetail.kqrdtxyws") }}-->
<!--      </div>-->
      <div class="select_type" id="1">
        <div class="flex-y-c">
          <p>
            <span class="main_theme_color_red fz14 mr4">●</span>
          </p>
          <p class="select_type_title mr10">
            1.{{ $t("cases.createDiagnosis.zyjzmb") }}
          </p>
        </div>
        <p class="problem_note" v-if="diagnosisProblem['1']">
          {{ diagnosisProblem["1"] }}
        </p>
        <div class="flex-y-c">
          <div
            :style="
              language === 'en_US'
                ? 'width: 1rem; text-align: left;margin-right:0'
                : ''
            "
            class="option_left_title"
          >
            {{ $t("cases.createDiagnosis.ycwt") }}
          </div>
          <div class="flex-y-c mt10">
            <div
              class="common_btn"
              @click="
                levelOneClick({
                  addItem: {
                    key: item.key,
                    value: item.value,
                    submitKey: 'toothProblemTwo'
                  },
                  radio: false
                })
              "
              :class="
                values(
                  map(diagnosisParams.toothProblemTwo.children, it => it.key)
                ).indexOf(item.key) !== -1
                  ? 'is_active'
                  : ''
              "
              v-for="(item, index) in caseOptions['tooth_problem_i']"
              :key="`${item.key}_${index}`"
            >
              {{ item.value }}
            </div>
          </div>
        </div>
        <div
          class="remark_box"
          v-show="
            values(
              map(diagnosisParams.toothProblemTwo.children, it => it.key)
            ).indexOf('other') !== -1
              ? 'is_active'
              : ''
          "
        >
          <el-input
            :disabled="isBackDisabled"
            :autosize="{ minRows: 4, maxRows: 6 }"
            maxlength="2000"
            class="tex_b_a"
            type="textarea"
            show-word-limit
            v-model="diagnosisParams.toothOtherProblemTwo"
            :placeholder="$t('common.common.qsrnr')"
          ></el-input>
        </div>
      </div>
      <div class="select_type" id="2">
        <div class="flex-y-c mb10">
          <p>
            <span class="main_theme_color_red fz14 mr4">●</span>
          </p>
          <p class="select_type_title mr10">
            2.{{ $t("cases.createDiagnosis.txt3") }}
          </p>
          <span class="main_theme_color_999 fz14 ml10">{{
            $t("cases.createDiagnosis.jsdgjz")
          }}</span>
        </div>
        <p class="problem_note" v-if="diagnosisProblem[4]">
          {{ diagnosisProblem[4] }}
        </p>
        <div class="flex-y-c">
          <div
            v-for="(item, index) in caseOptions['to_correct_tooth_jaw']"
            @click="
              levelOneClick({
                noClear: true,
                addItem: {
                  key: item.key,
                  value: item.value,
                  submitKey: 'toCorrectToothJaw'
                }
              })
            "
            :key="index"
          >
            <el-popover
              :disabled="isBackDisabled"
              placement="bottom"
              :popper-options="{
                boundariesElement: 'body'
              }"
              trigger="click"
              v-if="item.key !== 'whole_jaw'"
            >
              <div class="flex-y-c">
                <div
                  class="common_btn"
                  :class="{
                    is_active:
                      diagnosisParams['toCorrectToothJaw'].children &&
                      diagnosisParams['toCorrectToothJaw'].children[0].key ===
                        it.key
                  }"
                  @click="
                    levelTowClick({
                      wrapFiled: 'toCorrectToothJaw',
                      addItem: {
                        key: it.key,
                        submitKey: {
                          upjaw: 'toCorrectToothUpjaw',
                          downjaw: 'toCorrectToothDownjaw'
                        }[item.key],
                        value: diaKeyObj[it.key]
                      },
                      radio: true
                    })
                  "
                  v-for="(it, idx) in diagnosisParams.toCorrectToothJaw.key ===
                  'upjaw'
                    ? caseOptions['to_correct_tooth_upjaw']
                    : caseOptions['to_correct_tooth_downjaw']"
                  :key="idx"
                >
                  {{ it.value }}
                </div>
              </div>
              <div
                class="common_btn"
                :class="{
                  is_active: diagnosisParams.toCorrectToothJaw.key === item.key
                }"
                slot="reference"
                :data-key="item.key"
              >
                {{ item.value }}
              </div>
            </el-popover>
            <div
              class="common_btn"
              :class="{
                is_active: diagnosisParams.toCorrectToothJaw.key === item.key
              }"
              v-else
              :data-key="item.key"
            >
              {{ item.value }}
            </div>
          </div>
        </div>
        <div
          class="single_tip_box"
          v-show="get(diagnosisParams.toCorrectToothJaw, 'children[0].value')"
        >
          <p>
            {{ get(diagnosisParams.toCorrectToothJaw, "children[0].value") }}
          </p>
        </div>
      </div>
      <div class="select_type" id="3">
        <div class="flex-y-c mb2rem">
          <p class="select_type_title mr10">
            3.
            {{ $t("cases.createDiagnosis.txt4") }}
          </p>
          <p>
            <span class="main_theme_color_999 fz14">{{
              $t("cases.createDiagnosis.txt4ms")
            }}</span>
          </p>
        </div>
        <p class="problem_note" v-if="diagnosisProblem[5]">
          {{ diagnosisProblem[5] }}
        </p>
        <ChildrenTeeth
          :commonTeeth="commonTeeth"
          :patientType="'gap'"
          :readOnly="isBackDisabled"
          @updateTeeth="updateTeeth"
          class="mb40 mt40"
          :teethType="1"
        />
      </div>
      <div class="select_type" id="4">
        <div class="select_type_title mb2rem">
          4.
          {{ $t("cases.createDiagnosis.txt5") }}
          <span class="main_theme_color_999 fz14">{{
            $t("cases.createDiagnosis.kcydbkyd")
          }}</span>
          <p class="problem_note mt2rem" v-if="diagnosisProblem[6]">
            {{ diagnosisProblem[6] }}
          </p>

          <div class="mt10 flex-y-c">
            <div
              class="common_btn"
              :class="{
                is_active: item.key === diagnosisParams.nonMoveableTeeth.key
              }"
              @click="
                levelOneClick({
                  addItem: {
                    key: item.key,
                    value: item.value,
                    submitKey: 'nonMoveableTeeth'
                  }
                })
              "
              v-for="(item, index) in caseOptions['non_moveable_teeth']"
              :key="index"
            >
              {{ item.value }}
            </div>
          </div>
          <ChildrenTeeth
            :commonTeeth="commonTeeth"
            ref="teethComponents"
            :patientType="'gap'"
            v-show="diagnosisParams.nonMoveableTeeth.key === 'true'"
            :readOnly="isBackDisabled"
            @updateTeeth="updateTeeth"
            class="mb40 mt40"
            :teethType="2"
          />
        </div>
      </div>
      <div class="select_type" id="5">
        <div class="select_type_title mb2rem">
          5.
          {{ $t("cases.createDiagnosis.txt6") }}
          <span class="main_theme_color_999 fz14">{{
            $t("cases.createDiagnosis.kcyfj")
          }}</span>
          <p class="problem_note mt2rem" v-if="diagnosisProblem[7]">
            {{ diagnosisProblem[7] }}
          </p>

          <div class="mt1rem flex-y-c">
            <div
              class="common_btn"
              :class="{
                is_active:
                  item.key === diagnosisParams.notAvailableAttachments.key
              }"
              @click="
                levelOneClick({
                  addItem: {
                    key: item.key,
                    value: item.value,
                    submitKey: 'notAvailableAttachments'
                  }
                })
              "
              v-for="(item, index) in caseOptions[
                'not_available_for_attachments'
              ]"
              :key="index"
            >
              {{ item.value }}
            </div>
          </div>
          <ChildrenTeeth
            :commonTeeth="commonTeeth"
            :patientType="'gap'"
            :readOnly="isBackDisabled"
            v-show="diagnosisParams.notAvailableAttachments.key === 'true'"
            @updateTeeth="updateTeeth"
            class="mb40 mt40"
            :teethType="3"
          />
        </div>
      </div>
      <div class="select_type" id="6">
        <p class="select_type_title" style="margin-bottom: 24px;">
          6.{{ $t("cases.createDiagnosis.txt13") }}
          <span class="main_theme_color_999 fz14">{{
            `设计方案时，默认关闭散在间隙，如有必要请标记预留修复间隙。`
          }}</span>
        </p>
        <p class="problem_note" v-if="diagnosisProblem['14']">
          {{ diagnosisProblem["14"] }}
        </p>
        <IntervalCorrect
          ref="intervalCorrect"
          productType="I"
          @updateInterval="updateInterval"
          :uploadIntervalList="uploadIntervalList"
          :readOnly="isBackDisabled"
          class="mb40"
          :commonTeeth="commonTeeth"
        />
      </div>
      <div class="select_type" id="7">
        <p class="select_type_title mb20">
          7.{{ $t("cases.createDiagnosis.txt17") }}
        </p>
        <p class="problem_note" v-if="diagnosisProblem['19']">
          {{ diagnosisProblem["19"] }}
        </p>
        <div class="remark_box">
          <el-input
            :disabled="isBackDisabled"
            :autosize="{ minRows: 4, maxRows: 6 }"
            maxlength="2000"
            class="tex_b_a"
            type="textarea"
            show-word-limit
            v-model="diagnosisParams.specialInstructions"
            :placeholder="$t('common.common.qsrnr')"
          ></el-input>
        </div>
      </div>
    </div>
    <full-loading v-show="isLoading" />
  </div>
</template>

<script>
import FullLoading from "components/full-loading/full-loading";
import { getDictAllList } from "common/api/public";
import { columnDataTempRecordDetail } from "common/api/cases";
import $ from "jquery";
import { toNumber } from "../../../../../common/js/Fn";
import ChildrenTeeth from "../../../../../components/treatment-table/children-teeth/index";
import IntervalCorrect from "../../../../../components/treatment-table/interval-correct/index";
import AdjoinDislodge from "../../../../../components/treatment-table/children-adjoin-dislodge/index";
import ReserveDinterval from "../../../../../components/treatment-table/reserve-dinterval/index";
import { mapGetters, mapActions, mapState } from "vuex";
import {
  each,
  map,
  filter,
  get,
  isArray,
  keys,
  values,
  join,
  flattenDeep,
  find,
  isObject,
  extend,
  pickBy,
  split,
  isNumber,
  some
} from "lodash";
import {
  adultOptions,
  adultTeethList,
  adultOptionsKey
} from "../../../../../components/treatment-table/common";

export default {
  data() {
    return {
      caseOptions: {},
      diaKeyObj: {},
      rightHeight: "",
      diagnosisParams: {
        //1
        toothProblem: {},
        jawProblem: {},
        toothOtherProblem: "",

        //2
        toothProblemTwo: {},
        jawProblemTwo: {},
        toothOtherProblemTwo: "",

        //3
        angleClassification: {},
        sagittalMaxillomandibularClassification: {},
        clinicInfo: {},
        clinicOtherInfo: "",

        //4
        toCorrectToothJaw: {},

        // 6
        nonMoveableTeeth: "",
        // 7
        notAvailableAttachments: "",

        // 8
        leftSagittalRelation: {},
        leftSagittalRelationNote: {},
        leftSagittalRelationFar: {
          children: []
        },
        leftSagittalRelationBitJump: {
          children: []
        },
        leftSagittalRelationExtraction: {
          children: []
        },

        rightSagittalRelation: {},
        rightSagittalRelationNote: {},
        rightSagittalRelationFar: {
          children: []
        },
        rightSagittalRelationBitJump: {
          children: []
        },
        rightSagittalRelationExtraction: {
          children: []
        },

        //9
        coverInfo: {},

        // 10
        overbiteInfo: {},

        overbiteInfoOpen: {},
        overbiteInfoDeep: {},

        overbiteInfoIntrudeAnterior: {},
        overbiteInfoPosteriorsExrusion: {},
        overbiteOtherInfo: "",

        // 11
        frontToothInfo: {},

        // 12
        backToothInfo: {},

        //13
        middleLine: {},

        //14
        upjawGapCorrect: {},
        downjawGapCorrect: {},
        gapCorrect: {},

        // 15
        acceptWisdomTeethExtraction: {},
        acceptPremolarsExtraction: {},
        acceptDecayedTeethExtraction: {},
        acceptIpr: {},
        undergoTeethRestoration: {},
        implantAnchoragePlacement: {},
        cbctPhotography: {},
        orthodonticsTreatmentBefore: {},
        upjawLip: {},
        upjawExt: {},
        upjawFar: {},
        downjawExt: {},
        downjawLip: {},
        downjawFar: {},
        crowdCorrectSide: {},
        crowdCorrect: {},
        upjawSide: {},
        downjawSide: {},
        toothExtraction: {},
        upjawSideAnterior: {},
        upjawSideRight: {},
        upjawSideLeft: {},
        downjawSideAnterior: {},
        downjawSideRight: {},
        downjawSideLeft: {},

        //16
        growNailFlag: {},
        upGrowNailPosition: {},
        downGrowNailPosition: {},
        growNailPurpose: {},
        growNailFlagNote: "",

        //17
        underjawAbnormalFlag: {},
        underjawAbnormalFlagNote: "",

        //18
        needPassiveAppliance: {key:'true'},
        needPassiveApplianceNote: "",

        // 19
        specialInstructions: ""
      },
      commonTeeth: adultTeethList,
      lang: localStorage.getItem("user_lang") || "zh_CN",
      selectLeftKey: "1",
      leftList: [
        {
          title: this.$t("cases.createDiagnosis.zyjzmb")
        },
        {
          title: this.$t("cases.createDiagnosis.txt3")
        },
        {
          title: this.$t("cases.createDiagnosis.txt4")
        },
        {
          title: this.$t("cases.createDiagnosis.txt5")
        },
        {
          title: this.$t("cases.createDiagnosis.txt6")
        },
        {
          title: this.$t("cases.createDiagnosis.txt13")
        },
        {
          title: this.$t("cases.createDiagnosis.txt17")
        }
      ],
      leftTopObj: {},
      isScroll: true,
      needRTD: true,
      diagnosisProblem: {},
      details: {},
      isLoading: false,
      problemList: [],

      //间隙的矫治
      uploadIntervalList: [],

      // 8 二级弹层 选项
      sagittalRelationshipOption: [],
      sagittalRelationshipOptionTow: [],
      sagittalRelationshipOptionThree: [],
      sagittalRelationshipOptionFour: [],

      isResponse: 0,

      isProductErr: false
    };
  },
  computed: {
    ...mapState({
      language: state => state.language
    }),
    ...mapGetters({
      commonCaseDetail: "getCommonCaseDetail" //病例详情
    }),
    queryCaseId() {
      return this.$route.query.caseId;
    },
    queryCurePlanId() {
      return this.$route.query.curePlanId;
    },
    isBackDisabled() {
      return false;
      // return get(this.details, "status") === "to_resubmit" && this.isProductErr;
    },
    isDisabledPassivity() {
      return ["upjaw", "downjaw"].includes(
        this.diagnosisParams.toCorrectToothJaw.key
      );
    }
  },
  methods: {
    ...mapActions({
      getCaseCureDetail: "actionGetCaseCureDetail", //治疗表详情
      getCommonCaseDetail: "actionGetCommonCaseDetail" //病例详情
    }),
    isArray,
    map,
    keys,
    values,
    filter,
    join,
    flattenDeep,
    find,
    get,
    updateInterval(list) {
      this.uploadIntervalList = list;
    },
    overbiteText() {
      let list = this.diagnosisParams["overbiteInfo"]["children"];
      let ls = map(list, (item, index) => {
        if (item.children) {
          return (
            item.value +
            (["press_front_rise_back", "press_front"].includes(item.key)
              ? `${(item.children.length ? "-" : "") +
                  map(item.children, it => it.value).join("、")}`
              : "") +
            (list.length === 1 ? "" : ",")
          );
        }
        return (
          item.value +
          (index === list.length - 1
            ? ""
            : ["press_front_rise_back", "press_front"].includes(item.key)
            ? ","
            : "、")
        );
      });
      return ls.join("");
    },
    disposeNumber({ key, value }) {
      if (value === "") return (key.input = "");
      key.input =
        isNumber(toNumber(value)) && toNumber(value) <= 10
          ? toNumber(value)
          : "";
    },
    leftHandleScroll(val) {
      let div = this.$refs.scrollbar && this.$refs.scrollbar.$refs["wrap"];
      if (val < 0 && !div) return;
      div.scrollTop = val;
    },
    updateTeeth(data) {
      this.commonTeeth = data;
    },
    sortOptionThree(wrapFiled, index, parentKey) {
      let list = [];
      each(adultOptions[parentKey], i => {
        each(adultOptionsKey, item => {
          each(
            this.diagnosisParams[wrapFiled]["children"][index]["children"],
            it => {
              if (item === parentKey) {
                if (i === it.key) {
                  list.push(it);
                }
              }
            }
          );
        });
      });
      this.diagnosisParams[wrapFiled]["children"][index]["children"] = list;
    },
    sortOption(wrapFiled) {
      let list = [];
      each(adultOptions[wrapFiled], item => {
        each(this.diagnosisParams[wrapFiled]["children"], it => {
          if (item === it.key) {
            list.push(it);
          }
        });
      });
      this.diagnosisParams[wrapFiled]["children"] = list;
    },
    levelThreeClick({ wrapFiled, parentKey, addItem }) {
      const { submitKey, key } = addItem;
      if (!key || this.isBackDisabled) return;
      each(this.diagnosisParams[wrapFiled]["children"], (item, index) => {
        if (item.key === parentKey) {
          let list = this.diagnosisParams[wrapFiled]["children"][index]
            .children;
          if (isArray(list) && list.length) {
            let isSame;
            each(
              this.diagnosisParams[wrapFiled]["children"][index].children,
              (item, index) => {
                if (item.key === key && submitKey === item.submitKey) {
                  isSame = index;
                }
              }
            );
            if (isNumber(isSame)) {
              return this.diagnosisParams[wrapFiled]["children"][index][
                "children"
              ].splice(isSame, 1);
            }
            this.diagnosisParams[wrapFiled]["children"][index].children.push(
              addItem
            );
          } else {
            this.$set(
              this.diagnosisParams[wrapFiled]["children"][index],
              "children",
              [addItem]
            );
          }
          each(adultOptionsKey, it => {
            if (parentKey === it) {
              this.sortOptionThree(wrapFiled, index, parentKey);
            }
          });
        }
      });
    },
    levelTowClick({
      wrapFiled,
      radio = false,
      addItem,
      noClear = false,
      multipleRadio = false,
      multipleRadios = false,
      oneOfMore = false
    }) {
      // radio：是否单选,  multipleRadio, multipleRadios：1对1   oneOfMore: 1对多  addItem: 想要添加的数据
      // const submitKey = addItem.submitKey;
      const { key, submitKey } = addItem;
      if (!key || this.isBackDisabled) return;
      //输入框的清空
      let inputKeyObj = {
        growNailPurpose: "growNailFlagNote"
      };
      let inputKeyList = keys(inputKeyObj);
      if (
        inputKeyList.includes(submitKey) &&
        ["other", "false"].includes(key)
      ) {
        this.$set(this.diagnosisParams, inputKeyObj[submitKey], "");
      }

      if (radio) {
        if (!this.diagnosisParams[wrapFiled]["children"]) {
          this.$set(this.diagnosisParams[wrapFiled], "children", []);
        }
        let index;
        each(this.diagnosisParams[wrapFiled]["children"], (dia, dix) => {
          if (dia.submitKey === addItem.submitKey) {
            index = dix;
          }
        });
        if (index !== undefined) {
          return this.diagnosisParams[wrapFiled]["children"].splice(
            index,
            1,
            addItem
          );
        }
        this.diagnosisParams[wrapFiled]["children"].push(addItem);
      } else {
        let keyList = map(
          this.diagnosisParams[wrapFiled]["children"],
          item => item.key
        );

        if (isArray(this.diagnosisParams[wrapFiled]["children"])) {
          //1对1
          if (multipleRadio) {
            if (
              noClear &&
              map(
                this.diagnosisParams[wrapFiled]["children"],
                item => item.key
              ).includes(key)
            ) {
              return "";
            }

            if (multipleRadio.indexOf(key) !== -1) {
              let list = filter(multipleRadio, it => it !== key);
              each(keyList, (item, index) => {
                list.indexOf(item) !== -1 &&
                  this.diagnosisParams[wrapFiled]["children"].splice(index, 1);
              });
            }
          }

          if (multipleRadios) {
            if (
              noClear &&
              map(
                this.diagnosisParams[wrapFiled]["children"],
                item => item.key
              ).includes(key)
            ) {
              return "";
            }

            if (multipleRadios.indexOf(key) !== -1) {
              let list = filter(multipleRadios, it => it !== key);
              each(keyList, (item, index) => {
                list.indexOf(item) !== -1 &&
                  this.diagnosisParams[wrapFiled]["children"].splice(index, 1);
              });
            }
          }

          //1对多互斥
          if (oneOfMore) {
            let { one, more } = oneOfMore;
            if (key === one) {
              this.diagnosisParams[wrapFiled]["children"] = filter(
                this.diagnosisParams[wrapFiled]["children"],
                it => more.indexOf(it.key) === -1
              );
            }
            if (more.indexOf(key) !== -1) {
              this.diagnosisParams[wrapFiled]["children"] = filter(
                this.diagnosisParams[wrapFiled]["children"],
                it => it.key !== one
              );
            }
          }

          let isSame;
          each(this.diagnosisParams[wrapFiled]["children"], (item, index) => {
            if (item.key === key && submitKey === item.submitKey) {
              isSame = index;
            }
          });
          if (isNumber(isSame)) {
            // 成人 8矢状关系 多选 三级清空
            if (
              [
                "rightSagittalRelationNote",
                "leftSagittalRelationNote"
              ].includes(submitKey)
            ) {
              let isLeft = submitKey === "leftSagittalRelationNote";
              let resetKey = {
                far_grind: isLeft
                  ? "leftSagittalRelationFar"
                  : "rightSagittalRelationFar",
                bit_jump: isLeft
                  ? "leftSagittalRelationBitJump"
                  : "rightSagittalRelationBitJump",
                tooth_extraction: isLeft
                  ? "leftSagittalRelationExtraction"
                  : "rightSagittalRelationExtraction"
              }[key];
              this.$set(this.diagnosisParams, resetKey, { children: [] });
            }
            return this.diagnosisParams[wrapFiled]["children"].splice(
              isSame,
              1
            );
          }
          this.diagnosisParams[wrapFiled]["children"].push(addItem);
        } else {
          this.$set(this.diagnosisParams[wrapFiled], "children", []);
          this.diagnosisParams[wrapFiled]["children"].push(addItem);
        }
        each(adultOptionsKey, it => {
          if (it === wrapFiled) {
            this.sortOption(it);
          }
        });
      }
    },
    levelOneClick({
      addItem,
      multipleRadio,
      radio = true,
      oneOfMore = false,
      noClear = false
    }) {
      let { key, submitKey } = addItem;
      if (!key || this.isBackDisabled) return;
      // if (
      //   ["upjaw", "downjaw"].includes(key) &&
      //   submitKey === "toCorrectToothJaw"
      // ) {
      //   if (this.diagnosisParams.needPassiveAppliance.key !== "false") {
      //     this.diagnosisParams.needPassiveAppliance = {
      //       submitKey: "needPassiveAppliance",
      //       key: "false",
      //       value: this.lang === "zh_CN" ? "否" : "No"
      //     };
      //     this.diagnosisParams.needPassiveApplianceNote = "";
      //   }
      // }
      // if (
      //   submitKey === "needPassiveAppliance" &&
      //   key === "true" &&
      //   this.isDisabledPassivity
      // ) {
      //   return "";
      // }

      let frontToothInDefault = () => {
        let frontToothInfoKetList = map(
          this.diagnosisParams[submitKey].children,
          item => item.key
        );
        let frontToothInfoValue =
          !frontToothInfoKetList.includes("front_tooth_back") &&
          !frontToothInfoKetList.includes("front_tooth_unback");
        if (key === "correct_front_reverse" && frontToothInfoValue) {
          let obj = {
            key: "front_tooth_unback",
            submitKey: "correctFrontReverse",
            value:
              this.lang === "zh_CN"
                ? "前牙不可后退至切对切"
                : "Anterior Edge to Edge Occlusion cannot be Achieved"
          };
          this.diagnosisParams[submitKey]["children"].push(obj);
        }
      };

      //输入框的清空
      let inputKeyObj = {
        toothProblem: "toothOtherProblem",
        toothProblemTwo: "toothOtherProblemTwo",
        clinicInfo: "clinicOtherInfo",
        underjawAbnormalFlag: "underjawAbnormalFlagNote",
        needPassiveAppliance: "needPassiveApplianceNote"
      };
      let inputKeyList = keys(inputKeyObj);
      if (
        inputKeyList.includes(submitKey) &&
        ["other", "false"].includes(key)
      ) {
        this.$set(this.diagnosisParams, inputKeyObj[submitKey], "");
      }

      //组件值清空
      let componentsObj = {
        crowdCorrectSide:
          this.$refs["adjoinDislodge"] &&
          this.$refs["adjoinDislodge"].clearTeeth,
        toothExtraction:
          this.$refs["teethComponents"] &&
          this.$refs["teethComponents"].clearTeethType,
        notAvailableAttachments:
          this.$refs["teethComponents"] &&
          this.$refs["teethComponents"].clearTeethType,
        nonMoveableTeeth:
          this.$refs["teethComponents"] &&
          this.$refs["teethComponents"].clearTeethType,
        gapCorrect:
          this.$refs["intervalCorrect"] &&
          this.$refs["intervalCorrect"].clearTeeth
      };
      let componentsKeyList = keys(componentsObj);
      if (
        componentsKeyList.includes(submitKey) &&
        [
          "unchooseable",
          "tooth_extraction_options_nono",
          "false",
          "reserved_gap"
        ].includes(key)
      ) {
        let type = {
          toothExtraction: 4,
          notAvailableAttachments: 3,
          nonMoveableTeeth: 2
        }[submitKey];
        componentsObj[submitKey] && componentsObj[submitKey](type ? type : "");
      }

      // //拥挤的矫治互斥
      // let optionList = ['upjawExt','upjawLip','upjawFar','downjawExt',
      //   'downjawLip','downjawFar','upjawSide','downjawSide'];
      // if(optionList.includes(submitKey)){
      //   this.$set(this.diagnosisParams,'crowdCorrect',{});
      // }

      // 单选
      if (radio) {
        if (this.diagnosisParams[submitKey].key === key && !noClear) {
          return (this.diagnosisParams[submitKey] = {});
        }
        if (this.diagnosisParams[submitKey].key === key) return;
        if (submitKey === "overbiteInfo") {
          this.diagnosisParams.overbiteOtherInfo = "";
        }
        this.$set(this.diagnosisParams, submitKey, addItem);

        //拥挤的矫治  3D设计后再确定重置
        // if(key === 'after_design'){
        //   each(optionList, item => {
        //     this.$set(this.diagnosisParams,item,{})
        //   });
        // }

        //拟矫治牙颌 默认选中第一项
        if (
          submitKey === "toCorrectToothJaw" &&
          ["upjaw", "downjaw"].includes(key)
        ) {
          let list =
            key === "upjaw"
              ? this.caseOptions["to_correct_tooth_upjaw"]
              : this.caseOptions["to_correct_tooth_downjaw"];
          let currentObj = this.diagnosisParams.toCorrectToothJaw;
          let obj = extend({}, currentObj, {
            children: [
              {
                key: list[0].key,
                value: list[0].value,
                submitKey: {
                  upjaw: "toCorrectToothUpjaw",
                  downjaw: "toCorrectToothDownjaw"
                }[key]
              }
            ]
          });
          this.$set(this.diagnosisParams, "toCorrectToothJaw", obj);
        }
      } else {
        if (isArray(this.diagnosisParams[submitKey]["children"])) {
          let itx;
          each(this.diagnosisParams[submitKey]["children"], (item, index) => {
            if (item.key === key) {
              itx = index;
            }
          });
          if (itx !== undefined && !noClear) {
            return this.diagnosisParams[submitKey]["children"].splice(itx, 1);
          }
          if (itx !== undefined && noClear) {
            frontToothInDefault();
            return "";
          }

          //1对多互斥
          if (oneOfMore) {
            let { one, more } = oneOfMore;
            if (key === one) {
              this.diagnosisParams[submitKey]["children"] = [];
              this.diagnosisParams[submitKey]["children"] = filter(
                this.diagnosisParams[submitKey]["children"],
                it => more.indexOf(it.key) === -1
              );
            }
            if (more.indexOf(key) !== -1) {
              this.diagnosisParams[submitKey]["children"] = filter(
                this.diagnosisParams[submitKey]["children"],
                it => it.key !== one
              );
            }
          }

          this.diagnosisParams[submitKey]["children"].push(addItem);
        } else {
          this.$set(this.diagnosisParams[submitKey], "children", []);
          this.diagnosisParams[submitKey]["children"].push(addItem);
        }

        frontToothInDefault();

        each(adultOptionsKey, it => {
          if (it === submitKey) {
            this.sortOption(it);
          }
        });
      }
    },
    saveData() {
      let dia = this.diagnosisParams;
      let obj = {};
      for (let i in dia) {
        let k;
        if (isObject(dia[i]) && keys(dia[i]).length) {
          // 一级对象
          if (dia[i].key) {
            obj[i] = dia[i].key;
          }
          for (let childrenI in dia[i]) {
            // 对象内数组
            if (
              isArray(dia[i][childrenI]) &&
              dia[i][childrenI].length &&
              dia[i].key
            ) {
              let length = Array.from(
                new Set(map(dia[i][childrenI], item => item.submitKey))
              ).length;
              let threeList = flattenDeep(
                filter(map(dia[i][childrenI], item => item["children"]))
              );
              if (threeList.length) {
                let listKeyList = [];
                each(threeList, item => {
                  if (!listKeyList.includes(item.submitKey)) {
                    listKeyList.push(item.submitKey);
                  }
                });
                // 8 每个一个字段
                if (listKeyList.length > 1) {
                  each(listKeyList, submitKey => {
                    obj[submitKey] = map(
                      filter(threeList, item => item.submitKey === submitKey),
                      it => it.key
                    ).join();
                  });
                } else {
                  //10 多用一个字段
                  let str = map(threeList, item => {
                    k = item.submitKey;
                    if (item.inputKey) {
                      obj[item.inputKey] = item.input;
                    }
                    return item.key;
                  }).join();
                  obj[k] = str;
                }
              }

              if (length === 1) {
                let str = map(dia[i][childrenI], item => {
                  k = item.submitKey;
                  if (item.inputKey) {
                    obj[item.inputKey] = item.input;
                  }
                  return item.key;
                }).join();
                obj[k] = str;
              } else if (length > 1) {
                let soleList = [];
                each(dia[i][childrenI], item => {
                  if (soleList.indexOf(item.submitKey) === -1) {
                    soleList.push(item.submitKey);
                  }
                  if (item.inputKey) {
                    obj[item.inputKey] = item.input;
                  }
                });
                each(soleList, item => {
                  obj[item] = map(
                    filter(dia[i][childrenI], it => it.submitKey === item),
                    a => a.key
                  ).join();
                });
              }
            }

            if (
              !dia[i].key &&
              isArray(dia[i][childrenI]) &&
              dia[i][childrenI].length
            ) {
              //用于区分数组内是否有不同的submitKey   例子11
              let list = [];
              each(dia[i][childrenI], item => {
                if (!list.includes(item.submitKey)) {
                  list.push(item.submitKey);
                }
              });
              if (list.length > 1) {
                each(list, submitKey => {
                  obj[submitKey] = map(
                    filter(
                      dia[i][childrenI],
                      item => item.submitKey === submitKey
                    ),
                    it => it.key
                  ).join();
                });
              } else {
                let str = map(dia[i][childrenI], item => {
                  if (item.inputKey) {
                    obj[item.inputKey] = item.input;
                  }
                  return item.key;
                }).join();
                obj[i] = str;
              }
            }
          }
        }
      }
      let {
        overbiteOtherInfo,
        specialInstructions,
        toothOtherProblem,
        toothOtherProblemTwo,
        clinicOtherInfo,
        underjawAbnormalFlagNote,
        growNailFlagNote,
        needPassiveApplianceNote
      } = dia;
      let intervalList = map(
        filter(this.uploadIntervalList, item => item.nextNumber),
        it => {
          return extend(
            {},
            it,
            pickBy({ type: 5, spacingType: it.maintain ? 4 : "" })
          );
        }
      );
      let list = [];
      each(this.commonTeeth, item => {
        if (item.noMove) {
          list.push(extend({}, item, { type: 2 }));
        }
        if (item.noDesign) {
          list.push(extend({}, item, { type: 3 }));
        }
        if (item.remove) {
          list.push(extend({}, item, { type: 4 }));
        }
        if (item.deficiency) {
          list.push(extend({}, item, { type: 1 }));
        }
        if (item.number > 48) {
          list.push(extend({}, item, { type: 7 }));
        }
      });
      let toothInfoList = filter(
        [...list, ...intervalList],
        item => item.number > 0
      );

      let imgObj = pickBy({
        sideFacePicture: dia.sideFacePicture, // 侧面像
        frontFacePicture: dia.frontFacePicture, // 正面像
        smilePicture: dia.smilePicture, // 微笑像
        upToothPicture: dia.upToothPicture, // 上牙列像
        downToothPicture: dia.downToothPicture, // 下牙列像
        mouthRightPicture: dia.mouthRightPicture, // 口内右侧位像
        mouthFrontPicture: dia.mouthFrontPicture, // 口内正位像
        mouthLeftPicture: dia.mouthLeftPicture, // 口内左侧位像
        xrayCurvePicture: dia.xrayCurvePicture, // 全颌曲面断层片
        xraySidePicture: dia.xraySidePicture, // 头颅侧位定位片
        anteriorOverjetPicture: dia.anteriorOverjetPicture, //前牙覆盖像
        otherPicture:
          dia.otherPicture && dia.otherPicture.length ? dia.otherPicture : "" // 其他影像资料
      });
      let {
        acceptWisdomTeethExtraction,
        acceptPremolarsExtraction,
        acceptDecayedTeethExtraction,
        acceptIpr,
        undergoTeethRestoration,
        implantAnchoragePlacement,
        cbctPhotography,
        orthodonticsTreatmentBefore
      } = obj;
      let PicObj = {};
      for (const item in imgObj) {
        if (item !== "otherPicture") {
          PicObj[item] = JSON.stringify(this.isJSON(imgObj[item]));
        } else {
          try {
            JSON.parse(imgObj[item]);
            PicObj[item] = imgObj[item];
          } catch (error) {
            let list = [];
            map(imgObj[item].split(","), item => {
              list.push(this.isJSON(item));
            });
            PicObj[item] = JSON.stringify(list);
          }
        }
      }
      obj = extend(
        {},
        PicObj,
        obj,
        {
          acceptWisdomTeethExtraction: this.needRTD
            ? acceptWisdomTeethExtraction
            : "",
          acceptPremolarsExtraction: this.needRTD
            ? acceptPremolarsExtraction
            : "",
          acceptDecayedTeethExtraction: this.needRTD
            ? acceptDecayedTeethExtraction
            : "",
          acceptIpr: this.needRTD ? acceptIpr : "",
          undergoTeethRestoration: this.needRTD ? undergoTeethRestoration : "",
          implantAnchoragePlacement: this.needRTD
            ? implantAnchoragePlacement
            : "",
          cbctPhotography: this.needRTD ? cbctPhotography : "",
          orthodonticsTreatmentBefore: this.needRTD
            ? orthodonticsTreatmentBefore
            : ""
        },
        pickBy({
          overbiteOtherInfo,
          specialInstructions,
          toothOtherProblem,
          toothOtherProblemTwo,
          clinicOtherInfo,
          underjawAbnormalFlagNote,
          growNailFlagNote,
          toothInfoList: toothInfoList.filter(item => item.number !== "0"),
          needPassiveApplianceNote
        })
      );
      return pickBy(obj);
    },
    isJSON(str) {
      if (str == "") {
        return "";
      }
      if (typeof str == "string") {
        try {
          JSON.parse(str);
          let obj = JSON.parse(str);
          if (!obj.nanoId) {
            obj.nanoId = obj.ETag;
          }
          return obj;
        } catch (error) {
          return { ETag: str, nanoId: str };
        }
      }
    },
    //页面滚动
    handleScroll() {
      if (this.isScroll) {
        this.isScroll = false;
        return new Promise(resolve => {
          return setTimeout(() => {
            const TOP =
              document.documentElement && document.documentElement.scrollTop;
            let obj = {};
            let {
              d1,
              d2,
              d3,
              d4,
              d5,
              d6,
              d7,
              d8,
              d9,
              d10,
              d11,
              d12,
              d13,
              d14,
              d15,
              d16,
              d17,
              d18
            } = this.leftTopObj;
            obj = {
              [TOP < d1]: "d1",
              [TOP < d2 && TOP >= d1]: "d1",
              [TOP < d3 && TOP >= d2]: "d2",
              [TOP < d4 && TOP >= d3]: "d3",
              [TOP < d5 && TOP >= d4]: "d4",
              [TOP < d6 && TOP >= d5]: "d5",
              [TOP < d7 && TOP >= d6]: "d6"
            };
            this.selectLeftKey = obj[true] || "d7";
            this.leftHandleScroll(Math.floor(TOP / 8));
            this.isScroll = true;
            return resolve();
          }, 500);
        });
      }
    },
    initOption({ type, submitKey, addItem }) {
      // type: 1 一级对象, 2 数组
      let data = this.details;
      let valueData = this.diaKeyObj;
      let dia = this.diagnosisParams;
      if (data[submitKey]) {
        if (type === 1) {
          dia[submitKey] = {
            submitKey,
            key: data[submitKey],
            value: valueData[data[submitKey]]
          };
        } else if (type === 2) {
          dia[submitKey] = {
            children: map(
              data[submitKey] && data[submitKey].split(","),
              item => {
                return { key: item, value: valueData[item], submitKey };
              }
            )
          };
        } else if (type === 3) {
          this.$set(this.diagnosisParams, submitKey, addItem);
        }
      }
    },
    selectLeft(key, left) {
      this.isScroll = false;
      let leftStr;
      if (left === "left") {
        leftStr = `${+key + 1}`;
        document.documentElement.scrollTop =
          document.getElementById(leftStr).offsetTop - 20;
      } else {
        leftStr = `${key}`;
        document.documentElement.scrollTop =
          document.getElementById(key).offsetTop - 20;
      }
      this.selectLeftKey = "d" + leftStr;
      this.leftHandleScroll(document.documentElement.scrollTop / 3);
      setTimeout(() => {
        this.isScroll = true;
      }, 1000);
    },
    // 数据初始化
    initParams(data) {
      //预览提交的返回
      const diagnsisLeftKey = sessionStorage.getItem("diagnosisKey") || 1;
      this.selectLeft(
        diagnsisLeftKey !== "undefined" ? diagnsisLeftKey : 1,
        "init"
      );
      let imgObj = pickBy({
        sideFacePicture: data.sideFacePicture, // 侧面像
        frontFacePicture: data.frontFacePicture, // 正面像
        smilePicture: data.smilePicture, // 微笑像
        upToothPicture: data.upToothPicture, // 上牙列像
        downToothPicture: data.downToothPicture, // 下牙列像
        mouthRightPicture: data.mouthRightPicture, // 口内右侧位像
        mouthFrontPicture: data.mouthFrontPicture, // 口内正位像
        mouthLeftPicture: data.mouthLeftPicture, // 口内左侧位像
        xrayCurvePicture: data.xrayCurvePicture, // 全颌曲面断层片
        xraySidePicture: data.xraySidePicture, // 头颅侧位定位片
        otherPicture: data.otherPicture, // 其他影像资料
        anteriorOverjetPicture: data.anteriorOverjetPicture //前牙覆盖像
      });
      extend(this.diagnosisParams, imgObj);
      let dia = this.diagnosisParams;
      let valueData = this.diaKeyObj;

      this.diagnosisParams.digitalModelFile = data.digitalModelFile; // 数字模型
      this.diagnosisParams.siliconeRubberModel = data.siliconeRubberModel; // 硅橡胶模型

      let noMoveList = filter(data.toothInfoList, item => item.type === "2");
      let deficiencyList = filter(
        data.toothInfoList,
        item => item.type === "1"
      );
      let noDesignList = filter(data.toothInfoList, item => item.type === "3");
      let removeList = filter(data.toothInfoList, item => item.type === "4");
      let mainList = filter(
        data.toothInfoList,
        item => item.type === "7"
      ).concat(adultTeethList);
      let untreatedList = [
        ...noMoveList,
        ...noDesignList,
        ...removeList,
        ...deficiencyList
      ];
      if (untreatedList.length) {
        let teethTypeObj = {
          2: "noMove",
          3: "noDesign",
          4: "remove",
          1: "deficiency"
        };
        this.commonTeeth = filter(
          map(mainList, item => {
            let obj;
            each(untreatedList, it => {
              if (item.number === it.number && !item.nextNumber) {
                obj = extend({}, item, obj, {
                  [teethTypeObj[it.type]]: teethTypeObj[it.type]
                });
              }
            });
            return obj ? obj : item;
          })
        );
      } else {
        this.commonTeeth = data.toothInfoList
          ? filter(data.toothInfoList, item => !item.nextNumber).concat(
              adultTeethList
            )
          : adultTeethList;
      }

      //间隙的矫治
      this.uploadIntervalList = filter(
        data.toothInfoList,
        item => item.type === "5"
      );

      // 1
      this.initOption({ type: 2, submitKey: "toothProblem" });
      dia.toothOtherProblem = data.toothOtherProblem || "";
      this.initOption({ type: 2, submitKey: "jawProblem" });

      // 2
      this.initOption({ type: 2, submitKey: "toothProblemTwo" });
      this.initOption({ type: 1, submitKey: "jawProblemTwo" });
      dia.toothOtherProblemTwo = data.toothOtherProblemTwo || "";

      // 3
      this.initOption({ type: 1, submitKey: "angleClassification" });
      this.initOption({ type: 2, submitKey: "clinicInfo" });
      this.initOption({
        type: 1,
        submitKey: "sagittalMaxillomandibularClassification"
      });
      dia.clinicOtherInfo = data.clinicOtherInfo;

      // 4
      if (data["toCorrectToothJaw"]) {
        let isShowTowKey =
          data.toCorrectToothUpjaw || data.toCorrectToothDownjaw;
        let twoKeyList = ["upjaw", "downjaw"].includes(data.toCorrectToothJaw);
        let twoSubmitKey =
          isShowTowKey && twoKeyList
            ? data.toCorrectToothUpjaw
              ? "toCorrectToothUpjaw"
              : "toCorrectToothDownjaw"
            : "";
        let list;
        if (twoSubmitKey) {
          list = map(data[twoSubmitKey] && split(data[twoSubmitKey]), item => {
            return pickBy({
              key: item,
              value: valueData[item],
              submitKey: twoSubmitKey
            });
          });
        }
        let addItem = pickBy({
          submitKey: "toCorrectToothJaw",
          key: data["toCorrectToothJaw"],
          value: valueData[data["toCorrectToothJaw"]],
          children: isArray(list) && list.length ? list : ""
        });
        this.initOption({ type: 3, submitKey: "toCorrectToothJaw", addItem });
      }

      // 6
      if (data["nonMoveableTeeth"]) {
        this.initOption({ type: 1, submitKey: "nonMoveableTeeth" });
      }

      // 7
      if (data["notAvailableAttachments"]) {
        this.initOption({ type: 1, submitKey: "notAvailableAttachments" });
      }

      // 8
      if (data["rightSagittalRelation"]) {
        let list = split(data["rightSagittalRelationNote"], ",");
        let addList = map(list, item => {
          return pickBy({
            key: item,
            submitKey: "rightSagittalRelationNote",
            value: valueData[item]
          });
        });
        let addItem = pickBy({
          children: isArray(addList) && addList.length ? addList : "",
          submitKey: "rightSagittalRelation",
          value: valueData[data["rightSagittalRelation"]],
          key: data["rightSagittalRelation"]
        });
        this.initOption({
          type: 3,
          submitKey: "rightSagittalRelation",
          addItem
        });
        let threeList = [
          "rightSagittalRelationFar",
          "rightSagittalRelationBitJump",
          "rightSagittalRelationExtraction"
        ];
        each(threeList, submitKey => {
          if (data[submitKey]) {
            this.initOption({ type: 2, submitKey });
          }
        });
      }
      if (data["leftSagittalRelation"]) {
        let list = split(data["leftSagittalRelationNote"], ",");
        let addList = map(list, item => {
          return pickBy({
            key: item,
            submitKey: "leftSagittalRelationNote",
            value: valueData[item]
          });
        });
        let addItem = pickBy({
          children: isArray(addList) && addList.length ? addList : "",
          submitKey: "leftSagittalRelation",
          value: valueData[data["leftSagittalRelation"]],
          key: data["leftSagittalRelation"]
        });
        this.initOption({
          type: 3,
          submitKey: "leftSagittalRelation",
          addItem
        });

        let threeList = [
          "leftSagittalRelationFar",
          "leftSagittalRelationBitJump",
          "leftSagittalRelationExtraction"
        ];
        each(threeList, submitKey => {
          if (data[submitKey]) {
            this.initOption({ type: 2, submitKey });
          }
        });
      }

      // 9
      if (data["coverInfo"]) {
        this.initOption({ type: 1, submitKey: "coverInfo" });
      }

      // 10
      if (data["overbiteInfo"]) {
        let overbiteInfoOpen = filter(split(data["overbiteInfoOpen"], ","));
        let overbiteInfoDeep = filter(split(data["overbiteInfoDeep"], ","));
        let list = [...overbiteInfoOpen, ...overbiteInfoDeep];
        let twoList = ["press_front", "press_front_rise_back"];
        let addList;
        let threeKey = {
          press_front: data["overbiteInfoIntrudeAnterior"],
          press_front_rise_back: data["overbiteInfoPosteriorsExrusion"]
        };
        let threeSubKey = {
          press_front: "overbiteInfoIntrudeAnterior",
          press_front_rise_back: "overbiteInfoPosteriorsExrusion"
        };
        let isThree = filter([
          data["overbiteInfoIntrudeAnterior"],
          data["overbiteInfoPosteriorsExrusion"]
        ]);
        addList = map(list, item => {
          return pickBy({
            key: item,
            submitKey: overbiteInfoOpen.length
              ? "overbiteInfoOpen"
              : "overbiteInfoDeep",
            value: valueData[item],
            children:
              twoList.includes(item) && isThree.length
                ? map(split(threeKey[item], ","), three => {
                    return {
                      key: three,
                      value: valueData[three],
                      submitKey: threeSubKey[item]
                    };
                  })
                : ""
          });
        });
        let addItem = pickBy({
          children: isArray(addList) && addList.length ? addList : "",
          submitKey: "overbiteInfo",
          value: valueData[data["overbiteInfo"]],
          key: data["overbiteInfo"]
        });
        this.initOption({ type: 3, submitKey: "overbiteInfo", addItem });
        dia.overbiteOtherInfo = data.overbiteOtherInfo;
      }

      // 11
      if (data["frontToothInfo"]) {
        let addList = filter(
          map(split(data["frontToothInfo"], ","), item => {
            return pickBy({
              key: item,
              value: valueData[item],
              submitKey: "frontToothInfo"
            });
          }),
          it => it.key
        );
        let addList2 = filter(
          map(split(data["correctFrontReverse"], ","), item => {
            return pickBy({
              key: item,
              value: valueData[item],
              submitKey: "correctFrontReverse"
            });
          }),
          it => it.key
        );
        let addList3 = filter(
          map(split(data["correctFrontOpposite"], ","), item => {
            return {
              key: item,
              value: valueData[item],
              submitKey: "correctFrontOpposite"
            };
          }),
          it => it.key
        );
        let list = [...addList, ...addList2, ...addList3];
        let addItem = pickBy({
          children: isArray(list) && list.length ? list : ""
        });
        this.initOption({ type: 3, submitKey: "frontToothInfo", addItem });
      }

      // 12
      if (data["backToothInfo"]) {
        let addList = map(split(data["backToothInfoCorrect"], ","), item => {
          return {
            key: item,
            value: valueData[item],
            submitKey: "backToothInfoCorrect"
          };
        });
        let addItem = pickBy({
          children: isArray(addList) && addList.length ? addList : "",
          submitKey: "backToothInfo",
          value: valueData[data["backToothInfo"]],
          key: data["backToothInfo"]
        });
        this.initOption({ type: 3, submitKey: "backToothInfo", addItem });
      }

      // 13
      if (data["middleLine"]) {
        let twoSubmitKey = filter([
          data["upjawMiddleLineNote"],
          data["downjawMiddleLineNote"]
        ]);
        let addList = [];
        let obj, obj1;
        if (twoSubmitKey.length) {
          obj = {
            key: data["upjawMiddleLineNote"],
            value: valueData[data["upjawMiddleLineNote"]],
            submitKey: "upjawMiddleLineNote",
            input: data["upjawMiddleLine"],
            inputKey: "upjawMiddleLine"
          };
          obj1 = {
            key: data["downjawMiddleLineNote"],
            value: valueData[data["downjawMiddleLineNote"]],
            submitKey: "downjawMiddleLineNote",
            input: data["downjawMiddleLine"],
            inputKey: "downjawMiddleLine"
          };
          addList.push(obj, obj1);
        }
        let addItem = pickBy({
          children: isArray(addList) && addList.length ? addList : "",
          submitKey: "middleLine",
          value: valueData[data["middleLine"]],
          key: data["middleLine"]
        });
        this.initOption({ type: 3, submitKey: "middleLine", addItem });
      }

      //14
      if (data["upjawGapCorrect"]) {
        this.initOption({ type: 1, submitKey: "upjawGapCorrect" });
      }
      if (data["downjawGapCorrect"]) {
        this.initOption({ type: 1, submitKey: "downjawGapCorrect" });
      }
      if (data["gapCorrect"]) {
        this.initOption({ type: 2, submitKey: "gapCorrect" });
      }

      // 15{
      if (data["acceptWisdomTeethExtraction"]) {
        this.initOption({ type: 1, submitKey: "acceptWisdomTeethExtraction" });
      }
      if (data["acceptPremolarsExtraction"]) {
        this.initOption({ type: 1, submitKey: "acceptPremolarsExtraction" });
      }
      if (data["acceptDecayedTeethExtraction"]) {
        this.initOption({ type: 1, submitKey: "acceptDecayedTeethExtraction" });
      }
      if (data["acceptIpr"]) {
        this.initOption({ type: 1, submitKey: "acceptIpr" });
      }
      if (data["undergoTeethRestoration"]) {
        this.initOption({ type: 1, submitKey: "undergoTeethRestoration" });
      }
      if (data["implantAnchoragePlacement"]) {
        this.initOption({ type: 1, submitKey: "implantAnchoragePlacement" });
      }
      if (data["cbctPhotography"]) {
        this.initOption({ type: 1, submitKey: "cbctPhotography" });
      }
      if (data["orthodonticsTreatmentBefore"]) {
        this.initOption({ type: 1, submitKey: "orthodonticsTreatmentBefore" });
      }
      let optionList = [
        "upjawExt",
        "upjawLip",
        "upjawFar",
        "downjawExt",
        "downjawLip",
        "downjawFar",
        "crowdCorrectSide",
        "crowdCorrect"
      ];
      for (let i in data) {
        each(optionList, correct => {
          if (correct === i) {
            if (["upjawFar", "downjawFar"].includes(correct)) {
              let list = map(split(data[i], ","), item => {
                return {
                  key: item,
                  value: valueData[item],
                  submitKey: correct
                };
              });
              this.$set(
                this.diagnosisParams,
                i,
                pickBy({
                  children: list.length ? list : ""
                })
              );
            } else {
              this.$set(this.diagnosisParams, i, {
                key: data[i],
                value: valueData[data[i]],
                submitKey: correct
              });
            }
          }
        });
      }
      if (data["upjawSide"]) {
        let addList;
        let optionsList = [
          "upjawSideAnterior",
          "upjawSideRight",
          "upjawSideLeft"
        ];
        addList = filter(
          map(optionsList, item => {
            return {
              key: data[item],
              value: valueData[data[item]],
              submitKey: item
            };
          }),
          it => it.key
        );
        let addItem = pickBy({
          children: isArray(addList) && addList.length ? addList : "",
          submitKey: "upjawSide",
          value: valueData[data["upjawSide"]],
          key: data["upjawSide"]
        });
        this.initOption({ type: 3, submitKey: "upjawSide", addItem });
      }
      if (data["downjawSide"]) {
        let addList;
        let optionsList = [
          "downjawSideAnterior",
          "downjawSideRight",
          "downjawSideLeft"
        ];
        addList = filter(
          map(optionsList, item => {
            return {
              key: data[item],
              value: valueData[data[item]],
              submitKey: item
            };
          }),
          it => it.key
        );
        let addItem = pickBy({
          children: isArray(addList) && addList.length ? addList : "",
          submitKey: "downjawSide",
          value: valueData[data["downjawSide"]],
          key: data["downjawSide"]
        });
        this.initOption({ type: 3, submitKey: "downjawSide", addItem });
      }
      //拔牙
      if (data["toothExtraction"]) {
        this.initOption({ type: 1, submitKey: "toothExtraction" });
      }

      //16
      if (data["growNailFlag"]) {
        let optionsList = [
          "upGrowNailPosition",
          "downGrowNailPosition",
          "growNailPurpose"
        ];
        let lt = [];
        each(optionsList, submitKey => {
          let list = map(split(data[submitKey], ","), item => {
            return {
              key: item,
              value: valueData[item],
              submitKey: submitKey
            };
          });
          lt.push(list);
        });
        let list = flattenDeep(lt);
        let addItem = pickBy({
          children: isArray(list) && list.length ? list : "",
          submitKey: "growNailFlag",
          value: valueData[data["growNailFlag"]],
          key: data["growNailFlag"]
        });
        this.initOption({ type: 3, submitKey: "growNailFlag", addItem });
      }
      dia.growNailFlagNote = data.growNailFlagNote || "";

      //17
      if (data["underjawAbnormalFlag"]) {
        this.initOption({ type: 1, submitKey: "underjawAbnormalFlag" });
        dia.underjawAbnormalFlagNote = data.underjawAbnormalFlagNote;
      }

      //18
      if (data["needPassiveAppliance"]) {
        this.initOption({ type: 1, submitKey: "needPassiveAppliance" });
        dia.needPassiveApplianceNote = data.needPassiveApplianceNote;
      }

      // 19
      if (data["specialInstructions"]) {
        dia.specialInstructions = data.specialInstructions;
      }
      return Promise.resolve();
    },
    getCaseOptions() {
      document.body.scrollTop = 0;
      document.documentElement.scrollTop = 0;
      let id = this.queryCurePlanId;
      this.isLoading = true;
      getDictAllList([
        // 获取病例选项的值
        "tooth_problem",
        "tooth_problem_i",
        "jaw_problem",
        "jaw_problem_two",
        "tooth_problem_two",
        "angle_classification",
        "sagittal_maxillomandibular_classification",
        "clinic_info",
        "bad_habits",
        "dentition",
        "to_correct_tooth_jaw",
        "to_correct_tooth_upjaw",
        "to_correct_tooth_downjaw",
        "non_moveable_teeth",
        "not_available_for_attachments",
        "sagittal_relation",
        "left_sagittal_relation",
        "right_sagittal_relation",
        "improve_tip",
        "precision_cut",
        "updown_jaw_gap_correct",
        "gap_correct",
        "underjaw_abnormal_flag",
        "grow_nail_flag",
        "grow_nail_position",
        "grow_nail_purpose",

        "improve_molar",
        "up_horizontal_relationship",
        "down_horizontal_relationship",
        "cover_info",
        "overbite_info",
        "correct_open",
        "correct_deep_cover",
        "updown_jaw",
        "front_tooth_info",
        "correct_front_reverse",
        "back_tooth_info",
        "back_tooth_info_correct",
        "correct_front_reverse_two",
        "correct_front_opposite",
        "choose_option_b",
        "middle_line",
        "tooth_extraction",
        "improve_middle_move",
        "to_correct_tooth_downjaw",
        "tooth_extraction_options",
        "choose_option",
        "choose_option_b",
        "upjaw_far",
        "downjaw_far",
        "accept_decayed_teeth_extraction",
        "undergo_teeth_restoration"
      ])
        .then(data => {
          let dictObj = {},
            diaKeyObj = {};
          data.forEach(item => {
            if (!dictObj[item.parentKey]) {
              dictObj[item.parentKey] = [item];
            } else {
              dictObj[item.parentKey].push(item);
            }
            diaKeyObj[item.key] = item.value;
          });
          this.diaKeyObj = diaKeyObj;
          this.caseOptions = dictObj;

          this.sagittalRelationshipOption = this.caseOptions[
            "improve_tip"
          ].slice(0, 2);
          this.sagittalRelationshipOptionTow = this.caseOptions[
            "improve_tip"
          ].slice(2, 3);
          this.sagittalRelationshipOptionThree = this.caseOptions[
            "improve_tip"
          ].slice(3, 4);
          this.sagittalRelationshipOptionFour = this.caseOptions[
            "improve_tip"
          ].slice(4, 5);

          this.diagnosisParams.caseId = this.$route.query.caseId;
          const caseId = this.$route.query.caseId;
          const curePlanId = this.$route.query.curePlanId;
          this.diagnosisParams.id = id;
          columnDataTempRecordDetail({
            caseId,
            curePlanId,
            type: "open_rdt"
          }).then(obj => {
            let finalResult = {};
            if (obj.columnData && typeof obj.columnData === "object") {
              finalResult = Object.fromEntries(
                Object.entries(obj.columnData)
                  .filter(
                    ([key, value]) =>
                      value !== null &&
                      value !== undefined &&
                      Object.keys(value).length > 0
                  )
                  .map(([key, value]) => [key, value])
              );
            }
            this.details = { ...finalResult };
            this.initParams(finalResult).then(() => {
              this.isLoading = false;
              setTimeout(() => {
                this.isResponse = 0;
              }, 800);
            });
          });
        })
        .catch(() => {
          this.isLoading = false;
        });
    }
  },
  components: {
    ChildrenTeeth,
    IntervalCorrect,
    FullLoading,
    AdjoinDislodge,
    ReserveDinterval
  },
  mounted() {
    this.rightHeight = window.getComputedStyle(this.$refs["rightTable"]).height;
    each(this.leftList, (item, index) => {
      if (document.getElementById(index + 1)) {
        this.leftTopObj["d" + (index + 1)] = document.getElementById(
          index + 1
        ).offsetTop;
      }
    });
    window.addEventListener("scroll", this.handleScroll, false);
  },
  beforeDestroy() {
    window.removeEventListener("scroll", this.handleScroll, false);
  },
  created() {
    this.isResponse += 1;
    this.getCaseOptions();
  }
};
</script>

<style lang="scss" scoped>
/deep/ .mui-popover {
  position: fixed;
}

.dia_problem {
  width: 100%;
  background: #f4f4f4;
  padding: 10px 16px 30px;
  margin-bottom: 30px;

  h5 {
    font-size: 0.22rem;
    color: $main_theme_color_333;
  }

  span {
    margin-right: 0.2rem;
  }

  .pro_box {
    word-break: break-all;
    font-size: 0.16rem;
    line-height: 0.3rem;
    color: $main_theme_color_333;
  }
}

/deep/.el-scrollbar__wrap {
  overflow-x: hidden !important;
}

.sagittal_relationship_input_mm {
  position: absolute;
  right: 0;
  top: 0.08rem;
}

.midcourt_line_input_mm {
  position: absolute;
  right: 0.6rem;
  top: 0.1rem;
}

.midcourt_line_input_mm_two {
  position: absolute;
  right: 0.6rem;
  top: 0;
}

.table_wrap {
  display: flex;
  //margin-bottom: -1rem;
  //padding-top: 75px;

  .left_option {
    width: 2.4rem;
    background-color: #fff;
    position: fixed;

    .left_option_item {
      width: 100%;
      height: 0.5rem;
      transition: all 0.2s;
      font-size: 0.16rem;
      color: $main_theme_color_333;
      cursor: pointer;
      text-align: center;
      display: flex;
      justify-content: center;
      align-items: center;
      position: relative;
      padding: 0 0.12rem;

      .left_wire {
        width: 4px;
        height: 32px;
        background: $main_theme_color;
        position: absolute;
        left: 0;
      }
    }

    .left_option_item:hover {
      transition: all 0.2s;
      background: #f9f9f9;
    }
  }

  .right_table {
    margin-left: 2.4rem;
    width: calc(100% - 2.4rem);
    background-color: #fff;
    padding: 0.2rem 0.2rem 0.5rem 0.59rem;
    border-left: 1px solid #e5e5e5;

    .switch-irdt-tips {
      width: 984px;
      height: 62px;
      background-color: #f4f4f4;
      display: flex;
      align-items: center;
      padding: 0 16px;
      font-size: 22px;
      border-radius: 4px;
      margin-bottom: 30px;
      color: #333;

      img {
        width: 24px;
        height: 24px;
        margin-right: 10px;
      }
    }

    .select_type {
      margin-bottom: 30px;

      .select_type_title {
        font-size: 0.16rem;
        color: $main_theme_color_333;
      }
    }
  }
}

.input_mm {
  position: absolute;
  right: 0.2rem;
  top: 0.08rem;
}

.common_btn {
  position: relative;
  padding: 10px 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 0.2rem;
  color: $main_theme_color_666;
  border-radius: 4px;
  border: 0.01rem solid #bbbbbb;
  cursor: pointer;
  font-size: 0.14rem;
  margin-bottom: 0.1rem;
  white-space: nowrap;
  user-select: none;
  background-color: #fff;
}

.is_active {
  background-color: $main_theme_color;
  color: $main_theme_color_666 !important;
  border: 0.01rem solid $main_theme_color;
}

.remark_box {
  width: 100%;
  margin-top: 0.1rem;

  textarea {
    width: 90%;
    border: 0.01rem solid #c8c8c8;
    border-radius: 0.08rem;
    padding: 0.2rem;
    color: #333;
    min-height: 1.34rem;
  }
}

.option_left_title {
  min-width: 0.7rem;
  font-size: 14px;
  color: $main_theme_color_666;
}

.single_tip_box {
  color: $main_theme_color_333;
  background-color: #f4f4f4;
  font-size: 0.14rem;
  padding: 0.15rem 0.2rem;

  p {
    margin-bottom: 0.1rem;

    &:last-child {
      margin-bottom: 0;
    }

    input {
      width: 100%;
      outline: none;
      border: none;
    }
  }
}

.popover_con_right_iup {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-left: 0.2rem;
  border: 0.01rem solid #c8c8c8;
  width: 80%;
  height: 0.4rem;
  border-radius: 0.06rem;
  padding-right: 0.17rem;

  input {
    width: 50%;
    padding: 0.13rem 0.9rem 0.13rem 0.15rem;
    font-size: 0.14rem;
    flex: 1;
    outline: none;
    border: none;
  }
}

.single_con {
  color: $main_theme_color_333;
  font-size: 0.16rem;
  position: relative;

  .remark_box {
    width: 100%;

    textarea {
      width: 100%;
      border: 0.01rem solid #c8c8c8;
      border-radius: 0.08rem;
      padding: 0.2rem;
      color: #333;
      min-height: 1.34rem;
    }
  }

  .lc_box {
    background-color: #f9f9f9;
    padding: 0.3rem 0.2rem 0.16rem 0.3rem;
    display: flex;
    justify-content: space-between;
    font-size: 0.14rem;
    color: #333;

    .check_box {
      flex: 1;
    }

    .el-checkbox-group {
      display: flex;
      flex: 1;
      flex-wrap: wrap;

      .check_single {
        margin: 0 0.1rem 0.2rem;

        &:last-child {
          margin-bottom: 0;
        }
      }
    }

    .other_box {
      width: 2.81rem;

      .tit {
        margin-bottom: 0.12rem;
      }

      textarea {
        width: 100%;
        height: 0.98rem;
        border-radius: 0.04rem;
        background-color: #fff;
        padding: 0.1rem;
        box-sizing: border-box;
      }
    }
  }

  .single_c_head {
    margin-bottom: 0.3rem;
  }

  .s_c_box {
    display: flex;

    .s_c_b_left {
      margin-right: 0.2rem;
      margin-top: 0.13rem;
      color: $main_theme_color_333;
    }

    .t_b_p {
      color: $main_theme_color_666;
      min-width: 0.64rem;
      margin-right: 20px;
      position: relative;
      top: -4px;
    }

    .t_b_tip {
      color: $main_theme_color_999;
      font-size: 0.14rem;
    }
  }
}

.t_b_box {
  display: flex;
  align-items: center;
  font-size: 0.16rem;
  flex-wrap: wrap;
  margin-bottom: 0.16rem;
  line-height: 0.2rem;
}

.popover_con {
  display: flex;

  .popover_con_left {
    min-width: 0.3rem;
    white-space: nowrap;
    padding-top: 0.12rem;
    margin-right: 10px;
    color: $main_theme_color_333;
  }

  .popover_con_right {
    display: flex;
    flex-wrap: wrap;
    flex: 1;

    span {
      margin-top: 0.1rem;
      margin-bottom: 0.1rem;
    }

    .popover_con_right_p {
      margin-bottom: 0.1rem;
    }

    .popover_con_right_iup {
      position: relative;
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-left: 0.2rem;
      border: 0.01rem solid #c8c8c8;
      width: 80%;
      height: 0.4rem;
      border-radius: 0.06rem;
      padding-right: 0.17rem;

      input {
        padding: 0.13rem 0 0.13rem 0.15rem;
        font-size: 0.14rem;
        flex: 1;
        outline: none;
        border: none;
      }
    }
  }
}

.left_active {
  color: $main_theme_color !important;
}

.popover_db {
  display: flex;
  justify-content: center;
  align-items: center;

  .popover_db_left {
    border-left: 0.01rem solid #c8c8c8;
    border-bottom: 0.01rem solid #c8c8c8;
    width: 0.2rem;
    height: 0.3rem;
  }

  .popover_db_right {
    display: flex;
    padding-top: 0.4rem;

    span {
      margin-left: 0;
      margin-right: 0.2rem;
    }
  }
}

.problem_note {
  word-break: break-all;
  color: $main_theme_color;
  margin-bottom: 0.2rem;
  line-height: 0.22rem;
  font-size: 0.14rem;
  margin-top: 4px;
}
</style>
