<template>
  <div id="app">
    <div class="top_flex">
      <!--删除了banner-->
      <!--<el-carousel class="top_bc" v-if="$route.path === '/home'" height="0.9rem">-->
      <!--<el-carousel-item v-for="(item,index) in bannerList" :key="index">-->
      <!--<img class="banner_img" :src="item" alt="">-->
      <!--</el-carousel-item>-->
      <!--</el-carousel>-->
      <Head v-if="isShowHead == 2" />
      <div
        :class="{ false_show: !isShowNews }"
        class="realTime_news"
        v-if="isShowHead == 2"
      >
        <div v-if="isShowNews" class="news_box" @click="toAnnounDetail">
          <img src="/static/images/header/laba.png" alt="" />
          <vueSeamless
            style="flex: 1"
            class="seamless-warp"
            :data="seamlessList"
            :class-option="option"
          >
            <ul class="item">
              <li
                class="curp"
                v-for="(item, index) in seamlessList"
                :key="index"
              >
                <span
                  @click="informDetails(item.id)"
                  class="title"
                  v-text="item.title"
                ></span>
                <span class="date" v-text="item.date"></span>
              </li>
            </ul>
          </vueSeamless>
        </div>
        <img
          v-if="seamlessList[0]"
          :class="{ stay_cord_location: !isShowNews }"
          @click="showNews"
          class="stay_cord curp"
          src="/static/images/home/lasheng.png"
          alt=""
        />
      </div>
    </div>
    <!--    <img-->
    <!--      :class="{ banner_news: isShowNews }"-->
    <!--      v-if="$route.path === '/home'"-->
    <!--      class="banner"-->
    <!--      src="./common/imgs/home/banner.png"-->
    <!--      alt=""-->
    <!--    />-->
    <div
      class="app_con"
      :class="{
        app_con_news:
          isShowNews &&
          !['/login', '/cases/view3d', '/retrievePaw', '/exocad'].includes(
            $route.path
          ),
        app_con_68:
          !isShowNews &&
          !['/login', '/cases/view3d', '/retrievePaw', '/exocad'].includes(
            $route.path
          )
      }"
    >
      <router-view></router-view>
    </div>
    <Toast :text="textMsg" ref="toast" />
    <FullLoading id="fullLoading" v-show="isLoading" />
  </div>
</template>

<script type="text/ecmascript-6">
import Head from "components/header/index";
import Toast from "components/toast/toast";
import FullLoading from "components/full-loading/full-loading";
import {mapState, mapMutations} from "vuex";
import {isLogin} from "common/js/util";
import vueSeamless from "vue-seamless-scroll";
import {querySmsList} from "common/api/system";
import {PublicQueryEnvironment} from "common/api/cases";
import Vue from "vue";
import TYPES from "store/mutationTypes";

export default {
  name: "app",
  data() {
    return {
      textMsg: "",
      isLoading: false,
      interTime: null,
      pathUrl: "",
      uploadUrl: "",
      qiniuDomain: "",
      appCon: null,
      isShowHead: 1,
      seamlessList: [],
      option: {
        step: 2,
        limitMoveNum: 1,
        openTouch: false,
        waitTime: 48,
        direction: 2,
        singleHeight: 1,
      },
      bannerList: [require("./common/imgs/app/3.png")],
    };
  },
  watch: {
    $route: {
      handler: function () {
        this.isShowHead = sessionStorage.getItem("isShowHead") || 1;
        const YZ_LIST = [
          "/login",
          "/retrievePaw",
          "/downloadApp",
          "/cases/view3d",
          "/exocad",
        ];
        this.appCon = document.querySelector(".app_con");
        if (YZ_LIST.includes(sessionStorage.getItem("menuUrl"))) {
          this.appCon.style.paddingTop = 0;
        } else {
          // this.appCon.style.paddingTop = '1.53rem';
        }
      },
      deep: true,
    },
  },
  mounted() {
    //防止热更新出问题
    this.isShowHead = sessionStorage.getItem("isShowHead") || 1;
    const YZ_LIST = [
      "/login",
      "/retrievePaw",
      "/downloadApp",
      "/cases/view3d",
      "/exocad",
    ];
    this.appCon = document.querySelector(".app_con");
    if (YZ_LIST.includes(sessionStorage.getItem("menuUrl"))) {
      this.appCon.style.paddingTop = 0;
    } else {
      // this.appCon.style.paddingTop = '1.53rem';
    }
    this.$store.dispatch('fetchCityList');
  },
  async created() {
    this.GET_IS_SHOW_NEWS(sessionStorage.getItem("isShowNews") === "true");
    this.GET_language(window.localStorage.getItem("user_lang"));
    this.pathUrl = window.location.pathname;
    this.GET_LOGIN_STATUS(isLogin());
    await PublicQueryEnvironment({key: "qiniu_domain"}).then((data) => {
      Vue.prototype.$PicPrefix = data[0].value + "/";
    });
    if (isLogin()) {
      if (
          this.$route.path == "/cases/view3d" ||
          this.$route.path == "/exocad"
      ) {
        return;
      } else {
        querySmsList({
          status: "1",
          target: "DOCTOR",
          type: "SYS",
          readFlag: "0",
          language: localStorage.getItem("user_lang") || "zh_CN",
        }).then((data) => {
          // this.GET_IS_SHOW_NEWS(data.length > 0);
          if (data.length && !this.isShowNews) {
            this.$store.commit(TYPES.GET_IS_SHOW_NEWS, false);
          }
          if (!data.length) {
            this.$store.commit(TYPES.GET_IS_SHOW_NEWS, false);
          }
          if (data.length > 5) {
            this.seamlessList = data.slice(0, 5);
          } else {
            this.seamlessList = data;
          }
        });
      }
    }
  },
  methods: {
    ...mapMutations([
      "GET_LOGIN_STATUS",
      "GET_MENU_URL",
      "GET_IS_SHOW_NEWS",
      "GET_language",
    ]),
    informDetails(id) {
      sessionStorage.setItem("announcementId", id);
      this.$router.push({path: "/home/msgDetail"});
    },
    showNews() {
      this.GET_IS_SHOW_NEWS(!this.isShowNews);
    },
    toAnnounDetail(ev) {
      const target = ev.target;
      const id = target.getAttribute("data-id");
      if (id) {
        sessionStorage.setItem("announcementId", id);
        this.goPage("/home/msgDetail");
      }
    },
  },
  computed: {
    ...mapState({
      isLogin: (state) => state.isLogin,
      isShowNews: (state) => JSON.parse(state.isShowNews),
      language: (state) => state.language,
    }),
  },
  components: {
    Head,
    Toast,
    FullLoading,
    vueSeamless,
  },
};
</script>
<style lang="scss">
.batch-popover {
  border-radius: 6px;
  margin-top: 4px !important;
  padding: 8px !important;

  .el-popover__title {
    font-size: 13px;
    line-height: 18px;
    margin-bottom: 8px;
  }
}

/deep/ .confirm_cancel_button {
  color: #333333 !important;
}

.confirm_cancel_button:hover {
  background-color: white;
  color: #606266;
  border: 0.01rem solid #dcdfe6;
}

.confirm_cancel_button:focus {
  background-color: white !important;
  color: #606266 !important;
  border: 0.01rem solid #dcdfe6 !important;
}

@import "common/scss/reset.scss";
.false_show {
  height: 0.06rem !important;
}

.stay_cord_location {
  top: 0.01rem !important;
}

.confirm-switch-irdt {
  background-color: #fcc80e;
  color: #333;
  border-color: #fcc80e;
}

.more-list-popper {
  padding: 0px !important;
}

#app {
  font-family: "Microsoft YaHei" !important;

  .top_flex {
    width: 100%;
    position: fixed;
    top: 0;
    z-index: 1999;

    .top_bc {
      width: 100%;

      .banner_img {
        display: block;
        width: 100%;
        height: 100%;
      }
    }

    .realTime_news {
      width: 100%;
      height: 0.42rem;
      background-color: $main_theme_color;
      transition: height 0.3s;
      margin-top: 0.61rem;
      position: absolute;

      img {
        transition: all 0.3s;
      }

      .stay_cord {
        display: block;
        width: 0.31rem;
        position: absolute;
        right: 0.5rem;
        top: 0.4rem;
      }

      .news_box {
        width: 16rem;
        height: 100%;
        margin: 0 auto;
        display: flex;
        overflow: hidden;
        align-items: center;
        justify-content: center;

        img {
          display: block;
          width: 0.31rem;
          height: 0.25rem;
        }
      }
    }
  }

  .banner {
    width: 100%;
    display: block;
    height: 3.17rem;
    margin-top: 1.52rem;
  }

  .banner_news {
    margin-top: 1.94rem !important;
  }

  .seamless-warp {
    height: 0.62rem;
    overflow: hidden;

    ul.item {
      height: 0.62rem;
      width: 14.5rem;
      font-size: 0.2rem;
      font-weight: 500;
      color: #ffffff;
      display: flex;
      align-items: center;
      flex-flow: row-reverse;

      li {
        float: left;
        margin-right: 0.1rem;
      }
    }
  }
}

.app_con {
  transition: margin-top 0.3s;
  margin: 0 auto;
  position: relative;

  .vertical_line {
    display: inline-block;
    width: 0.03rem;
    height: 0.24rem;
    background-color: $main_theme_color;
    margin-right: 0.1rem;
  }

  .line {
    width: 100%;
    border-top: 0.01rem solid #d2d2d2;
  }

  .foo_back {
    color: #fff;
    background-color: $main_theme_color;
  }

  .foo_ok {
    border: 0.01rem solid $main_theme_color;
    color: $main_theme_color;
  }

  .click_box {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
    z-index: 2;
    cursor: pointer;
  }

  .r_sr_icon {
    width: 0.24rem;
    height: 0.24rem;
    margin-right: 0.06rem;
    background-size: 100% 100%;
    background-image: url("common/imgs/age.png");
  }

  .r_m_icon {
    width: 0.24rem;
    height: 0.24rem;
    margin-left: 0.19rem;
    margin-right: 0.07rem;
    background-size: 100% 100%;
    background-image: url("./common/imgs/iphone_icon.png");
  }

  .r_d_icon {
    width: 0.24rem;
    height: 0.24rem;
    margin-right: 0.09rem;
    background-size: 100% 100%;
    background-image: url("common/imgs/address.png");
  }

  .pagination-box {
    .el-pagination .btn-prev,
    .btn-next {
      background-color: transparent !important;
    }

    .el-pager li {
      background-color: transparent !important;
    }
  }
}

.app_con_68 {
  margin-top: 0.9rem;
  transition: all 0.3s;
}

.app_con_news {
  margin-top: 1.29rem;
  transition: all 0.3s;
}

.number_sj_sp {
  position: absolute;
  right: 0.01rem;
  top: 0;
  bottom: 0;
  width: 0.2rem;
  z-index: 1;
  background-color: #f4f4f4;
}

.el-picker-panel {
  z-index: 4000 !important;
}

.el-notification {
  z-index: 5000 !important;
}

.band_we_chat .el-upload {
  display: block;
  height: 100%;
}

.el-upload-dragger {
  background-color: transparent;
}

.el-upload-dragger:hover {
  border: 0.01rem solid $main_theme_color !important;
}

.el-upload-dragger {
  width: 100% !important;
  height: 100% !important;
}

.el-input {
  font-size: 0.14rem;
}

.el-input__inner {
  background-color: white !important;
  border-radius: 0.04rem;
  border: 0.01rem solid #bbbbbb;
  padding: 0 0.2rem;
  white-space: nowrap;
  -ms-text-overflow: ellipsis;
  text-overflow: ellipsis;
  overflow: hidden;
}

.el-input__icon {
  width: 0.25rem !important;
  line-height: 0.32rem !important;
}

.el-input__inner:focus {
  border-color: $main_theme_color !important;
}

.el-textarea__inner {
  color: $main_theme_color_333;
}

.el-textarea__inner:focus {
  border-color: $main_theme_color !important;
}

.el-select .el-input .el-select__caret {
  font-size: 0.14rem !important;
  color: #bbbbbb !important;
}

.el-select .el-input.is-focus .el-input__inner {
  border-color: $main_theme_color !important;
}

.el-select-dropdown__item {
  color: #333333 !important;
  font-size: 0.14rem;
}

.el-radio__input.is-checked .el-radio__inner {
  border-color: $main_theme_color !important;
  background: $main_theme_color !important;
}

.el-radio__input.is-checked + .el-radio__label {
  color: $main_theme_color_333 !important;
}

/*
  .el-tooltip__popper.is-dark {
    background: $main_theme_FCC80E !important;
    color: #fff;
  }
  .el-tooltip__popper[x-placement^=top] .popper__arrow:after {
    border-top-color: $main_theme_color !important;
  }
  .el-tooltip__popper[x-placement^=top] .popper__arrow{
    border-top-color: $main_theme_color !important;
  }
  */

.clinical_box .el-radio__input.is-checked + .el-radio__label,
.el-checkbox__input.is-checked + .el-checkbox__label {
  color: $main_theme_color !important;
}

.clinical_box .el-radio__input.is-checked .el-radio__inner,
.el-checkbox__input.is-checked .el-checkbox__inner {
  border-color: $main_theme_color !important;
  background: $main_theme_color !important;
}

.clinical_box .el-radio__input.is-disabled .el-radio__inner,
.el-checkbox__input.is-disabled .el-checkbox__inner {
  border-color: #f5f7fa;
  background: #e4e7ed;
}

.clinical_box .el-radio__input.is-disabled + .el-radio__label,
.el-checkbox__input.is-disabled + .el-checkbox__label {
  color: #ccc;
}

//日历
.el-date-picker__header-label {
  font-size: 14px;
}

//分页
.el-pagination.is-background .el-pager li:not(.disabled).active {
  background-color: $main_theme_color !important;
  color: $main_theme_color_333;
}

.el-pagination.is-background .el-pager li:hover {
  color: $main_theme_color_333 !important;
}

.clinical_box .el-radio__label {
  white-space: normal;
  line-height: 0.28rem;
}

.clinical_box .el-radio {
  display: flex;
  align-items: center;
}

.clinical_box .el-checkbox__label {
  display: initial;
  white-space: normal;
}

//级联选择
.el-cascader-node.in-active-path,
.el-cascader-node.is-active,
.el-cascader-node.is-selectable.in-checked-path {
  color: $main_theme_color !important;
}

.el-cascader .el-input .el-input__inner:focus,
.el-cascader .el-input.is-focus .el-input__inner {
  border-color: $main_theme_color !important;
}

.el-cascader-node {
  color: #333333 !important;
}

.el-popover {
  z-index: 5000 !important;
}

.el-form-item__error {
  color: #ed4027 !important;
}

.el-form-item__label {
  color: $main_theme_color_333 !important;
}

.el-pagination__total {
  font-size: 14px !important;
  color: $main_theme_color_999;
  position: relative !important;
  top: 2px !important;
}

.el-pager {
  font-size: 14px !important;
  color: $main_theme_color_333;
}

.el-select-dropdown__item {
  font-size: 14px;
  color: $main_theme_color_333;
}
</style>
<style lang="scss" scoped>
/deep/ .el-carousel__button {
  width: 0.15rem;
  height: 0.15rem;
  border-radius: 50%;
  background-color: white !important;
}

/deep/ .el-carousel__indicators {
  position: absolute;
  bottom: 0.15rem;
  left: 19.2%;
}

/deep/ .is-active .el-carousel__button {
  background-color: $main_theme_color !important;
}

/deep/ .el-button--primary {
  background-color: #fcc80e !important;
}

.el-select-dropdown__item.hover,
.el-select-dropdown__item:hover {
  background: #fffbed !important;
  color: $main_theme_color !important;
}

.el-cascader-node:hover {
  background: #fffbed !important;
}

//表格
::v-deep .el-checkbox__input.is-checked .el-checkbox__inner,
.el-checkbox__input.is-indeterminate .el-checkbox__inner {
  background-color: $main_theme_color !important;
  border-color: $main_theme_color !important;
}

::v-deep.el-checkbox__inner {
  background-color: $main_theme_color !important;
  border-color: $main_theme_color !important;
}
</style>
