<template>
  <div :class="tabsKey === 3 ? 'information-large' : 'information'">
    <div class="title">
      <div class="label">
        <svg class="label-icon" aria-hidden="true">
          <use xlink:href="#icon-gerenziliao_xuanzhong"></use>
        </svg>
        <span>{{ $t("threeD.blzl") }}</span>
      </div>
      <i class="el-icon-close close-icon" @click="closeDrawer"></i>
    </div>
    <div class="tabs-menu">
      <div
        @click="changeTabsKey(index)"
        :class="tabsKey === index ? 'tabs-item-active' : 'tabs-item'"
        v-for="(item, index) in tabsList"
        :key="index"
      >
        {{ item }}
      </div>
    </div>
    <div class="content" v-if="!isLoading">
      <div class="patient_pic_wrap" v-show="tabsKey === 0">
        <template v-if="patientPictureList.length > 0">
          <div
            class="patient_pic curp"
            v-for="(patient, pax) in patientPictureList"
            :key="pax"
          >
            <img
              @click="showPicFn(patient.name, getPicIndex(patient.filed))"
              :src="`${$PicPrefix}${patient['nanoId']}`"
              :style="{
                transform: `rotate(${patient['degree']}deg) scaleX(${
                  patient['horizontal'] ? -1 : 1
                }) scaleY(${patient['vertical'] ? -1 : 1})`,
              }"
              class="patient_pic_item"
            />
            <p class="tac">{{ patient.name }}</p>
          </div>
        </template>
        <div class="mon_img" v-else>
          <img :src="noPicFn()" alt="" />
          <span class="init_text">{{ $t("threeD.zsmytp") }}</span>
        </div>
      </div>
      <div class="patient_pic_wrap" v-show="tabsKey === 1">
        <template v-if="xLightPictureList.length > 0">
          <div
            class="patient_pic curp"
            v-for="(item, index) in xLightPictureList"
            :key="index"
          >
            <img
              class="patient_pic_item"
              @click="showPicFn(item.name, getPicIndex(item.filed))"
              :src="`${$PicPrefix}${item['nanoId']}`"
              :style="{
                transform: `rotate(${item['degree']}deg) scaleX(${
                  item['horizontal'] ? -1 : 1
                }) scaleY(${item['vertical'] ? -1 : 1})`,
              }"
            />
            <p class="tac">{{ item.name }}</p>
          </div>
        </template>
        <div class="mon_img" v-else>
          <img :src="noPicFn()" alt="" />
          <span class="init_text">{{ $t("threeD.zsmytp") }}</span>
        </div>
      </div>
      <div class="patient_pic_wrap" v-show="tabsKey === 2">
        <template v-if="otherPictureList.length > 0">
          <div
            class="patient_pic curp"
            v-for="(item, index) in otherPictureList"
            :key="index"
          >
            <img
              class="patient_pic_item"
              @click="showPicFn(item.name, item.index)"
              :src="`${$PicPrefix}${item.nanoId}`"
              :style="{
                transform: `rotate(${item['degree']}deg) scaleX(${
                  item['horizontal'] ? -1 : 1
                }) scaleY(${item['vertical'] ? -1 : 1})`,
              }"
            />
            <p class="tac">{{ item.name }}</p>
          </div>
        </template>
        <div class="mon_img" v-else>
          <img :src="noPicFn()" alt="" />
          <span class="init_text">{{ $t("threeD.zsmytp") }}</span>
        </div>
      </div>
      <oper-preview
        :isOldCase="get(cureNoteDetail, 'toothViewVersion') === '0'"
        v-show="
          tabsKey === 3 && cureNoteDetail.id && diagnosisDetail.type !== '4'
        "
        selectedTabKey="3"
        :diagnosisDetail="diagnosisDetail"
        :details="{ ...cureNoteDetail, ...caseDetail, ...diagnosisDetail }"
        :caseInfo="caseDetail"
        :userInfo="caseDetail['userInfo']"
        :teethKeyObj="teethKeyObj"
        :babyTeethKeyObj="babyTeethKeyObj"
        :commonTeeth="commonTeeth"
        :uploadIntervalList="uploadIntervalList"
        :crowdTeethList="crowdTeethList"
        :commonVeneerList="commonVeneerList"
        :curePlanDetail="caseDetail['curePlanInfo']"
      />
      <div
        class="stage-adjust-preview"
        v-if="
          tabsKey === 3 && cureNoteDetail.id && diagnosisDetail.type === '4'
        "
      >
        <stageAdjustmentPreview
          :dictObj="dictObj"
          :caseDetail="caseDetail"
          :diaKeyObj="diaKeyObj"
          :previewData="diagnosisDetail"
          :isDatabase="true"
          :previewCom="['table']"
        />
      </div>
    </div>
    <PreviewImg
      :carouselList="carouselList"
      ref="previewImg"
      @imgChange="imgChange"
    />
  </div>
</template>

<script>
import { extend, filter, map, split, get, each } from "lodash";
import PreviewImg from "components/preview-image/preview-image.vue";
import OperPreview from "../../casesChildren/operationChildren/operPreview_copy";
import { getDictAllList } from "common/api/public";
import stageAdjustmentPreview from "../../../cases-detail/stageAdjustment/components/preview.vue";

export default {
  props: {
    tabsKey: Number,
    cureNoteDetail: Object,
    diagnosisDetail: Object,
    caseDetail: Object,
  },
  components: {
    PreviewImg,
    OperPreview,
    stageAdjustmentPreview,
  },
  data() {
    return {
      tabsList: [
        this.$t("threeD.zp"),
        this.$t("threeD.xg"),
        this.$t("threeD.qt"),
        this.$t("threeD.zlb"),
      ],
      diaKeyObj: {},
      dictObj: {},
      isLoading: true,
      otherPictureList: [],
      xLightPictureList: [],
      patientPictureList: [],
      carouselList: [],
      teethKeyObj: {},
      babyTeethKeyObj: {},
      commonVeneerList: [],
      commonTeeth: [],
      crowdTeethList: [],
      uploadIntervalList: [],
      defaultFacePic: [
        "sideFacePicture",
        "frontFacePicture",
        "smilePicture",
        "upToothPicture",
        "anteriorOverjetPicture",
        "downToothPicture",
        "mouthRightPicture",
        "mouthFrontPicture",
        "mouthLeftPicture",
      ],
      defaultXrayPic: ["xrayCurvePicture", "xraySidePicture"],
      adjustFacePic: [
        "sideProfile",
        "frontal",
        "frontalSmile",
        "upperArch",
        "anteriorOverjet",
        "lowerArch",
        "intraOralRight",
        "intraOralFrontal",
        "intraOralLeft",
      ],
      adjustXrayPic: ["panoramicRadiograph", "lateralCephalogram"],
      adultTeethList: [
        { number: 11 },
        { number: 12 },
        { number: 13 },
        { number: 14 },
        { number: 15 },
        { number: 16 },
        { number: 17 },
        { number: 18 },
        { number: 21 },
        { number: 22 },
        { number: 23 },
        { number: 24 },
        { number: 25 },
        { number: 26 },
        { number: 27 },
        { number: 28 },
        { number: 48 },
        { number: 47 },
        { number: 46 },
        { number: 45 },
        { number: 44 },
        { number: 43 },
        { number: 42 },
        { number: 41 },
        { number: 31 },
        { number: 32 },
        { number: 33 },
        { number: 34 },
        { number: 35 },
        { number: 36 },
        { number: 37 },
        { number: 38 },
      ],
    };
  },
  methods: {
    get,
    getAdjustKeyObj() {
      getDictAllList([
        "fit_status",
        "accessory_adjustment",
        "dentition_space",
        "underjaw_abnormal_flag",
        "non_moveable_teeth",
        "not_available_for_attachments",
      ]).then((data) => {
        let diaKeyObj = {},
          dictObj = {};
        data.forEach((item) => {
          if (!dictObj[item.parentKey]) {
            dictObj[item.parentKey] = [item];
          } else {
            dictObj[item.parentKey].push(item);
          }
          diaKeyObj[item.key] = item.value;
        });
        this.diaKeyObj = diaKeyObj;
        this.dictObj = dictObj;
      });
    },
    imgChange(obj, idx) {
      const current = this.carouselList[idx];
      if (obj.filed !== "otherPicture") {
        if (["xrayCurvePicture", "xraySidePicture"].includes(obj.filed)) {
          this.xLightPictureList.map((it) => {
            if (it.filed == obj.filed) {
              it.nanoId = obj.nanoId;
              it.degree = obj.degree;
              it.horizontal = obj.horizontal;
              it.vertical = obj.vertical;
              it.ETag = obj.ETag;
            }
          });
        } else {
          this.patientPictureList.map((it) => {
            if (it.filed == obj.filed) {
              it.degree = obj.degree;
              it.horizontal = obj.horizontal;
              it.vertical = obj.vertical;
              it.ETag = obj.ETag;
              it.nanoId = obj.nanoId;
            }
          });
        }
      } else {
        this.otherPictureList.map((item) => {
          if (item.key == current.key) {
            this.$set(item, "degree", obj.degree);
            this.$set(item, "horizontal", obj.horizontal);
            this.$set(item, "vertical", obj.vertical);
          }
        });
      }
    },
    closeDrawer() {
      this.$emit("closeDrawer");
    },
    changeTabsKey(index) {
      this.$emit("changeTabsKey", index);
    },
    showPicFn(name, key) {
      this.$nextTick(() => {
        this.$refs["previewImg"] && this.$refs["previewImg"].show(name, key);
      });
    },
    getPicIndex(filed) {
      return filter(this.carouselList, (item) => item.filed === filed)[0]?.index;
    },
    noPicFn() {
      return (
        {
          clinic: require("../../../../../common/imgs/3d/blue_zanwu.png"),
          irdt: require("../../../../../common/imgs/3d/purple_zanwu.png"),
          admin: require("../../../../../common/imgs/3d/blue_zanwu.png"),
        }[this.queryPort] ||
        require("../../../../../common/imgs/3d/view_empty.png")
      );
    },
    isJSON(str) {
      if (str == "") {
        return "";
      }
      if (typeof str == "string") {
        try {
          JSON.parse(str);
          let obj = JSON.parse(str);
          !obj.nanoId && (obj.nanoId = obj.ETag);
          !obj.degree && (obj.degree = 0);
          !obj.horizontal && (obj.horizontal = false);
          !obj.vertical && (obj.vertical = false);
          return obj;
        } catch (error) {
          return {
            ETag: str,
            nanoId: str,
            degree: 0,
            horizontal: false,
            vertical: false,
          };
        }
      } else if (typeof str === "object") {
        return str;
      }
    },
    init() {
      this.isLoading = true;
      const {
        type,
        toothInfoList,
        cutInfoList = [],
        otherPicture = [],
      } = this.diagnosisDetail;
      if (type === "4") {
        this.getAdjustKeyObj();
      }
      let xLightPictureList =
        type !== "4" ? [...this.defaultXrayPic] : [...this.adjustXrayPic];
      xLightPictureList = xLightPictureList
        .map((item) => {
          if (item in this.diagnosisDetail) {
            const itemObj = this.isJSON(this.diagnosisDetail[item]);
            return {
              ...itemObj,
              filed: item,
              name: this.$t(`threeD.picName.${item}`),
            };
          }
        })
        .filter((item) => item !== undefined && "nanoId" in item);
      let patientPictureList =
        type !== "4" ? [...this.defaultFacePic] : [...this.adjustFacePic];
      patientPictureList = patientPictureList
        .map((item) => {
          if (item in this.diagnosisDetail) {
            const itemObj = this.isJSON(this.diagnosisDetail[item]);
            return {
              ...itemObj,
              filed: item,
              name: this.$t(`threeD.picName.${item}`),
            };
          }
        })
        .filter((item) => item !== undefined && "nanoId" in item);
      this.carouselList = [...patientPictureList, ...xLightPictureList];
      this.carouselList = [
        ...this.carouselList,
        ...map(this.fileList, (item) => {
          return {
            href: this.$PicPrefix + this.isJSON(item).ETag,
            filed: "otherPicture",
            name: this.$t("cases.createImage.yxzl"),
          };
        }),
      ];
      if (otherPicture.length) {
        try {
          JSON.parse(otherPicture);
          this.otherPictureList = map(
            JSON.parse(otherPicture),
            (item, index) => {
              return {
                name: this.$t(
                  "casesDetail.casesChildren.operationChildren.yxzl"
                ),
                href: this.$PicPrefix + item.ETag,
                key: index,
                filed: "otherPicture",
                ETag: item.ETag,
                nanoId: item.nanoId || item.ETag,
                degree: item.degree,
                horizontal: item.horizontal,
                vertical: item.vertical,
              };
            }
          );
        } catch (error) {
          this.otherPictureList = map(
            split(otherPicture, ","),
            (item, index) => {
              return {
                name: this.$t(
                  "casesDetail.casesChildren.operationChildren.yxzl"
                ),
                href: this.$PicPrefix + item,
                ETag: item,
                nanoId: item,
                filed: "otherPicture",
                degree: 0,
                horizontal: false,
                vertical: false,
              };
            }
          );
        }
      }
      this.carouselList = [...this.carouselList, ...this.otherPictureList];
      this.carouselList = map(this.carouselList, (item, index) => {
        return extend({}, item, { index: index + "" });
      });
      this.patientPictureList = patientPictureList;
      this.xLightPictureList = xLightPictureList;

      this.otherPictureList = filter(
        this.carouselList,
        (item) => item.filed === "otherPicture"
      );
      if (Array.isArray(toothInfoList)) {
        const babyObj = {};
        const missingObj = {}; // missingTeethKeyObj
        const noMoveObj = {}; // noMoveTeethKeyObj
        const noDesignObj = {}; // noDesignTeethKeyObj
        const extractionObj = {}; // extractionTeethKeyObj
        const attachmentObj = {}; // attachmentTeethKeyObj
        toothInfoList.forEach((item) => {
          if (item.type === "1") {
            missingObj[item.number] = {
              type: "1",
              number: item.number,
              className: "teeth_missing",
            };
          } else if (item.type === "2") {
            noMoveObj[item.number] = {
              type: "2",
              number: item.number,
              className: "teeth_no_move",
            };
          } else if (item.type === "3") {
            noDesignObj[item.number] = {
              type: "3",
              number: item.number,
              className: "teeth_no_design",
            };
          } else if (item.type === "4") {
            extractionObj[item.number] = {
              type: "4",
              number: item.number,
              className: "teeth_extraction",
            };
          } else if (item.type === "6") {
            attachmentObj[item.number] = {
              type: "6",
              number: item.number,
              className: "t__s_tag_fj",
            };
          } else if (item.type === "7") {
            babyObj[item.number] = {
              id: item.id,
              type: "7",
              number: item.number,
            };
          }
        });
        this.teethKeyObj = {
          missingTeethKeyObj: missingObj,
          noMoveTeethKeyObj: extend({}, missingObj, noMoveObj),
          noDesignTeethKeyObj: extend({}, missingObj, noDesignObj),
          extractionTeethKeyObj: extend(
            {},
            missingObj,
            extractionObj,
            noMoveObj
          ),
        };
        this.babyTeethKeyObj = babyObj;
      }

      if (Array.isArray(cutInfoList)) {
        let obj = {};
        cutInfoList.forEach((item) => {
          obj[+item.toothEnd] = {
            ...item,
          };
        });
        this.cutInfoObj = obj;
      }
      this.uploadIntervalList = filter(
        this.diagnosisDetail.toothInfoList,
        (item) => item.type === "5"
      );
      this.crowdTeethList = filter(
        this.diagnosisDetail.toothInfoList,
        (item) => item.type === "9"
      );

      let noMoveList = filter(
        this.diagnosisDetail.toothInfoList,
        (item) => item.type === "2"
      );
      let noDesignList = filter(
        this.diagnosisDetail.toothInfoList,
        (item) => item.type === "3"
      );
      let deficiencyList = map(
        filter(this.diagnosisDetail.toothInfoList, (item) => item.type === "1"),
        (it) => extend({}, it, { deficiency: "deficiency" })
      );
      let removeList = filter(
        this.diagnosisDetail.toothInfoList,
        (item) => item.type === "4"
      );
      let commonVeneerList = filter(
        this.diagnosisDetail.toothInfoList,
        (item) => item.type == "10"
      );
      if (commonVeneerList.length) {
        this.commonVeneerList = commonVeneerList;
      }

      const productType = get(this.caseDetail, "cureType");

      let mainList = ["A", "E", "I"].includes(productType)
        ? filter(
            this.diagnosisDetail.toothInfoList,
            (item) => item.type === "7"
          ).concat(this.adultTeethList)
        : filter(
            this.diagnosisDetail.toothInfoList,
            (item) => item.type === "8"
          );
      let untreatedList = [
        ...noMoveList,
        ...noDesignList,
        ...removeList,
        ...deficiencyList,
      ];
      if (untreatedList.length || ["A", "E", "I"].includes(productType)) {
        let teethTypeObj = {
          2: "noMove",
          3: "noDesign",
          4: "remove",
          1: "deficiency",
        };
        this.commonTeeth = map(mainList, (item) => {
          let obj;
          each(untreatedList, (it) => {
            if (item.number === it.number && !item.nextNumber) {
              obj = extend({}, item, obj, {
                [teethTypeObj[it.type]]: teethTypeObj[it.type],
              });
            }
          });
          return obj ? obj : item;
        });
      } else {
        this.commonTeeth =
          filter(
            this.diagnosisDetail.toothInfoList,
            (item) => !item.nextNumber
          ) || [];
      }
      this.isLoading = false;
    },
  },
  mounted() {
    this.init();
  },
};
</script>

<style lang="scss" scoped>
.information,
.information-large {
  max-width: 444px;
  height: 100%;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  color: var(--textColor);
  padding: 10px 0 0;

  .title {
    margin-bottom: 10px;
    padding: 0 30px 0;
    width: 100%;
    height: 32px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .label {
      display: flex;
      align-items: center;
      font-size: 14px;
      color: #fcc80e;

      &-icon {
        width: 32px;
        height: 32px;
        margin-right: 12px;
      }
    }

    i {
      font-size: 20px;
      cursor: pointer;
    }

    img {
      cursor: pointer;
      width: 16px;
      height: 16px;
    }
  }

  .tabs-menu {
    max-width: 444px;
    margin-top: 14px;
    height: 30px;
    display: flex;
    align-items: center;

    .tabs-item,
    .tabs-item-active {
      cursor: pointer;
      display: flex;
      height: 100%;
      justify-content: center;
      flex: 1;
      font-size: 14px;
      line-height: 20px;
      position: relative;
    }

    .tabs-item-active {
      color: #fcc80e;
    }

    .tabs-item-active::after {
      content: "";
      position: absolute;
      bottom: 0;
      left: 50%;
      transform: translateX(-50%);
      width: 50px;
      height: 2px;
      background-color: #fcc80e;
    }
  }

  .content {
    flex: 1;
    padding: 0 30px;
    overflow-y: auto;

    .patient_pic_wrap {
      width: 100%;
      display: flex;
      align-items: center;
      flex-flow: row wrap;
      margin-top: 20px;
      padding-bottom: 40px;
      overflow: hidden;

      .mon_img {
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-flow: column;

        img {
          display: block;
          width: 2.04rem;
          margin-top: 2rem;
        }

        .init_text {
          margin-top: 0.2rem;
          font-size: 0.18rem;
        }
      }

      .patient_pic {
        width: 100%;
        border-radius: 8px;
        margin-bottom: 30px;
        background-color: rgba($color: #000000, $alpha: 0.04);
        position: relative;
        overflow: hidden;

        .patient_pic_item {
          display: block;
          width: 100%;
        }

        p {
          width: 100%;
          height: 40px;
          position: relative;
          font-size: 14px;
          background-color: var(--darkColor);
          z-index: 22;
          color: var(--textColor);
          display: flex;
          justify-content: center;
          align-items: center;
          border-bottom-right-radius: 8px;
          border-bottom-left-radius: 8px;
        }
      }
    }
  }
}

/deep/ .stage-adjust-preview {
  .preview {
    background-color: transparent;
  }

  .result-title > div:first-child {
    color: var(--messageColor);
  }
  
  .label {
    color: var(--messageColor) !important;
  }

  .value,
  .tmj-issues-note {
    color: var(--textColor) !important;
  }

  .jaw-item-changed {
    .space-input {
      .el-input__inner {
        background-color: transparent !important;
      }
    }
  }
  .el-input__inner {
    background-color: transparent !important;
  }

  // }
}

.information-large {
  max-width: 100%;
}
.el-icon-close:hover{
  color: #fcc80e;
}
</style>
