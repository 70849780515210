import {getUser} from 'common/api/user';
import {formatDate} from 'common/js/util';
import {getCityList} from "common/api/public";

export default {
  getUserMsg(context){
    getUser().then(data => {
      localStorage.setItem('userName', data.loginName)
      const obj = {
        parentId: data.parentId,
        kind: data.kind,
        nickname: data.nickname,
        loginName: data.loginName,
        realName: data.realName,
        photo: data.photo,
        mobile: data.mobile,
        email: data.email,
        wechatCode: data.wechatCode,
        wechatQrCode: data.wechatQrCode,
        description: data.description,
        isEmail: data.emailBindFlag,
        isPhone: data.mobileBindFlag,
        isKind: !!data.idNo,
        loginPwdFlag: data.loginPwdStrength,
        tradepwdFlag: data.tradePwdStrength,
        googleAuthFlag: data.googleAuthFlag,
        createDatetime: formatDate(data.createDatetime, 'yyyy-MM-dd hh:mm:ss'),
        lastLogin: formatDate(data.lastLoginDatetime, 'yyyy-MM-dd hh:mm:ss')
      };
      context.commit('USER_MSG', obj);
    });
  },
  async fetchCityList({ commit }){
    if (this.state.cityList.length > 0) {
      return; // 如果数据已存在，直接返回
    }
    const result=await getCityList()
    commit('CITY_LIST', result)
  },
}
