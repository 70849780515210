<template>
  <div
    class="preview-table"
    :style="{ padding: isCaseData && '0px' }"
  >
    <div class="faultiness" v-if="hasQuestion && !isCaseData">
      <div class="title">{{ $t("cases.maintain.wwcts") }}</div>
      <div class="faultiness-content">
        <div
          class="question-item"
          v-for="(item, index) in questionList"
          :key="index"
          @click="jumpQuestion(item)"
        >
          {{ item }}
        </div>
      </div>
    </div>
    <div class="prefect" v-else>
      <div class="title" v-if="!isCaseData">
        {{ $t("cases.maintain.bltxwz") }}
      </div>
      <div class="prefect-content">
        <div class="user-card" v-if="!isCaseData">
          <div class="user-avatar">
            <img :src="`${$PicPrefix}${userInfo.photo}`" alt="" />
          </div>
          <div class="user-info">
            <div class="user-name">{{ caseInfo.userName }}</div>
            <div class="case-no">
              {{ $t("cases.cases.blh") }}：{{ caseInfo.caseNumber }}
            </div>
            <div class="clinical-situation">
              {{ $t("cases.cases.lcqk") }}: {{ caseInfo.clinicInfo }}
            </div>
          </div>
        </div>
        <div class="echo-result">
          <div v-if="!isShowPic">
            <div class="result-title">1. {{ $t("cases.maintain.jzwcqk") }}</div>
            <div class="result-subtitle">
              <div
                class="label"
                :style="{ width: language === 'zh_CN' ? '2.4rem' : '3.5rem' }"
              >
                1.1 {{ $t("cases.maintain.qrwcsynzqk") }}：
              </div>
              <div class="value">
                {{ diaKeyObj[curePlanInfo["treatmentStatus"]] }}
              </div>
            </div>
            <div
              class="other-input"
              v-if="curePlanInfo['treatmentStatus'] === 'other'"
            >
              {{ curePlanInfo["treatmentStatusOther"] }}
            </div>
            <div class="result-subtitle">
              <div
                class="label"
                :style="{ width: language === 'zh_CN' ? '2.4rem' : '3.5rem' }"
              >
                1.2 {{ $t("cases.maintain.jzwcrq") }}：
              </div>
              <div class="value">
                {{ curePlanInfo["treatmentCompletionDate"] }}
              </div>
            </div>
          </div>
          <div v-if="!isShowPic">
            <div class="result-title">2. {{ $t("cases.maintain.dzbcq") }}</div>
            <div class="result-value">
              {{
                curePlanInfo["retainer"] == "true"
                  ? $t("cases.maintain.xy")
                  : $t("cases.maintain.bxy")
              }}
            </div>
            <div v-if="curePlanInfo['retainer'] === 'true'">
              <div class="result-subtitle">
                <div
                  class="label"
                  :style="{ width: language === 'zh_CN' ? '2.4rem' : '3.5rem' }"
                >
                  2.1 {{ $t("cases.maintain.dzfs") }}：
                </div>
                <div class="value">
                  {{ diaKeyObj[curePlanInfo["alignerBasedOn"]] }}
                </div>
              </div>
              <div class="result-subtitle">
                <div
                  class="label"
                  :style="{ width: language === 'zh_CN' ? '2.4rem' : '3.5rem' }"
                >
                  2.2 {{ $t("cases.maintain.czxz") }}：
                </div>
                <div class="value">{{ plasticsName }}</div>
              </div>
              <div class="result-subtitle">
                <div
                  class="label"
                  :style="{ width: language === 'zh_CN' ? '2.4rem' : '3.5rem' }"
                >
                  2.3 {{ $t("cases.maintain.sfmcfj") }}：
                </div>
                <div class="value">
                  {{ diaKeyObj[curePlanInfo["removeAttachment"]] }}
                </div>
              </div>

              <div class="result-subtitle">
                <div
                  class="label"
                  :style="{ width: language === 'zh_CN' ? '2.4rem' : '3.5rem' }"
                >
                  2.4 {{ $t("cases.maintain.sfxyccmxqg") }}：
                </div>
                <div class="value">
                  {{ diaKeyObj[curePlanInfo["aestheticCutting"]] }}
                </div>
              </div>

              <div class="result-subtitle">
                <div
                  class="label"
                  :style="{ width: language === 'zh_CN' ? '2.4rem' : '3.5rem' }"
                >
                  2.5 {{ $t("cases.maintain.scssfxyylwz") }}：
                </div>
                <div class="value">
                  {{ diaKeyObj[curePlanInfo["reservedPosition"]] }}
                </div>
              </div>

              <div class="result-subtitle">
                <div
                  class="label"
                  :style="{ width: language === 'zh_CN' ? '2.4rem' : '3.5rem' }"
                >
                  2.6 {{ $t("cases.maintain.shscsfxyfzyhdb") }}：
                </div>
                <div class="value">
                  {{ diaKeyObj[curePlanInfo["occlusalGuide"]] }}
                </div>
              </div>
            </div>
          </div>
          <div class="result-title">
            {{ !isShowPic ? "3. " : "" }}{{ $t("cases.maintain.jzwchmxjknzp") }}
          </div>
          <div class="face-pic-container">
            <div
              class="pic-item"
              v-for="(item, index) in facePic"
              :key="index"
              v-if="curePlanInfo[item.id] && curePlanInfo[item.id].nanoId"
            >
              <div
                class="after-upload"
                @click="
                  showCarousel({
                    filed: item.id,
                    text: item.name,
                    index: getPicIndex(item.id),
                  })
                "
              >
                <img
                  class="after-upload-img"
                  v-if="curePlanInfo[item.id] && curePlanInfo[item.id].nanoId"
                  :src="`${$PicPrefix}${
                    curePlanInfo[item.id].nanoId
                  }?imageView2/0/w/360/h/250`"
                  :style="{
                    transform: `rotate(${
                      curePlanInfo[item.id]['degree']
                    }deg) scaleY(${
                      curePlanInfo[item.id]['vertical'] ? -1 : 1
                    }) scaleX(${curePlanInfo[item.id]['horizontal'] ? -1 : 1})`,
                  }"
                  alt=""
                />
                <p class="pic_look">
                  <img src="/static/images/createCases/check_icon.png" alt="" />
                  <span>{{ $t("cases.createImage.ck") }}</span>
                </p>
              </div>
              <div class="img-name">{{ item.name }}</div>
            </div>
          </div>
          <div class="result-title">
            {{ !isShowPic ? "4. " : "" }}{{ $t("cases.maintain.jzwchxgp") }}
          </div>
          <div class="xray-pic-container">
            <div
              class="pic-item"
              v-for="(item, index) in XRayPic"
              :key="index"
              v-if="curePlanInfo[item.id] && curePlanInfo[item.id].nanoId"
            >
              <div
                class="after-upload"
                @click="
                  showCarousel({
                    filed: item.id,
                    text: item.name,
                    index: getPicIndex(item.id),
                  })
                "
              >
                <img
                  class="after-upload-img"
                  v-if="curePlanInfo[item.id] && curePlanInfo[item.id].nanoId"
                  :src="`${$PicPrefix}${
                    curePlanInfo[item.id].nanoId
                  }?imageView2/0/w/360/h/250`"
                  :style="{
                    transform: `rotate(${
                      curePlanInfo[item.id]['degree']
                    }deg) scaleY(${
                      curePlanInfo[item.id]['vertical'] ? -1 : 1
                    }) scaleX(${curePlanInfo[item.id]['horizontal'] ? -1 : 1})`,
                  }"
                  alt=""
                />
                <p class="pic_look">
                  <img src="/static/images/createCases/check_icon.png" alt="" />
                  <span>{{ $t("cases.createImage.ck") }}</span>
                </p>
              </div>
              <div class="img-name">{{ item.name }}</div>
            </div>
          </div>
          <div class="result-title">
            {{ !isShowPic ? "5. " : "" }}{{ $t("cases.maintain.yhmx") }}
          </div>
          <div class="result-value">
            {{ diaKeyObj[curePlanInfo["dentalModel"]] }}
          </div>
          <div
            class="field-container"
            v-if="curePlanInfo['dentalModel'] == 'submit'"
          ></div>
          {{ curePlanInfo["stlFIle"] }}
          <PublicTeethModel
            v-if="curePlanInfo['dentalModel'] == 'submit'"
            :details="curePlanInfo"
            :fileList="curePlanInfo.attachmentList"
            :showTitle="false"
            renderModelType="siliconeImpression"
            renderFiled="stlFile"
          />
        </div>
      </div>
    </div>
    <PreviewImg
      :carouselList="carouselList"
      ref="previewImg"
      @imgChange="imgChange"
    />
  </div>
</template>

<script>
import PreviewImg from "components/preview-image/preview-image.vue";
import PublicTeethModel from "components/treatment-table/preview-teeth-model/index";
import { toCompleteCurePlan, caseDoneDetail } from "common/api/cases";
import { mapActions, mapState } from "vuex";
import { filter, map, extend } from "lodash";
import { init } from "events";

export default {
  data() {
    return {
      isLoading: false,
      hasQuestion: false,
      questionList: [],
      curePlanInfo: {},
      userInfo: {},
      plasticsName: "",
      facePic: [
        {
          id: "sideProfile",
          name: this.$t("cases.createImage.cmx"),
          label: "cmx",
        },
        {
          id: "frontal",
          name: this.$t("cases.createImage.zmx"),
          label: "zmx",
        },
        {
          id: "frontalSmile",
          name: this.$t("cases.createImage.wxx"),
          label: "wxx",
        },
        {
          id: "upperArch",
          name: this.$t("cases.createImage.sylx"),
          label: "sylx",
        },
        {
          id: "anteriorOverjet",
          name: this.$t("cases.createImage.qyfhfgx"),
          label: "qyfhfgx",
        },
        {
          id: "lowerArch",
          name: this.$t("cases.createImage.xylx"),
          label: "xylx",
        },
        {
          id: "intraOralRight",
          name: this.$t("cases.createImage.knzcyx"),
          label: "knzcyx",
        },
        {
          id: "intraOralFrontal",
          name: this.$t("cases.createImage.knzwx"),
          label: "knzwx",
        },
        {
          id: "intraOralLeft",
          name: this.$t("cases.createImage.knycyx"),
          label: "knycyx",
        },
      ],
      XRayPic: [
        {
          id: "panoramicRadiograph",
          name: this.$t("cases.createImage.dcp"),
          label: "dcp",
        },
        {
          id: "lateralCephalogram",
          name: this.$t("cases.createImage.dwp"),
          label: "dwp",
        },
      ],
      carouselList: [],
    };
  },
  computed: {
    ...mapState({
      language: (state) => state.language,
    }),
  },
  components: {
    PreviewImg,
    PublicTeethModel,
  },
  props: {
    isDatabase: {
      default:false,
    },
    diaKeyObj: {
      required: true,
    },
    plasticsSheetList: {
      required: true,
    },
    isCaseData: {
      required: false,
      type: Boolean,
      default: false,
    },
    details: {
      type: Object,
      default: () => ({}),
    },
    isShowPic: {
      required: false,
      type: Boolean,
      default: false,
    },
    caseInfo: {
      required: false,
    },
  },
  watch: {
    isDatabase:{
      handler: function (nval) {
          if (!nval) {
            this.toCompleteCurePlan();
          }
      },
      immediate: true,
      deep: true,
    },
    details: {
      handler: function (nval) {
        this.curePlanInfo = nval;
        this.init();
      },
      deep: true,
    },
    plasticsSheetList: {
      deep: true,
      handler: function (nval) {
        nval.map((item) => {
          if (item.id === this.curePlanInfo["materialSelection"]) {
            this.plasticsName = item.name;
          }
        });
      },
    },
  },
  methods: {
    ...mapActions({
      getCaseCureDetail: "actionGetCaseCureDetail", //治疗表详情
    }),
    imgChange(obj) {
      this.$forceUpdate();
      for (const key in this.curePlanInfo) {
        if (obj.filed === key) {
          const current = this.curePlanInfo[key];
          this.$set(current, "degree", obj.degree);
          this.$set(current, "horizontal", obj.horizontal);
          this.$set(current, "vertical", obj.vertical);
        }
      }
    },
    jumpQuestion(val) {
      const pageOne = [this.$t("cases.maintain.jzwcqk")];
      const pageTwo = [
        this.$t("cases.maintain.dzbcq"),
        this.$t("cases.maintain.dzbcqdzfs"),
        this.$t("cases.maintain.dzbcqczxz"),
        this.$t("cases.maintain.dzbcqsfmcfj"),
      ];
      const pageThree = [this.$t("cases.maintain.yhmx")];
      let fixIdx = 0;
      if (pageOne.includes(val)) fixIdx = 1;
      if (pageTwo.includes(val)) fixIdx = 2;
      if (pageThree.includes(val)) fixIdx = 3;
      this.$emit("jumpQuestion", fixIdx);
    },
    showCarousel(item) {
      this.$nextTick(() => {
        this.$refs["previewImg"] &&
          this.$refs["previewImg"].show(item.text, item.index);
      });
    },
    getPicIndex(filed) {
      return filter(this.carouselList, (item) => item.filed === filed)[0].index;
    },
    toCompleteCurePlan() {
      this.isLoading = true;
      toCompleteCurePlan({ id: this.$route.query.curePlanId, type: 'completion' })
        .then((res) => {
          this.isLoading = false;
          const { groupNameList = [] } = res;
          const questionList = groupNameList;
          this.questionList = questionList;
          if (questionList.length === 0) {
            if (!this.isCaseData) {
              this.getCurePlanDetail();
            } else {
              this.curePlanInfo = this.details;
              this.init();
            }
            this.hasQuestion = false;
          } else {
            this.hasQuestion = true;
          }
        })
        .catch((err) => {
          this.isLoading = false;
        });
    },
    initObj(str, filed, name) {
      if (!str) {
        return "";
      }
      let obj = JSON.parse(str);
      !obj.nanoId && (obj.nanoId = obj.ETag);
      !obj.degree && (obj.degree = 0);
      !obj.horizontal && (obj.horizontal = false);
      !obj.vertical && (obj.vertical = false);
      obj.filed = filed;
      obj.name = name;
      return obj;
    },
    getCurePlanDetail() {
      caseDoneDetail({
        caseId: this.$route.query.caseId,
        curePlanId: this.$route.query.curePlanId,
        type:'completion'
      }).then((res) => {
        this.curePlanInfo = res.columnData;
        this.userInfo = this.caseInfo["userInfo"];
        this.init();
        this.isLoading = true;
      });
    },
    init() {
      this.curePlanInfo["sideProfile"] = this.initObj(
        this.curePlanInfo["sideProfile"],
        "sideProfile",
        this.$t("cases.createImage.cmx")
      );
      this.curePlanInfo["frontal"] = this.initObj(
        this.curePlanInfo["frontal"],
        "frontal",
        this.$t("cases.createImage.zmx")
      );
      this.curePlanInfo["frontalSmile"] = this.initObj(
        this.curePlanInfo["frontalSmile"],
        "frontalSmile",
        this.$t("cases.createImage.wxx")
      );
      this.curePlanInfo["upperArch"] = this.initObj(
        this.curePlanInfo["upperArch"],
        "upperArch",
        this.$t("cases.createImage.sylx")
      );
      this.curePlanInfo["anteriorOverjet"] = this.initObj(
        this.curePlanInfo["anteriorOverjet"],
        "anteriorOverjet",
        this.$t("cases.createImage.qyfhfgx")
      );
      this.curePlanInfo["lowerArch"] = this.initObj(
        this.curePlanInfo["lowerArch"],
        "lowerArch",
        this.$t("cases.createImage.xylx")
      );
      this.curePlanInfo["intraOralRight"] = this.initObj(
        this.curePlanInfo["intraOralRight"],
        "intraOralRight",
        this.$t("cases.createImage.knzcyx")
      );
      this.curePlanInfo["intraOralFrontal"] = this.initObj(
        this.curePlanInfo["intraOralFrontal"],
        "intraOralFrontal",
        this.$t("cases.createImage.knzwx")
      );
      this.curePlanInfo["intraOralLeft"] = this.initObj(
        this.curePlanInfo["intraOralLeft"],
        "intraOralLeft",
        this.$t("cases.createImage.knycyx")
      );
      this.curePlanInfo["panoramicRadiograph"] = this.initObj(
        this.curePlanInfo["panoramicRadiograph"],
        "panoramicRadiograph",
        this.$t("cases.createImage.dcp")
      );
      this.curePlanInfo["lateralCephalogram"] = this.initObj(
        this.curePlanInfo["lateralCephalogram"],
        "lateralCephalogram",
        this.$t("cases.createImage.dwp")
      );
      let echoFacePic = [
        this.curePlanInfo["sideProfile"],
        this.curePlanInfo["frontal"],
        this.curePlanInfo["frontalSmile"],
        this.curePlanInfo["upperArch"],
        this.curePlanInfo["anteriorOverjet"],
        this.curePlanInfo["lowerArch"],
        this.curePlanInfo["intraOralRight"],
        this.curePlanInfo["intraOralFrontal"],
        this.curePlanInfo["intraOralLeft"],
      ];
      let echoXRayPic = [
        this.curePlanInfo["panoramicRadiograph"],
        this.curePlanInfo["lateralCephalogram"],
      ];
      let picList = [...echoFacePic, ...echoXRayPic];
      picList = picList.filter((item) => item.nanoId);
      this.carouselList = map(picList, (item, index) => {
        return extend({}, item, { index: index + "" });
      });
      this.plasticsSheetList.map((item) => {
        if (item.id === this.curePlanInfo["materialSelection"]) {
          this.plasticsName = item.name;
        }
      });
    },
  },
  created() {
    if (Object.keys(this.details).length) {
      console.log(this.details)
      this.curePlanInfo = this.details;
    }
  },
  mounted() {
    if (Object.keys(this.details).length) {
      console.log(this.details)
      this.curePlanInfo = this.details;
    }
  },
};
</script>

<style lang="scss" scoped>
.preview-table {
  background: #fff;
  padding: 30px 60px;

  .title {
    font-size: 22px;
    line-height: 30px;
    color: #333;
    margin-bottom: 30px;
  }

  .faultiness-content {
    display: flex;
    flex-wrap: wrap;
    margin-top: 37px;

    .question-item {
      cursor: pointer;
      font-size: 18px;
      line-height: 26px;
      color: #333;
      margin-right: 20px;
      margin-bottom: 20px;
      text-decoration: underline;
      word-break: break-all;
    }
  }

  .prefect-content {
    .user-card {
      height: 180px;
      border-radius: 6px;
      padding: 30px;
      background-color: #f4f4f4;
      display: flex;
      align-items: center;

      .user-avatar {
        margin-right: 20px;
        img {
          width: 120px;
          height: 120px;
          border-radius: 50%;
        }
      }

      .user-info {
        font-size: 14px;
        line-height: 20px;
        color: #333;

        .user-name {
          font-size: 20px;
          line-height: 28px;
        }

        .case-no {
          margin: 4px 0 10px;
        }

        .clinical-situation {
          font-size: 16px;
          line-height: 22px;
        }
      }
    }
  }

  .echo-result {
    .result-title {
      font-size: 16px;
      line-height: 22px;
      color: #333;
      margin-bottom: 10px;
      margin-top: 30px;
    }

    .result-value {
      font-size: 16px;
      line-height: 22px;
      color: #333;
      margin-bottom: 10px;
    }

    .result-subtitle {
      display: flex;
      align-items: center;
      margin-bottom: 10px;
      font-size: 14px;
      line-height: 20px;
      color: #666;

      .label {
        width: 180px;
        text-align: left;
        margin-right: 30px;
      }

      .value {
        color: #333;
      }
    }

    .other-input {
      padding: 10px 20px;
      border-radius: 4px;
      background: #f4f4f4;
      font-size: 14px;
      line-height: 20px;
      color: #333;
      margin-bottom: 10px;
      word-break: break-all;
    }
  }

  .face-pic-container,
  .xray-pic-container {
    display: flex;
    flex-wrap: wrap;

    .pic-item {
      width: 344px;
      height: 280px;
      margin: 0 43px 30px 0;
      display: flex;
      flex-direction: column;
      justify-content: space-between;

      &:nth-child(3n + 3) {
        margin-right: 0px;
      }
    }

    .after-upload {
      cursor: pointer;
      display: flex;
      justify-content: center;
      align-items: center;
      height: 240px;
      //border: 1px solid #bbb;
      border-radius: 8px;
      background-color: #f0f0f0;
      position: relative;
      overflow: hidden;

      &:hover .pic_look {
        opacity: 1;
      }

      .pic_look {
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        height: 0.5rem;
        background-color: rgba(0, 0, 0, 0.6);
        color: #fff;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 0.16rem;
        opacity: 0;
        transition: opacity 0.6s;
        z-index: 1;
        img {
          width: 0.24rem;
          height: 0.16rem;
          margin-right: 0.1rem;
        }
      }

      img {
        max-width: 100%;
        max-height: 100%;
      }
    }

    .img-name {
      font-size: 16px;
      line-height: 22px;
      color: #333;
      text-align: center;
      margin-top: 10px;
    }
  }
}
</style>