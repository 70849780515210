<template>
  <div v-loading="loading" class="form_wrap">
    <div class="form_wrap_content">
      <el-form
        :model="form"
        status-icon
        :rules="rules"
        ref="Form"
        :label-width="language === 'en_US' ? '140px' : '105px'"
      >
        <el-form-item :label="$t('birdie.jjr')" prop="sender">
          <el-input
            :disabled="+$route.query.location === 1"
            :placeholder="$t('birdie.qtxlxr')"
            class="input_width"
            v-model="form.sender"
            autocomplete="off"
          ></el-input>
        </el-form-item>
        <el-form-item :label="$t('birdie.xzdq')" prop="provinces">
          <el-cascader
            :props="{
              value: 'id',
              label: 'name',
              children: 'sub',
            }"
            :placeholder="$t('birdie.qxzdq')"
            class="input_width"
            v-model="form.provinces"
            :options="cityData"
            @change="handleChange"
          ></el-cascader>
        </el-form-item>
        <el-form-item :label="$t('cases.createUser.xxdz')" prop="address">
          <el-input
            :placeholder="$t('birdie.qxzjddz')"
            class="input_width"
            v-model="form.address"
            autocomplete="off"
          ></el-input>
        </el-form-item>
        <el-form-item
          :label="$t('birdie.lxdh')"
          :prop="form.contactWay === '1' ? 'phone' : 'phone_'"
        >
          <el-select
            class="input_width"
            v-model="form.contactWay"
            @change="optionsChange"
            :placeholder="$t('login.retrievePaw.sjh')"
          >
            <el-option
              v-for="item in iphoneTypeOptions"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
          <div class="flex-y-c">
            <div
              style="position: absolute; left: -82px; top: 0; z-index: 999"
              class="c-red"
            >
              *
            </div>
            <el-input
              v-show="form.contactWay === '1'"
              :placeholder="$t('birdie.qtxsjh')"
              class="input_width mt16"
              v-model="form.phone"
              autocomplete="off"
            ></el-input>
          </div>
          <div v-show="form.contactWay === '2'" class="flex-y-c">
            <el-form :model="form" class="flex-y-c">
              <div
                style="position: absolute; left: -82px; top: 0; z-index: 999"
                class="c-red"
              >
                *
              </div>
              <el-form-item prop="phoneArea">
                <el-input
                  :placeholder="$t('birdie.qh')"
                  class="qh_input_width mt16"
                  v-model="form.phoneArea"
                  autocomplete="off"
                ></el-input>
              </el-form-item>
              <div class="iphone_wire"></div>
              <!--<div style="position: absolute; left: 110px; z-index: 999" class="c-red">*</div>-->
              <el-form-item prop="phone" class="mt16 ml18">
                <el-input
                  :placeholder="$t('birdie.dhhm')"
                  class="input_width_two"
                  v-model="form.phone"
                  autocomplete="off"
                ></el-input>
              </el-form-item>
            </el-form>
          </div>
        </el-form-item>
      </el-form>

      <div
        @click="submitOrEdit"
        :class="{ form_wrap_content_btn_dis: isDis }"
        class="form_wrap_content_btn flex-x-y-c"
      >
        {{
          +$route.query.location === 2
            ? $t("birdie.xj")
            : $t("casesDetail.clinicalPreferences.bc")
        }}
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions, mapState } from "vuex";
import {
  PublicSendAddressModify,
  PublicSendAddressCreate,
  PublicSendAddressDetailId,
} from "common/api/cases";
import { pickBy, some } from "lodash";
import { notifyMsg } from "common/js/util";

export default {
  data() {
    return {
      form: {
        sender: "",
        provinces: "",
        address: "",
        phone: "",
        phoneArea: "",
        contactWay: "1",
      },
      // cityData: JSON.parse(sessionStorage.getItem("cityData")),
      rules: {
        sender: [
          {
            required: true,
            message: this.$t("birdie.qtxjjr"),
            trigger: "blur",
          },
        ],
        provinces: [
          { required: true, message: this.$t("birdie.qxzdq"), trigger: "blur" },
        ],
        address: [
          {
            required: true,
            message: this.$t("birdie.qxzjddz"),
            trigger: "blur",
          },
        ],
      },
      iphoneTypeOptions: [
        {
          value: "1",
          label: this.$t("cases.createUser.sjhm"),
        },
        {
          value: "2",
          label: this.$t("birdie.gddh"),
        },
      ],
      loading: false,
    };
  },
  computed: {
    ...mapState({
      language: (state) => state.language,
      cityData:state => state.cityList

    }),
    ...mapGetters({}),
    isDis() {
      let { sender, provinces, address, phone, phoneArea, contactWay } =
        this.form;
      let currentPhone = +contactWay === 1 ? phone : !!phoneArea && !!phone;
      let list = [sender, currentPhone, provinces, address];
      return some(list, (item) => !item);
    },
  },
  methods: {
    ...mapActions({}),
    handleChange(val) {},
    optionsChange() {
      this.form.phone = "";
      this.form.phoneArea = "";
    },
    submitOrEdit() {
      if (this.isDis) return;
      this.$refs["Form"].validate((val) => {
        if (val) {
          let { location, editId } = this.$route.query;
          let api =
            +location === 2 ? PublicSendAddressCreate : PublicSendAddressModify;
          let { provinces, address, phone, phoneArea, sender, contactWay } =
            this.form;
          let params = pickBy({
            refType: +location === 2 ? "DOCTOR" : "",
            refCode: +location === 2 ? localStorage.getItem("userId") : "",
            id: +location !== 2 ? editId : "",
            province: provinces[0],
            city: provinces[1],
            area: provinces[2],
            address,
            sender,
            contactWay,
          });
          const reg_phone =
            /^1(3[0-9]|4[01456879]|5[0-35-9]|6[2567]|7[0-8]|8[0-9]|9[0-35-9])\d{8}$/;
          const reg_areaPhone = /\d{3}-\d{8}|\d{4}-\{7,8}/;
          if (contactWay == 1 && !reg_phone.test(this.form.phone)) {
            return notifyMsg(this, "error", this.$t("common.message.qzqtxsjh"));
          }
          if (contactWay == 1 && reg_phone.test(this.form.phone)) {
            params.phone = this.form.phone;
            return api(params).then(() => {
              this.$router.back();
              return notifyMsg(
                this,
                "success",
                this.$t("login.retrievePaw.czcg")
              );
            });
          }
          if (contactWay !== 1) {
            if (this.form.phoneArea && this.form.phone) {
              const areaPhonePass = /^0\d{2,4}$/.test(this.form.phoneArea);
              const phonePass = /^\d{7,8}$/.test(this.form.phone);
              if (!areaPhonePass || !phonePass) {
                if (!areaPhonePass) {
                  notifyMsg(this, "warning", this.$t("birdie.qhsryw"));
                  return Promise.reject(this.$emit("saveError"));
                }
                if (!phonePass) {
                  notifyMsg(this, "warning", this.$t("birdie.dhhmsryw"));
                  return Promise.reject(this.$emit("saveError"));
                }
              }
            }
          }
          params.phoneArea = this.form.phoneArea;
          params.phone = this.form.phone;
          api(params).then(() => {
            this.$router.back();
            return notifyMsg(
              this,
              "success",
              this.$t("login.retrievePaw.czcg")
            );
          });
        }
      });
    },
    getDetail() {
      this.loading = true;
      PublicSendAddressDetailId(this.$route.query.editId)
        .then((data) => {
          let {
            province,
            city,
            area,
            sender,
            address,
            contactWay,
            phone,
            phoneArea,
          } = data;
          this.form = {
            provinces: [province, city, area],
            sender: sender,
            address: address,
            contactWay,
            phone: phone,
            phoneArea: phoneArea,
          };
          this.loading = false;
        })
        .catch(() => {
          this.loading = false;
        })
        .catch(() => {
          this.loading = false;
        });
    },
  },

  created() {
    let { editId, location } = this.$route.query;
    let str = {
      1: this.$t("birdie.bjjjjjdz"),
      2: this.$t("birdie.xzgedz"),
      3: this.$t("birdie.bjgedz"),
    }[location];
    this.$emit("getDiaTitle", str);
    if (editId) {
      this.getDetail();
    }
  },
};
</script>

<style lang="scss" scoped>
.form_wrap {
  width: 100%;
  height: 100%;
  padding: 20px 30px 30px 30px;
  .form_wrap_content {
    width: 100%;
    height: 100%;
    box-shadow: 0 0 8px 0 rgba(51, 51, 51, 0.12);
    padding: 30px;
    position: relative;
    .iphone_wire {
      width: 10px;
      height: 1px;
      background: #bbbbbb;
      position: relative;
      left: 9px;
      top: 7px;
    }
    .form_wrap_content_btn {
      width: 216px;
      border-radius: 6px;
      background: #fcc80e;
      height: 42px;
      color: #333333;
      font-size: 16px;
      position: absolute;
      bottom: 20px;
      left: 50%;
      transform: translateX(-50%);
      cursor: pointer;
    }
    .form_wrap_content_btn_dis {
      background: #e5e5e5 !important;
      cursor: auto;
      color: #999999;
    }
  }
}
.input_width {
  width: 3.16rem;
}
.qh_input_width {
  width: 88px;
}
.input_width_two {
  width: 200px;
}
.qh_input_width_three {
  width: 146px;
}
/deep/ .el-form-item__content .input_width_two .el-form-item__error {
  left: 10px !important;
}
/deep/ .el-input__inner {
  padding-right: 0.3rem;
}
/deep/ .el-form {
  width: 500px !important;
}
/deep/ .el-form-item__label {
  color: #333333;
  font-size: 14px;
}
/deep/ .el-form-item__error {
  color: #ed4027;
}
</style>