<template>
  <div class="login_box">
    <div v-if="!domain" class="l_container">
      <img
        class="left_logo"
        src="../../common/imgs/login/logo_innobuilder.png"
        alt=""
      />
      <div class="l_form_right">
        <div class="form_header flex-x-between flex-y-c">
          <img src="../../../public/static/images/logo/login_logo.png" alt="" />

          <div>
            <span
              @click="changeLanguage('zh_CN')"
              :class="{
                main_theme_color_999: lang === 'en_US',
                main_theme_color_333: lang === 'zh_CN'
              }"
              class="fz16 curp"
              >CN</span
            >
            <span
              @click="changeLanguage('en_US')"
              :class="{
                main_theme_color_999: lang === 'zh_CN',
                main_theme_color_333: lang === 'en_US'
              }"
              class="fz16 ml32 curp"
              >EN</span
            >
          </div>
        </div>
        <h5 class="l_form_right_logo fzBold">{{ $t("login.login.dl") }}</h5>
        <p class="fz14 main_theme_color_333 mt6">
          {{ $t("login.login.hyldylpt") }}
        </p>
        <div class="l_f_box">
          <transition name="el-zoom-in-center"> </transition>
          <el-input
            class="text mb30"
            type="text"
            :placeholder="$t('login.login.qsryhm')"
            v-model.trim="params.loginName"
            @input="trim('loginName')"
          />
          <div class="flex-y-c posr">
            <el-input
              ref="password"
              :type="passwordType"
              :placeholder="$t('login.login.srmm')"
              v-model.trim="params.loginPwd"
              @keyup.enter="toLogin"
              @input="trim('loginPwd')"
            >
            </el-input>
            <img
              @click="changeInput"
              class="eyes curp"
              src="../../common/imgs/login/eyes.png"
              alt=""
            />
          </div>
        </div>
        <div class="f_r_foo">
          <el-checkbox class="main_theme_color_333" v-model="isRemember">{{
            $t("login.login.jzmm")
          }}</el-checkbox>
          <span
            class="f_r_foo_wj main_theme_color_999 fz14"
            @click="goPage('/retrievePaw')"
            >{{ $t("login.login.wjmm") }}</span
          >
        </div>
        <div
          :class="{ dis_btn: !isDisBtn }"
          class="btn_box w100 main_theme_color_btn"
          @click="toLogin"
        >
          {{ $t("login.login.dl") }}
        </div>
      </div>
      <p class="bottom_text" :class="{ bottom_text_en: lang === 'en_US' }">
        {{ $t("login.login.jszc") }}
        <a href="https://www.aigrin.cn" target="_blank">
          <span class="curp main_theme_color_333">{{
            $t("login.login.gfwz")
          }}</span>
        </a>
      </p>
    </div>
    <div class="duole-container" v-else>
      <div class="duole-content">
        <div class="login-wrap">
          <div class="login-header">
            <div class="logo"></div>
            <div class="locale-switch">
              <span
                :class="{
                  main_theme_color_999: lang === 'en_US',
                  main_theme_color_333: lang === 'zh_CN'
                }"
                @click="changeLanguage('zh_CN')"
                >ZH</span
              >
              /
              <span
                :class="{
                  main_theme_color_999: lang === 'zh_CN',
                  main_theme_color_333: lang === 'en_US'
                }"
                @click="changeLanguage('en_US')"
                >EN</span
              >
            </div>
          </div>
          <div class="welcome-label">
            <div>{{ $t("login.login.hyld") }}</div>
            <div>{{ $t("login.login.yxjzyspt") }}</div>
          </div>
          <div class="form-content">
            <el-input
              class="form-input"
              type="text"
              :placeholder="$t('login.login.qsryhm')"
              v-model.trim="params.loginName"
              @input="trim('loginName')"
            />
            <el-input
              class="form-input"
              ref="password"
              :type="passwordType"
              :placeholder="$t('login.login.srmm')"
              v-model.trim="params.loginPwd"
              @keyup.enter="toLogin"
              @input="trim('loginPwd')"
            >
            </el-input>
            <div class="forget-password" @click="goPage('/retrievePaw')">
              {{ $t("login.login.wjmm") }}
            </div>
            <div
              :class="{ dis_btn: !isDisBtn }"
              class="submit"
              @click="toLogin"
            >
              {{ $t("login.login.dl") }}
            </div>
          </div>
          <div class="technical-support">{{ $t("login.login.dljszc") }}</div>
        </div>
      </div>
    </div>
    <full-loading v-show="isLoading" />
  </div>
</template>

<script>
import { notifyMsg, setUser } from "common/js/util";
import { userLogin } from "common/api/user";
import FullLoading from "components/full-loading/full-loading";
import { mapActions, mapMutations } from "vuex";
import LangStorage from "common/js/cookie";
import {
  getLocaleCookie,
  setLocaleCookie,
  clearCookieByKey
} from "common/js/util";
import { querySmsList } from "common/api/system";
import VUE_APP_VERSION from "../../../package.json";
export default {
  data() {
    return {
      isLoading: false,
      isClass: false,
      params: {
        loginName: "",
        loginPwd: ""
      },
      domain: window.location.host.includes("duole"),
      isRemember:
        getLocaleCookie().isRemember &&
        eval(getLocaleCookie().isRemember.toLowerCase()),
      lang: localStorage.getItem("user_lang") || "zh_CN",
      isShowErr: false,
      passwordType: "password"
    };
  },
  computed: {
    isDisBtn() {
      let { loginName, loginPwd } = this.params;
      return !!loginName && !!loginPwd;
    }
  },
  methods: {
    ...mapMutations(["GET_IS_SHOW_NEWS"]),
    trim(val) {
      if (val == "loginName") {
        this.params["loginName"] = this.params["loginName"].replace(/\s+/g, "");
      } else if (val == "loginPwd") {
        this.params["loginPwd"] = this.params["loginPwd"].replace(/\s+/g, "");
      }
    },
    changeInput() {
      if (this.passwordType === "password") {
        this.passwordType = "text";
      } else {
        this.passwordType = "password";
      }
    },
    changeLanguage(type) {
      if (type === this.lang) return;
      this.$i18n.locale = type;
      LangStorage.setLang(this.$i18n.locale);
      window.location.reload();
    },
    controlRightShow() {
      this.isShowRight = !this.isShowRight;
    },
    resetForm() {
      this.params = {
        loginName: "",
        loginPwd: ""
      };
    },
    async toLogin() {
      const { loginName, loginPwd } = this.params;
      if (!loginName || !loginPwd) {
        return "";
      } else {
        this.isLoading = true;
        window.localStorage.setItem("appVersion", VUE_APP_VERSION.version);
        userLogin(this.params)
          .then(async data => {
            setUser(data);
            await querySmsList({
              status: "1",
              target: "DOCTOR",
              type: "SYS",
              readFlag: "0",
              language: localStorage.getItem("user_lang") || "zh_CN",
              token: data.token
            }).then(data => {
              this.GET_IS_SHOW_NEWS(data.length > 0);
            });
            this.isLoading = false;
            window.lastTime = new Date().getTime();
            if (this.isRemember) {
              document.cookie = `username=${loginName}`;
              document.cookie = `userPdw=${loginPwd}`;
            }
            this.getUserMsg();
            this.$router.push("/");
          })
          .catch(() => {
            this.isLoading = false;
          });
      }
    },
    goPage(url) {
      if (url) {
        this.$router.push(url);
      }
    },
    ...mapActions(["getUserMsg"])
  },
  watch: {
    isRemember(val) {
      if (val) {
        setLocaleCookie("isRemember", "true");
      }
      if (!val) {
        setLocaleCookie("isRemember", "false");
        clearCookieByKey("userPdw");
        clearCookieByKey("username");
      }
    }
  },
  components: {
    FullLoading
  },
  created() {
    document.title = "隐速Grintastic医生端";
    document.body.style.height = "100%";
  },
  mounted() {
    let { username, userPdw } = getLocaleCookie();
    this.params.loginName = username || "";
    this.params.loginPwd = userPdw || "";
  }
};
</script>

<style scoped lang="scss">
.duole-container {
  height: 100vh;
  overflow: hidden;
  background: linear-gradient(
    90deg,
    #fed400 0%,
    rgba(253, 213, 11, 0.96) 9%,
    rgba(252, 218, 43, 0.84) 26%,
    rgba(251, 225, 95, 0.65) 48%,
    rgba(249, 235, 166, 0.39) 83%,
    rgba(247, 247, 247, 0.1) 100%
  );

  .duole-content {
    position: relative;
    width: 100%;
    height: 100%;
    background: url("../../common/imgs/login/duole-bg.webp") no-repeat left
      center;
    background-size: auto 100%;
  }

  .welcome-wrap {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    color: #333333;
    padding-left: 185px;

    .big-title {
      width: fit-content;
      font-size: 144px;
      line-height: 209px;
      border-bottom: 2px solid #333333;
    }

    .en-message {
      font-size: 14px;
      letter-spacing: 2px;
      line-height: 20px;
      margin: 32px 0 8px;
    }

    .zh-message {
      max-width: 650px;
      font-size: 40px;
      line-height: 58px;
      font-weight: 600;
    }
  }

  .login-wrap {
    position: absolute;
    right: 193px;
    top: 50%;
    transform: translateY(-50%);
    width: 646px;
    height: 736px;
    border-radius: 16px;
    padding: 64px;
    background-color: #ffffff;

    .technical-support {
      white-space: nowrap;
      position: absolute;
      font-size: 14px;
      line-height: 20px;
      position: absolute;
      bottom: -24px;
      left: 50%;
      transform: translate(-50%, 100%);
    }

    .login-header {
      display: flex;
      justify-content: space-between;
      align-items: center;

      .logo {
        width: 112px;
        height: 48px;
        background: url("../../common/imgs/login/duole-logo.png") no-repeat
          center;
        background-size: 100% 100%;
      }

      .locale-switch {
        font-size: 16px;
        line-height: 22px;
        color: #333333;

        span {
          cursor: pointer;
        }
      }
    }

    .welcome-label {
      min-height: 92px;
      padding-left: 56px;
      margin: 64px 0 48px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: flex-start;
      position: relative;
      font-size: 32px;
      line-height: 38px;
      color: #333333;

      div {
        font-weight: 600;
      }

      &::before {
        position: absolute;
        left: 0;
        top: 0;
        content: "";
        width: 24px;
        height: 100%;
        background-color: #fcc80e;
      }
    }

    .form-content {
      .form-input {
        width: 100%;
        height: 48px;

        /deep/ .el-input__inner {
          height: 48px;
          border-radius: 12px;
          border: none;
          background-color: #f2f2f2 !important;
        }

        &:first-child {
          margin-bottom: 24px;
        }
      }

      .forget-password {
        cursor: pointer;
        margin-top: 16px;
        text-align: right;
        font-size: 14px;
        line-height: 20px;
      }

      .submit {
        cursor: pointer;
        margin-top: 112px;
        width: 100%;
        height: 48px;
        border-radius: 12px;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: #fcc80e;
        color: #333333;
        font-size: 18px;
        color: #333333;
      }
    }
  }
}

.dis_btn {
  background: #e5e5e5 !important;
  cursor: auto !important;
}

.eyes {
  display: block;
  width: 24px;
  height: 24px;
  position: absolute;
  right: 20px;
  top: 50%;
  transform: translateY(-50%);
}

.login_box {
  height: 100vh;
  display: flex;
  flex-direction: column;

  .l_container {
    width: 100%;
    height: 100%;
    display: flex;
    background-size: 100% 100%;
    background-image: url("../../../public/static/images/logo/logo_backdrop.png");
    position: relative;

    .left_logo {
      display: block;
      width: 284px;
      height: 92px;
      position: absolute;
      left: 406px;
      top: 50%;
      transform: translateY(-50%);
    }

    .l_form_right {
      background-color: white;
      width: 6.46rem;
      height: 6.25rem;
      padding: 0.6rem 1rem 0 1rem;
      border-radius: 16px;
      position: absolute;
      top: 1.31rem;
      right: 2.6rem;

      .l_form_right_img {
        margin-bottom: 0.2rem;
      }

      .l_f_box {
        margin-bottom: 0.2rem;
        margin-top: 0.6rem;

        .err_div {
          width: 446px;
          height: 40px;
          background: rgba(237, 64, 39, 0.04);
          border-radius: 4px;
          border: 1px solid #ed4027;
          margin-bottom: 20px;
        }
      }

      .l_f_box_mt60 {
        margin-top: 6rem;
      }

      .btn_box {
        height: 0.48rem;
      }

      .f_r_foo {
        display: flex;
        justify-content: space-between;
        align-items: center;
        font-size: 0.16rem;
        margin-bottom: 0.68rem;

        .f_r_foo_wj {
          cursor: pointer;
        }

        .f_r_foo_xz {
          color: #666;
          font-family: PingFang-SC-Bold;
          display: flex;
          align-items: center;
          cursor: pointer;

          .xz_icon {
            display: inline-block;
            width: 0.18rem;
            height: 0.16rem;
            margin-right: 0.1rem;
            background-size: 100% 100%;
            background-image: url("../../../public/static/images/web_download_app.png");
          }
        }
      }

      .l_form_right_logo {
        margin-top: 0.5rem;
        font-size: 0.24rem;
        color: #333333;
        border-left: 4px $main_theme_color solid;
        padding-left: 10px;
      }
    }
  }

  .l_foo {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    padding: 0.22rem 0 0.26rem;

    .l_foo_t {
      display: flex;
      align-items: center;
      margin-bottom: 0.22rem;

      p {
        padding: 0 0.58rem;
        color: #333333;
        font-size: 0.16rem;
        cursor: pointer;
      }

      .foo_p_b {
        border-right: 1px solid #b1b1b1;
        border-left: 1px solid #b1b1b1;
      }
    }

    .l_foo_p {
      color: #999;
      font-size: 0.14rem;
    }
  }
}

/deep/ .el-checkbox .el-checkbox__inner {
  width: 0.16rem !important;
  height: 0.16rem !important;
}

/deep/ .el-checkbox .el-checkbox__inner::after {
  width: 0.06rem !important;
  height: 0.1rem !important;
  left: 0.05rem !important;
  top: 0.01rem !important;
}

/deep/ .el-checkbox .el-checkbox__label {
  font-size: 0.14rem !important;
}

/deep/ .clinical_box .el-radio__input.is-checked + .el-radio__label,
.el-checkbox__input.is-checked + .el-checkbox__label {
  color: $main_theme_color_333 !important;
}

/deep/ .el-checkbox__input.is-checked + .el-checkbox__label {
  color: #555657 !important;
}

/deep/ .el-input__inner {
  height: 52px;
}
.bottom_text {
  font-size: 0.14rem;
  color: #fff !important;
  position: absolute;
  bottom: 50px;
  left: 50px;
  a {
    color: #fff !important;
    span {
      color: #fff !important;
    }
  }
}

.bottom_text_en {
  //right: 280px !important;
}
</style>
