<template>
  <div class="orthodontic-instructions">
    <div class="title">
      <div class="label">
        <svg class="label-icon" aria-hidden="true">
          <use xlink:href="#icon-shuoming_xuanzhong"></use>
        </svg>
        <span>{{ $t("threeD.jzfa") }}</span>
      </div>
      <i class="el-icon-close close-icon" @click="closeDrawer"></i>
    </div>
    <div class="content">
      <div class="oper_box">
        <p class="mb10">
          <span
            >{{ $t("casesDetail.casesChildren.operationChildren.hzxm") }}：
            <b>
              {{ get(cureNoteDetail, "userInfo.realName") }}
            </b>
          </span>
          <span style="margin: 0 0.2rem"
            >{{ $t("casesDetail.casesChildren.operationChildren.sjrq") }}：<b>{{
              get(cureNoteDetail, "designDate") || "-"
            }}</b></span
          >
        </p>
        <p v-if="cureNoteDetail.type === '2'">
          <span>{{
            $t("casesDetail.casesChildren.operationChildren.skjzbz")
          }}</span
          >：<b>
            {{ get(cureNoteDetail, "correctStepUpjaw") == 0 ? "" : "1-"
            }}{{ get(cureNoteDetail, "correctStepUpjaw") || "-" }}</b
          >
          <span style="margin-left: 0.2rem">{{
            $t("casesDetail.casesChildren.operationChildren.xkjzbz")
          }}</span
          >：<b>
            {{ get(cureNoteDetail, "correctStepDownjaw") == 0 ? "" : "1-"
            }}{{ get(cureNoteDetail, "correctStepDownjaw") || "-" }}</b
          >
        </p>
        <p v-if="cureNoteDetail.type === '1'">
          {{ $t("casesDetail.casesChildren.operationChildren.yjjzzq") }}
          {{ get(cureNoteDetail, "correctPeriod") }}
          {{ $t("casesDetail.casesChildren.operationChildren.y") }}
        </p>
      </div>
      <div class="diagram_box">
        <h5 class="diagram_box_h5">
          <img
            class="mr10"
            src="../../../../../common/imgs/3d/dia_msg_init.png"
            alt=""
          />
          {{ $t("casesDetail.casesChildren.operationChildren.lccz") }}
        </h5>
        <ul class="diagram_ul">
          <li
            v-for="(item, index) in cureNote"
            :key="item.id"
            class="diagram_single"
          >
            <div class="single_left">
              ●
              <span v-if="cureNote.length - 1 !== index">
                {{ $t("casesDetail.casesChildren.lookWearingRecord.d") }} </span
              >{{
                item.step === "其他临床操作说明"
                  ? $t("common.message.qtlcczsm")
                  : item.step
              }}{{ language == "en_US" ? "：" : ""
              }}<span
                :class="[language == 'en_US' && 'ml4']"
                v-if="cureNote.length - 1 !== index && language !== 'en_US'"
              >
                {{ $t("casesDetail.casesDetail.bu")
                }}{{ language !== "en_US" ? "：" : "" }}</span
              >
            </div>
            <div class="single_right">
              <p
                class="single_right_p"
                v-for="(cItem, cIndex) in item.note"
                :key="`note_${cIndex}`"
              >
                {{ cItem }}
              </p>
            </div>
          </li>
        </ul>
      </div>
      <div v-if="cureNoteDetail.helpFlag == 1" class="diagram_box mt30">
        <h5 class="diagram_box_h5">
          <img
            class="mr10"
            src="../../../../../common/imgs/3d/dia_msg2.png"
            alt=""
          />
          {{ $t("casesDetail.casesChildren.operationChildren.fzjkyj") }}
        </h5>
        <div class="diagram_single wsp">
          {{ cureNoteDetail.visitSuggest }}
        </div>
      </div>
<!--            <iframe
              style="width: 100%;height: 100%;"
              :src="`http://compatible.dev.aismile.cn/compatible/clinical-operation-order?curePlanId=${curePlanId}&token=eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOiI1NjcyNDczODg0OTkyNTUyOTYiLCJleHAiOjE3MjIxMzYyMjd9.-xF8nfdo28zSkB8Shv-3k1G6RiWHs1L8SSnxYJTjVpE`"
            />-->
      <div class="teeth-view">
        <teeth-view
          :isOldCase="get(cureNoteDetail, 'toothViewVersion') === '0'"
          v-if="['A', 'E', 'I'].includes(get(caseDetail, 'cureType'))"
          :cutInfoList="
            get(cureNoteDetail, 'toothCurePlanHis.cutInfoList') || []
          "
          :teethList="
            get(cureNoteDetail, 'toothCurePlanHis.toothCureInfoList') || []
          "
        />
        <Children-Teeth-View
          :isOldCase="get(cureNoteDetail, 'toothViewVersion') === '0'"
          :teethList="
            get(cureNoteDetail, 'toothCurePlanHis.toothCureInfoList') || []
          "
          :patientType="get(caseDetail, 'cureType') === 'C' ? 'baby' : 'youth'"
          :cutInfoList="
            get(cureNoteDetail, 'toothCurePlanHis.cutInfoList') ||
              adultTeethList
          "
          v-if="['B', 'C'].includes(get(caseDetail, 'cureType'))"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { splitText } from "common/js/util";
import ChildrenTeethView from "components/treatment-table/teeth_view_copy/infancy";
import TeethView from "components/treatment-table/teeth_view_copy/index";
import { get } from "lodash";
import { mapState } from "vuex";

export default {
  data() {
    return {
      cureNote: [],
      adultTeethList: [
        { number: 11 },
        { number: 12 },
        { number: 13 },
        { number: 14 },
        { number: 15 },
        { number: 16 },
        { number: 17 },
        { number: 18 },
        { number: 21 },
        { number: 22 },
        { number: 23 },
        { number: 24 },
        { number: 25 },
        { number: 26 },
        { number: 27 },
        { number: 28 },
        { number: 48 },
        { number: 47 },
        { number: 46 },
        { number: 45 },
        { number: 44 },
        { number: 43 },
        { number: 42 },
        { number: 41 },
        { number: 31 },
        { number: 32 },
        { number: 33 },
        { number: 34 },
        { number: 35 },
        { number: 36 },
        { number: 37 },
        { number: 38 }
      ],
      pdfUrl:'',
      curePlanId: "",
      token:''
    };
  },
  components: {
    TeethView,
    ChildrenTeethView
  },
  computed: {
    ...mapState({
      language: state => state.language
    })
  },
  props: {
    cureNoteDetail: {
      type: Object,
      default: () => ({})
    },
    caseDetail: {
      type: Object,
      default: () => ({})
    }
  },
  methods: {
    get,
    closeDrawer() {
      this.$emit("closeDrawer");
    }
  },

  // mounted() {
  //   const { curePlanId } = this.$route.query;
  //   console.log(this.$route)
  //   const token=  localStorage.getItem('token')
  //   this.curePlanId = curePlanId;
  //   console.log(curePlanId)
  //   this.pdfUrl=`http://compatible.dev.aismile.cn/compatible/clinical-operation-order?curePlanId=${curePlanId}&token=eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOiI1NjcyNDczODg0OTkyNTUyOTYiLCJleHAiOjE3MjIxMzYyMjd9.-xF8nfdo28zSkB8Shv-3k1G6RiWHs1L8SSnxYJTjVpE`
  // },
  watch: {
    cureNoteDetail: {
      handler(obj) {
        const { cureNoteDetailList = [] } = obj;
        this.cureNote = cureNoteDetailList.map(item => ({
          ...item,
          note: splitText(item.note)
        }));
      },
      deep: true,
      immediate: true
    }
  }
};
</script>

<style lang="scss" scoped>
.orthodontic-instructions {
  width: 100%;
  height: 100%;
  padding: 10px 0 30px;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  color: var(--textColor);

  .title {
    padding: 0 30px;
    margin-bottom: 10px;
    width: 100%;
    height: 32px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .label {
      display: flex;
      align-items: center;
      font-size: 14px;
      color: #fcc80e;

      &-icon {
        width: 32px;
        height: 32px;
        margin-right: 12px;
      }
    }

    i {
      font-size: 20px;
      cursor: pointer;
    }

    img {
      cursor: pointer;
      width: 16px;
      height: 16px;
    }
  }

  .content {
    flex: 1;
    overflow-y: auto;
    padding: 0 30px;
  }

  .oper_box {
    margin-bottom: 0.2rem;

    p {
      line-height: 0.24rem;
      font-size: 0.16rem;
      display: flex;
      align-items: center;

      b {
        font-weight: 500;
      }
    }
  }

  .diagram_box {
    .diagram_box_h5 {
      display: flex;
      align-items: center;
      font-size: 0.16rem;
    }

    .diagram_single {
      margin-top: 10px;
      display: flex;
      word-break: break-all;
      background-color: var(--darkColor);
      padding: 10px 20px;
      cursor: default;
      font-size: 14px;
      border-radius: 4px;
      line-height: 24px;

      .single_left,
      .single_right {
        display: flex;
        align-items: center;
      }

      .single_left {
        width: 194px;
      }

      .single_right {
        flex: 1;
        position: relative;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;

        .single_right_p {
          line-height: 26px;
        }
      }
    }
  }

  .teeth-view {
    margin-top: 30px;

    .teeth_view {
      background-color: var(--darkColor);
    }
  }
}
.el-icon-close:hover{
  color: #fcc80e;
}
</style>
