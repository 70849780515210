<template>
  <div class="do_keep">
    <back-step
      :goList="[{
        url: '/cases',
        name: $t('casesDetail.casesChildren.common.bl')
      }, {
        url: `/cases/detail?caseId=${$route.query.caseId}&curePlanId=${$route.query.curePlanId}`,
        name: $t('casesDetail.casesChildren.common.blxq')
      }]"
      :currentTxt="type === 'done' ? $t('casesDetail.casesChildren.casesDone.wcbl') : $t('casesDetail.casesChildren.casesDone.gdbl')"
    />
    <div class="plan_head">
      <span class="plan_h_strong">{{$t('casesDetail.casesChildren.casesDone.blh')}}：{{casesInfo.caseNumber}}</span>
      <span class="plan_h_sp">{{casesInfo.clinicName}}</span>
      <span class="plan_h_sp">{{casesInfo.productName}}</span>
    </div>
    <div class="cases_d_box">
      <cases-user-msg v-if="commonCaseDetail.state !== 'loading'"/>
      <div class="cases_d_foo">
        <span class="submit_btn flex-x-y-c curp ml36" @click="toDone">{{$t('casesDetail.casesChildren.common.qr')}}</span>
        <span class="back_btn flex-x-y-c curp" @click="goPage('/cases/detail')">{{$t('casesDetail.casesChildren.common.fh')}}</span>
      </div>
    </div>
    <confirm-modal
      :confirmObj="{
        tipText: $t('casesDetail.casesChildren.common.qrjx'),
        backText: $t('casesDetail.casesChildren.common.qx'),
        okText: $t('casesDetail.casesChildren.common.qd')
      }"
      @confirmSubmit="confirmSubmit"
      @confirmHide="confirmHide"
      v-show="isShow"
    />
    <FullLoading v-show="isLoading"/>
  </div>
</template>

<script>
  import BackStep from '../childrenPublic/backStep';
  import CasesUserMsg from '../childrenPublic/casesUserMsg';
  import FullLoading from 'components/full-loading/full-loading';
  import ConfirmModal from 'components/confirm-modal/confirm';
  import {cureDone, cureArchive} from 'common/api/cases';
  import {notifyMsg} from 'common/js/util';
  import {mapActions,mapGetters} from 'vuex';
  import {extend} from 'lodash';

  export default {
    data() {
      return {
        isLoading: false,
        isShow: false,
        casesInfo: {},
        userForm: {
          yt01: '',
          yt02: ''
        },
        type: 'done'
      }
    },
    computed:{
      ...mapGetters({
        'commonCaseDetail': 'getCommonCaseDetail',//病例详情
      }),
    },

    methods: {
      ...mapActions({
        'getCommonCaseDetail' : 'actionGetCommonCaseDetail',//病例详情
      }),
      goPage(url) {
        this.$router.push({
          path: url,
          query: extend({}, this.$route.query)
        });
      },
      toDone() {
        this.isShow = true;
      },
      confirmSubmit() {
        const id = this.$route.query.caseId;
        if(id) {
          this.isLoading = true;
          const cureName = this.type === 'done' ? cureDone : cureArchive;
          cureName({id}).then(() => {
            this.isShow = false;
            this.isLoading = false;
            notifyMsg(this, 'success', this.$t('casesDetail.casesChildren.common.czcg')).then(() => {
              this.goPage('/cases/detail');
            });
          }).catch(() => {
            this.isLoading = false;
            this.isShow = false;
          })
        }else {
          notifyMsg(this, 'error', this.$t('casesDetail.casesChildren.common.czsb'));
        }
      },
      confirmHide() {
        this.isShow = false;
      }
    },
    created() {
      const casesId = this.$route.query.caseId;
      this.type = this.$route.query.type || this.type;
      document.title = this.type === 'done' ? this.$t('casesDetail.casesChildren.casesDone.wcbl') : this.$t('casesDetail.casesChildren.casesDone.gdbl');
      this.getCommonCaseDetail(casesId).then((data) => {
        const {productName, clinicName, caseNumber} = data;
        this.casesInfo = {
          caseNumber,
          clinicName,
          productName
        };
      })
    },
    components: {
      BackStep,
      CasesUserMsg,
      FullLoading,
      ConfirmModal
    }
  }
</script>

<style scoped lang="scss">
  .do_keep {
    width: 16rem;
    margin: 0 auto;
    padding-top: 40px;
    .plan_head {
      display: flex;
      align-items: center;
      margin-bottom: 0.2rem;
      .plan_h_strong {
        font-size: 0.2rem;
        color: $main_theme_color_333;
      }
      .plan_h_sp {
        padding-left: 0.1rem;
        border-left: 0.01rem solid #666666;
        color: $main_theme_color_333;
        margin-left: 0.1rem;
        font-size: 16px;
      }
    }
    .cases_d_box {
      background-color: #fff;
      border-radius: 6px;
      padding: 30px;
      height: 344px;
      position: relative;
      .cases_d_foo {
        width: 100%;
        display: flex;
        flex-flow: row-reverse;
        align-items: center;
        position: absolute;
        bottom: 30px;
        right: 30px;
        .back_btn{
          width: 176px;
          height: 42px;
          background: #FFFFFF;
          border-radius: 6px;
          border: 1px solid #BBBBBB;
          font-size: 16px;
          color: $main_theme_color_333;
        }
        .submit_btn{
          width: 176px;
          height: 42px;
          background: #FCC80E;
          border-radius: 6px;
          font-size: 16px;
          color: $main_theme_color_333;
        }
      }
    }
  }
</style>
