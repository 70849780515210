<template>
  <div class="all_logistics">
    <back-step
      :goList="[{
        url: '/cases',
        name: $t('casesDetail.casesChildren.common.bl')
      }, {
        url: `/cases/detail?caseId=${$route.query.caseId}&curePlanId=${$route.query.curePlanId}`,
        name: $t('casesDetail.casesChildren.common.blxq')
      }]"
      :currentTxt="$t('casesDetail.casesChildren.allLogistics.qbwl')"
    />
    <ul class="logistics_list">
      <li class="single_li" v-for="item in logistics.list" :key="item.id">
        <div class="single_l">
          <div class="s_l_l">
            <div class="s_l_l_pic" style="min-width: 0.56rem;"
                 :style="{backgroundImage: `url('http://file.aismile.cn/express/${item.logisticsInfo.companyCode}.png')`}"></div>
            <div class="s_l_l_box">
              <h5 class="s_l_l_h5">{{item.logisticsInfo.logisticsCompany}}</h5>
              <p class="s_l_l_p01">{{$t('casesDetail.casesChildren.allLogistics.ydh')}}：{{item.logisticsInfo.logisticsNo}}</p>
              <p class="s_l_l_p02">{{$t('casesDetail.casesChildren.allLogistics.wjnr')}}：{{item.logisticsName || '-'}}</p>
            </div>
          </div>
          <div class="s_l_r">
            <p class="s_l_l_r_p">{{item.logisticsInfo.senderProvinceName || '-'}}</p>
            <div class="s_l_l_r_box">
              <p class="s__l_r_p">{{statusObj[item.status]}}</p>
              <p class="s__l_r_line"></p>
              <p class="s__l_r_p01">{{item.logisticsInfo.createDatetime}}</p>
            </div>
            <p class="s_l_l_r_p">{{item.logisticsInfo.receiverProvinceName || '-'}}</p>
          </div>
        </div>
        <div class="single_r main_big_btn main_theme_color_btn" @click="toLogisticsDetail(item.id)">
          {{$t('casesDetail.casesChildren.allLogistics.ckxq')}}
        </div>
      </li>
    </ul>
    <el-pagination
      v-if="!isLoading"
      class="fr"
      :page-sizes="[10, 20, 30, 50, 100]"
      :current-page="+queryParams.pageNum"
      :page-size="+queryParams.pageSize"
      @size-change="changeRouteQuery({
        pageSize:arguments[0],
        pageNum: 1
      })"
      @current-change="changeRouteQuery({
        pageNum:arguments[0]
      })"
      background
      layout="total, sizes, prev, pager, next"
      :total="logistics.total">
    </el-pagination>
    <FullLoading v-show="isLoading"/>
  </div>
</template>

<script>
  import BackStep from '../childrenPublic/backStep';
  import FullLoading from 'components/full-loading/full-loading';
  import {notifyMsg} from 'common/js/util';
  import {logisticsList, PublicLogisticsCasePage} from 'common/api/cases';
  import {extend} from 'lodash';

  export default {
    data() {
      return {
        statusObj: {
          '0': this.$t('casesDetail.casesChildren.allLogistics.dsh'),
          '1': this.$t('casesDetail.casesChildren.allLogistics.ysh')
        },
        isLoading: false,
        logistics:{
          total: 0,
          list: [],
        },
        logisticsPic: {}
      }
    },
    computed:{
      queryParams(){
        let query = this.$route.query;
        return {
          pageNum: query.pageNum || 1,
          pageSize: query.pageSize || 10,
          caseId: query.caseId,
          refType: '2',
          pageType: 'logistics',
          generateFlag: '1',
        }
      }
    },
    methods: {
      toLogisticsDetail(id) {
        let route = this.$route;
        this.$router.push({
          name: 'ViewLogistics',
          query: extend({}, route.query,{logisticsId: id})
        });
      },
      changeRouteQuery(args){
        let route = this.$route;
        this.$router.replace({
          name: route.name,
          query: extend({}, route.query,{pageNum: 1}, args)
        });
        this.$nextTick(()=>{
          this.getList();
        });
      },
      getList(){
        this.isLoading = true;
        return PublicLogisticsCasePage(this.queryParams).then(data => {
          this.isLoading = false;
          this.logistics.list = data.list || [];
          this.logistics.total = data.total;
        }).catch(() => {
          this.isLoading = false;
        });
      }
    },
    created() {
      document.title = this.$t('casesDetail.casesChildren.allLogistics.title');
      const caseId = this.$route.query.caseId;
      if(caseId) {
        this.getList();
      }
    },
    components: {
      BackStep,
      FullLoading
    }
  }
</script>

<style scoped lang="scss">
  .all_logistics{
    width: 16rem;
    margin: 0.1rem auto 1rem;
    color: $main_theme_color_333;
    //padding-top: 53px;
    .single_li{
      margin-bottom: 0.2rem;
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 0.34rem 0.3rem 0.33rem;
      background-color: #fff;
      border-radius: 0.08rem;
      .single_l{
        display: flex;
        flex: 1;
        align-items: center;
        justify-content: space-between;
        .s_l_l{
          display: flex;
          .s_l_l_pic{
            width: 0.56rem;
            height: 0.56rem;
            margin-right: 0.11rem;
            background-size: 100% 100%;
          }
          .s_l_l_box{
            padding-top: 0.08rem;
            .s_l_l_h5{
              font-size: 0.2rem;
              color: $main_theme_color_333;
              margin-bottom: 0.11rem;
            }
            .s_l_l_p01{
              font-size: 0.14rem;
              color: $main_theme_color_333;
              margin-bottom: 0.22rem;
            }
          }
        }
        .s_l_r{
          display: flex;
          align-items: center;
          .s_l_l_r_p {
            font-size: 0.2rem;
            font-weight:bold;
          }
          .s_l_l_r_box{
            margin: 0 0.13rem;
            text-align: center;
            .s__l_r_p{
              color: $main_theme_color;
            }
            .s__l_r_line{
              height: 0.01rem;
              background-color: #E1E1E1;
              margin: 0.1rem 0;
            }
            .s__l_r_p01{
              color: #999999;
              font-size: 0.14rem;
            }
          }
        }
      }
      .single_r{
        width: 2rem;
        margin-left: 1.46rem;
      }
    }
  }
</style>
