<template>
  <div class="ceshi">
    <div style="display: flex">
      <div ref="listDom" class="item" :class="{ spreadSty: isSpread }">
        <div v-if="showBtn" class="btn" @click.stop="isSpread = !isSpread">
          {{ isSpread ? "收起" : "展开" }} <i v-if="!isSpread" class="el-icon-arrow-down" />
          <i v-if="isSpread" class="el-icon-arrow-up"/>
        </div>
        <div ref="textHeight" class="desc" v-html="text"></div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    text: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      showBtn: true, // 是否显示【展开/收起】按钮，按所需条件显示
      isSpread: false // 是否展开文字动态判断
    };
  },
  created() {
    this.$nextTick(() => {
      const { scrollHeight, clientHeight } = this.$refs.textHeight;
      this.showBtn = scrollHeight > clientHeight;
    });
  },
  beforeDestroy() {
    this.showBtn=false
  }
};
</script>

<style lang="scss" scoped>
.ceshi {
  .item {
    flex: 1;
    //text-align: left;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    text-overflow: ellipsis;
    overflow: hidden;
    .desc {
      word-break: break-all; /*英文换行*/
      line-height: 20px;
      font-size: 14px !important;
      color: #666666 !important;
    }
    /*重点1 使用伪元素进行浮动*/
    &::before {
      content: "";
      float: right;
      /*动态高度，使用负margin, 性能比calc 好一点*/
      height: 100%;
      margin-top: -20px;
      /*height: calc(100% - 20px);*/
    }

    .btn {
      position: relative;
      /*重点2 按钮设置浮动，并使用clear: both 将按钮移到文本右下角*/
      float: right;
      clear: both;
      font-size: 14px;
      height: 20px;
      //line-height: 20px;
      padding: 0 4px;
      cursor: pointer;
      color: #fcc80e;
      margin-left: 10px;
    }

    /*重点3  展开时的样式*/
    &.spreadSty {
      -webkit-line-clamp: 999; /*设置一个足够大的行数就可以了*/
    }
  }
}
</style>
