<template>
  <div class="stage-adjustment">
    <div class="header-title">
      <div class="done-case">{{ $t("stageAdjust.jdtz") }}</div>
      <div class="clinic-name">
        {{ $t("cases.maintain.zsmc") }}: {{ caseDetail.clinicName }}
      </div>
      <div class="product-name">
        {{ $t("cases.maintain.cpmc") }}: {{ caseDetail.productName }}
      </div>
    </div>
    <div class="content">
      <div class="option">
        <div class="tabs">
          <div
            v-for="(item, index) in tabs"
            :key="index"
            :class="['tabs-item', nowTabIndex === index && 'active-tabs-item']"
            @click="changeTabIndex(index)"
          >
            <img
              :src="`/static/images/adjustment/${item.icon}.png`"
              alt=""
              class="tabs-icon"
            />
            {{ item.name }}
          </div>
        </div>
        <div class="option-control">
          <div
            v-if="nowTabIndex !== 0"
            class="previous-page"
            @click="changeTabIndex(nowTabIndex - 1)"
          >
            {{ $t("cases.maintain.syy") }}
          </div>
          <div
            v-if="nowTabIndex !== 2"
            class="next-page"
            @click="changeTabIndex(nowTabIndex + 1)"
          >
            {{ $t("cases.maintain.xyy") }}
          </div>
          <div
            :class="[
              nowTabIndex !== 2
                ? 'staging'
                : nowTabIndex === 2 && canSubmit
                ? 'submit'
                : 'disabled-submit'
            ]"
            @click="modifyOrSave"
          >
            {{
              nowTabIndex !== 2
                ? $t("cases.maintain.zc")
                : $t("cases.maintain.tj")
            }}
          </div>
        </div>
      </div>
      <div v-if="!isLoading">
        <adjustment-table
          v-if="nowTabIndex === 0"
          ref="treatment"
          :caseDetail="caseDetail"
          :caseOptions="caseOptions"
          :cureProblemList="cureProblemList"
          :questionName="questionName"
        />
        <image-modal
          v-if="nowTabIndex === 1"
          ref="imageModal"
          :caseDetail="caseDetail"
          :caseOptions="caseOptions"
          :cureProblemList="cureProblemList"
          :questionName="questionName"
        />
        <Preview
          v-if="nowTabIndex === 2"
          ref="preview"
          :caseDetail="caseDetail"
          :diaKeyObj="diaKeyObj"
          :dictObj="caseOptions"
          @handleCanSubmit="handleCanSubmit"
          @jumpQuestion="jumpQuestion"
        />
      </div>
    </div>
    <!--    <el-dialog
          :visible.sync="dialogVisible"
          custom-class="dialog-submit"
          top="35vh"
          width="25%"
        >
          <div class="dialog-title">
            {{ $t("cases.casesCreate.sftj") }}
          </div>
          <div slot="footer" class="dialog-footer">
            <div class="cancel" @click="dialogVisible = false">
              {{ $t("cases.theDelivery.qx") }}
            </div>
            <div class="submit" @click="submitTable">
              {{ $t("cases.casesCreate.qdtj") }}
            </div>
          </div>
        </el-dialog>-->
    <confirm-modal
      :confirmObj="{
        tipText: $t('cases.casesCreate.sftj'),
        backText: $t('casesDetail.casesChildren.common.qx'),
        okText: $t('cases.casesCreate.qdtj')
      }"
      @confirmSubmit="submitTable()"
      @confirmHide="dialogVisible = false"
      v-show="dialogVisible"
    />
    <FullLoading v-show="confirmLoading" />
  </div>
</template>

<script>
import { getDictAllList } from "common/api/public";
import { casesDetail, cureProblemList } from "common/api/cases";
import AdjustmentTable from "./components/adjustment-table.vue";
import imageModal from "./components/image-modal.vue";
import Preview from "./components/preview.vue";
import { notifyMsg } from "common/js/util";
import {
  columnDataTempRecordSave,
  curePlanPhaseAdjustment
} from "common/api/cases";
import FullLoading from "components/full-loading/full-loading.vue";
import ConfirmModal from "components/confirm-modal/confirm.vue";

export default {
  data() {
    return {
      confirmLoading: false,
      tabs: [
        {
          name: "中期反馈",
          idx: 0,
          icon: "treatment"
        },
        {
          name: "影像资料及模型",
          idx: 1,
          icon: "imageModal"
        },
        {
          name: "预览及提交",
          idx: 2,
          icon: "preview"
        }
      ],
      dialogVisible: false,
      nowTabIndex: 0,
      caseDetail: {},
      diaKeyObj: {},
      caseOptions: {},
      isLoading: true,
      canSubmit: false,
      questionName: "",
      cureProblemList: []
    };
  },
  components: {
    ConfirmModal,
    FullLoading,
    AdjustmentTable,
    imageModal,
    Preview
  },
  methods: {
    jumpQuestion(fixIdx, val) {
      this.questionName = val;
      this.nowTabIndex = fixIdx;
    },
    goPage(url) {
      this.$router.replace({
        path: url,
        query: {
          caseId: this.$route.query.caseId,
          curePlanId: this.$route.query.curePlanId
        }
      });
    },
    handleCanSubmit(e) {
      this.canSubmit = e;
    },
    submitTable() {
      if (!this.canSubmit) return;
      const { curePlanId } = this.$route.query;
      this.confirmLoading = true;
      curePlanPhaseAdjustment({ id: curePlanId })
        .then(() => {
          this.confirmLoading = false;
          notifyMsg(this, "success", this.$t("cases.createPreview.tjcg"));
          this.goPage("/cases/detail");
        })
        .catch(() => {
          this.confirmLoading = false;
        });
    },
    modifyOrSave() {
      const currentRef = {
        0: this.$refs.treatment,
        1: this.$refs.imageModal
      }[this.nowTabIndex];
      this.questionName = "";
      if (this.nowTabIndex !== 2) {
        const data = currentRef.modifyData();
        this.modifyData(data);
      } else {
        this.dialogVisible = true;
      }
    },
    modifyData(data, idx) {
      const { caseId, curePlanId } = this.$route.query;
      columnDataTempRecordSave({
        columnData: {
          ...data
        },
        caseId,
        curePlanId,
        type: "phase_adjustment"
      }).then(res => {
        notifyMsg(this, "success", this.$t("common.message.yzc"), 2500);
        if (idx !== undefined) this.nowTabIndex = idx;
      });
    },
    changeTabIndex(idx) {
      const currentRef = {
        0: this.$refs.treatment,
        1: this.$refs.imageModal
      }[this.nowTabIndex];
      this.questionName = "";
      if (this.nowTabIndex !== 2) {
        const data = currentRef.modifyData();
        this.modifyData(data, idx);
      } else {
        this.nowTabIndex = idx;
      }
    },
    queryCaseDetail(id) {
      this.isLoading = true;
      casesDetail(id).then(res => {
        this.caseDetail = res;
        const { nextStatus } = res;
        if (nextStatus === "to_resubmit") {
          cureProblemList({
            caseId: id,
            curePlanId: this.$route.query.curePlanId
          }).then(problem => {
            this.cureProblemList = problem;
          });
        }
        this.isLoading = false;
      });
    },
    init() {
      getDictAllList([
        "fit_status",
        "accessory_adjustment",
        "dentition_space",
        "underjaw_abnormal_flag",
        "non_moveable_teeth",
        "not_available_for_attachments"
      ]).then(data => {
        let diaKeyObj = {},
          dictObj = {};
        data.forEach(item => {
          if (!dictObj[item.parentKey]) {
            dictObj[item.parentKey] = [item];
          } else {
            dictObj[item.parentKey].push(item);
          }
          diaKeyObj[item.key] = item.value;
        });
        this.diaKeyObj = diaKeyObj;
        this.caseOptions = dictObj;
      });
    }
  },
  mounted() {
    const { caseId } = this.$route.query;
    this.init();
    this.queryCaseDetail(caseId);
  }
};
</script>

<style lang="scss" scoped>
//.stage-adjustment::-webkit-scrollbar{
//  display: none;
//}
.stage-adjustment {
  //width: 16rem;
  height: 87.5vh;
  overflow-y: scroll;
  padding: 0 100px;
  position: relative;
  margin: auto;
  //scrollbar-width: none; /* Firefox */
  //-ms-overflow-style: none; /* IE 10+ */

  .header-title {
    font-weight: 700 !important;
    margin: 0px 0 20px 0;
    font-size: 16px;
    line-height: 28px;
    color: #333;
    display: flex;
    align-items: center;

    .clinic-name {
      max-width: 240px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }

    .done-case {
      font-size: 20px;
      line-break: 28px;
      //font-weight: 500;
    }

    > div {
      padding-right: 10px;
      margin-left: 10px;
      position: relative;

      &::after {
        position: absolute;
        right: 0;
        top: 50%;
        transform: translateY(-50%);
        content: "";
        width: 1px;
        height: 16px;
        background-color: #333;
        margin-left: 10px;
      }

      &:last-child {
        &::after {
          display: none;
        }
      }
    }
  }

  .content {
    min-height: 100%;
    background-color: #fff;
    border-radius: 6px;

    .option {
      height: 72px;
      padding: 0 30px;
      border-bottom: 4px solid #e5e5e5;
      display: flex;
      justify-content: space-between;
      align-items: center;
      position: sticky;
      top: 0;
      background-color: #fff;
      z-index: 99;

      .tabs {
        height: 100%;
        display: flex;
        align-items: center;

        .tabs-item {
          white-space: nowrap;
          cursor: pointer;
          height: 100%;
          margin-right: 80px;
          display: flex;
          align-items: center;
          font-size: 18px;
          line-height: 26px;
          color: #333;
          position: relative;

          .tabs-icon {
            width: 24px;
            height: 24px;
            margin-right: 4px;
          }

          &:last-child {
            margin-right: 0px;
          }
        }

        .active-tabs-item {
          &::after {
            position: absolute;
            content: "";
            width: 100%;
            height: 4px;
            background-color: #fcc80e;
            left: 0;
            bottom: -4px;
          }
        }
      }

      .option-control {
        display: flex;
        align-items: center;
        font-size: 14px;
        color: #333;

        > div {
          cursor: pointer;
          display: flex;
          justify-content: center;
          align-items: center;
          height: 36px;
          padding: 20px;
          min-width: 96px;
          border-radius: 4px;
        }

        .next-page,
        .previous-page,
        .submit {
          background-color: #fcc80e;
          margin-right: 10px;
        }

        .submit {
          margin-right: 0px;
        }

        .disabled-submit {
          cursor: not-allowed;
          background-color: #f1f1f1;
        }

        .staging {
          border: 1px solid #e5e5e5;
        }
      }
    }
  }

  .dialog-title {
    font-size: 18px;
    line-height: 26px;
    color: #333;
    text-align: center;
  }

  .dialog-footer {
    display: flex;
    justify-content: center;
    align-items: center;

    .cancel,
    .submit {
      width: 96px;
      height: 36px;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 14px;
      line-height: 20px;
      color: #333;
      border-radius: 4px;
      cursor: pointer;
    }

    .cancel {
      border: 1px solid #bbb;
      margin-right: 32px;
    }

    .submit {
      background: #fcc80e;
    }
  }

  /deep/ .el-dialog {
    border-radius: 8px;
  }
}
</style>
