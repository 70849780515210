<template>
  <div class="msg_page">
    <div class="msg_box">
      <div class="msg_con">
        <p>{{ $t("home.publicChildren.msgPage.xxnr") }}</p>
        <p>{{ $t("home.publicChildren.msgPage.tssj") }}</p>
        <p
          :class="
            language === 'en_US' ? 'msg_l_sp_three_en' : 'msg_l_sp_three_zh'
          "
        >
          {{ $t("home.publicChildren.msgPage.cz") }}
        </p>
      </div>
      <ul v-if="msgList.length" class="msg_ul">
        <li
          ref="msg_li"
          class="msg_li"
          v-for="(item, index) in msgList"
          :key="item.id"
        >
          <p :title="item.content" class="msg_content txt-ellipsis2">
            {{ item.content }}
          </p>
          <p>{{ item.createDatetime }}</p>
          <p @click="jumpPage(item, index)">
            <span
              :class="language === 'en_US' ? 'eng_btn' : 'main_small_btn'"
              class="main_theme_color_btn curp fz14"
            >
              <span
                v-if="item.status !== 'handled'"
                :data-note="item.node"
                :data-id="item.id"
                :data-caseId="item.caseId"
                :data-cureplanid="item.curePlanId"
                :data-content="item.contentDetail"
              >
                {{
                  noteObj[item.node]
                    ? noteObj[item.node].text
                    : $t("common.header.wzdl")
                }}
              </span>
              <span class="ycl" v-else>{{ $t("common.message.ycl") }}</span>
            </span>
          </p>
          <p class="msg_l_sp" @click.stop="showConfirm(item.id)">
            <i class="el-icon-close"/>
          </p>
        </li>
      </ul>
      <div class="msg_no" v-if="!msgList.length">
        <div class="msg_no_pic"></div>
        <p class="msg_no_p fz14 main_theme_color_666">
          {{ $t("home.home.nzshmyxxx") }}
        </p>
      </div>
    </div>

    <Confirm
      :confirmObj="confirmObj"
      @confirmHide="confirmHide"
      @confirmSubmit="confirmSubmit"
      v-show="isShowConfirm"
    />
    <Confirm
      :showPic="false"
      :confirmObj="whyObj"
      @confirmHide="whyHide"
      @confirmSubmit="whySubmit"
      v-show="isShowWhy"
    />
    <FullLoading v-show="isLoading" />
  </div>
</template>

<script>
import FullLoading from "components/full-loading/full-loading";
import Confirm from "components/confirm-modal/confirm";
import { getDictList } from "common/api/public";
import {
  caseChatHandle,
  removeCaseMessage,
  casesDetail
} from "common/api/cases";
import { defPhoto } from "common/js/requireImage";
import { mapMutations, mapState } from "vuex";
import $ from "jquery";
import { notifyMsg, splitText } from "common/js/util";

export default {
  data() {
    return {
      typeObj: {},
      defPhoto,
      isLoading: false,
      noteObj: {
        to_improve_plan: {
          text: this.$t("common.header.qtj"),
          href: "/cases/create"
        },
        to_data_approve: {
          text: this.$t("common.header.qtj"),
          href: "/cases/create"
        },
        to_submit: {
          text: this.$t("common.header.qtj"),
          href: "/cases/create"
        },
        to_text_plan: {
          text: this.$t("common.header.qpz"),
          href: "/cases/reviewTextPlan?schemeType=target"
        },
        to_sign: {
          text: this.$t("common.header.qpz"),
          href: "/cases/view3d?schemeType=cure"
        },
        to_doctor_confirm_plan: {
          text: this.$t("common.header.qpz"),
          href: "/cases/view3d?schemeType=cure"
        },
        reject: {
          text: this.$t("common.header.ckyy"),
          isModal: true
        },
        to_receive: {
          text: this.$t("casesDetail.casesDetail.jghx"),
          href: "/cases/detail?delivery=1"
        },
        to_approve_plan: {
          text: this.$t("common.header.qck"),
          href: "/cases/view3d",
          type: "cure"
        },
        to_check_order: {
          text: this.$t("common.header.qtj"),
          href: "/cases/create"
        },
        to_recheck_order: {
          text: this.$t("common.header.qtj"),
          href: "/cases/create"
        },
        to_scan_y: {
          text: this.$t("common.header.qtj"),
          href: "/cases/create"
        },
        to_rescan_y: {
          text: this.$t("common.header.qtj"),
          href: "/cases/create"
        },
        to_done: {
          text: this.$t("common.header.qtj"),
          href: "/cases/maintain"
        }
      },
      confirmObj: {
        tipText: this.$t("home.publicChildren.msgPage.qrsc"),
        backText: this.$t("common.message.qx"),
        okText: this.$t("common.message.qd")
      },
      removeId: "",
      isShowConfirm: false,
      isShowWhy: false,
      whyObj: {
        tipText: [],
        backText: this.$t("common.message.qx"),
        okText: this.$t("common.header.wzdl")
      },
      listIndex: ""
    };
  },
  props: {
    msgList: {
      type: Array,
      default: () => []
    }
  },
  computed: {
    ...mapState({
      language: state => state.language
    })
  },
  created() {
    sessionStorage.removeItem("casesId");
    this.isLoading = true;
    getDictList("caseMessage.type")
      .then(data => {
        this.isLoading = false;
        const typeObj = {};
        data.forEach(item => {
          typeObj[item.key] = item.value;
        });
        this.typeObj = typeObj;
      })
      .catch(() => {
        this.isLoading = false;
      });
  },
  methods: {
    jumpPage(item, index) {
      if (item.status === "handled") return;
      const caseId = item.caseId;
      const curePlanId = item.curePlanId;
      const id = item.id;
      let query = `caseId=${caseId}&curePlanId=${curePlanId}`;
      if (!item.node) {
        if (item.type === "deliver_remind") {
          const href = "/cases/detail?delivery=1&";
          this.$router.replace({ path: href + query });
        }
        caseChatHandle(id).then(() => {
          const msgLi = $(".msg_li");
          msgLi[index].animate(
            {
              marginLeft: "-300px",
              opacity: "0"
            },
            500
          );
          const pathname = window.location.pathname;
          if (pathname === "/home/moreHomePage") {
            setTimeout(() => {
              msgLi[index].animate(
                {
                  marginLeft: "0",
                  opacity: "1"
                },
                500
              );
            }, 16);
          }
          this.isLoading = false;
          this.isShowWhy = false;
          this.HANDLE_MSG();
        });
      } else {
        localStorage.removeItem("casesDetail");
        sessionStorage.removeItem("casesDetail");
        if (["reissue"].includes(item.node)) {
          caseChatHandle(id).then(() => {
            const msgLi = $(".msg_li");
            msgLi[index].animate(
              {
                marginLeft: "-300px",
                opacity: "0"
              },
              500
            );
            const pathname = window.location.pathname;
            if (pathname === "/home/moreHomePage") {
              setTimeout(() => {
                msgLi[index].animate(
                  {
                    marginLeft: "0",
                    opacity: "1"
                  },
                  500
                );
              }, 16);
            }
            this.isLoading = false;
            this.isShowWhy = false;
            this.HANDLE_MSG();
            setTimeout(() => {
              window.open("/cases/bracesReissue?tabIdx=1&" + query);
            }, 1000);
          });
        }
        if (
          ["to_sign", "to_doctor_confirm_plan", "to_approve_plan"].includes(
            item.node
          )
        ) {
          casesDetail(caseId).then(res => {
            const { cureType } = { ...res };
            if (cureType === "F") {
              window.open(
                `/exocad?schemeType=cure&cureNoteId=${item.refCode}&` + query
              );
            } else {
              window.open(
                `/cases/view3d?schemeType=cure&cureNoteId=${item.refCode}&` +
                  query
              );
            }
          });
        }
        if (item.node === "to_text_plan") {
          window.open(
            `/cases/view3d?schemeType=target&cureNoteId=${item.refCode}&` +
              query
          );
        }
        if (item.node === "to_receive") {
          window.open("/cases/detail?delivery=1&" + query);
        }
        if (
          [
            "to_submit",
            "to_data_approve",
            "precuring",
            "to_scan",
            "to_agent_scan",
            "to_check_order",
            "to_recheck_order",
            "to_scan_y",
            "to_rescan_y",
            "to_improve_plan"
          ].includes(item.node)
        ) {
          casesDetail(caseId).then(res => {
            const curePlanInfo = res["curePlanInfo"];
            if (curePlanInfo.phaseAdjustmentNumber !== 0) {
              this.$router.replace({ path: "/stage-adjustment?" + query });
            } else {
              const href = "/cases/create?&";
              window.open(
                href + `caseId=${caseId}&curePlanId=${curePlanId}&submitTabKey=0`,
                "_blank"
              );
            }
          });
        }
        if (item.node === "to_draw_line" && item.status === "to_handle") {
          window.open(
            "/cases/maintain?" + `caseId=${caseId}&curePlanId=${curePlanId}`,
            "_blank"
          );
        }
        if (item.node === "reject") {
          this.whyObj.tipText = splitText(item.contentDetail);
          this.removeId = id;
          this.isShowWhy = true;
          this.listIndex = index;
        }
        // if (item.node === "to_draw_line") {
        //   window.open("/cases/paymentScheme?payType=1&" + query);
        // }
      }
    },
    chatHandle(id, target = null) {
      caseChatHandle(id).then(() => {
        const msgLi = $(".msg_li");
        msgLi[this.listIndex].animate(
          {
            marginLeft: "-300px",
            opacity: "0"
          },
          500
        );
        if (target) {
          const pathname = window.location.pathname;
          if (pathname === "/home/moreHomePage") {
            setTimeout(() => {
              msgLi.animate(
                {
                  marginLeft: "0",
                  opacity: "1"
                },
                500
              );
            }, 16);
          }
        } else {
          setTimeout(() => {
            msgLi.animate(
              {
                marginLeft: "0",
                opacity: "1"
              },
              500
            );
          }, 16);
        }
        this.isShowWhy = false;
      });
    },
    showConfirm(id) {
      this.removeId = id;
      this.isShowConfirm = true;
    },
    confirmHide() {
      this.isShowConfirm = false;
    },
    confirmSubmit() {
      if (this.removeId) {
        this.isLoading = true;
        removeCaseMessage(this.removeId)
          .then(() => {
            this.isLoading = false;
            notifyMsg(this, "success", this.$t("common.message.czcg"));
            this.isShowConfirm = false;
            this.HANDLE_MSG();
          })
          .catch(() => {
            this.isLoading = false;
          });
      }
    },
    whyHide() {
      this.isShowWhy = false;
    },
    whySubmit() {
      this.chatHandle(this.removeId);
    },
    ...mapMutations(["HANDLE_MSG"])
  },
  components: {
    FullLoading,
    Confirm
  }
};
</script>

<style scoped lang="scss">
.msg_page {
  .msg_box {
    background-color: #fff;

    .msg_con {
      font-size: 0.16rem;
      height: 0.54rem;
      display: flex;
      align-items: center;
      color: $main_theme_color_333;
      border-bottom: 0.01rem solid #e5e5e5;
      //border-radius: 6px;

      p {
        line-height: 0.25rem;
      }

      & p:nth-child(1) {
        flex: 1;
        padding-left: 60px;
      }

      & p:nth-child(2) {
        width: 300px;
        margin-left: 100px;
      }

      & p:nth-child(3) {
        width: 200px;
        //display: flex;
        //justify-content: center;
      }

      .msg_l_sp_three_en {
        //margin-left: 3.9rem;
      }

      .msg_l_sp_three_zh {
        //margin-left: 4.2rem;
      }
    }

    .msg_li {
      display: flex;
      align-items: center;
      height: 0.6rem;
      //border-radius: 0.1rem;
      position: relative;
      justify-content: space-evenly;

      p {
        line-height: 0.25rem;
        font-size: 14px;
        color: $main_theme_color_333;
      }

      & p:nth-child(1) {
        //width: 424px;
        flex: 1;
        padding-left: 60px;
        //margin-left: 60px;
      }

      & p:nth-child(2) {
        width: 300px;
        //display: flex;
        //justify-content: center;
        //white-space: nowrap;
        //overflow: hidden;
        //text-overflow: ellipsis;
        margin-left: 100px;
      }

      & p:nth-child(3) {
        /*width: 96px;*/
        width: 200px;
        display: flex;
        //justify-content: center;
        //margin-left: 455px;
      }

      //p:hover {
      //  background: #f9f9f9;
      //}

      .msg_l_sp {
        position: absolute;
        right: 0.15rem;
        top: 0.22rem;
        width: 0.12rem;
        height: 0.12rem;
        //background-image: url("/static/images/xiaoxi_delet.png");
        //background-size: 100% 100%;
        cursor: pointer;
        line-height: 0.12rem;
        font-size: 18px;
      }
      .msg_l_sp:hover{
        color: $main_theme_color

      }
    }

    .msg_li:hover {
      transition: 0.3s all;
      background-color: #f9f9f9;
    }
  }

  .msg_no {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0.65rem 0 0.75rem;
    color: #666666;
    flex-direction: column;

    .msg_no_pic {
      margin-bottom: 0.27rem;
      width: 2.04rem;
      height: 1.3rem;
      background-size: 100% 100%;
      background-image: url("../../../common/imgs/home/empty_message.png");
    }
  }
}

.eng_btn {
  width: 1.7rem;
  height: 0.42rem;
  padding: 0 0.08rem;
  position: relative;
  right: 0.45rem;
}
</style>
