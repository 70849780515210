<template>
  <div class="adjustment-table">
    <div class="table-left">
      <div
        :class="
          activeLeftOption === item.index
            ? 'left-options-active'
            : 'left-options'
        "
        @click="changeOptions(item.index)"
        v-for="(item, index) in leftOptions"
        :key="index"
      >
        {{ item.name }}
      </div>
    </div>
    <div class="table-right">
      <div v-if="filterCureProblemList.length" class="problem-content">
        <div class="problem-title">
          <img src="/static/images/createCases/web_preview_tips.png" alt="" />
          {{ $t("cases.createImage.czrxwt") }}
        </div>
        <div class="problem-show">
          <div
            class="problem-item"
            v-for="item in filterCureProblemList"
            :key="item.groupNo"
            @click="scrollToCurrent(item.groupNo)"
          >
            {{ `${item.groupNo}.${item.groupName}` }}
          </div>
        </div>
      </div>
      <div id="question-1" class="case-question">
        <div class="case-title required-title">
          1.{{ $t("stageAdjust.dqjzqthqk") }}
          <span>{{ $t("stageAdjust.pdyj") }}</span>
        </div>
        <p
          class="problem_note"
          v-if="!!filterCureProblemList.find(it => it.groupNo === 1)"
        >
          {{ filterCureProblemList.find(it => it.groupNo === 1).content }}
        </p>
        <div class="c-q-content">
          <CommonButton
            :renderList="caseOptions['fit_status']"
            parentKey="fitStatus"
            :echoContent="resultObj['fitStatus']"
            @buttonChange="buttonHandlerChange"
          />
        </div>
      </div>
      <div id="question-2" class="case-question">
        <div class="case-title required-title">
          2.{{ $t("stageAdjust.lcjc") }}
        </div>
        <p
          class="problem_note"
          v-if="!!filterCureProblemList.find(it => it.groupNo === 2)"
        >
          {{ filterCureProblemList.find(it => it.groupNo === 2).content }}
        </p>
        <div class="c-q-content">
          <div id="question-2" class="sub-title required-title">
            2.1{{ $t("stageAdjust.bcfkspdbs") }}
          </div>
          <div class="sub-content">
            <div class="jaw-step">
              {{ $t("stageAdjust.shd")
              }}<input
                type="text"
                v-model="maxillaryStep"
                @input="
                  disposeNumber(
                    lastStageInfo['maxillaryTotalStep'],
                    'maxillaryStep'
                  )
                "
              />{{ $t("stageAdjust.bs")
              }}<span>{{
                $t("stageAdjust.sjdsjdbs", {
                  totalStep: lastStageInfo["maxillaryTotalStep"],
                  stepDate: lastStageInfo["deliverDate"],
                  stepSection: ` ${lastStageInfo["maxillaryStartStep"]}-${lastStageInfo["maxillaryEndStep"]}`
                })
              }}</span>
            </div>
            <div class="jaw-step">
              {{ $t("stageAdjust.xhd")
              }}<input
                type="text"
                v-model="mandibularStep"
                @input="
                  disposeNumber(
                    lastStageInfo['mandibularTotalStep'],
                    'mandibularStep'
                  )
                "
              />{{ $t("stageAdjust.bs")
              }}<span>
                {{
                  $t("stageAdjust.sjdsjdbs", {
                    totalStep: lastStageInfo["mandibularTotalStep"],
                    stepDate: lastStageInfo["deliverDate"],
                    stepSection: ` ${lastStageInfo["mandibularStartStep"]}-${lastStageInfo["mandibularEndStep"]}`
                  })
                }}</span
              >
            </div>
          </div>
          <div class="sub-title">2.2{{ $t("stageAdjust.fjtz") }}</div>
          <CommonButton
            :renderList="caseOptions['accessory_adjustment']"
            parentKey="accessoryAdjustment"
            :echoContent="resultObj['accessoryAdjustment']"
            @buttonChange="buttonHandlerChange"
            alignType="column"
          >
            <template slot="tips-1">
              <span style="margin-left: 10px; color: #666666;">{{
                $t("stageAdjust.fjknhtz")
              }}</span>
            </template>
            <template slot="tips-2">
              <span style="margin-left: 10px; color: #666666;">{{
                $t("stageAdjust.gjsjfakntz")
              }}</span>
            </template>
            <template slot="tips-3">
              <span style="margin-left: 10px; color: #666666;">{{
                $t("stageAdjust.gjsjfakntj")
              }}</span>
            </template>
            <template
              slot="content-2"
              v-if="
                resultObj['accessoryAdjustment'] ===
                  'reserved_specified_attachment'
              "
            >
              <ChildrenTeeth
                v-if="
                  resultObj['accessoryAdjustment'] ===
                    'reserved_specified_attachment'
                "
                ref="teethComponents"
                :commonTeeth="commonTeeth"
                :patientType="patientType[caseDetail['cureType']]"
                @updateTeeth="updateTeeth"
                class="mb40 mt40"
                :teethType="6"
              />
            </template>
          </CommonButton>
          <div id="question-3" class="sub-title required-title">
            2.3 {{ $t("stageAdjust.yljx") }}
          </div>
          <CommonButton
            :renderList="caseOptions['dentition_space']"
            parentKey="dentitionSpace"
            :echoContent="resultObj['dentitionSpace']"
            @buttonChange="buttonHandlerChange"
          />
          <div
            class="have-space"
            v-show="resultObj['dentitionSpace'] === 'have'"
          >
            <div class="space-tips">{{ $t("stageAdjust.qjlfk") }}</div>
            <dentition-space
              ref="intervalCorrect"
              :cureType="caseDetail['cureType']"
              :commonTeeth="commonTeeth"
              @updateInterval="updateInterval"
              :uploadIntervalList="uploadIntervalList"
            />
          </div>
          <div class="sub-title" v-if="!['C'].includes(caseDetail['cureType'])">
            2.4 {{ $t("stageAdjust.nxhgjsfyc") }}
          </div>
          <CommonButton
            v-if="!['C'].includes(caseDetail['cureType'])"
            :renderList="caseOptions['underjaw_abnormal_flag']"
            parentKey="tmjIssues"
            :echoContent="resultObj['tmjIssues']"
            @buttonChange="buttonHandlerChange"
          />
          <el-input
            v-if="resultObj['tmjIssues'] === 'true'"
            :autosize="{ minRows: 4, maxRows: 6 }"
            maxlength="2000"
            class="other-textarea"
            type="textarea"
            show-word-limit
            v-model="tmjIssuesNote"
            :placeholder="$t('cases.maintain.qyqkxzz')"
          ></el-input>
          <div class="sub-title">
            {{ !["C"].includes(caseDetail["cureType"]) ? "2.5" : "2.4" }}
            {{ $t("stageAdjust.qtjc") }}
          </div>
          <el-input
            :autosize="{ minRows: 4, maxRows: 6 }"
            maxlength="2000"
            class="other-textarea"
            type="textarea"
            show-word-limit
            v-model="otherTests"
            :placeholder="$t('cases.maintain.qyqkxzz')"
          ></el-input>
        </div>
      </div>
      <div id="question-3" class="case-question">
        <div class="case-title">
          3.{{ $t("stageAdjust.qygjddsjyq") }}
          <span>{{ $t("stageAdjust.rybbgqysjstz") }}</span>
        </div>
        <p
          class="problem_note"
          v-if="!!filterCureProblemList.find(it => it.groupNo === 3)"
        >
          {{ filterCureProblemList.find(it => it.groupNo === 3).content }}
        </p>
        <div class="c-q-content">
          <div class="sub-title">
            3.1 {{ $t("stageAdjust.bkydyc")
            }}<span>{{ $t("stageAdjust.kcyxftt") }}</span>
          </div>
          <CommonButton
            :renderList="caseOptions['non_moveable_teeth']"
            parentKey="nonMoveableTeeth"
            :echoContent="resultObj['nonMoveableTeeth']"
            @buttonChange="buttonHandlerChange"
          />
          <ChildrenTeeth
            v-if="resultObj['nonMoveableTeeth'] === 'true'"
            :commonTeeth="commonTeeth"
            ref="teethComponents"
            :patientType="patientType[caseDetail['cureType']]"
            @updateTeeth="updateTeeth"
            class="mb40 mt40"
            :teethType="2"
          />
          <div class="sub-title">
            3.2 {{ $t("stageAdjust.bksjfjyc")
            }}<span>{{ $t("stageAdjust.kcyxfqtwfzt") }}</span>
          </div>
          <CommonButton
            :renderList="caseOptions['not_available_for_attachments']"
            parentKey="notAvailableAttachments"
            :echoContent="resultObj['notAvailableAttachments']"
            @buttonChange="buttonHandlerChange"
          />
          <ChildrenTeeth
            v-if="resultObj['notAvailableAttachments'] === 'true'"
            :commonTeeth="commonTeeth"
            ref="teethComponents"
            :patientType="patientType[caseDetail['cureType']]"
            @updateTeeth="updateTeeth"
            class="mb40 mt40"
            :teethType="3"
          />
        </div>
      </div>
      <div id="question-4" class="case-question">
        <div class="case-title required-title">
          4.{{ $t("stageAdjust.hxjzyq") }}
          <span>{{ $t("stageAdjust.bygbcsjzmb") }}</span>
        </div>
        <p
          class="problem_note"
          v-if="!!filterCureProblemList.find(it => it.groupNo === 4)"
        >
          {{ filterCureProblemList.find(it => it.groupNo === 4).content }}
        </p>
        <div class="c-q-content">
          <el-input
            :autosize="{ minRows: 4, maxRows: 6 }"
            maxlength="2000"
            class="other-textarea"
            type="textarea"
            show-word-limit
            v-model="subsequentOrthodonticRequirements"
            :placeholder="$t('cases.maintain.qyqkxzz')"
          ></el-input>
        </div>
      </div>
      <full-loading v-show="isLoading" />
    </div>
  </div>
</template>

<script>
import {
  extend,
  each,
  values,
  get,
  map,
  filter,
  keys,
  find,
  pickBy,
  isNumber
} from "lodash";
import FullLoading from "components/full-loading/full-loading";
import IntervalCorrect from "@/components/treatment-table/interval-correct/index";
import ChildrenTeeth from "@/components/treatment-table/children-teeth/index";
import CommonButton from "../../casesChildren/components/common-button.vue";
import dentitionSpace from "./dentition-space.vue";
import { toNumber } from "@/common/js/Fn";
import { adultTeethList } from "@/components/treatment-table/common";
import { columnDataTempRecordDetail } from "common/api/cases";

export default {
  props: ["caseOptions", "caseDetail", "questionName", "cureProblemList"],
  components: {
    CommonButton,
    ChildrenTeeth,
    dentitionSpace,
    IntervalCorrect,
    FullLoading
  },
  data() {
    return {
      resultObj: {},
      recordDetail: {},
      maxillaryStep: "",
      mandibularStep: "",
      otherTests: "",
      subsequentOrthodonticRequirements: "",
      tmjIssuesNote: "",
      commonTeeth: [],
      patientType: {
        A: "adult",
        B: "youth",
        C: "children",
        E: "adult",
        I: "gap"
      },
      leftOptions: [
        {
          index: 1,
          name: this.$t("stageAdjust.dqjzqthqk")
        },
        {
          index: 2,
          name: this.$t("stageAdjust.lcjc")
        },
        {
          index: 3,
          name: this.$t("stageAdjust.qygjddsjyq")
        },
        {
          index: 4,
          name: this.$t("stageAdjust.hxjzyq")
        }
      ],
      activeLeftOption: 1,
      uploadIntervalList: [],
      isLoading: false
    };
  },
  computed: {
    lastStageInfo() {
      return this.recordDetail["lastStageInfo"] || {};
    },
    filterCureProblemList() {
      return this.cureProblemList.filter(item => item.groupType === "1");
    }
  },
  beforeDestroy() {
    document
      .querySelector(".stage-adjustment")
      .removeEventListener("scroll", this.tableScrollListen);
  },
  mounted() {
    document
      .querySelector(".stage-adjustment")
      .addEventListener("scroll", this.tableScrollListen);
    this.getRecordDetail();
  },
  methods: {
    values,
    get,
    map,
    find,
    keys,
    filter,
    tableScrollListen() {
      const scrollTop = document.querySelector(".stage-adjustment").scrollTop;
      if (scrollTop === 0) {
        this.activeLeftOption = 1;
        return;
      }
      if (
        scrollTop >= document.querySelector(".stage-adjustment").clientHeight
      ) {
        this.activeLeftOption = 4;
        return;
      }
      this.leftOptions.map(item => {
        if (
          scrollTop >=
          document.querySelector(`#question-${item.index}`).offsetTop
        ) {
          this.activeLeftOption = item.index;
        }
      });
    },
    changeOptions(index) {
      this.activeLeftOption = index;
      this.scrollToCurrent(index);
    },
    disposeNumber(num, key, value) {
      if (this[key] === "") {
        return (this[key] = "");
      }
      this[key] =
        isNumber(toNumber(this[key])) && toNumber(this[key]) <= num
          ? toNumber(this[key])
          : "";
    },
    scrollToCurrent(current) {
      const parent = document.querySelector(".stage-adjustment");
      const child = document.querySelector(`#question-${current}`);

      if (current >= 3) {
        window.scrollTo({
          top: 10000000,
          behavior: "smooth"
        });
      } else {
        console.log(111)
        window.scrollTo({
          top: child.offsetTop + 90,
          behavior: "smooth"
        });
      }
    },
    getRecordDetail() {
      this.isLoading = true;
      const { caseId, curePlanId } = this.$route.query;
      columnDataTempRecordDetail({
        caseId,
        curePlanId,
        type: "phase_adjustment"
      }).then(res => {
        this.recordDetail = res;
        this.initParams(res);
        this.isLoading = false;
        if (this.questionName !== "") {
          let questionFix;
          switch (this.questionName) {
            case this.$t("stageAdjust.dqjzqthqk"):
              questionFix = 1;
              break;
            case this.$t("stageAdjust.bcfkspdbs"):
              questionFix = 2;
              break;
            case this.$t("stageAdjust.yljx"):
              questionFix = 3;
              break;
            case this.$t("stageAdjust.hxjzyq"):
              questionFix = 4;
              break;
          }
          setTimeout(() => {
            this.scrollToCurrent(questionFix);
          }, 200);
        } else {
          const parent = document.querySelector(".stage-adjustment");
          parent.scrollTo({
            top: 0
          });
        }
      });
    },
    initParams(data) {
      const { cureType } = this.caseDetail;
      const { columnData = {} } = data;
      const { toothInfoList = [] } = columnData;
      const {
        mandibularStep = "",
        maxillaryStep = "",
        otherTests = "",
        subsequentOrthodonticRequirements = "",
        tmjIssuesNote = ""
      } = columnData;
      this.resultObj = columnData;
      this.mandibularStep = mandibularStep;
      this.maxillaryStep = maxillaryStep;
      this.otherTests = otherTests;
      this.subsequentOrthodonticRequirements = subsequentOrthodonticRequirements;
      this.tmjIssuesNote = tmjIssuesNote;
      this.uploadIntervalList = filter(
        toothInfoList,
        item => item.type === "15"
      );
      let nearList = filter(toothInfoList, item => item.type === "6");
      let noMoveList = filter(toothInfoList, item => item.type === "2");
      let noDesignList = filter(toothInfoList, item => item.type === "3");
      let deficiencyList = map(
        filter(toothInfoList, item => item.type === "1"),
        it => extend({}, it, { deficiency: "deficiency" })
      );
      let removeList = filter(toothInfoList, item => item.type === "4");
      let mainList = ["A", "E", "I"].includes(cureType)
        ? filter(toothInfoList, item => item.type === "7").concat(
            adultTeethList
          )
        : filter(toothInfoList, item => item.type === "8");
      let untreatedList = [
        ...nearList,
        ...noMoveList,
        ...noDesignList,
        ...removeList,
        ...deficiencyList
      ];
      if (untreatedList.length || ["A", "E", "I"].includes(cureType)) {
        let teethTypeObj = {
          2: "noMove",
          3: "noDesign",
          4: "remove",
          1: "deficiency",
          6: "near"
        };
        this.commonTeeth = map(mainList, item => {
          let obj;
          each(untreatedList, it => {
            if (item.number === it.number && !item.nextNumber) {
              obj = extend({}, item, obj, {
                [teethTypeObj[it.type]]: teethTypeObj[it.type]
              });
            }
          });
          return obj ? obj : item;
        });
      } else {
        this.commonTeeth =
          filter(toothInfoList, item => !item.nextNumber) || [];
      }
    },
    modifyData() {
      const { cureType } = this.caseDetail;
      let intervalList =
        filter(this.uploadIntervalList, item => item.nextNumber) || [];
      let list = [];
      each(this.commonTeeth, item => {
        if (item.noMove) {
          list.push(extend({}, item, { type: 2 }));
        }
        if (item.noDesign) {
          list.push(extend({}, item, { type: 3 }));
        }
        if (item.remove) {
          list.push(extend({}, item, { type: 4 }));
        }
        if (item.deficiency) {
          list.push(extend({}, item, { type: 1 }));
        }
        if (item.near) {
          list.push(extend({}, item, { type: 6 }));
        }
        if (["A", "E", "I"].includes(cureType)) {
          if (item.number > 48) {
            list.push(extend({}, item, { type: 7 }));
          }
        } else {
          list.push(extend({}, item, { type: 8 }));
        }
      });
      let toothInfoList = filter(
        [...list, ...intervalList],
        item => item.number > 0
      );
      toothInfoList = toothInfoList.filter(item => item.number !== "0");
      const { columnData = {} } = this.recordDetail;
      let imgObj = pickBy({
        sideProfile: columnData.sideProfile, // 侧面像
        frontal: columnData.frontal, // 正面像
        frontalSmile: columnData.frontalSmile, // 微笑像
        upperArch: columnData.upperArch, // 上牙列像
        anteriorOverjet: columnData.anteriorOverjet, // 下牙列像
        lowerArch: columnData.lowerArch, // 口内右侧位像
        intraOralRight: columnData.intraOralRight, // 口内正位像
        intraOralFrontal: columnData.intraOralFrontal, // 口内左侧位像
        intraOralLeft: columnData.intraOralLeft, // 全颌曲面断层片
        panoramicRadiograph: columnData.panoramicRadiograph, // 头颅侧位定位片
        lateralCephalogram: columnData.lateralCephalogram, //前牙覆盖像
        otherPicture: columnData.otherPicture, // 其他影像资料
        notFitPicture: columnData.notFitPicture
      });
      return {
        ...this.resultObj,
        toothInfoList,
        maxillaryStep: this.maxillaryStep,
        mandibularStep: this.mandibularStep,
        otherTests: this.otherTests,
        subsequentOrthodonticRequirements: this
          .subsequentOrthodonticRequirements,
        tmjIssuesNote: this.tmjIssuesNote,
        ...imgObj
      };
    },
    updateTeeth(data) {
      this.commonTeeth = data;
    },
    updateInterval(list) {
      this.uploadIntervalList = list;
    },
    buttonHandlerChange(val, parentKey, key) {
      this.$forceUpdate();
      this.resultObj = Object.assign(this.resultObj, val);
      let inputClearKey = {
        tmjIssues: "tmjIssuesNote"
      };
      let inputKeyList = keys(inputClearKey);
      if (
        inputKeyList.includes(parentKey) &&
        ["other", "false"].includes(key)
      ) {
        this[inputClearKey[parentKey]] = "";
      }
      let clearTeethObj = {
        dentitionSpace:
          this.$refs["intervalCorrect"] &&
          this.$refs["intervalCorrect"].clearTeeth,
        notAvailableAttachments:
          this.$refs["teethComponents"] &&
          this.$refs["teethComponents"].clearTeethType,
        nonMoveableTeeth:
          this.$refs["teethComponents"] &&
          this.$refs["teethComponents"].clearTeethType,
        accessoryAdjustment:
          this.$refs["teethComponents"] &&
          this.$refs["teethComponents"].clearTeethType
      };
      let componentsKeyList = keys(clearTeethObj);
      if (
        componentsKeyList.includes(parentKey) &&
        [
          "retain_all_attachments",
          "design_decision",
          "false",
          "not_have"
        ].includes(key)
      ) {
        let type = {
          accessoryAdjustment: 6,
          notAvailableAttachments: 3,
          nonMoveableTeeth: 2
        }[parentKey];
        clearTeethObj[parentKey] && clearTeethObj[parentKey](type ? type : "");
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.adjustment-table {
  padding-right: 64px;
  display: flex;
  position: relative;

  .table-left {
    margin-top: 10px;
    position: sticky;
    margin-right: 60px;
    width: 240px;
    height: 500px;
    border-right: 1px solid #e5e5e5;
    top: 80px;

    .left-options,
    .left-options-active {
      height: 54px;
      padding-left: 30px;
      font-size: 16px;
      display: flex;
      align-items: center;
      cursor: pointer;
    }

    .left-options-active {
      color: #fcc80e;
      position: relative;

      &::before {
        content: "";
        width: 4px;
        height: 32px;
        background-color: #fcc80e;
        position: absolute;
        left: 0;
        top: 50%;
        transform: translateY(-50%);
      }
    }
  }

  .table-right {
    flex: 1;
  }

  .problem-content {
    margin-top: 30px;
    padding: 13px 16px;
    background-color: #f4f4f4;
    border-radius: 4px;

    .problem-title {
      display: flex;
      align-items: center;
      font-size: 22px;
      line-height: 30px;
      margin-bottom: 10px;

      img {
        width: 24px;
        height: 24px;
        margin-right: 10px;
      }
    }

    .problem-show {
      display: flex;
      flex-wrap: wrap;
      font-size: 16px;
      line-height: 22px;

      .problem-item {
        cursor: pointer;
        margin-right: 20px;
        transition: linear 0.2s;

        &:hover {
          color: #fcc80e;
        }
      }
    }
  }

  .case-question {
    .case-title {
      display: flex;
      align-items: flex-end;
      font-size: 18px;
      font-weight: bold;
      line-height: 26px;
      color: #333;
      margin: 20px 0 10px;

      .link {
        display: flex;
        align-items: center;
        color: #fcc80e;
        margin-left: 10px;
        font-size: 14px;
        cursor: pointer;
      }

      span {
        font-size: 12px;
        line-height: 16px;
        margin-left: 20px;
        padding-bottom: 3px;
        color: #666666;
      }
    }

    .required-title {
      padding-left: 10px;
      display: flex;
      align-items: flex-end;
      position: relative;

      &::before {
        position: absolute;
        content: "";
        left: 0;
        top: 50%;
        transform: translateY(-50%);
        width: 6px;
        height: 6px;
        border-radius: 50%;
        background-color: #ed4027;
        margin-right: 4px;
      }
    }

    .have-space {
      .space-tips {
        font-size: 12px;
        color: #666666;
        margin-bottom: 20px;
      }
    }

    .c-q-content {
      padding-left: 30px;

      .other-textarea {
        margin-bottom: 20px;
      }

      .sub-title {
        font-size: 16px;
        line-height: 20px;
        margin-bottom: 10px;
      }

      .sub-content {
        .jaw-step {
          font-size: 14px;
          line-height: 20px;
          display: flex;
          align-items: center;
          margin-bottom: 20px;

          input {
            box-sizing: border-box;
            margin: 0 4px;
            width: 64px;
            height: 32px;
            border: 1px solid #bbbbbb;
            border-radius: 4px;
            padding: 6px 10px;
          }

          span {
            font-size: 12px;
            line-height: 16px;
            color: #666;
          }
        }
      }
    }
  }
}

.problem_note {
  word-break: break-all;
  color: $main_theme_color;
  line-height: 0.22rem;
  font-size: 0.14rem;
}
</style>
