<template>
  <div class="table_wrap">
    <div :style="{ height: rightHeight }" class="left_option">
      <div style="height: 6.4rem">
        <el-scrollbar ref="scrollbar" style="height: 100%; overflow-x: hidden">
          <template v-for="(item, index) in leftList">
            <div
              class="left_option_item"
              :class="{ left_active: selectLeftKey === `d${index + 1}` }"
              @click="selectLeft(index, 'left')"
              :key="index"
            >
              <div
                v-show="selectLeftKey === `d${index + 1}`"
                class="left_wire"
              ></div>
              {{ item.title }}
            </div>
          </template>
        </el-scrollbar>
      </div>
    </div>
    <div ref="rightTable" class="right_table">
      <div class="dia_problem" v-if="problemList && problemList.length > 0">
        <div class="flex-y-c mb10">
          <img
            class="mr10"
            style="width: 24px"
            src="../../../common/imgs/case/dia_warn_cion.png"
            alt=""
          />
          <h5>{{ $t("cases.createImage.czrxwt") }}</h5>
        </div>
        <div class="pro_box curp">
          <span
            @click="selectLeft(item.groupNo - 1, 'left')"
            v-for="(item, index) in problemList"
            :key="`${item.id}_${index}`"
          >
            {{ item.groupNo }} {{ item.groupName }}
          </span>
        </div>
      </div>
      <div class="select_type" id="1">
        <div class="flex-y-c">
          <p>
            <span class="main_theme_color_red fz14 mr4">●</span>
          </p>
          <p class="select_type_title mr10">
            1.{{ $t("cases.createDiagnosis.txt1") }}
          </p>
        </div>
        <p class="problem_note" v-if="diagnosisProblem['1']">
          {{ diagnosisProblem["1"] }}
        </p>

        <div class="flex-y-c">
          <div
            :style="language === 'en_US' ? 'width: 1.1rem' : ''"
            class="fz14 option_left_title"
          >
            {{ $t("cases.createDiagnosis.ycwt") }}
          </div>
          <div class="flex-y-c-w mt10">
            <div
              class="common_btn"
              @click="
                levelOneClick({
                  addItem: {
                    key: item.key,
                    value: item.value,
                    submitKey: 'toothProblem'
                  },
                  radio: false
                })
              "
              :class="
                values(
                  map(diagnosisParams.toothProblem.children, it => it.key)
                ).indexOf(item.key) !== -1
                  ? 'is_active'
                  : ''
              "
              v-for="(item, index) in caseOptions['tooth_problem_c']"
              :key="index"
            >
              {{ item.value }}
            </div>
          </div>
        </div>
        <div class="flex-y-c">
          <div
            :style="language === 'en_US' ? 'width: 1.1rem' : ''"
            class="fz14 option_left_title"
          >
            {{ $t("cases.createDiagnosis.ggwt") }}
          </div>
          <div class="flex-y-c-w mt10">
            <div
              class="common_btn"
              @click="
                levelOneClick({
                  addItem: {
                    key: item.key,
                    value: item.value,
                    submitKey: 'jawProblem'
                  }
                })
              "
              :class="{
                is_active: item.key === diagnosisParams.jawProblem.key
              }"
              v-for="(item, index) in caseOptions['jaw_problem_b']"
              :key="`${item.key}_${index}`"
            >
              {{ item.value }}
            </div>
          </div>
        </div>
        <div
          class="remark_box"
          v-show="
            values(
              map(diagnosisParams.toothProblem.children, it => it.key)
            ).indexOf('other') !== -1
              ? 'is_active'
              : ''
          "
        >
          <el-input
            :disabled="isBackDisabled"
            class="tex_b_a"
            :autosize="{ minRows: 4, maxRows: 6 }"
            maxlength="2000"
            type="textarea"
            show-word-limit
            v-model="diagnosisParams.toothOtherProblem"
            :placeholder="$t('common.common.qsrnr')"
          ></el-input>
        </div>
      </div>
      <div class="select_type" id="2">
        <div class="flex-y-c">
          <p>
            <span v-if="!needRTD" class="main_theme_color_red fz14 mr4">●</span>
          </p>
          <p class="select_type_title mr10">
            2.{{ $t("cases.createDiagnosis.zyjzmb") }}
          </p>
        </div>
        <p class="problem_note" v-if="diagnosisProblem['2']">
          {{ diagnosisProblem["2"] }}
        </p>

        <div class="flex-y-c">
          <div
            :style="language === 'en_US' ? 'width: 1.1rem' : ''"
            class="option_left_title"
          >
            {{ $t("cases.createDiagnosis.ycwt") }}
          </div>
          <div class="flex-y-c mt10">
            <div
              class="common_btn"
              @click="
                levelOneClick({
                  addItem: {
                    key: item.key,
                    value: item.value,
                    submitKey: 'toothProblemTwo'
                  },
                  radio: false
                })
              "
              :class="{
                is_active: values(
                  map(diagnosisParams.toothProblemTwo.children, it => it.key)
                ).includes(item.key)
              }"
              v-for="(item, index) in caseOptions['tooth_problem_two_c']"
              :key="`${item.key}_${index}`"
            >
              {{ item.value }}
            </div>
          </div>
        </div>
        <div class="flex-y-c">
          <div
            :style="language === 'en_US' ? 'width: 1.1rem' : ''"
            class="option_left_title"
          >
            {{ $t("cases.createDiagnosis.ggwt") }}
          </div>
          <div class="flex-y-c mt10">
            <div
              class="common_btn"
              @click="
                levelOneClick({
                  ev: item,
                  addItem: {
                    key: item.key,
                    value: item.value,
                    submitKey: 'jawProblemTwo'
                  }
                })
              "
              :class="{
                is_active: item.key === diagnosisParams.jawProblemTwo.key
              }"
              v-for="(item, index) in caseOptions['jaw_problem_two']"
              :key="index"
            >
              {{ item.value }}
            </div>
          </div>
        </div>
        <div
          class="remark_box"
          v-show="
            values(
              map(diagnosisParams.toothProblemTwo.children, it => it.key)
            ).includes('other')
          "
        >
          <el-input
            class="tex_b_a"
            :disabled="isBackDisabled"
            :autosize="{ minRows: 4, maxRows: 6 }"
            maxlength="2000"
            type="textarea"
            show-word-limit
            v-model="diagnosisParams.toothOtherProblemTwo"
            :placeholder="$t('common.common.qsrnr')"
          ></el-input>
        </div>
      </div>
      <!-- <div class="select_type" id="3">
        <div class="flex-y-c">
          <p>
            <span class="main_theme_color_red fz14 mr4">●</span>
          </p>
          <p class="select_type_title mr10">
            3.{{ $t("cases.createDiagnosis.txt2") }}
          </p>
        </div>
        <p class="problem_note" v-if="diagnosisProblem['3']">
          {{ diagnosisProblem["3"] }}
        </p>

        <div class="flex-y-c">
          <div
            :style="language === 'en_US' ? 'width: 1.82rem' : 'width: 0.94rem'"
            class="option_left_title"
          >
            <span style="position: relative; left: 0.16rem">{{
              $t("cases.createDiagnosis.gxfl")
            }}</span>
          </div>
          <div class="flex-y-c mt10">
            <div
              class="common_btn"
              @click="
                levelOneClick({
                  addItem: {
                    key: item.key,
                    value: item.value,
                    submitKey: 'sagittalMaxillomandibularClassification',
                  },
                })
              "
              :class="{
                is_active:
                  item.key ===
                  diagnosisParams.sagittalMaxillomandibularClassification.key,
              }"
              v-for="(item, index) in caseOptions[
                'sagittal_maxillomandibular_classification'
              ]"
              :key="`${item.key}_${index}`"
            >
              {{ item.value }}
            </div>
          </div>
        </div>
        <div style="display: flex" class="mt10">
          <div :style="language === 'en_US' ? 'width: 6rem' : 'width: 1.88rem'">
            <div class="option_left_title mt10">
              <span class="main_theme_color_red fz14 mr4">●</span>
              {{ $t("cases.createDiagnosis.chlx") }}
            </div>
          </div>
          <div class="flex-y-c-w">
            <div
              class="common_btn"
              @click="
                levelOneClick({
                  radio: false,
                  addItem: {
                    key: item.key,
                    value: item.value,
                    submitKey: 'clinicInfo',
                  },
                })
              "
              :class="{
                is_active: values(
                  map(diagnosisParams.clinicInfo.children, (it) => it.key)
                ).includes(item.key),
              }"
              v-for="(item, index) in caseOptions['clinic_info']"
              :key="`${item.key}_${index}`"
            >
              {{ item.value }}
            </div>
          </div>
        </div>
        <div
          class="remark_box"
          v-show="
            values(
              map(diagnosisParams.clinicInfo.children, (it) => it.key)
            ).includes('other')
          "
        >
          <el-input
            :disabled="isBackDisabled"
            class="tex_b_a"
            :autosize="{ minRows: 4, maxRows: 6 }"
            maxlength="2000"
            type="textarea"
            show-word-limit
            v-model="diagnosisParams.clinicOtherInfo"
            :placeholder="$t('common.common.qsrnr')"
          ></el-input>
        </div>
      </div> -->
      <div class="select_type" id="3">
        <div class="flex-y-c mb10">
          <p class="select_type_title mr10">
            3.{{ $t("cases.createDiagnosis.blxg") }}
          </p>
        </div>
        <p class="problem_note" v-if="diagnosisProblem['3']">
          {{ diagnosisProblem["3"] }}
        </p>
        <div class="flex-y-c">
          <div
            class="common_btn"
            @click="
              levelOneClick({
                addItem: {
                  key: item.key,
                  value: item.value,
                  submitKey: 'badHabits'
                }
              })
            "
            :class="{ is_active: item.key === diagnosisParams.badHabits.key }"
            v-for="(item, index) in caseOptions['bad_habits']"
            :key="index"
          >
            {{ item.value }}
          </div>
        </div>
        <div
          class="remark_box"
          v-show="diagnosisParams.badHabits.key === 'true'"
        >
          <el-input
            :disabled="isBackDisabled"
            class="tex_b_a"
            :autosize="{ minRows: 4, maxRows: 6 }"
            maxlength="2000"
            type="textarea"
            show-word-limit
            v-model="diagnosisParams.badHabitsNote"
            :placeholder="$t('common.common.qsrnr')"
          ></el-input>
        </div>
      </div>
      <div class="select_type" id="4">
        <div class="flex-y-c mb10">
          <p>
            <span class="main_theme_color_red fz14 mr4">●</span>
          </p>
          <p class="select_type_title mr10">
            4.{{ $t("cases.createDiagnosis.txt3") }}
            <span class="main_theme_color_999 fz14">{{
              $t("cases.createDiagnosis.jsdgjz")
            }}</span>
          </p>
        </div>
        <p class="problem_note" v-if="diagnosisProblem['4']">
          {{ diagnosisProblem["4"] }}
        </p>
        <div class="flex-y-c">
          <div
            v-for="(item, index) in caseOptions['to_correct_tooth_jaw']"
            @click="
              levelOneClick({
                noClear: true,
                addItem: {
                  key: item.key,
                  value: item.value,
                  submitKey: 'toCorrectToothJaw'
                }
              })
            "
            :key="index"
          >
            <el-popover
              :disabled="isBackDisabled"
              placement="bottom"
              :popper-options="{
                boundariesElement: 'body'
              }"
              trigger="click"
              v-if="item.key !== 'whole_jaw'"
            >
              <div class="flex-y-c">
                <div
                  class="common_btn"
                  :class="{
                    is_active:
                      diagnosisParams['toCorrectToothJaw'].children &&
                      diagnosisParams['toCorrectToothJaw'].children[0].key ===
                        it.key
                  }"
                  @click="
                    levelTowClick({
                      wrapFiled: 'toCorrectToothJaw',
                      addItem: {
                        key: it.key,
                        submitKey: {
                          upjaw: 'toCorrectToothUpjaw',
                          downjaw: 'toCorrectToothDownjaw'
                        }[item.key],
                        value: diaKeyObj[it.key]
                      },
                      radio: true
                    })
                  "
                  v-for="(it, idx) in diagnosisParams.toCorrectToothJaw.key ===
                  'upjaw'
                    ? caseOptions['to_correct_tooth_upjaw_b']
                    : caseOptions['to_correct_tooth_downjaw_b']"
                  :key="idx"
                >
                  {{ it.value }}
                </div>
              </div>
              <span
                class="common_btn"
                :class="{
                  is_active: diagnosisParams.toCorrectToothJaw.key === item.key
                }"
                slot="reference"
                :data-key="item.key"
              >
                {{ item.value }}
              </span>
            </el-popover>
            <span
              class="common_btn"
              :class="{
                is_active: diagnosisParams.toCorrectToothJaw.key === item.key
              }"
              v-else
              :data-key="item.key"
            >
              {{ item.value }}
            </span>
          </div>
        </div>
        <div
          class="single_tip_box"
          v-show="
            get(diagnosisParams['toCorrectToothJaw'].children, '[0].value')
          "
        >
          <p>
            {{
              get(diagnosisParams["toCorrectToothJaw"].children, "[0].value")
            }}
          </p>
        </div>
      </div>
      <div class="select_type" id="5">
        <div class="select_type_title mb10">
          5.
          {{ $t("cases.createDiagnosis.txt4") }}
          <span class="main_theme_color_999 fz14">{{
            $t("cases.createDiagnosis.yjmcyc")
          }}</span>
          <p class="problem_note" v-if="diagnosisProblem['5']">
            {{ diagnosisProblem["5"] }}
          </p>

          <ChildrenTeeth
            :commonTeeth="commonTeeth"
            :patientType="'children'"
            :readOnly="isBackDisabled"
            @updateTeeth="updateTeeth"
            class="mb40 mt20"
            :teethType="1"
          />
        </div>
      </div>
      <div class="select_type" id="6">
        <div class="select_type_title">
          6.
          {{ $t("cases.createDiagnosis.txt5") }}
          <span class="main_theme_color_999 fz14">{{
            $t("cases.createDiagnosis.bzbkydyc")
          }}</span>
          <p class="problem_note" v-if="diagnosisProblem['6']">
            {{ diagnosisProblem["6"] }}
          </p>

          <div class="flex-y-c mt10">
            <div
              class="common_btn"
              :class="{
                is_active: item.key === diagnosisParams.nonMoveableTeeth.key
              }"
              @click="
                levelOneClick({
                  addItem: {
                    submitKey: 'nonMoveableTeeth',
                    value: item.value,
                    key: item.key
                  }
                })
              "
              v-for="(item, index) in caseOptions['non_moveable_teeth']"
              :key="index"
            >
              {{ item.value }}
            </div>
          </div>
          <ChildrenTeeth
            v-show="diagnosisParams.nonMoveableTeeth.key === 'true'"
            :commonTeeth="commonTeeth"
            :patientType="'children'"
            ref="teethComponents"
            :readOnly="isBackDisabled"
            @updateTeeth="updateTeeth"
            class="mb40 mt10"
            :teethType="2"
          />
        </div>
      </div>
      <div class="select_type" id="7">
        <div class="select_type_title mb2rem">
          7.
          {{ $t("cases.createDiagnosis.txt6") }}
          <span class="main_theme_color_999 fz14">{{
            $t("cases.createDiagnosis.bzbksjyc")
          }}</span>
          <p class="problem_note mt20" v-if="diagnosisProblem[7]">
            {{ diagnosisProblem[7] }}
          </p>

          <div class="flex-y-c mt10">
            <div
              class="common_btn"
              :class="{
                is_active:
                  item.key === diagnosisParams.notAvailableAttachments.key
              }"
              @click="
                levelOneClick({
                  addItem: {
                    key: item.key,
                    value: item.value,
                    submitKey: 'notAvailableAttachments'
                  }
                })
              "
              v-for="(item, index) in caseOptions[
                'not_available_for_attachments'
              ]"
              :key="index"
            >
              {{ item.value }}
            </div>
          </div>
          <ChildrenTeeth
            v-show="diagnosisParams.notAvailableAttachments.key === 'true'"
            :commonTeeth="commonTeeth"
            :patientType="'children'"
            ref="teethComponents"
            :readOnly="isBackDisabled"
            @updateTeeth="updateTeeth"
            class="mb40 mt10"
            :teethType="3"
          />
        </div>
      </div>
      <div class="select_type" id="8">
        <p class="select_type_title mb10">
          <span v-if="!needRTD" class="main_theme_color_red fz14 mr4">●</span>
          8.{{ $t("cases.createDiagnosis.txt7") }}
        </p>
        <p class="problem_note" v-if="diagnosisProblem['8']">
          {{ diagnosisProblem["8"] }}
        </p>

        <div class="flex-y-c">
          <div
            v-for="(item, index) in caseOptions['sagittal_relation_c']"
            :key="index"
            @click="
              levelOneClick({
                noClear: true,
                addItem: {
                  key: item.key,
                  value: item.value,
                  submitKey: 'sagittalRelation'
                }
              })
            "
          >
            <el-popover
              placement="bottom"
              :disabled="isBackDisabled"
              :popper-options="{
                boundariesElement: 'body'
              }"
              :offset="170"
              width="450"
              trigger="click"
              v-if="item.key === 'molar_relationship_improvement'"
            >
              <div class="popover_con mb20">
                <span
                  :style="language === 'en_US' ? 'width: 0.6rem' : ''"
                  :class="{
                    is_active: find(
                      diagnosisParams.sagittalRelationMolar['children'],
                      dia => dia.key === 'left'
                    )
                  }"
                  @click.stop="
                    levelTowClick({
                      wrapFiled: 'sagittalRelationMolar',
                      addItem: {
                        key: 'left',
                        value: $t('cases.createDiagnosis.zc'),
                        input: '',
                        inputKey: 'leftSagittalRelationMolarNote'
                      }
                    })
                  "
                  class="common_btn"
                >
                  {{ $t("cases.createDiagnosis.zc") }}
                </span>
                <div
                  class="posr"
                  v-show="
                    keys(
                      find(
                        diagnosisParams.sagittalRelationMolar['children'],
                        dia => dia.key === 'left'
                      )
                    ).includes('input')
                  "
                >
                  <el-input
                    class="ml10"
                    style="height: 0.36rem; width: 2.4rem"
                    :disabled="isBackDisabled"
                    maxlength="200"
                    v-model="
                      find(
                        diagnosisParams.sagittalRelationMolar['children'],
                        dia => dia.key === 'left'
                      ) &&
                        find(
                          diagnosisParams.sagittalRelationMolar['children'],
                          dia => dia.key === 'left'
                        )['input']
                    "
                    :placeholder="$t('cases.createDiagnosis.zcxzsm')"
                  />
                </div>
              </div>
              <div class="popover_con">
                <span
                  :style="language === 'en_US' ? 'width: 0.6rem' : ''"
                  :class="{
                    is_active: find(
                      diagnosisParams.sagittalRelationMolar['children'],
                      dia => dia.key === 'right'
                    )
                  }"
                  @click.stop="
                    levelTowClick({
                      wrapFiled: 'sagittalRelationMolar',
                      addItem: {
                        key: 'right',
                        value: $t('cases.createDiagnosis.yc'),
                        input: '',
                        inputKey: 'rightSagittalRelationMolarNote'
                      }
                    })
                  "
                  class="common_btn"
                >
                  {{ $t("cases.createDiagnosis.yc") }}
                </span>
                <div
                  class="posr"
                  v-show="
                    keys(
                      find(
                        diagnosisParams.sagittalRelationMolar['children'],
                        dia => dia.key === 'right'
                      )
                    ).includes('input')
                  "
                >
                  <el-input
                    class="ml10"
                    style="height: 0.36rem !important; width: 2.4rem"
                    :disabled="isBackDisabled"
                    maxlength="200"
                    v-model="
                      find(
                        diagnosisParams.sagittalRelationMolar['children'],
                        dia => dia.key === 'right'
                      ) &&
                        find(
                          diagnosisParams.sagittalRelationMolar['children'],
                          dia => dia.key === 'right'
                        )['input']
                    "
                    :placeholder="$t('cases.createDiagnosis.ycxzsm')"
                  />
                </div>
              </div>
              <span
                class="common_btn"
                :class="{
                  is_active: diagnosisParams.sagittalRelation.key === item.key
                }"
                slot="reference"
                :data-key="item.key"
                >{{ item.value }}</span
              >
            </el-popover>
            <span
              v-else
              class="common_btn"
              :class="{
                is_active: diagnosisParams.sagittalRelation.key === item.key
              }"
              >{{ item.value }}</span
            >
          </div>
        </div>
        <div
          class="single_tip_box"
          v-show="
            get(
              find(
                diagnosisParams.sagittalRelationMolar['children'],
                item => item.key === 'left'
              ),
              'value'
            ) ||
              get(
                find(
                  diagnosisParams.sagittalRelationMolar['children'],
                  item => item.key === 'right'
                ),
                'value'
              )
          "
        >
          <p style="word-break: break-all" class="w100">
            {{
              get(
                find(
                  diagnosisParams.sagittalRelationMolar["children"],
                  item => item.key === "left"
                ),
                "value"
              )
            }}
            {{
              get(
                find(
                  diagnosisParams.sagittalRelationMolar["children"],
                  item => item.key === "left"
                ),
                "input"
              )
                ? "- " +
                  get(
                    find(
                      diagnosisParams.sagittalRelationMolar["children"],
                      item => item.key === "left"
                    ),
                    "input"
                  )
                : ""
            }}
            {{
              get(
                find(
                  diagnosisParams.sagittalRelationMolar["children"],
                  item => item.key === "left"
                ),
                "value"
              ) &&
              get(
                find(
                  diagnosisParams.sagittalRelationMolar["children"],
                  item => item.key === "right"
                ),
                "value"
              )
                ? "、"
                : ""
            }}
            {{
              get(
                find(
                  diagnosisParams.sagittalRelationMolar["children"],
                  item => item.key === "right"
                ),
                "value"
              )
            }}
            {{
              get(
                find(
                  diagnosisParams.sagittalRelationMolar["children"],
                  item => item.key === "right"
                ),
                "input"
              )
                ? "- " +
                  get(
                    find(
                      diagnosisParams.sagittalRelationMolar["children"],
                      item => item.key === "right"
                    ),
                    "input"
                  )
                : ""
            }}
          </p>
        </div>
      </div>
      <div class="select_type" id="9">
        <p class="select_type_title mb10">
          <span v-if="!needRTD" class="main_theme_color_red fz14 mr4">●</span>
          9.{{ $t("cases.createDiagnosis.hxgx") }}
        </p>
        <p class="problem_note" v-if="diagnosisProblem['9']">
          {{ diagnosisProblem["9"] }}
        </p>

        <div class="flex-y-c">
          <span
            :style="language === 'en_US' ? 'width: 0.6rem' : ''"
            class="mr10 main_theme_color_666 fz14"
            >{{ $t("common.common.up") }}</span
          >
          <div
            class="mt10"
            v-for="(item, index) in caseOptions['up_horizontal_relationship']"
            :key="index"
            @click="
              levelOneClick({
                noClear: true,
                addItem: {
                  key: item.key,
                  value: item.value,
                  submitKey: 'upHorizontalRelationship'
                }
              })
            "
          >
            <el-popover
              :disabled="isBackDisabled"
              placement="bottom"
              :popper-options="{
                boundariesElement: 'body'
              }"
              trigger="click"
              v-if="item.key === 'expansion' || item.key === 'contraction'"
            >
              <div class="popover_con">
                <div style="flex-direction: column">
                  <div class="flex-y-c">
                    <div
                      class="common_btn"
                      :class="{
                        is_active: map(
                          diagnosisParams.upHorizontalRelationship['children'],
                          dia => dia.key
                        ).includes(it.key)
                      }"
                      @click.stop="
                        levelTowClick({
                          radio: true,
                          addItem: {
                            key: it.key,
                            submitKey: 'upHorizontalRelationshipNote',
                            value: diaKeyObj[it.key],
                            [it.key === 'specify' ? 'input' : '']: '',
                            inputKey: 'upHorizontalRelationshipValue'
                          },
                          wrapFiled: 'upHorizontalRelationship'
                        })
                      "
                      v-for="(it, itx) in caseOptions[
                        'up_horizontal_expansion'
                      ]"
                      :key="itx"
                    >
                      {{ it.value }}
                    </div>
                  </div>
                  <div
                    class="flex-y-c"
                    v-show="
                      map(
                        diagnosisParams.upHorizontalRelationship['children'],
                        dia => dia.key
                      ).includes('specify')
                    "
                  >
                    <p style="width: 18%">
                      {{ $t("cases.createDiagnosis.total") }}
                    </p>
                    <p class="flex-y-c posr">
                      <el-input
                        :style="
                          language === 'en_US'
                            ? 'width: 2.8rem'
                            : 'width: 1.85rem'
                        "
                        :disabled="isBackDisabled"
                        :value="
                          find(
                            diagnosisParams.upHorizontalRelationship[
                              'children'
                            ],
                            dia => dia.key === 'specify'
                          ) &&
                            find(
                              diagnosisParams.upHorizontalRelationship[
                                'children'
                              ],
                              dia => dia.key === 'specify'
                            )['input']
                        "
                        @change="
                          disposeNumber({
                            key: find(
                              diagnosisParams.upHorizontalRelationship[
                                'children'
                              ],
                              dia => dia.key === 'specify'
                            ),
                            value: arguments[0]
                          })
                        "
                        @input="
                          disposeNumber({
                            key: find(
                              diagnosisParams.upHorizontalRelationship[
                                'children'
                              ],
                              dia => dia.key === 'specify'
                            ),
                            value: arguments[0]
                          })
                        "
                        :placeholder="$t('cases.createDiagnosis.srjtsz')"
                      />
                      <span class="crosswise_input_mm" style="right: 0.2rem"
                        >mm</span
                      >
                    </p>
                  </div>
                </div>
              </div>
              <span
                class="common_btn"
                :class="{
                  is_active:
                    item.key === diagnosisParams.upHorizontalRelationship.key
                }"
                slot="reference"
                :data-key="item.key"
                >{{ item.value }}</span
              >
            </el-popover>
            <span
              v-else
              class="common_btn"
              :class="{
                is_active:
                  item.key === diagnosisParams.upHorizontalRelationship.key
              }"
              >{{ item.value }}</span
            >
          </div>
        </div>
        <div
          class="single_tip_box"
          v-show="
            get(
              diagnosisParams['upHorizontalRelationship'].children,
              '[0].value'
            )
          "
        >
          <p>
            {{
              get(
                diagnosisParams["upHorizontalRelationship"].children,
                "[0].value"
              )
            }}
            {{
              get(
                find(
                  diagnosisParams.upHorizontalRelationship["children"],
                  item => item && item.input
                ),
                "input"
              )
                ? "--"
                : ""
            }}
            {{
              get(
                find(
                  diagnosisParams.upHorizontalRelationship["children"],
                  item => item && item.input
                ),
                "input"
              )
                ? `${$t("cases.createDiagnosis.total")}`
                : ""
            }}
            {{
              get(
                find(
                  diagnosisParams.upHorizontalRelationship["children"],
                  item => item && item.input
                ),
                "input"
              )
            }}
            {{
              get(
                find(
                  diagnosisParams.upHorizontalRelationship["children"],
                  item => item && item.input
                ),
                "input"
              )
                ? "mm"
                : ""
            }}
          </p>
        </div>
        <div class="flex-y-c">
          <span
            :style="language === 'en_US' ? 'width: 0.6rem' : ''"
            class="mr10 main_theme_color_666 fz14"
            >{{ $t("common.common.down") }}</span
          >
          <div
            class="mt10"
            v-for="(item, index) in caseOptions['down_horizontal_relationship']"
            :key="index"
            @click="
              levelOneClick({
                noClear: true,
                addItem: {
                  key: item.key,
                  value: item.value,
                  submitKey: 'downHorizontalRelationship'
                }
              })
            "
          >
            <el-popover
              :disabled="isBackDisabled"
              placement="bottom"
              :popper-options="{
                boundariesElement: 'body'
              }"
              trigger="click"
              v-if="item.key === 'expansion' || item.key === 'contraction'"
            >
              <div class="popover_con">
                <div style="flex-direction: column">
                  <div class="flex-y-c">
                    <div
                      class="common_btn"
                      :class="{
                        is_active: map(
                          diagnosisParams.downHorizontalRelationship[
                            'children'
                          ],
                          dia => dia.key
                        ).includes(it.key)
                      }"
                      @click.stop="
                        levelTowClick({
                          radio: true,
                          noClear: true,
                          addItem: {
                            key: it.key,
                            submitKey: 'downHorizontalRelationshipNote',
                            value: diaKeyObj[it.key],
                            [it.key === 'specify' ? 'input' : '']: '',
                            inputKey: 'downHorizontalRelationshipValue'
                          },
                          wrapFiled: 'downHorizontalRelationship'
                        })
                      "
                      v-for="(it, itx) in caseOptions[
                        'down_horizontal_expansion'
                      ]"
                      :key="itx"
                    >
                      {{ it.value }}
                    </div>
                  </div>
                  <div
                    class="flex-y-c"
                    v-show="
                      map(
                        diagnosisParams.downHorizontalRelationship['children'],
                        dia => dia.key
                      ).includes('specify')
                    "
                  >
                    <p style="width: 18%">
                      {{ $t("cases.createDiagnosis.total") }}
                    </p>
                    <div class="flex-y-c posr">
                      <el-input
                        :style="
                          language === 'en_US'
                            ? 'width: 2.8rem'
                            : 'width: 1.85rem'
                        "
                        :disabled="isBackDisabled"
                        :value="
                          find(
                            diagnosisParams.downHorizontalRelationship[
                              'children'
                            ],
                            dia => dia.key === 'specify'
                          ) &&
                            find(
                              diagnosisParams.downHorizontalRelationship[
                                'children'
                              ],
                              dia => dia.key === 'specify'
                            )['input']
                        "
                        @change="
                          disposeNumber({
                            key: find(
                              diagnosisParams.downHorizontalRelationship[
                                'children'
                              ],
                              dia => dia.key === 'specify'
                            ),
                            value: arguments[0]
                          })
                        "
                        @input="
                          disposeNumber({
                            key: find(
                              diagnosisParams.downHorizontalRelationship[
                                'children'
                              ],
                              dia => dia.key === 'specify'
                            ),
                            value: arguments[0]
                          })
                        "
                        :placeholder="$t('cases.createDiagnosis.srjtsz')"
                      />
                      <span class="crosswise_input_mm" style="right: 0.2rem"
                        >mm</span
                      >
                    </div>
                  </div>
                </div>
              </div>
              <span
                class="common_btn"
                :class="{
                  is_active:
                    item.key === diagnosisParams.downHorizontalRelationship.key
                }"
                slot="reference"
                >{{ item.value }}</span
              >
            </el-popover>
            <span
              v-else
              class="common_btn"
              :class="{
                is_active:
                  item.key === diagnosisParams.downHorizontalRelationship.key
              }"
              >{{ item.value }}</span
            >
          </div>
        </div>
        <div
          class="single_tip_box"
          v-show="
            get(
              diagnosisParams['downHorizontalRelationship'].children,
              '[0].value'
            )
          "
        >
          <p>
            {{
              get(
                diagnosisParams["downHorizontalRelationship"].children,
                "[0].value"
              )
            }}
            {{
              get(
                find(
                  diagnosisParams.downHorizontalRelationship["children"],
                  item => item && item.input
                ),
                "input"
              )
                ? "--"
                : ""
            }}
            {{
              get(
                find(
                  diagnosisParams.downHorizontalRelationship["children"],
                  item => item && item.input
                ),
                "input"
              )
                ? `${$t("cases.createDiagnosis.total")}`
                : ""
            }}
            {{
              get(
                find(
                  diagnosisParams.downHorizontalRelationship["children"],
                  item => item && item.input
                ),
                "input"
              )
            }}
            {{
              get(
                find(
                  diagnosisParams.downHorizontalRelationship["children"],
                  item => item && item.input
                ),
                "input"
              )
                ? "mm"
                : ""
            }}
          </p>
        </div>
      </div>
      <div class="select_type" id="10">
        <p class="select_type_title mb10">
          10.{{ $t("cases.createDiagnosis.txt8") }}
        </p>
        <p class="problem_note" v-if="diagnosisProblem['10']">
          {{ diagnosisProblem["10"] }}
        </p>

        <div class="flex-y-c">
          <div
            class="common_btn"
            @click="
              levelOneClick({
                addItem: {
                  key: item.key,
                  value: item.value,
                  submitKey: 'coverInfo'
                }
              })
            "
            :class="{ is_active: item.key === diagnosisParams.coverInfo.key }"
            v-for="(item, index) in caseOptions['cover_info']"
            :key="index"
          >
            {{ item.value }}
          </div>
        </div>
      </div>
      <div class="select_type" id="11">
        <p class="select_type_title mb10">
          11.{{ $t("cases.createDiagnosis.txt9") }}
        </p>
        <p class="problem_note" v-if="diagnosisProblem['11']">
          {{ diagnosisProblem["11"] }}
        </p>
        <div class="flex-y-c">
          <div
            @click="
              levelOneClick({
                noClear: true,
                addItem: {
                  key: item.key,
                  value: item.value,
                  submitKey: 'overbiteInfo'
                }
              })
            "
            v-for="(item, index) in caseOptions['overbite_info']"
            :key="index"
          >
            <el-popover
              placement="bottom-start"
              :disabled="isBackDisabled"
              :offset="-100"
              :popper-options="{
                boundariesElement: 'body'
              }"
              trigger="click"
              v-if="item.key === 'correct_open'"
            >
              <div class="popover_con">
                <div style="flex-direction: column">
                  <div class="flex-y-c">
                    <div
                      class="common_btn"
                      :class="{
                        is_active: map(
                          diagnosisParams.overbiteInfo['children'],
                          dia => dia.key
                        ).includes(it.key)
                      }"
                      @click.stop="
                        levelTowClick({
                          multipleRadio: ['other'],
                          multipleRadios: [
                            'press_back_tooth',
                            'extend_front_press_back',
                            'extend_front_tooth'
                          ],
                          addItem: {
                            key: it.key,
                            submitKey: 'overbiteInfoOpen',
                            value: it.value
                          },
                          wrapFiled: 'overbiteInfo'
                        })
                      "
                      v-for="(it, itx) in caseOptions['correct_open_b']"
                      :key="itx"
                    >
                      {{ it.value }}
                    </div>
                  </div>
                  <div
                    style="margin-left: -2.4rem; margin-top: -0.5rem"
                    v-show="
                      map(
                        diagnosisParams.overbiteInfo['children'],
                        dia => dia.key
                      ).includes('extend_front_tooth')
                    "
                    class="popover_db"
                  >
                    <div class="popover_db_left mt20"></div>
                    <div class="popover_db_right mt20">
                      <span
                        class="common_btn"
                        :class="{
                          is_active: map(
                            find(
                              diagnosisParams.overbiteInfo['children'],
                              dia => dia.key === 'extend_front_tooth'
                            ) &&
                              find(
                                diagnosisParams.overbiteInfo['children'],
                                dia => dia.key === 'extend_front_tooth'
                              )['children'],
                            diaC => diaC.key
                          ).includes(singleItem.key)
                        }"
                        @click.stop="
                          levelThreeClick({
                            wrapFiled: 'overbiteInfo',
                            addItem: {
                              submitKey: 'overbiteInfoExtrudeAnterior',
                              value: singleItem.value,
                              key: singleItem.key
                            },
                            parentKey: 'extend_front_tooth'
                          })
                        "
                        v-for="(singleItem, index) in caseOptions['updown_jaw']"
                        :key="index"
                      >
                        {{ singleItem.value }}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
              <div
                class="common_btn"
                :class="{
                  is_active: item.key === diagnosisParams.overbiteInfo.key
                }"
                slot="reference"
              >
                {{ item.value }}
              </div>
            </el-popover>
            <el-popover
              placement="bottom"
              trigger="click"
              :disabled="isBackDisabled"
              :popper-options="{
                boundariesElement: 'body'
              }"
              v-else-if="item.key === 'correct_deep_cover'"
            >
              <div class="popover_con">
                <div style="flex-direction: column">
                  <div class="flex-y-c">
                    <div
                      class="common_btn"
                      :class="{
                        is_active: map(
                          diagnosisParams.overbiteInfo['children'],
                          dia => dia.key
                        ).includes(it.key)
                      }"
                      @click.stop="
                        levelTowClick({
                          multipleRadio: ['other'],
                          multipleRadios: [
                            'press_front_rise_back',
                            'press_front'
                          ],
                          addItem: {
                            key: it.key,
                            submitKey: 'overbiteInfoDeep',
                            value: it.value
                          },
                          wrapFiled: 'overbiteInfo'
                        })
                      "
                      v-for="(it, itx) in caseOptions['correct_deep_cover_b']"
                      :key="itx"
                    >
                      {{ it.value }}
                    </div>
                  </div>
                  <div
                    style="margin-left: -1.2rem; margin-top: -0.5rem"
                    v-show="
                      map(
                        diagnosisParams.overbiteInfo['children'],
                        dia => dia.key
                      ).includes('press_front')
                    "
                    class="popover_db"
                  >
                    <div class="popover_db_left mt20"></div>
                    <div class="popover_db_right mt20">
                      <span
                        class="common_btn"
                        :class="{
                          is_active: map(
                            find(
                              diagnosisParams.overbiteInfo['children'],
                              dia => dia.key === 'press_front'
                            ) &&
                              find(
                                diagnosisParams.overbiteInfo['children'],
                                dia => dia.key === 'press_front'
                              )['children'],
                            diaC => diaC.key
                          ).includes(singleItem.key)
                        }"
                        @click.stop="
                          levelThreeClick({
                            wrapFiled: 'overbiteInfo',
                            addItem: {
                              submitKey: 'overbiteInfoIntrudeAnterior',
                              value: singleItem.value,
                              key: singleItem.key
                            },
                            parentKey: 'press_front'
                          })
                        "
                        v-for="(singleItem, index) in caseOptions['updown_jaw']"
                        :key="index"
                      >
                        {{ singleItem.value }}
                      </span>
                    </div>
                  </div>
                  <div
                    style="margin-left: 2.2rem; margin-top: -0.5rem"
                    v-show="
                      map(
                        diagnosisParams.overbiteInfo['children'],
                        dia => dia.key
                      ).includes('press_front_rise_back')
                    "
                    class="popover_db"
                  >
                    <div class="popover_db_left mt20"></div>
                    <div class="popover_db_right mt20">
                      <span
                        class="common_btn"
                        :class="{
                          is_active: map(
                            find(
                              diagnosisParams.overbiteInfo['children'],
                              dia => dia.key === 'press_front_rise_back'
                            ) &&
                              find(
                                diagnosisParams.overbiteInfo['children'],
                                dia => dia.key === 'press_front_rise_back'
                              )['children'],
                            diaC => diaC.key
                          ).includes(singleItem.key)
                        }"
                        @click.stop="
                          levelThreeClick({
                            wrapFiled: 'overbiteInfo',
                            addItem: {
                              submitKey: 'overbiteInfoPosteriorsExrusion',
                              value: singleItem.value,
                              key: singleItem.key
                            },
                            parentKey: 'press_front_rise_back'
                          })
                        "
                        v-for="(singleItem, index) in caseOptions['updown_jaw']"
                        :key="index"
                      >
                        {{ singleItem.value }}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
              <div
                class="common_btn"
                :class="{
                  is_active: item.key === diagnosisParams.overbiteInfo.key
                }"
                slot="reference"
              >
                {{ item.value }}
              </div>
            </el-popover>
            <div
              v-else
              class="common_btn"
              :class="{
                is_active: item.key === diagnosisParams.overbiteInfo.key
              }"
            >
              {{ item.value }}
            </div>
          </div>
        </div>
        <div
          class="single_tip_box"
          v-show="
            map(
              diagnosisParams['overbiteInfo'].children,
              dia => dia.value
            ).join()
          "
        >
          <p>
            {{ overbiteText() }}
          </p>
        </div>
        <div
          v-show="
            map(
              diagnosisParams.overbiteInfo['children'],
              dia => dia.key
            ).includes('other')
          "
          class="remark_box"
        >
          <el-input
            class="tex_b_a"
            :disabled="isBackDisabled"
            :autosize="{ minRows: 4, maxRows: 6 }"
            maxlength="2000"
            type="textarea"
            show-word-limit
            v-model="diagnosisParams.overbiteOtherInfo"
            :placeholder="$t('common.common.qsrnr')"
          ></el-input>
        </div>
      </div>
      <div class="select_type" id="12">
        <p class="select_type_title mb10">
          12.{{ $t("cases.createDiagnosis.qyfh") }}
        </p>
        <p class="problem_note" v-if="diagnosisProblem['12']">
          {{ diagnosisProblem["12"] }}
        </p>

        <div class="flex-y-c">
          <div
            @click="
              levelOneClick({
                noClear: true,
                addItem: {
                  submitKey: 'frontToothInfo',
                  key: item.key,
                  value: item.value
                }
              })
            "
            v-for="(item, index) in caseOptions['front_tooth_info_b']"
            :key="index"
          >
            <el-popover
              placement="bottom"
              :disabled="isBackDisabled"
              :popper-options="{
                boundariesElement: 'body'
              }"
              trigger="click"
              v-if="item.key === 'open_bite_correction'"
            >
              <div class="popover_con mb2rem">
                <div class="flex-y-c">
                  <div
                    style="display: block"
                    class="common_btn"
                    :class="{
                      is_active: map(
                        diagnosisParams.frontToothInfo['children'],
                        dia => dia.key
                      ).includes(it.key)
                    }"
                    @click.stop="
                      levelTowClick({
                        multipleRadio: [
                          'front_tooth_unback',
                          'front_tooth_back'
                        ],
                        addItem: {
                          key: it.key,
                          submitKey: 'correctFrontReverse',
                          value: diaKeyObj[it.key]
                        },
                        noClear: true,
                        wrapFiled: 'frontToothInfo'
                      })
                    "
                    v-for="(it, itx) in caseOptions['correct_front_reverse']"
                    :key="itx"
                  >
                    {{ it.value }}
                  </div>
                </div>
                <p
                  :class="{
                    'c-red': filter(
                      diagnosisParams.frontToothInfo['children'],
                      dia => ['front_tooth_back'].includes(dia.key)
                    ).length
                  }"
                  class="ml20 mt8"
                >
                  {{ $t("cases.cases.rxz") }}
                </p>
              </div>
              <div class="flex-y-c mb20">
                <div
                  class="common_btn"
                  :class="{
                    is_active: map(
                      diagnosisParams.frontToothInfo['children'],
                      dia => dia.key
                    ).includes(it.key)
                  }"
                  @click.stop="
                    levelTowClick({
                      multipleRadios: [
                        'lip_up_front',
                        'tongue_down_front',
                        'lip_up_front_tongue_down_front'
                      ],
                      addItem: {
                        key: it.key,
                        submitKey: 'correctFrontReverse',
                        value: diaKeyObj[it.key]
                      },
                      wrapFiled: 'frontToothInfo'
                    })
                  "
                  v-for="(it, itx) in caseOptions['correct_front_reverse_two']"
                  :key="itx"
                >
                  {{ it.value }}
                </div>
              </div>
              <div
                class="common_btn"
                :class="{
                  is_active: item.key === diagnosisParams.frontToothInfo.key
                }"
                slot="reference"
              >
                {{ item.value }}
              </div>
            </el-popover>
            <div
              v-else
              class="common_btn"
              :class="{
                is_active: item.key === diagnosisParams.frontToothInfo.key
              }"
            >
              {{ item.value }}
            </div>
          </div>
        </div>
        <div
          class="single_tip_box"
          v-show="
            map(
              diagnosisParams['frontToothInfo'].children,
              dia => dia.value
            ).join()
          "
        >
          <p
            v-for="(item, index) in diagnosisParams['frontToothInfo'].children"
            :key="index"
          >
            {{ item.value }}
            <span class="c-red" v-if="item.key === 'front_tooth_back'">
              {{ $t("cases.createDiagnosis.qubtjjl") }}</span
            >
          </p>
        </div>
      </div>
      <div class="select_type" id="13">
        <p class="select_type_title mb10">
          13.{{ $t("cases.createDiagnosis.txt11") }}
        </p>
        <p class="problem_note" v-if="diagnosisProblem['13']">
          {{ diagnosisProblem["13"] }}
        </p>

        <div class="flex-y-c">
          <div
            v-for="(item, index) in caseOptions['back_tooth_info']"
            @click="
              levelOneClick({
                noClear: true,
                addItem: {
                  key: item.key,
                  value: item.value,
                  submitKey: 'backToothInfo'
                }
              })
            "
            :key="index"
          >
            <el-popover
              :disabled="isBackDisabled"
              placement="bottom"
              :popper-options="{
                boundariesElement: 'body'
              }"
              trigger="click"
              v-if="item.key === 'correct'"
            >
              <div class="flex-y-c">
                <span
                  :style="
                    caseOptions['back_tooth_info_correct_b'].length - 1 === idx
                      ? 'margin-right: 0'
                      : ''
                  "
                  class="common_btn"
                  :class="{
                    is_active:
                      diagnosisParams['backToothInfo'].children &&
                      diagnosisParams['backToothInfo'].children[0].key ===
                        it.key
                  }"
                  @click="
                    levelTowClick({
                      wrapFiled: 'backToothInfo',
                      addItem: {
                        key: it.key,
                        submitKey: 'backToothInfoCorrect',
                        value: it.value
                      },
                      radio: true
                    })
                  "
                  v-for="(it, idx) in caseOptions['back_tooth_info_correct_b']"
                  :key="idx"
                >
                  {{ it.value }}</span
                >
              </div>
              <div
                class="common_btn"
                :class="{
                  is_active: diagnosisParams.backToothInfo.key === item.key
                }"
                slot="reference"
                :data-key="item.key"
              >
                {{ item.value }}
              </div>
            </el-popover>
            <span
              class="common_btn"
              :class="{
                is_active: diagnosisParams.backToothInfo.key === item.key
              }"
              v-else
              :data-key="item.key"
            >
              {{ item.value }}
            </span>
          </div>
        </div>
        <div
          class="single_tip_box"
          v-show="
            map(
              diagnosisParams['backToothInfo'].children,
              dia => dia.value
            ).join()
          "
        >
          <p>
            {{
              `${map(
                diagnosisParams["backToothInfo"].children,
                dia => dia.value
              ).join()}`
            }}
          </p>
        </div>
      </div>
      <div class="select_type" id="14">
        <div class="flex-y-c mb10">
          <p><span class="main_theme_color_red fz14 mr4">●</span></p>
          <p class="select_type_title mr10">
            14.{{ $t("cases.createDiagnosis.txt12") }}
          </p>
        </div>
        <p class="problem_note" v-if="diagnosisProblem['14']">
          {{ diagnosisProblem["14"] }}
        </p>

        <div class="flex-y-c">
          <div
            v-for="(item, index) in caseOptions['middle_line']"
            :key="index"
            @click="
              levelOneClick({
                noClear: true,
                addItem: {
                  key: item.key,
                  value: item.value,
                  submitKey: 'middleLine'
                }
              })
            "
          >
            <el-popover
              :disabled="isBackDisabled"
              placement="bottom"
              trigger="click"
              :popper-options="{
                boundariesElement: 'body'
              }"
              v-if="item.value === $t('cases.createDiagnosis.gszx')"
            >
              <div class="popover_con">
                <div
                  :style="language === 'en_US' ? 'width: 0.8rem' : ''"
                  class="main_theme_color_333 mr10 mt10"
                >
                  {{ $t("cases.createDiagnosis.se") }}
                </div>
                <div style="flex-direction: column">
                  <div class="flex-y-c">
                    <div
                      class="common_btn"
                      @click.stop="
                        levelTowClick({
                          wrapFiled: 'middleLine',
                          radio: true,
                          addItem: {
                            key: get(it, 'key'),
                            submitKey: 'upjawMiddleLineNote',
                            value: diaKeyObj[get(it, 'key')],
                            input: '',
                            inputKey: 'upjawMiddleLine'
                          },
                          parentFiled: item.key
                        })
                      "
                      :class="{
                        is_active: find(
                          diagnosisParams.middleLine['children'],
                          dia =>
                            get(dia, 'key') === get(it, 'key') &&
                            get(dia, 'submitKey') === 'upjawMiddleLineNote'
                        )
                      }"
                      v-for="(it, itx) in caseOptions['improve_middle_move']"
                      :key="itx"
                    >
                      {{ it.value }}
                    </div>
                  </div>
                  <p
                    class="flex-y-c posr mt10"
                    v-show="
                      find(
                        diagnosisParams.middleLine['children'],
                        dia => get(dia, 'submitKey') === 'upjawMiddleLineNote'
                      )
                    "
                  >
                    <el-input
                      style="width: 2.6rem"
                      :disabled="isBackDisabled"
                      :value="
                        find(
                          diagnosisParams.middleLine['children'],
                          dia => get(dia, 'submitKey') === 'upjawMiddleLineNote'
                        ) &&
                          find(
                            diagnosisParams.middleLine['children'],
                            dia =>
                              get(dia, 'submitKey') === 'upjawMiddleLineNote'
                          )['input']
                      "
                      @change="
                        disposeNumber({
                          key: find(
                            diagnosisParams.middleLine['children'],
                            dia =>
                              get(dia, 'submitKey') === 'upjawMiddleLineNote'
                          ),
                          value: arguments[0]
                        })
                      "
                      @input="
                        disposeNumber({
                          key: find(
                            diagnosisParams.middleLine['children'],
                            dia =>
                              get(dia, 'submitKey') === 'upjawMiddleLineNote'
                          ),
                          value: arguments[0]
                        })
                      "
                      :placeholder="$t('cases.createDiagnosis.srjtsz')"
                    />
                    <span class="midcourt_line_input_mm c-white">mm</span>
                  </p>
                </div>
              </div>
              <div class="popover_con mt30">
                <div
                  :style="language === 'en_US' ? 'width: 0.8rem' : ''"
                  class="main_theme_color_333 mr10 mt10"
                >
                  {{ $t("cases.createDiagnosis.xe") }}
                </div>
                <div class="popover_con_right" style="flex-direction: column">
                  <div class="flex-y-c">
                    <div
                      class="common_btn"
                      :class="{
                        is_active: find(
                          diagnosisParams.middleLine['children'],
                          dia =>
                            dia.key === it.key &&
                            get(dia, 'submitKey') === 'downjawMiddleLineNote'
                        )
                      }"
                      @click.stop="
                        levelTowClick({
                          wrapFiled: 'middleLine',
                          radio: true,
                          addItem: {
                            key: it.key,
                            submitKey: 'downjawMiddleLineNote',
                            value: diaKeyObj[it.key],
                            input: '',
                            inputKey: 'downjawMiddleLine'
                          }
                        })
                      "
                      v-for="(it, itx) in caseOptions['improve_middle_move']"
                      :key="itx"
                    >
                      {{ it.value }}
                    </div>
                  </div>
                  <div
                    class="flex-y-c posr mt10"
                    v-show="
                      find(
                        diagnosisParams.middleLine['children'],
                        dia => dia.submitKey === 'downjawMiddleLineNote'
                      )
                    "
                  >
                    <el-input
                      style="width: 2.6rem"
                      :disabled="isBackDisabled"
                      :value="
                        find(
                          diagnosisParams.middleLine['children'],
                          dia =>
                            get(dia, 'submitKey') === 'downjawMiddleLineNote'
                        ) &&
                          find(
                            diagnosisParams.middleLine['children'],
                            dia =>
                              get(dia, 'submitKey') === 'downjawMiddleLineNote'
                          )['input']
                      "
                      @change="
                        disposeNumber({
                          key: find(
                            diagnosisParams.middleLine['children'],
                            dia =>
                              get(dia, 'submitKey') === 'downjawMiddleLineNote'
                          ),
                          value: arguments[0]
                        })
                      "
                      @input="
                        disposeNumber({
                          key: find(
                            diagnosisParams.middleLine['children'],
                            dia =>
                              get(dia, 'submitKey') === 'downjawMiddleLineNote'
                          ),
                          value: arguments[0]
                        })
                      "
                      :placeholder="$t('cases.createDiagnosis.srjtsz')"
                    />
                    <span class="midcourt_line_input_mm_two c-white">mm</span>
                  </div>
                </div>
              </div>
              <span
                class="common_btn"
                :class="{
                  is_active: diagnosisParams.middleLine.key === item.key
                }"
                slot="reference"
                >{{ item.value }}</span
              >
            </el-popover>
            <div
              v-else
              class="common_btn"
              :class="
                values(diagnosisParams.middleLine).indexOf(item.key) !== -1
                  ? 'is_active'
                  : ''
              "
            >
              {{ item.value }}
            </div>
          </div>
        </div>
        <div
          class="single_tip_box"
          v-show="
            get(
              find(
                diagnosisParams['middleLine'].children,
                dia => get(dia, 'submitKey') === 'upjawMiddleLineNote'
              ),
              'value'
            ) ||
              get(
                find(
                  diagnosisParams['middleLine'].children,
                  dia => get(dia, 'submitKey') === 'downjawMiddleLineNote'
                ),
                'value'
              )
          "
        >
          <p>
            {{
              get(
                find(
                  diagnosisParams["middleLine"].children,
                  dia => get(dia, "submitKey") === "upjawMiddleLineNote"
                ),
                "value"
              )
                ? $t("cases.createImage.se") + ":"
                : ""
            }}
            {{
              get(
                find(
                  diagnosisParams["middleLine"].children,
                  dia => get(dia, "submitKey") === "upjawMiddleLineNote"
                ),
                "value"
              )
            }}
            {{
              get(
                find(
                  diagnosisParams["middleLine"].children,
                  dia => get(dia, "submitKey") === "upjawMiddleLineNote"
                ),
                "input"
              )
            }}
            {{
              get(
                find(
                  diagnosisParams["middleLine"].children,
                  dia => dia["submitKey"] === "upjawMiddleLineNote"
                ),
                "input"
              )
                ? "mm"
                : ""
            }}
          </p>
          <p>
            {{
              get(
                find(
                  diagnosisParams["middleLine"].children,
                  dia => get(dia, "submitKey") === "downjawMiddleLineNote"
                ),
                "value"
              )
                ? $t("cases.createImage.xe") + ":"
                : ""
            }}
            {{
              get(
                find(
                  diagnosisParams["middleLine"].children,
                  dia => get(dia, "submitKey") === "downjawMiddleLineNote"
                ),
                "value"
              )
            }}
            {{
              get(
                find(
                  diagnosisParams["middleLine"].children,
                  dia => get(dia, "submitKey") === "downjawMiddleLineNote"
                ),
                "input"
              )
            }}
            {{
              get(
                find(
                  diagnosisParams["middleLine"].children,
                  dia => get(dia, "submitKey") === "downjawMiddleLineNote"
                ),
                "input"
              )
                ? "mm"
                : ""
            }}
          </p>
        </div>
      </div>
      <div class="select_type" id="15">
        <p class="select_type_title mb10">
          15.{{ $t("cases.createDiagnosis.txt13") }}
          <span class="main_theme_color_999 fz14">{{
            $t("cases.createDiagnosis.babysjfas")
          }}</span>
        </p>
        <p class="problem_note" v-if="diagnosisProblem['15']">
          {{ diagnosisProblem["15"] }}
        </p>
        <IntervalCorrect
          ref="intervalCorrect"
          @updateInterval="updateInterval"
          :uploadIntervalList="uploadIntervalList"
          :readOnly="isBackDisabled"
          class="mb40"
          :commonTeeth="commonTeeth"
          productType="C"
        />
      </div>
      <div class="select_type" id="16">
        <div class="flex-y-c mb10">
          <!-- <p v-if="needRTD">
            <span class="main_theme_color_red fz14 mr4">●</span>
          </p> -->
          <p class="select_type_title mr10">
            16.{{ $t("cases.createDiagnosis.txt14") }}
          </p>
        </div>
        <p class="problem_note" v-if="diagnosisProblem['16']">
          {{ diagnosisProblem["16"] }}
        </p>
        <div :class="{ ml20: needRTD }" class="select_type">
          <div class="single_con">
            <div class="s_c_box">
              <div
                :style="lang === 'en_US' ? 'width: 1.2rem' : 'width: 0.9rem'"
                class="s_c_b_left"
              >
                {{ $t("cases.createDiagnosis.se") }}
              </div>
              <div class="s_c_b_right">
                <div class="t_b_box">
                  <p
                    :style="lang === 'en_US' ? 'width: 1.3rem' : ''"
                    class="t_b_p fz14"
                  >
                    {{ $t("cases.createDiagnosis.kk") }}
                  </p>
                  <div
                    class="common_btn"
                    style="margin-bottom: 0px"
                    @click="
                      levelOneClick({
                        addItem: {
                          key: item.key,
                          value: item.value,
                          submitKey: 'upjawExt'
                        }
                      })
                    "
                    :class="{
                      is_active: item.key === diagnosisParams.upjawExt.key
                    }"
                    v-for="(item, index) in caseOptions.choose_option"
                    :key="index"
                  >
                    {{ item.value }}
                  </div>
                </div>
                <div class="t_b_box">
                  <p
                    :style="lang === 'en_US' ? 'width: 1.3rem' : ''"
                    class="t_b_p fz14"
                  >
                    {{ $t("cases.createDiagnosis.cq") }}
                  </p>
                  <div
                    class="common_btn"
                    style="margin-bottom: 0px"
                    @click="
                      levelOneClick({
                        addItem: {
                          key: item.key,
                          value: item.value,
                          submitKey: 'upjawLip'
                        }
                      })
                    "
                    :class="{
                      is_active: item.key === diagnosisParams.upjawLip.key
                    }"
                    v-for="(item, index) in caseOptions.choose_option"
                    :key="index"
                  >
                    {{ item.value }}
                  </div>
                </div>
                <div
                  class="t_b_box"
                  v-if="caseOptions.upjaw_far && caseOptions.upjaw_far.length"
                >
                  <p
                    :style="lang === 'en_US' ? 'width: 1.3rem' : ''"
                    class="t_b_p fz14"
                  >
                    {{ $t("cases.createDiagnosis.yymry") }}
                  </p>
                  <div
                    class="common_btn"
                    style="margin-bottom: 0px"
                    @click="
                      levelOneClick({
                        addItem: {
                          key: item.key,
                          value: item.value,
                          submitKey: 'upjawFar'
                        },
                        radio: false
                      })
                    "
                    :class="
                      values(
                        map(diagnosisParams.upjawFar.children, it => it.key)
                      ).indexOf(item.key) !== -1
                        ? 'is_active'
                        : ''
                    "
                    v-for="(item, index) in caseOptions.upjaw_far"
                    :key="index"
                  >
                    {{ item.value }}
                  </div>
                  <p
                    :class="{
                      main_theme_color_red:
                        values(
                          map(diagnosisParams.upjawFar.children, it => it.key)
                        ).includes('left_up') ||
                        values(
                          map(diagnosisParams.upjawFar.children, it => it.key)
                        ).includes('right_up')
                    }"
                    class="t_b_tip"
                  >
                    {{ $t("cases.createDiagnosis.zyxgbx") }}
                  </p>
                </div>
              </div>
            </div>
            <div class="s_c_box">
              <div
                :style="lang === 'en_US' ? 'width: 1.2rem' : 'width: 0.9rem'"
                class="s_c_b_left"
              >
                {{ $t("cases.createDiagnosis.xe") }}
              </div>
              <div class="s_c_b_right">
                <div class="t_b_box">
                  <p
                    :style="lang === 'en_US' ? 'width: 1.3rem' : ''"
                    class="t_b_p fz14"
                  >
                    {{ $t("cases.createDiagnosis.kk") }}
                  </p>
                  <div
                    style="margin-bottom: 0px"
                    class="common_btn"
                    @click="
                      levelOneClick({
                        addItem: {
                          key: item.key,
                          value: item.value,
                          submitKey: 'downjawExt'
                        }
                      })
                    "
                    :class="{
                      is_active: item.key === diagnosisParams.downjawExt.key
                    }"
                    v-for="item in caseOptions.choose_option"
                    :key="`${item.key}_downjawExt`"
                    :data-key="item.key"
                  >
                    {{ item.value }}
                  </div>
                </div>
                <div class="t_b_box">
                  <p
                    :style="lang === 'en_US' ? 'width: 1.3rem' : ''"
                    class="t_b_p fz14"
                  >
                    {{ $t("cases.createDiagnosis.cq") }}
                  </p>
                  <div
                    style="margin-bottom: 0px"
                    class="common_btn"
                    @click="
                      levelOneClick({
                        addItem: {
                          key: item.key,
                          value: item.value,
                          submitKey: 'downjawLip'
                        }
                      })
                    "
                    :class="{
                      is_active: item.key === diagnosisParams.downjawLip.key
                    }"
                    v-for="(item, index) in caseOptions.choose_option"
                    :key="index"
                  >
                    {{ item.value }}
                  </div>
                </div>
                <div class="t_b_box">
                  <p
                    :style="lang === 'en_US' ? 'width: 1.3rem' : ''"
                    class="t_b_p fz14"
                  >
                    {{ $t("cases.createDiagnosis.yymry") }}
                  </p>
                  <div
                    class="common_btn"
                    style="margin-bottom: 0px"
                    @click="
                      levelOneClick({
                        addItem: {
                          key: item.key,
                          value: item.value,
                          submitKey: 'downjawFar'
                        },
                        radio: false
                      })
                    "
                    :class="{
                      is_active: values(
                        map(diagnosisParams.downjawFar.children, it => it.key)
                      ).includes(item.key)
                    }"
                    v-for="(item, index) in caseOptions.downjaw_far"
                    :key="index"
                  >
                    {{ item.value }}
                  </div>
                  <p
                    :class="{
                      main_theme_color_red:
                        values(
                          map(diagnosisParams.downjawFar.children, it => it.key)
                        ).includes('left_down') ||
                        values(
                          map(diagnosisParams.downjawFar.children, it => it.key)
                        ).includes('right_down')
                    }"
                    class="t_b_tip"
                  >
                    {{ $t("cases.createDiagnosis.zyxgbx") }}
                  </p>
                </div>
              </div>
            </div>
            <div class="s_c_box">
              <div style="width: 0.9rem" class="s_c_b_left">
                {{ $t("cases.createDiagnosis.lmqz") }}
              </div>
              <div class="s_c_b_right">
                <div class="t_b_box flex-y-c">
                  <span
                    class="common_btn"
                    @click="
                      levelOneClick({
                        addItem: {
                          key: item.key,
                          value: item.value,
                          submitKey: 'crowdCorrectSide'
                        }
                      })
                    "
                    :class="{
                      is_active:
                        item.key === diagnosisParams.crowdCorrectSide.key
                    }"
                    v-for="(item, index) in caseOptions.choose_option_b"
                    :key="index"
                    >{{ item.value }}</span
                  >
                  <p class="fz14rem main_theme_color_999 ml10">
                    {{ $t("cases.createDiagnosis.zyxry") }}
                  </p>
                </div>
              </div>
            </div>
            <div>
              <AdjoinDislodge
                ref="adjoinDislodge"
                class="mb20"
                :productType="details.cureType"
                :crowdTeethList="crowdTeethList"
                v-show="
                  diagnosisParams.crowdCorrectSide.key &&
                    diagnosisParams.crowdCorrectSide.key === 'chooseable'
                "
              />
            </div>
            <div
              :style="lang === 'en_US' ? 'margin-left: 2.39rem' : ''"
              class="t_b_box"
            >
              <div
                class="common_btn"
                @click="
                  levelOneClick({
                    addItem: {
                      key: 'after_design',
                      value: $t('cases.createDiagnosis.threedsjhqd'),
                      submitKey: 'crowdCorrect'
                    }
                  })
                "
                :class="{
                  is_active: 'after_design' === diagnosisParams.crowdCorrect.key
                }"
              >
                {{ $t("cases.createDiagnosis.sjhzqr") }}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="select_type" id="17">
        <div class="flex-y-c mb2rem">
          <p class="select_type_title mr10">
            17.{{ $t("cases.createDiagnosis.sfxyfzmy") }}
          </p>
        </div>
        <p class="problem_note" v-if="diagnosisProblem['17']">
          {{ diagnosisProblem["17"] }}
        </p>
        <div class="flex-y-c">
          <div
            class="common_btn"
            @click="
              levelOneClick({
                addItem: {
                  key: item.key,
                  value: item.value,
                  submitKey: 'eruptedCompensate'
                }
              })
            "
            :class="{
              is_active: item.key === diagnosisParams.eruptedCompensate.key
            }"
            v-for="(item, index) in caseOptions['underjaw_abnormal_flag']"
            :key="index"
          >
            {{ item.value }}
          </div>
        </div>
      </div>
      <div class="select_type" id="18">
        <div class="flex-y-c mb10">
          <p><span class="main_theme_color_red fz14 mr4">●</span></p>
          <p class="select_type_title mr10">
            18.{{ $t("cases.createDiagnosis.sfxybdjzq") }}
          </p>
          <el-tooltip
            class="item"
            effect="dark"
            :content="$t('cases.createDiagnosis.sfxybdjzqts')"
            placement="right"
          >
            <img
              style="position: relative; top: -5px"
              src="../../../common/imgs/case/case_dia_query.png"
              alt=""
            />
          </el-tooltip>
        </div>
        <p class="problem_note" v-if="diagnosisProblem['18']">
          {{ diagnosisProblem["18"] }}
        </p>
        <div class="flex-y-c">
          <div
            class="common_btn"
            @click="
              levelOneClick({
                addItem: {
                  key: item.key,
                  value: item.value,
                  submitKey: 'needPassiveAppliance'
                }
              })
            "
            :class="{
              is_active: item.key === diagnosisParams.needPassiveAppliance.key,
              // disabled_btn: item.key === 'true' && isDisabledPassivity
            }"
            v-for="(item, index) in caseOptions['underjaw_abnormal_flag']"
            :key="index"
          >
            {{ item.value }}
          </div>
        </div>
        <div
          class="remark_box"
          v-show="diagnosisParams.needPassiveAppliance.key === 'true'"
        >
          <el-input
            :disabled="isBackDisabled"
            class="tex_b_a"
            :autosize="{ minRows: 4, maxRows: 6 }"
            maxlength="2000"
            type="textarea"
            show-word-limit
            v-model="diagnosisParams.needPassiveApplianceNote"
            :placeholder="$t('cases.createDiagnosis.qtjdxjzq')"
          ></el-input>
        </div>
      </div>
      <div class="select_type" id="19">
        <p class="select_type_title mb2rem">
          19.{{ $t("cases.createDiagnosis.txt17") }}
        </p>
        <p class="problem_note" v-if="diagnosisProblem['19']">
          {{ diagnosisProblem["19"] }}
        </p>

        <div class="remark_box">
          <el-input
            :disabled="isBackDisabled"
            class="tex_b_a"
            :autosize="{ minRows: 4, maxRows: 6 }"
            maxlength="2000"
            type="textarea"
            show-word-limit
            v-model="diagnosisParams.specialInstructions"
            :placeholder="$t('common.common.qsrnr')"
          ></el-input>
        </div>
      </div>
    </div>
    <full-loading v-show="isLoading" />
  </div>
</template>

<script>
import {
  extend,
  isArray,
  each,
  values,
  get,
  map,
  filter,
  keys,
  isObject,
  find,
  pickBy,
  flattenDeep,
  split,
  isNumber,
  some
} from "lodash";
import { getDictAllList } from "common/api/public";
import ChildrenTeeth from "../../../components/treatment-table/children-teeth/index";
import IntervalCorrect from "../../../components/treatment-table/interval-correct/index";
import AdjoinDislodge from "../children-adjoin-dislodge/index";
import { notifyMsg } from "common/js/util";
import {
  createCurePlan,
  editCurePlan,
  detailCurePlan,
  cureProblemList
} from "common/api/cases";
import $ from "jquery";
import FullLoading from "components/full-loading/full-loading";
import { toNumber } from "../../../common/js/Fn";
import { changeRouteQuery } from "../../../common/js/Fn";
import { mapState } from "vuex";
import { babyDefaultBright } from "../common";

export default {
  data() {
    return {
      isLoading: false,
      caseOptions: {},
      rightHeight: "",
      diaKeyObj: {},
      intervalList: [],
      adjoinList: [], // 接收临面去釉
      crowdTeethList: [], //更新临面去釉
      intervalList: [],
      uploadIntervalList: [],
      diagnosisParams: {
        toothProblem: {},
        jawProblem: {},
        toothOtherProblem: "",

        toothProblemTwo: {},
        jawProblemTwo: {},
        toothOtherProblemTwo: "",

        // sagittalMaxillomandibularClassification: {},
        // clinicInfo: {},
        // clinicOtherInfo: '',

        badHabits: {},
        badHabitsNote: "",

        toCorrectToothJaw: {},

        sagittalRelation: {},
        sagittalRelationMolar: {},
        leftSagittalRelationNote: "",
        rightSagittalRelationNote: "",

        upHorizontalRelationship: "",
        downHorizontalRelationship: "",

        nonMoveableTeeth: "",
        notAvailableAttachments: "",

        coverInfo: {},

        overbiteInfo: {},
        overbiteInfoOpen: "",
        overbiteInfoDeep: "",
        overbiteInfoExtrudeAnterior: {},
        overbiteOtherInfo: "",
        overbiteInfoNote: "",

        frontToothInfo: {},

        backToothInfo: {},

        middleLine: {},

        upjawLip: {},
        upjawExt: {},
        upjawFar: {},
        downjawExt: {},
        downjawLip: {},
        downjawFar: {},
        crowdCorrect: {},
        crowdCorrectSide: {},

        needPassiveAppliance: {
          key:'true'
        },
        needPassiveApplianceNote: "",

        toothExtraction: {},
        eruptedCompensate: {},
        eruptedCompensateNote: "",

        specialInstructions: ""
      },
      commonTeeth: babyDefaultBright,
      lang: localStorage.getItem("user_lang") || "zh_CN",
      selectLeftKey: "1",
      leftList: [
        {
          title: this.$t("cases.createDiagnosis.txt1"),
          isShow: true
        },
        {
          title: this.$t("cases.createDiagnosis.zyjzmb"),
          isShow: true
        },
        // {
        //   title: this.$t("cases.createDiagnosis.txt2"),
        //   isShow: true,
        // },
        {
          title: this.$t("cases.createDiagnosis.blxg"),
          isShow: true
        },
        {
          title: this.$t("cases.createDiagnosis.txt3"),
          isShow: true
        },
        {
          title: this.$t("cases.createDiagnosis.txt4"),
          isShow: true
        },
        {
          title: this.$t("cases.createDiagnosis.txt5"),
          isShow: true
        },
        {
          title: this.$t("cases.createDiagnosis.txt6"),
          isShow: true
        },
        {
          title: this.$t("cases.createDiagnosis.txt7"),
          isShow: true
        },
        {
          title: this.$t("cases.createDiagnosis.hxgx"),
          isShow: true
        },
        {
          title: this.$t("cases.createDiagnosis.txt8"),
          isShow: true
        },
        {
          title: this.$t("cases.createDiagnosis.txt9"),
          isShow: true
        },
        {
          title: this.$t("cases.createDiagnosis.qyfh"),
          isShow: true
        },
        {
          title: this.$t("cases.createDiagnosis.txt11"),
          isShow: true
        },
        {
          title: this.$t("cases.createDiagnosis.txt12"),
          isShow: true
        },
        {
          title: this.$t("cases.createDiagnosis.txt13"),
          isShow: true
        },
        {
          title: this.$t("cases.createDiagnosis.txt14"),
          isShow: true
        },
        {
          title: this.$t("cases.createDiagnosis.sfxyfzmy"),
          isShow: true
        },
        {
          title: this.$t("cases.createDiagnosis.sfxybdjzq"),
          isShow: true
        },
        {
          title: this.$t("cases.createDiagnosis.txt17"),
          isShow: true
        }
      ],
      leftTopObj: {},
      problemList: [],
      isScroll: true,
      needRTD: sessionStorage.getItem("needRTD") === "1",
      diagnosisProblem: {},
      details: {},
      isResponse: 0,
      isProductErr: false
    };
  },
  computed: {
    ...mapState({
      language: state => state.language
    }),
    queryCaseId() {
      return this.$route.query.caseId;
    },
    queryCurePlanId() {
      return this.$route.query.curePlanId;
    },
    isBackDisabled() {
      return false;
      return get(this.details, "status") === "to_resubmit" && this.isProductErr;
    },
    isDisabledPassivity() {
      return ["upjaw", "downjaw"].includes(
        this.diagnosisParams.toCorrectToothJaw.key
      );
    }
  },
  components: {
    ChildrenTeeth,
    IntervalCorrect,
    FullLoading,
    AdjoinDislodge
  },
  methods: {
    values,
    get,
    map,
    find,
    keys,
    filter,
    updateInterval(list) {
      this.intervalList = list;
    },
    overbiteText() {
      let list = this.diagnosisParams["overbiteInfo"]["children"];
      let ls = map(list, (item, index) => {
        if (item.children) {
          return (
            item.value +
            ([
              "press_front_rise_back",
              "press_front",
              "extend_front_tooth"
            ].includes(item.key)
              ? `${(item.children.length ? "-" : "") +
                  map(item.children, it => it.value).join("、")}`
              : "")
          );
        }
        return item.value;
      });
      return ls.join(",");
    },
    disposeNumber({ key, value }) {
      if (value === "") return (key.input = "");
      key.input =
        isNumber(toNumber(value)) && toNumber(value) <= 10
          ? toNumber(value)
          : "";
    },
    selectLeft(key, left) {
      this.isScroll = false;
      let leftStr;
      if (left === "left") {
        leftStr = `${+key + 1}`;
        document.documentElement.scrollTop = document.getElementById(
          leftStr
        ).offsetTop-20;
      } else {
        leftStr = `${key}`;
        document.documentElement.scrollTop = document.getElementById(
          key
        ).offsetTop-20;
      }
      this.selectLeftKey = "d" + leftStr;
      this.leftHandleScroll(document.documentElement.scrollTop / 3);
      setTimeout(() => {
        this.isScroll = true;
      }, 1000);
    },
    leftHandleScroll(val) {
      let div = this.$refs.scrollbar && this.$refs.scrollbar.$refs["wrap"];
      if (val < 0) return;
      div.scrollTop = val;
    },
    updateTeeth(data) {
      this.commonTeeth = data;
    },
    levelThreeClick({ wrapFiled, parentKey, addItem }) {
      const val = addItem.key;
      if (!val || this.isBackDisabled) return;
      each(this.diagnosisParams[wrapFiled]["children"], (item, index) => {
        if (item.key === parentKey) {
          let list = this.diagnosisParams[wrapFiled]["children"][index]
            .children;
          if (isArray(list)) {
            let keyList = map(
              this.diagnosisParams[wrapFiled]["children"][index].children,
              item => item.key
            );
            if (isArray(keyList) && keyList.indexOf(val) !== -1) {
              return this.diagnosisParams[wrapFiled]["children"][
                index
              ].children.splice(keyList.indexOf(val), 1);
            }
            this.diagnosisParams[wrapFiled]["children"][index].children.push(
              addItem
            );
          } else {
            this.$set(
              this.diagnosisParams[wrapFiled]["children"][index],
              "children",
              []
            );
            this.diagnosisParams[wrapFiled]["children"][index].children.push(
              addItem
            );
          }
        }
      });
    },
    levelTowClick({
      wrapFiled,
      radio = false,
      addItem,
      multipleRadio = false,
      multipleRadios = false,
      oneOfMore = false
    }) {
      // radio：是否单选,  multipleRadio, multipleRadios：1对1   oneOfMore: 1对多  addItem: 想要添加的数据
      const val = addItem.key;
      if (!val || this.isBackDisabled) return;
      if (radio) {
        if (!this.diagnosisParams[wrapFiled]["children"]) {
          this.$set(this.diagnosisParams[wrapFiled], "children", []);
        }
        let index;
        each(this.diagnosisParams[wrapFiled]["children"], (dia, dix) => {
          if (dia.submitKey === addItem.submitKey) {
            index = dix;
          }
        });
        if (index !== undefined) {
          return this.diagnosisParams[wrapFiled]["children"].splice(
            index,
            1,
            addItem
          );
        }
        this.diagnosisParams[wrapFiled]["children"].push(addItem);
      } else {
        let keyList = map(
          this.diagnosisParams[wrapFiled]["children"],
          item => item.key
        );
        if (isArray(this.diagnosisParams[wrapFiled]["children"])) {
          //1对1
          if (multipleRadio) {
            if (multipleRadio.indexOf(val) !== -1) {
              let list = filter(multipleRadio, it => it !== val);
              each(keyList, (item, index) => {
                list.indexOf(item) !== -1 &&
                  this.diagnosisParams[wrapFiled]["children"].splice(index, 1);
              });
            }
          }
          if (multipleRadios) {
            if (multipleRadios.indexOf(val) !== -1) {
              let list = filter(multipleRadios, it => it !== val);
              each(keyList, (item, index) => {
                list.indexOf(item) !== -1 &&
                  this.diagnosisParams[wrapFiled]["children"].splice(index, 1);
              });
            }
          }

          //1对多互斥
          if (oneOfMore) {
            let { one, more } = oneOfMore;
            if (val === one) {
              this.diagnosisParams[wrapFiled]["children"] = filter(
                this.diagnosisParams[wrapFiled]["children"],
                it => more.indexOf(it.key) === -1
              );
            }
            if (more.indexOf(val) !== -1) {
              this.diagnosisParams[wrapFiled]["children"] = filter(
                this.diagnosisParams[wrapFiled]["children"],
                it => it.key !== one
              );
            }
          }

          if (keyList.indexOf(val) !== -1) {
            return this.diagnosisParams[wrapFiled]["children"].splice(
              keyList.indexOf(val),
              1
            );
          }
          this.diagnosisParams[wrapFiled]["children"].push(addItem);
        } else {
          this.$set(this.diagnosisParams[wrapFiled], "children", []);
          this.diagnosisParams[wrapFiled]["children"].push(addItem);
        }
      }
    },
    levelOneClick({
      addItem,
      multipleRadio,
      radio = true,
      oneOfMore = false,
      noClear = false
    }) {
      if (this.isBackDisabled) return;
      let { key, submitKey } = addItem;
      // if (
      //   ["upjaw", "downjaw"].includes(key) &&
      //   submitKey === "toCorrectToothJaw"
      // ) {
      //   if (this.diagnosisParams.needPassiveAppliance.key !== "false") {
      //     this.diagnosisParams.needPassiveAppliance = {
      //       submitKey: "needPassiveAppliance",
      //       key: "false",
      //       value: this.lang === "zh_CN" ? "否" : "No"
      //     };
      //     this.diagnosisParams.needPassiveApplianceNote = "";
      //   }
      // }
      let frontToothInDefault = () => {
        if (!this.diagnosisParams[submitKey].children) {
          this.$set(this.diagnosisParams[submitKey], "children", []);
        }
        let frontToothInfoKetList = map(
          this.diagnosisParams[submitKey].children,
          item => item.key
        );
        let frontToothInfoValue =
          !frontToothInfoKetList.includes("front_tooth_back") &&
          !frontToothInfoKetList.includes("front_tooth_unback");
        if (key === "open_bite_correction" && frontToothInfoValue) {
          let obj = {
            key: "front_tooth_unback",
            submitKey: "correctFrontReverse",
            value:
              this.lang === "zh_CN"
                ? "前牙不可后退至切对切"
                : "Anterior Edge to Edge Occlusion cannot be Achieved"
          };
          this.diagnosisParams[submitKey]["children"].push(obj);
        }
      };
      // if (
      //   submitKey === "needPassiveAppliance" &&
      //   key === "true" &&
      //   this.isDisabledPassivity
      // )
      //   return;

      //输入框的清空
      let inputKeyObj = {
        toothProblem: "toothOtherProblem",
        toothProblemTwo: "toothOtherProblemTwo",
        clinicInfo: "clinicOtherInfo",
        badHabits: "badHabitsNote",
        needPassiveAppliance: "needPassiveApplianceNote",
        eruptedCompensate: "eruptedCompensateNote"
      };
      let inputKeyList = keys(inputKeyObj);
      if (
        inputKeyList.includes(submitKey) &&
        ["other", "false"].includes(key)
      ) {
        this.$set(this.diagnosisParams, inputKeyObj[submitKey], "");
      }

      //组件值清空
      let componentsObj = {
        crowdCorrectSide:
          this.$refs["adjoinDislodge"] &&
          this.$refs["adjoinDislodge"].clearTeeth,
        toothExtraction:
          this.$refs["teethComponents"] &&
          this.$refs["teethComponents"].clearTeethType,
        notAvailableAttachments:
          this.$refs["teethComponents"] &&
          this.$refs["teethComponents"].clearTeethType,
        nonMoveableTeeth:
          this.$refs["teethComponents"] &&
          this.$refs["teethComponents"].clearTeethType
      };
      let componentsKeyList = keys(componentsObj);
      if (
        componentsKeyList.includes(submitKey) &&
        ["unchooseable", "tooth_extraction_options_nono", "false"].includes(key)
      ) {
        let type = {
          toothExtraction: 4,
          notAvailableAttachments: 3,
          nonMoveableTeeth: 2
        }[submitKey];
        componentsObj[submitKey] && componentsObj[submitKey](type ? type : "");
      }

      if (
        submitKey === "sagittalRelation" &&
        ["mandibular_forward_movement", "maintain"].includes(key)
      ) {
        this.$set(this.diagnosisParams, "sagittalRelationMolar", {});
      }

      let optionList = [
        "upjawExt",
        "upjawLip",
        "upjawFar",
        "downjawExt",
        "downjawLip",
        "downjawFar",
        "crowdCorrectSide"
      ];
      if (optionList.includes(submitKey)) {
        this.$set(this.diagnosisParams, "crowdCorrect", {});
      }

      // 单选
      if (radio) {
        if (this.diagnosisParams[submitKey].key === key && !noClear) {
          return (this.diagnosisParams[submitKey] = {});
        }
        if (this.diagnosisParams[submitKey].key === key) return;
        this.$set(this.diagnosisParams, submitKey, addItem);

        if (submitKey === "frontToothInfo") {
          frontToothInDefault();
        }

        if (key === "after_design") {
          this.$refs["adjoinDislodge"] &&
            this.$refs["adjoinDislodge"].clearTeeth("");
          each(optionList, item => {
            this.$set(this.diagnosisParams, item, {});
          });
        }

        //拟矫治牙颌 默认选中第一项
        if (
          submitKey === "toCorrectToothJaw" &&
          ["upjaw", "downjaw"].includes(key)
        ) {
          let list =
            key === "upjaw"
              ? this.caseOptions["to_correct_tooth_upjaw_b"]
              : this.caseOptions["to_correct_tooth_downjaw_b"];
          let currentObj = this.diagnosisParams.toCorrectToothJaw;
          let obj = extend({}, currentObj, {
            children: [
              {
                key: list[0].key,
                value: list[0].value,
                submitKey: {
                  upjaw: "toCorrectToothUpjaw",
                  downjaw: "toCorrectToothDownjaw"
                }[key]
              }
            ]
          });
          this.$set(this.diagnosisParams, "toCorrectToothJaw", obj);
        }
      } else {
        if (isArray(this.diagnosisParams[submitKey]["children"])) {
          let itx;
          each(this.diagnosisParams[submitKey]["children"], (item, index) => {
            if (item.key === key) {
              itx = index;
            }
          });
          if (itx !== undefined) {
            return this.diagnosisParams[submitKey]["children"].splice(itx, 1);
          }

          //1对多互斥
          if (oneOfMore) {
            let { one, more } = oneOfMore;
            if (key === one) {
              this.diagnosisParams[submitKey]["children"] = [];
              this.diagnosisParams[submitKey]["children"] = filter(
                this.diagnosisParams[submitKey]["children"],
                it => more.indexOf(it.key) === -1
              );
            }
            if (more.indexOf(key) !== -1) {
              this.diagnosisParams[submitKey]["children"] = filter(
                this.diagnosisParams[submitKey]["children"],
                it => it.key !== one
              );
            }
          }

          this.diagnosisParams[submitKey]["children"].push(addItem);
        } else {
          this.$set(this.diagnosisParams[submitKey], "children", []);
          this.diagnosisParams[submitKey]["children"].push(addItem);
        }
      }
    },
    handleScroll() {
      if (this.isScroll) {
        this.isScroll = false;
        return new Promise(resolve => {
          return setTimeout(() => {
            const TOP = document.documentElement.scrollTop;
            let domList = document.getElementsByClassName("select_type");
            let obj = {};
            each(domList, item => {
              obj[`d${item.id}`] = item.offsetTop - 25;
            });
            let {
              d1,
              d2,
              d3,
              d4,
              d5,
              d6,
              d7,
              d8,
              d9,
              d10,
              d11,
              d12,
              d13,
              d14,
              d15,
              d16,
              d17,
              d18,
              d19
            } = obj;
            let current = {
              [TOP <= d1]: "d1",
              [TOP <= d2 && TOP >= d1]: "d1",
              [TOP <= d3 && TOP >= d2]: "d2",
              [TOP <= d4 && TOP >= d3]: "d3",
              [TOP <= d5 && TOP >= d4]: "d4",
              [TOP <= d6 && TOP >= d5]: "d5",
              [TOP <= d7 && TOP >= d6]: "d6",
              [TOP <= d8 && TOP >= d7]: "d7",
              [TOP <= d9 && TOP >= d8]: "d8",
              [TOP <= d10 && TOP >= d9]: "d9",
              [TOP <= d11 && TOP >= d10]: "d10",
              [TOP <= d12 && TOP >= d11]: "d11",
              [TOP <= d13 && TOP >= d12]: "d12",
              [TOP <= d14 && TOP >= d13]: "d13",
              [TOP <= d15 && TOP >= d14]: "d14",
              [TOP <= d16 && TOP >= d15]: "d15",
              [TOP <= d17 && TOP >= d16]: "d16",
              [TOP <= d19 && TOP >= d16]: "d19"
            };
            this.selectLeftKey = current[true] || "d19";
            this.leftHandleScroll(Math.floor(TOP / 3));
            this.isScroll = true;
            return resolve();
          }, 500);
        });
      }
    },
    getCaseOptions() {
      let id = this.queryCurePlanId;
      this.isLoading = true;
      getDictAllList([
        // 获取病例选项的值
        "tooth_problem_c",
        "jaw_problem_b",
        "tooth_problem_two_c",
        "jaw_problem_two",
        "down_horizontal_expansion",
        "bad_habits",
        "sagittal_relation_c",
        "improve_molar",
        "to_correct_tooth_jaw",
        "non_moveable_teeth",
        "not_available_for_attachments",
        "to_correct_tooth_upjaw_b",
        "to_correct_tooth_downjaw_b",
        "up_horizontal_relationship",
        "down_horizontal_relationship",
        "up_horizontal_expansion",
        "underjaw_abnormal_flag",
        "cover_info",
        "overbite_info",
        "correct_open_b",
        "correct_deep_cover_b",
        "updown_jaw",
        "front_tooth_info_b",
        "correct_front_reverse",
        "correct_front_reverse_two",
        "back_tooth_info_correct_b",
        "back_tooth_info",
        "middle_line",
        "improve_middle_move",
        "choose_option",
        "choose_option_b",
        "upjaw_far",
        "downjaw_far",
        "erupted_compensate",
        // 'sagittal_maxillomandibular_classification',
        // 'clinic_info',
        "to_correct_tooth_downjaw",
        "tooth_extraction_options"
      ])
        .then(data => {
          this.isLoading = false;
          let dictObj = {},
            diaKeyObj = {};
          data.forEach(item => {
            if (!dictObj[item.parentKey]) {
              dictObj[item.parentKey] = [item];
            } else {
              dictObj[item.parentKey].push(item);
            }
            diaKeyObj[item.key] = item.value;
          });
          this.diaKeyObj = diaKeyObj;
          this.caseOptions = dictObj;
          this.diagnosisParams.caseId = this.queryCaseId || "";
          if (id) {
            this.diagnosisParams.id = id;
            detailCurePlan(id).then(obj => {
              this.details = obj;
              this.initParams(obj).then(() => {
                setTimeout(() => {
                  this.isResponse = 0;
                }, 800);
              });
            });
            cureProblemList({
              caseId: this.diagnosisParams.caseId,
              curePlanId: id,
              backNode: "to_submit"
            }).then(data => {
              const diagnosisProblem = {};
              let gnType = "groupNo";
              data.forEach(item => {
                diagnosisProblem[item[gnType]] = item.content;
              });
              this.problemList = data.filter(
                item => +item[gnType] < 20 && item[gnType] > 0
              );

              this.isProductErr = this.problemList.length
                ? false
                : !some(data, item => +item.groupNo === 0);

              this.diagnosisProblem = diagnosisProblem;
              setTimeout(() => {
                if ($(".dia_problem").length > 0) {
                  this.pEleHeight = $(".dia_problem").height() + 30;
                }
              }, 100);
            });
          } else {
            setTimeout(() => {
              this.isResponse = 0;
            }, 800);
          }
        })
        .catch(() => {
          this.isLoading = false;
        });
    },
    initOption({ type, submitKey, addItem }) {
      //预览提交的返回
      const diagnsisLeftKey = sessionStorage.getItem("diagnosisKey") || "1";
      this.selectLeft(
        diagnsisLeftKey !== "undefined" ? diagnsisLeftKey : 1,
        "init"
      );
      // type: 1 一级对象, 2 数组
      let data = this.details;
      let valueData = this.diaKeyObj;
      let dia = this.diagnosisParams;
      if (data[submitKey]) {
        if (type === 1) {
          dia[submitKey] = {
            submitKey,
            key: data[submitKey],
            value: valueData[data[submitKey]]
          };
        } else if (type === 2) {
          dia[submitKey] = {
            children: map(
              data[submitKey] && data[submitKey].split(","),
              item => {
                return { key: item, value: valueData[item], submitKey };
              }
            )
          };
        } else if (type === 3) {
          this.$set(this.diagnosisParams, submitKey, addItem);
        }
      }
    },
    initParams(data) {
      let imgObj = pickBy({
        sideFacePicture: data.sideFacePicture, // 侧面像
        frontFacePicture: data.frontFacePicture, // 正面像
        smilePicture: data.smilePicture, // 微笑像
        upToothPicture: data.upToothPicture, // 上牙列像
        downToothPicture: data.downToothPicture, // 下牙列像
        mouthRightPicture: data.mouthRightPicture, // 口内右侧位像
        mouthFrontPicture: data.mouthFrontPicture, // 口内正位像
        mouthLeftPicture: data.mouthLeftPicture, // 口内左侧位像
        xrayCurvePicture: data.xrayCurvePicture, // 全颌曲面断层片
        xraySidePicture: data.xraySidePicture, // 头颅侧位定位片
        otherPicture: data.otherPicture, // 其他影像资料
        anteriorOverjetPicture: data.anteriorOverjetPicture //前牙覆盖像
      });
      extend(this.diagnosisParams, imgObj);

      this.diagnosisParams.digitalModelFile = data.digitalModelFile;
      this.diagnosisParams.siliconeRubberModel = data.siliconeRubberModel; // 硅橡胶模型

      let dia = this.diagnosisParams;
      let valueData = this.diaKeyObj;

      if (
        isArray(this.toothInfoList) &&
        !this.toothInfoList.length &&
        this.$route.query.productType === "C"
      ) {
        this.commonTeeth = babyDefaultBright;
      }

      let noMoveList = filter(data.toothInfoList, item => item.type === "2");
      let noDesignList = filter(data.toothInfoList, item => item.type === "3");
      let removeList = filter(data.toothInfoList, item => item.type === "4");
      let mainList = filter(data.toothInfoList, item => item.type === "8");
      let untreatedList = [...noMoveList, ...noDesignList, ...removeList];
      if (untreatedList.length) {
        let teethTypeObj = {
          2: "noMove",
          3: "noDesign",
          4: "remove"
        };
        let list = filter(
          map(mainList, item => {
            let obj;
            each(untreatedList, it => {
              if (item.number === it.number && !item.nextNumber) {
                obj = extend({}, item, obj, {
                  [teethTypeObj[it.type]]: teethTypeObj[it.type]
                });
              }
            });
            return obj ? obj : item;
          })
        );
        this.commonTeeth = list.length < 1 ? babyDefaultBright : list;
      } else {
        if (data.toothInfoList) {
          this.commonTeeth =
            filter(data.toothInfoList, item => !item.nextNumber) ||
            babyDefaultBright;
        } else {
          this.commonTeeth = [];
        }
      }
      // 邻面去釉
      this.crowdTeethList = filter(
        data.toothInfoList,
        item => item.type === "9"
      );

      //间隙的矫治
      this.uploadIntervalList = filter(
        data.toothInfoList,
        item => item.type === "5"
      );

      //1
      this.initOption({ type: 2, submitKey: "toothProblem" });
      dia.toothOtherProblem = data.toothOtherProblem;
      this.initOption({ type: 1, submitKey: "jawProblem" });

      //2
      this.initOption({ type: 2, submitKey: "toothProblemTwo" });
      this.initOption({ type: 1, submitKey: "jawProblemTwo" });
      dia.toothOtherProblemTwo = data.toothOtherProblemTwo;

      //3 新版治疗表删除，暂先注释
      // this.initOption({type: 2, submitKey: 'clinicInfo'});
      // this.initOption({type: 1, submitKey: 'sagittalMaxillomandibularClassification'});
      // dia.clinicOtherInfo = data.clinicOtherInfo;

      //4
      this.initOption({ type: 1, submitKey: "badHabits" });
      dia.badHabitsNote = data.badHabitsNote;

      //5  toCorrectToothJaw
      if (data["toCorrectToothJaw"]) {
        let isShowTowKey =
          data.toCorrectToothUpjaw || data.toCorrectToothDownjaw;
        let twoKeyList = ["upjaw", "downjaw"].includes(data.toCorrectToothJaw);
        let twoSubmitKey =
          isShowTowKey && twoKeyList
            ? data.toCorrectToothUpjaw
              ? "toCorrectToothUpjaw"
              : "toCorrectToothDownjaw"
            : "";
        let list;
        if (twoSubmitKey) {
          list = map(
            data[twoSubmitKey] && data[twoSubmitKey].split(","),
            item => {
              return pickBy({
                key: item,
                value: valueData[item],
                submitKey: twoSubmitKey
              });
            }
          );
        }
        let addItem = pickBy({
          submitKey: "toCorrectToothJaw",
          key: data["toCorrectToothJaw"],
          value: valueData[data["toCorrectToothJaw"]],
          children: isArray(list) && list.length ? list : ""
        });
        this.initOption({ type: 3, submitKey: "toCorrectToothJaw", addItem });
      }

      if (data["nonMoveableTeeth"]) {
        this.initOption({ type: 1, submitKey: "nonMoveableTeeth" });
      }

      if (data["notAvailableAttachments"]) {
        this.initOption({ type: 1, submitKey: "notAvailableAttachments" });
      }

      // 8
      if (data["sagittalRelation"]) {
        //leftSagittalRelation rightSagittalRelation
        let addItem = {
          submitKey: "sagittalRelation",
          value: valueData[data["sagittalRelation"]],
          key: data["sagittalRelation"]
        };
        this.initOption({ type: 3, submitKey: "sagittalRelation", addItem });
      }

      if (data["sagittalRelationMolar"]) {
        let addList;
        let list = data["sagittalRelationMolar"].split(",");
        if (list.length) {
          addList = map(list, item => {
            return {
              key: item,
              value: {
                left: this.$t("cases.createDiagnosis.zc"),
                right: this.$t("cases.createDiagnosis.yc")
              }[item],
              input:
                data[
                  {
                    left: "leftSagittalRelationMolarNote",
                    right: "rightSagittalRelationMolarNote"
                  }[item]
                ],
              inputKey: {
                left: "leftSagittalRelationMolarNote",
                right: "rightSagittalRelationMolarNote"
              }[item]
            };
          });
        }
        let addItem = pickBy({
          children: isArray(addList) && addList.length ? addList : ""
        });
        this.initOption({
          type: 3,
          submitKey: "sagittalRelationMolar",
          addItem
        });
      }

      //9
      if (data["upHorizontalRelationship"]) {
        let addList;
        addList = [
          pickBy({
            key: data["upHorizontalRelationshipNote"],
            value: valueData[data["upHorizontalRelationshipNote"]],
            submitKey: "upHorizontalRelationshipNote",
            input:
              data["upHorizontalRelationshipNote"] === "specify"
                ? data["upHorizontalRelationshipValue"]
                : "",
            inputKey:
              data["upHorizontalRelationshipNote"] === "specify"
                ? "upHorizontalRelationshipValue"
                : ""
          })
        ];
        let addItem = pickBy({
          children: isArray(addList) && addList.length ? addList : "",
          submitKey: "upHorizontalRelationship",
          value: valueData[data["upHorizontalRelationship"]],
          key: data["upHorizontalRelationship"]
        });
        this.initOption({
          type: 3,
          submitKey: "upHorizontalRelationship",
          addItem
        });
      }
      if (data["downHorizontalRelationship"]) {
        // downHorizontalRelationshipNote    downHorizontalRelationshipValue
        let addList;
        addList = [
          pickBy({
            key: data["downHorizontalRelationshipNote"],
            value: valueData[data["downHorizontalRelationshipNote"]],
            submitKey: "downHorizontalRelationshipNote",
            input:
              data["downHorizontalRelationshipNote"] === "specify"
                ? data["downHorizontalRelationshipValue"]
                : "",
            inputKey:
              data["downHorizontalRelationshipNote"] === "specify"
                ? "downHorizontalRelationshipValue"
                : ""
          })
        ];
        let addItem = pickBy({
          children: isArray(addList) && addList.length ? addList : "",
          submitKey: "downHorizontalRelationship",
          value: valueData[data["downHorizontalRelationship"]],
          key: data["downHorizontalRelationship"]
        });
        this.initOption({
          type: 3,
          submitKey: "downHorizontalRelationship",
          addItem
        });
      }

      // 10
      if (data["coverInfo"]) {
        this.initOption({ type: 1, submitKey: "coverInfo" });
      }

      // 13
      if (data["overbiteInfo"]) {
        let overbiteInfoOpen = filter(split(data["overbiteInfoOpen"], ","));
        let overbiteInfoDeep = filter(split(data["overbiteInfoDeep"], ","));
        let list = [...overbiteInfoOpen, ...overbiteInfoDeep];
        let twoList = [
          "extend_front_tooth",
          "press_front",
          "press_front_rise_back"
        ];
        let addList;
        let threeKey = {
          extend_front_tooth: data["overbiteInfoExtrudeAnterior"],
          press_front: data["overbiteInfoIntrudeAnterior"],
          press_front_rise_back: data["overbiteInfoPosteriorsExrusion"]
        };
        let threeSubKey = {
          extend_front_tooth: "overbiteInfoExtrudeAnterior",
          press_front: "overbiteInfoIntrudeAnterior",
          press_front_rise_back: "overbiteInfoPosteriorsExrusion"
        };
        let isThree = filter([
          data["overbiteInfoExtrudeAnterior"],
          data["overbiteInfoIntrudeAnterior"],
          data["overbiteInfoPosteriorsExrusion"]
        ]);
        addList = map(list, item => {
          return pickBy({
            key: item,
            submitKey: overbiteInfoOpen.length
              ? "overbiteInfoOpen"
              : "overbiteInfoDeep",
            value: valueData[item],
            children:
              twoList.includes(item) && isThree.length
                ? map(split(threeKey[item], ","), three => {
                    return {
                      key: three,
                      value: valueData[three],
                      submitKey: threeSubKey[item]
                    };
                  })
                : ""
          });
        });
        let addItem = pickBy({
          children: isArray(addList) && addList.length ? addList : "",
          submitKey: "overbiteInfo",
          value: valueData[data["overbiteInfo"]],
          key: data["overbiteInfo"]
        });
        this.initOption({ type: 3, submitKey: "overbiteInfo", addItem });
        dia.overbiteOtherInfo = data.overbiteOtherInfo;
      }

      if (data["frontToothInfo"]) {
        let addList;
        addList = map(split(data["correctFrontReverse"], ","), item => {
          return {
            key: item,
            value: valueData[item],
            submitKey: "correctFrontReverse"
          };
        });
        let addItem = pickBy({
          children: isArray(addList) && addList.length ? addList : "",
          submitKey: "frontToothInfo",
          value: valueData[data["frontToothInfo"]],
          key: data["frontToothInfo"]
        });
        this.initOption({ type: 3, submitKey: "frontToothInfo", addItem });
      }

      if (data["middleLine"]) {
        let twoSubmitKey = filter([
          data["upjawMiddleLineNote"],
          data["downjawMiddleLineNote"]
        ]);
        let addList = [];
        let obj, obj1;
        if (twoSubmitKey.length) {
          obj = {
            key: data["upjawMiddleLineNote"],
            value: valueData[data["upjawMiddleLineNote"]],
            submitKey: "upjawMiddleLineNote",
            input: data["upjawMiddleLine"],
            inputKey: "upjawMiddleLine"
          };
          obj1 = {
            key: data["downjawMiddleLineNote"],
            value: valueData[data["downjawMiddleLineNote"]],
            submitKey: "downjawMiddleLineNote",
            input: data["downjawMiddleLine"],
            inputKey: "downjawMiddleLine"
          };
          addList.push(obj, obj1);
        }
        let addItem = pickBy({
          children: isArray(addList) && addList.length ? addList : "",
          submitKey: "middleLine",
          value: valueData[data["middleLine"]],
          key: data["middleLine"]
        });
        this.initOption({ type: 3, submitKey: "middleLine", addItem });
      }

      if (data["backToothInfo"]) {
        let addList;
        addList = map(split(data["backToothInfoCorrect"], ","), item => {
          return {
            key: item,
            value: valueData[item],
            submitKey: "backToothInfoCorrect"
          };
        });
        let addItem = pickBy({
          children: isArray(addList) && addList.length ? addList : "",
          submitKey: "backToothInfo",
          value: valueData[data["backToothInfo"]],
          key: data["backToothInfo"]
        });
        this.initOption({ type: 3, submitKey: "backToothInfo", addItem });
      }

      // 15{
      if (data["acceptWisdomTeethExtraction"]) {
        this.initOption({ type: 1, submitKey: "acceptWisdomTeethExtraction" });
      }
      if (data["acceptPremolarsExtraction"]) {
        this.initOption({ type: 1, submitKey: "acceptPremolarsExtraction" });
      }
      if (data["acceptDecayedTeethExtraction"]) {
        this.initOption({ type: 1, submitKey: "acceptDecayedTeethExtraction" });
      }
      if (data["acceptIpr"]) {
        this.initOption({ type: 1, submitKey: "acceptIpr" });
      }
      if (data["undergoTeethRestoration"]) {
        this.initOption({ type: 1, submitKey: "undergoTeethRestoration" });
      }
      if (data["implantAnchoragePlacement"]) {
        this.initOption({ type: 1, submitKey: "implantAnchoragePlacement" });
      }
      if (data["cbctPhotography"]) {
        this.initOption({ type: 1, submitKey: "cbctPhotography" });
      }
      if (data["orthodonticsTreatmentBefore"]) {
        this.initOption({ type: 1, submitKey: "orthodonticsTreatmentBefore" });
      }
      let optionList = [
        "upjawExt",
        "upjawLip",
        "upjawFar",
        "downjawExt",
        "downjawLip",
        "downjawFar",
        "crowdCorrect",
        "crowdCorrectSide"
      ];
      for (let i in data) {
        each(optionList, correct => {
          if (correct === i) {
            if (["upjawFar", "downjawFar"].includes(correct)) {
              let list = map(split(data[i], ","), item => {
                return {
                  key: item,
                  value: valueData[item],
                  submitKey: correct
                };
              });
              this.$set(
                this.diagnosisParams,
                i,
                pickBy({
                  children: list.length ? list : ""
                })
              );
            } else {
              this.$set(this.diagnosisParams, i, {
                key: data[i],
                value: valueData[data[i]],
                submitKey: correct
              });
            }
          }
        });
      }
      //拔牙
      if (data["toothExtraction"]) {
        this.initOption({ type: 1, submitKey: "toothExtraction" });
      }

      //20
      if (data["eruptedCompensate"]) {
        this.initOption({ type: 1, submitKey: "eruptedCompensate" });
        dia.eruptedCompensateNote = data.eruptedCompensateNote;
      }

      //12
      if (data["needPassiveAppliance"]) {
        this.initOption({ type: 1, submitKey: "needPassiveAppliance" });
        dia.needPassiveApplianceNote = data.needPassiveApplianceNote;
      }

      //13
      if (data["specialInstructions"]) {
        dia.specialInstructions = data.specialInstructions;
      }

      return Promise.resolve();
    },
    isJSON(str) {
      if (str == "") {
        return "";
      }
      if (typeof str == "string") {
        try {
          JSON.parse(str);
          let obj = JSON.parse(str);
          if (!obj.nanoId) {
            obj.nanoId = obj.ETag;
          }
          return obj;
        } catch (error) {
          return { ETag: str, nanoId: str };
        }
      }
    },
    saveData() {
      let dia = this.diagnosisParams;
      let obj = {};
      for (let i in dia) {
        let k;
        if (isObject(dia[i]) && keys(dia[i]).length) {
          // 一级对象
          if (dia[i].key) {
            obj[i] = dia[i].key;
          }
          for (let childrenI in dia[i]) {
            // 对象内数组
            if (
              isArray(dia[i][childrenI]) &&
              dia[i][childrenI].length &&
              dia[i].key
            ) {
              let length = Array.from(
                new Set(map(dia[i][childrenI], item => item.submitKey))
              ).length;
              let threeList = flattenDeep(
                filter(map(dia[i][childrenI], item => item["children"]))
              );
              if (threeList.length) {
                let str = map(threeList, item => {
                  k = item.submitKey;
                  if (item.inputKey) {
                    obj[item.inputKey] = item.input;
                  }
                  return item.key;
                }).join();
                obj[k] = str;
              }

              if (length === 1) {
                let str = map(dia[i][childrenI], item => {
                  k = item.submitKey;
                  if (item.inputKey) {
                    obj[item.inputKey] = item.input;
                  }
                  return item.key;
                }).join();
                obj[k] = str;
              } else if (length > 1) {
                each(dia[i][childrenI], item => {
                  obj[item.submitKey] = item.key;
                  if (item.inputKey) {
                    obj[item.inputKey] = item.input;
                  }
                });
              }
            }

            //10 矢状关系
            if (
              !dia[i].key &&
              isArray(dia[i][childrenI]) &&
              dia[i][childrenI].length
            ) {
              let str = map(dia[i][childrenI], item => {
                if (item.inputKey) {
                  obj[item.inputKey] = item.input;
                }
                return item.key;
              }).join();
              obj[i] = str;
            }
          }
        }
      }
      let {
        badHabitsNote,
        overbiteOtherInfo,
        specialInstructions,
        toothOtherProblem,
        toothOtherProblemTwo,
        clinicOtherInfo,
        eruptedCompensateNote,
        needPassiveApplianceNote
      } = dia;
      let list = [];
      let commonList = map(this.commonTeeth, item => {
        if (item.noMove) {
          list.push(extend({}, item, { type: 2 }));
        }
        if (item.noDesign) {
          list.push(extend({}, item, { type: 3 }));
        }
        return extend({}, item, { type: 8 });
      });

      let sumCrowdAdjoinList = filter(
        this.$refs["adjoinDislodge"] && this.$refs["adjoinDislodge"].totalTeeth,
        item => item.width
      );

      let crowdAdjoinList = map(
        filter(this.adjoinList.concat(sumCrowdAdjoinList), item => item.width),
        it => {
          return extend({}, it, { type: 9 });
        }
      );
      let sumInterValList = filter(
        this.$refs["intervalCorrect"] &&
          this.$refs["intervalCorrect"].totalTeeth,
        item => item.nextNumber
      );
      let intervalList = map(
        filter(sumInterValList, item => item.nextNumber),
        it => {
          return extend(
            {},
            it,
            pickBy({ type: 5, spacingType: it.maintain ? 4 : "" })
          );
        }
      );

      let toothInfoList = [
        ...commonList,
        ...list,
        ...crowdAdjoinList,
        ...intervalList
      ];
      let imgObj = pickBy({
        sideFacePicture: dia.sideFacePicture, // 侧面像
        frontFacePicture: dia.frontFacePicture, // 正面像
        smilePicture: dia.smilePicture, // 微笑像
        upToothPicture: dia.upToothPicture, // 上牙列像
        downToothPicture: dia.downToothPicture, // 下牙列像
        mouthRightPicture: dia.mouthRightPicture, // 口内右侧位像
        mouthFrontPicture: dia.mouthFrontPicture, // 口内正位像
        mouthLeftPicture: dia.mouthLeftPicture, // 口内左侧位像
        xrayCurvePicture: dia.xrayCurvePicture, // 全颌曲面断层片
        xraySidePicture: dia.xraySidePicture, // 头颅侧位定位片
        otherPicture: dia.otherPicture, // 其他影像资料
        anteriorOverjetPicture: dia.anteriorOverjetPicture //前牙覆盖像
      });
      let PicObj = {};
      for (const item in imgObj) {
        if (item !== "otherPicture") {
          PicObj[item] = JSON.stringify(this.isJSON(imgObj[item]));
        } else {
          try {
            JSON.parse(imgObj[item]);
            PicObj[item] = imgObj[item];
          } catch (error) {
            let list = [];
            map(imgObj[item].split(","), item => {
              list.push(this.isJSON(item));
            });
            PicObj[item] = JSON.stringify(list);
          }
        }
      }
      obj = extend(
        {},
        PicObj,
        obj,
        pickBy({
          toothOtherProblem,
          badHabitsNote,
          overbiteOtherInfo,
          specialInstructions,
          toothInfoList: toothInfoList.filter(item => item.number !== "0"),
          toothOtherProblemTwo,
          clinicOtherInfo,
          eruptedCompensateNote,
          needPassiveApplianceNote,
          caseId: dia.caseId,
          id: dia.id,
          digitalModelFile: dia.digitalModelFile, //数字模型
          siliconeRubberModel: dia.siliconeRubberModel // 硅橡胶模型
        })
      );
      this.isLoading = true;
      if (!dia.id) {
        return createCurePlan(obj)
          .then(data => {
            this.isLoading = false;
            this.diagnosisParams.id = data;
            notifyMsg(this, "success", this.$t("common.message.yzc"), 2500);
            changeRouteQuery(this, { curePlanId: data });
            return Promise.resolve();
          })
          .catch(() => {
            this.isLoading = false;
            this.$emit("saveError");
            return Promise.reject();
          });
      } else {
        return editCurePlan(obj)
          .then(() => {
            this.isLoading = false;
            notifyMsg(this, "success", this.$t("common.message.yzc"), 2500);
            changeRouteQuery(this, { curePlanId: obj.id });
            return Promise.resolve();
          })
          .catch(() => {
            this.isLoading = false;
            this.$emit("saveError");
            return Promise.reject();
          });
      }
    }
  },
  isJSON(str) {
    if (str == "") {
      return "";
    }
    if (typeof str == "string") {
      try {
        JSON.parse(str);
        let obj = JSON.parse(str);
        if (!obj.nanoId) {
          obj.nanoId = obj.ETag;
        }
        return obj;
      } catch (error) {
        return { ETag: str, nanoId: str };
      }
    }
  },
  beforeDestroy() {
    window.removeEventListener("scroll", this.handleScroll, false);
  },
  mounted() {
    this.rightHeight = window.getComputedStyle(this.$refs["rightTable"]).height;
    each(this.leftList, (item, index) => {
      if (document.getElementById(index + 1)) {
        this.leftTopObj["d" + (index + 1)] = document.getElementById(
          index + 1
        ).offsetTop;
      }
    });
    window.addEventListener("scroll", this.handleScroll, false);
  },
  created() {
    this.isResponse += 1;
    this.getCaseOptions();
  }
};
</script>

<style lang="scss" scoped>
.crosswise_input_mm {
  position: absolute;
  right: 0.02rem;
  top: 0.08rem;
}
.table_wrap {
  display: flex;
  margin-bottom: -1rem;
  .left_option {
    width: 2.4rem;
    height: 6.1rem;
    background-color: #fff;
    position: fixed;
    //top: 204px;

    .el-scrollbar {
      overflow-x: hidden;
    }

    /deep/ .el-scrollbar__wrap {
      overflow-x: hidden;
    }

    .left_option_item {
      width: 100%;
      height: 0.5rem;
      transition: all 0.2s;
      font-size: 0.16rem;
      color: $main_theme_color_333;
      cursor: pointer;
      overflow: hidden;
      text-overflow: ellipsis;
      display: flex;
      text-align: center;
      justify-content: center;
      align-items: center;
      position: relative;
      line-height: 0.2rem;
      .left_wire {
        width: 4px;
        height: 32px;
        background: $main_theme_color;
        position: absolute;
        left: 0;
      }
    }
    .left_option_item:hover {
      transition: all 0.2s;
      background: #f9f9f9;
    }
  }
  .right_table {
    margin-left: 2.4rem;
    width: calc(100% - 2.4rem);
    background-color: #fff;
    padding: 0.2rem 0.2rem 0.5rem 0.59rem;
    border-left: 1px solid #e5e5e5;
    //margin-top: -0.15rem;

    .select_type {
      margin-bottom: 30px;
      .select_type_title {
        font-size: 0.16rem;
        color: $main_theme_color_333;
      }
    }
  }
}
.common_btn {
  position: relative;
  padding: 10px 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 0.2rem;
  color: $main_theme_color_666;
  border-radius: 4px;
  border: 0.01rem solid #bbbbbb;
  cursor: pointer;
  font-size: 0.14rem;
  margin-bottom: 0.1rem;
  white-space: nowrap;
  user-select: none;
  background-color: #fff;
}
.is_active {
  background-color: $main_theme_color;
  color: $main_theme_color_666 !important;
  border: 0.01rem solid $main_theme_color;
}
.remark_box {
  width: 100%;
  margin-top: 0.1rem;
  textarea {
    width: 90%;
    border: 0.01rem solid #c8c8c8;
    border-radius: 0.08rem;
    padding: 0.2rem;
    color: #333;
    min-height: 1.34rem;
  }
}
.option_left_title {
  min-width: 0.7rem;
  font-size: 14px;
  color: $main_theme_color_666;
}
.single_tip_box {
  margin-top: 0.2rem;
  color: $main_theme_color_333;
  background-color: #f4f4f4;
  font-size: 0.14rem;
  margin-bottom: 0.2rem;
  padding: 0.15rem 0.2rem;
  p {
    margin-bottom: 0.1rem;
    &:last-child {
      margin-bottom: 0;
    }
    input {
      width: 100%;
      outline: none;
      border: none;
    }
  }
}
.popover_con_right_iup {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-left: 0.2rem;
  border: 0.01rem solid #c8c8c8;
  width: 80%;
  height: 0.4rem;
  border-radius: 0.06rem;
  padding-right: 0.17rem;
  input {
    width: 50%;
    padding: 0.13rem 0.9rem 0.13rem 0.15rem;
    font-size: 0.14rem;
    flex: 1;
    outline: none;
    border: none;
  }
}
.single_con {
  color: #333;
  font-size: 0.16rem;
  position: relative;
  .remark_box {
    width: 100%;
    textarea {
      width: 100%;
      border: 0.01rem solid #c8c8c8;
      border-radius: 0.08rem;
      padding: 0.2rem;
      color: #333;
      min-height: 1.34rem;
    }
  }
  .lc_box {
    background-color: #f9f9f9;
    padding: 0.3rem 0.2rem 0.16rem 0.3rem;
    display: flex;
    justify-content: space-between;
    font-size: 0.14rem;
    color: #333;
    .check_box {
      flex: 1;
    }
    .el-checkbox-group {
      display: flex;
      flex: 1;
      flex-wrap: wrap;
      .check_single {
        margin: 0 0.1rem 0.2rem;
        &:last-child {
          margin-bottom: 0;
        }
      }
    }
    .other_box {
      width: 2.81rem;
      .tit {
        margin-bottom: 0.12rem;
      }
      textarea {
        width: 100%;
        height: 0.98rem;
        border-radius: 0.04rem;
        background-color: #fff;
        padding: 0.1rem;
        box-sizing: border-box;
      }
    }
  }
  .single_c_head {
    margin-bottom: 0.3rem;
  }
  .s_c_box {
    display: flex;
    .s_c_b_left {
      margin-right: 0.2rem;
      margin-top: 0.13rem;
    }
    .t_b_p {
      color: #666;
      min-width: 0.8rem;
    }
    .t_b_tip {
      color: #666;
      font-size: 0.14rem;
      margin-left: 0.1rem;
    }
  }
}
.t_b_box {
  display: flex;
  align-items: center;
  font-size: 0.16rem;
  flex-wrap: wrap;
  margin-bottom: 0.16rem;
  line-height: 0.2rem;
}
.popover_con {
  display: flex;
  .popover_con_left {
    min-width: 0.3rem;
    white-space: nowrap;
    padding-top: 0.15rem;
  }
  .popover_con_right {
    display: flex;
    flex-wrap: wrap;
    flex: 1;
    span {
      margin-top: 0.1rem;
      margin-bottom: 0.1rem;
    }
    .popover_con_right_p {
      margin-bottom: 0.1rem;
    }
    .popover_con_right_iup {
      position: relative;
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-left: 0.2rem;
      border: 0.01rem solid #c8c8c8;
      width: 80%;
      height: 0.4rem;
      border-radius: 0.06rem;
      padding-right: 0.17rem;
      input {
        padding: 0.13rem 0 0.13rem 0.15rem;
        font-size: 0.14rem;
        flex: 1;
        outline: none;
        border: none;
      }
    }
  }
}
.left_active {
  color: $main_theme_color !important;
}
.popover_db {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: -1.5rem;
  margin-top: -0.3rem;
  .popover_db_left {
    border-left: 0.01rem solid #c8c8c8;
    border-bottom: 0.01rem solid #c8c8c8;
    width: 0.2rem;
    height: 0.3rem;
  }
  .popover_db_right {
    display: flex;
    padding-top: 0.4rem;
    span {
      margin-left: 0;
      margin-right: 0.2rem;
    }
  }
}
.dia_problem {
  width: 100%;
  background: #f4f4f4;
  padding: 10px 16px 30px;
  margin-bottom: 30px;
  h5 {
    font-size: 0.22rem;
    color: $main_theme_color_333;
  }
  span {
    margin-right: 0.2rem;
  }
  .pro_box {
    word-break: break-all;
    font-size: 0.16rem;
    line-height: 0.3rem;
    color: $main_theme_color_333;
  }
}
.problem_note {
  word-break: break-all;
  color: $main_theme_color;
  margin-bottom: 0.2rem;
  line-height: 0.22rem;
  font-size: 0.14rem;
  margin-top: 4px;
}
</style>
