<template>
  <div v-loading="caseList.loading" class="add_send">
    <div class="add_send_header flex-auto">
      <el-input
        class="input_width"
        @keyup.enter.native="changeQuery({ pageNum: 1 })"
        :placeholder="$t('birdie.qsrblhxm')"
        v-model="nameOrNumberForQuery"
      >
      </el-input>
      <div
        @click="changeQuery({ pageNum: 1 })"
        class="add_send_search flex-x-y-c flex-fixed"
      >
        <img
          src="../../../common/imgs/expressage_birdie/add_send_search.png"
          alt=""
        />
      </div>
    </div>
    <div
      class="add_send_content"
      :class="{ add_send_content_small: diaHeight }"
    >
      <el-scrollbar style="height: 100%">
        <template>
          <div
            v-for="(item, index) in caseList.list"
            :key="index"
            class="add_send_content_item"
          >
            <div class="flex-y-c">
              <img
                class="user_img"
                :src="PIC_PREFIX + item.userInfo.photo"
                alt=""
              />
              <p class="ml10 user_name">{{ item.userName }}</p>
            </div>
            <div class="case_number">{{ item.caseNumber }}</div>
            <div class="create_time">{{ item.createDate }}</div>
            <div
              @click="addCase(item)"
              class="add_send_content_item_option flex-y-c curp"
            >
              <i class="el-icon-plus ml8 fz14"></i>
              <p class="ml4">{{ $t("birdie.tj") }}</p>
            </div>
          </div>
        </template>
        <div
          v-if="caseList.list && caseList.list.length < 1 && isGet"
          class="add_empty"
        >
          <img
            class="add_empty_img"
            src="../../../common/imgs/expressage_birdie/add_empty.png"
            alt=""
          />
          <div>
            <p class="add_empty_h1">{{ $t("birdie.wssdbl") }}！</p>
            <p class="add_empty_h2">{{ $t("birdie.knczyy") }}：</p>
            <p class="add_empty_h2">1.{{ $t("birdie.wzqsrxm") }}</p>
            <p class="add_empty_h2">2.{{ $t("birdie.bsydqjg") }}</p>
          </div>
        </div>
      </el-scrollbar>
    </div>
    <div v-if="dialogVisible" class="modeltype-content">
      <div class="modeltype-header">
        {{ $t("braceReissue.gxjmxxz") }}
        <i
          style="cursor: pointer"
          @click="dialogVisible = false"
          class="el-icon-close"
        ></i>
      </div>
      <div class="modeltype-wrap">
        <div class="modeltype-checkbox">
          <div class="label">{{ $t("braceReissue.mxxz") }}：</div>
          <div class="values">
            <el-radio-group v-if="isMainCase" v-model="modelType">
              <el-radio label="0">{{ $t('stageAdjust.fasjmx') }}</el-radio>
              <el-radio label="3">{{ $t('stageAdjust.qtmx') }}</el-radio>
            </el-radio-group>
            <el-radio-group v-else v-model="modelType">
              <el-radio
                label="0"
                v-if="
                  [
                    'to_submit',
                    'to_resubmit',
                    'to_deliver_model',
                    'to_deliver',
                    'to_done',
                    'to_deliver_model_remain',
                  ].includes(nowChoosen.nextStatus)
                "
                >{{ $t('stageAdjust.jdtzmx') }}</el-radio
              >
              <el-radio
                label="2"
                v-if="
                  ['to_deliver', 'to_done', 'to_deliver_model_remain'].includes(
                    nowChoosen.nextStatus
                  )
                "
                >{{ $t('stageAdjust.wcblmx') }}</el-radio
              >
              <el-radio
                label="1"
                v-if="nowChoosen.isExistUnCompletedLogistics == '1'"
                >{{ $t('stageAdjust.jjytmx') }}</el-radio
              >
              <el-radio label="3">{{ $t('stageAdjust.qtmx') }}</el-radio>
            </el-radio-group>
          </div>
        </div>
        <div class="modeltype-tips">
          {{
            $t('stageAdjust.mxtips')
          }}
        </div>

      </div>
      <div class="options">
        <div class="option-btn cancel" @click="dialogVisible = false">
          {{ $t("braceReissue.qx") }}
        </div>
        <div class="option-btn confirm" @click="confirmModelType">{{ $t("braceReissue.qd") }}</div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import {
  queryCasesList,
  PublicCaseSendAddressCreateModify,
} from "common/api/cases";
import { extend } from "lodash";
import { PIC_PREFIX } from "common/js/config";
import { notifyMsg } from "common/js/util";

export default {
  data() {
    return {
      diaHeight: "",
      modelTypeList: [],
      PIC_PREFIX,
      caseList: {
        list: [],
        loading: false,
      },
      isGet: false,
      dialogVisible: false,
      nowChoosen: {},
      modelType: "",
    };
  },
  props: {
    caseDetail: {
      type: Object,
      default: () => ({}),
    },
    isReissueFlag: {
      default: false,
    },
  },
  computed: {
    ...mapGetters({}),
    isMainCase() {
      const { curePlanInfo = {} } = this.nowChoosen;
      return (
        curePlanInfo["phaseAdjustmentNumber"] === 0 &&
        ["A", "B"].includes(this.nowChoosen["stage"])
      );
    },
    nameOrNumberForQuery: {
      get() {
        return this.$route.query.nameOrNumberForQuery || "";
      },
      set(val) {
        let route = this.$route;
        this.$router.replace({
          path: route.path,
          query: extend({}, route.query, { nameOrNumberForQuery: val }),
        });
      },
    },
  },

  methods: {
    ...mapActions({}),
    confirmModelType() {
      let { addClinicId, addAddressId } = this.$route.query;
      if (this.modelType === '') {
        return notifyMsg(this, "warning", this.$t("braceReissue.qxzmxlx"));
      }
      this.caseList.loading = true;
      PublicCaseSendAddressCreateModify({
        caseId: this.nowChoosen.id,
        sendAddressId: addAddressId,
        clinicId: addClinicId,
        type: this.modelType,
      })
        .then(() => {
          let route = this.$route;
          this.$router.replace({
            path: route.path,
            query: extend({}, route.query, {
              indexType: "model",
              resetId: addClinicId,
            }),
          });
          this.caseList.loading = false;
          return notifyMsg(
            this,
            "success",
            this.$t("casesDetail.casesChildren.common.czcg")
          );
        })
        .catch(() => {
          this.caseList.loading = false;
        });
    },
    addCase(item) {
      if (this.caseList.loading) return;
      let { addClinicId, addAddressId } = this.$route.query;
      this.nowChoosen = item;
      this.dialogVisible = true;
      // if (item.isExistUnCompletedLogistics == '1') {
      //   this.nowChoosen = item;
      // } else {
      //   this.caseList.loading = true;
      //   PublicCaseSendAddressCreateModify({
      //     caseId: item.id,
      //     sendAddressId: addAddressId,
      //     clinicId: addClinicId,
      //     type: this.isReissueFlag ? '1' : '0'
      //   }).then(() => {
      //     let route = this.$route;
      //     this.$router.replace({
      //       path: route.path,
      //       query: extend({}, route.query, { indexType: 'model', resetId: addClinicId })
      //     });
      //     this.caseList.loading = false;
      //     return notifyMsg(this, 'success', this.$t('casesDetail.casesChildren.common.czcg'));
      //   }).catch(() => {
      //     this.caseList.loading = false;
      //   })
      // }
    },
    getList() {
      if (this.caseList.loading || !this.nameOrNumberForQuery) return;
      this.caseList.loading = true;
      queryCasesList({
        clinicId: this.$route.query.addClinicId,
        nameOrNumberForQuery: this.nameOrNumberForQuery,
      })
        .then((data) => {
          this.isGet = true;
          this.caseList.list = data;
          this.caseList.loading = false;
        })
        .catch(() => {
          this.caseList.loading = false;
        });
    },

    changeQuery(arg) {
      let route = this.$route;
      this.$router.replace({
        path: route.path,
        query: extend({}, route.query, arg),
      });
      this.$nextTick(() => {
        this.getList();
      });
    },
  },

  created() {
    this.$emit("getDiaTitle", this.$t("birdie.tjjsmx"));
    this.diaHeight = window.screen.height < 1000;
  },
};
</script>

<style lang="scss" scoped>
.add_send {
  height: 100%;
  padding: 20px 30px 30px 30px;
  position: relative;

  .modeltype-content {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 506px;
    //min-height: 330px;
    border-radius: 8px;
    z-index: 10;
    background-color: #ffffff;
    box-shadow: 0px 0px 8px 0px rgba(51, 51, 51, 0.12);
    display: flex;
    flex-direction: column;


    .modeltype-header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      height: 58px;
      padding: 0 20px;
      font-size: 18px;
      line-height: 26px;
      border-bottom: 1px solid #e5e5e5;
    }

    .modeltype-wrap {
      flex: 1;
      display: flex;
      flex-direction: column;
      padding: 30px 30px 0;

      .modeltype-checkbox {
        display: flex;
        align-items: flex-start;

        .label {
          padding-top: 2px;
          white-space: nowrap;
          margin-right: 16px;

          &::before {
            content: "*";
            color: #ed4027;
            margin-right: 4px;
          }
        }

        /deep/ .el-radio {
          margin-bottom: 10px;
        }
      }

      .modeltype-tips {
        width: 384px;
        font-size: 14px;
        line-height: 20px;
        color: #999999;
        margin: 24px 0 34px;
      }

      .options {
        display: flex;
        justify-content: center;
        align-items: center;

        .option-btn {
          cursor: pointer;
          width: 138px;
          height: 36px;
          border-radius: 4px;
          display: flex;
          justify-content: center;
          align-items: center;
          font-size: 14px;
          color: #333333;
        }

        .cancel {
          border: 1px solid #e5e5e5;
          margin-right: 32px;
        }

        .confirm {
          background-color: #fcc80e;
        }
      }
    }
  }
  .options {
    display: flex;
    justify-content: center;
    align-items: center;
    box-shadow: 0 0 8px 0 rgba(51, 51, 51, 0.12);

    padding: 15px;

    .option-btn {
      cursor: pointer;
      width: 138px;
      height: 36px;
      border-radius: 4px;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 14px;
      color: #333333;
    }

    .cancel {
      border: 1px solid #e5e5e5;
      margin-right: 32px;
    }

    .confirm {
      background-color: #fcc80e;
    }
  }
  .add_send_header {
    width: 100%;
    height: 80px;
    background: #ffffff;
    box-shadow: 0 0 8px 0 rgba(51, 51, 51, 0.12);
    border-radius: 8px 0 0 8px;
    padding: 20px;

    .add_send_search {
      width: 56px;
      height: 40px;
      background: #fec111;
      box-shadow: 0 0 8px 0 rgba(51, 51, 51, 0.12);
      border-radius: 0 4px 4px 0;
      cursor: pointer;

      img {
        display: block;
        width: 32px;
        height: 32px;
      }
    }
  }

  .add_send_content {
    width: 100%;
    height: 628px;
    background: #ffffff;
    box-shadow: 0 0 8px 0 rgba(51, 51, 51, 0.12);
    border-radius: 8px;
    margin-top: 20px;
    padding: 20px 0 20px 30px;
    position: relative;

    .add_empty {
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
      top: 60px;
      text-align: center;

      img {
        display: block;
        width: 2.29rem;
      }

      .add_empty_h1 {
        font-size: 14px;
        margin-top: 24px;
        color: #333333;
      }

      .add_empty_h2 {
        font-size: 14px;
        color: #666666;
        margin-top: 4px;
      }
    }

    .add_send_content_item {
      width: 100%;
      height: 32px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 30px;
      padding-right: 30px;

      .user_img {
        display: block;
        width: 32px;
        height: 32px;
        border-radius: 50%;
      }

      .user_name {
        width: 0.6rem;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        font-size: 14px;
        color: #333333;
      }

      .case_number {
        width: 1.1rem;
        overflow: hidden;
        text-overflow: ellipsis;
        font-size: 14px;
        color: #333333;
      }

      .create_time {
        color: #333333;
        width: 1rem;
        overflow: hidden;
        text-overflow: ellipsis;
        font-size: 14px;
      }

      .add_send_content_item_option {
        width: 64px;
        height: 28px;
        box-shadow: 0 0 8px 0 rgba(51, 51, 51, 0.12);
        border-radius: 3px;
        transition: all 0.2s;
        background: white;
        color: #fec111;
        border: 1px solid #fec111;
      }

      .add_send_content_item_option:hover {
        background: #fec111 !important;
        color: white !important;
        border: 1px solid white !important;
        transition: all 0.2s;
      }
    }
  }

  .add_send_content_small {
    height: 454px !important;
  }
}

/deep/ .el-scrollbar__wrap {
  overflow-x: hidden;
}

/deep/ .el-input__inner {
  border-radius: 4px 0 0 4px !important;
}
.el-icon-close:hover{
  color: #FCC80E;
}
</style>
