import { render, staticRenderFns } from "./sendAdvice.vue?vue&type=template&id=f271abac&scoped=true"
import script from "./sendAdvice.vue?vue&type=script&lang=js"
export * from "./sendAdvice.vue?vue&type=script&lang=js"
import style0 from "./sendAdvice.vue?vue&type=style&index=0&id=f271abac&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "f271abac",
  null
  
)

export default component.exports