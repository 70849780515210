import TYPES from "./mutationTypes";

export default {
  [TYPES.GET_LOGIN_STATUS]: (state, isLogin) => {
    return (state.isLogin = isLogin);
  },
  [TYPES.GET_MENU_URL]: (state, menuUrl) => {
    return (state.menuUrl = menuUrl);
  },
  [TYPES.USER_MSG]: (state, userMsg) => {
    return (state.userMsg = userMsg);
  },
  [TYPES.HANDLE_MSG]: state => {
    return (state.isHandleMsg = !state.isHandleMsg);
  },
  [TYPES.HEAD_SEARCH]: (state, conditionForQuery) => {
    return (state.conditionForQuery = conditionForQuery);
  },
  [TYPES.GET_IS_SHOW_NEWS]: (state, type) => {
    sessionStorage.setItem("isShowNews", type);
    return (state.isShowNews = type);
  },
  [TYPES.GET_language]: (state, type) => {
    return (state.language = type);
  },
  [TYPES.MODIFY_USER_PHOTO]: state => {
    return (state.isModifyUserPhoto = !state.isModifyUserPhoto);
  },
  [TYPES.CITY_LIST]: (state, newCityList) => {
    state.cityList = newCityList;
  }
};
