<template>
  <div class="wrap">
    <div class="help_center_top"></div>
    <div class="help_center">
      <ul class="help_left">
        <li v-for="(item, index) in helpLeftList" :key="`help_${index}`" class="left_single">
          <h5 class="l_s_h5" @click="upDown(item.id)">
            <span :class="titleKey === item.id ? 'l_s_cion_down' : 'l_s_cion'"></span>
            {{ item.title }}
          </h5>
          <ul v-if="item.cList.length" v-show="titleKey === item.id" class="s_ul" @click.stop="clickArticle">
            <li v-for="singleItem in item.cList" :key="singleItem.id"
                :class="selectedArticle === singleItem.id ? 's_u_single_active' : ''" :data-key="singleItem.id"
                class="s_u_single">{{ singleItem.title }}
            </li>
          </ul>
        </li>
      </ul>
      <div class="help_right">
        <mattersNeedAttention v-show="$route.query.type === '14'"/>
        <OperationTeaching v-show="$route.query.type === '15'"/>
        <LedToSurface v-show="$route.query.type === '16'"/>
        <FromManagement v-show="$route.query.type === '17'"/>
        <Inappropriate v-show="$route.query.type === '18'"/>
        <ModelMake v-show="$route.query.type === '11'" style="max-height: 100%"/>
        <PictureShoot v-show="$route.query.type === '12'"/>
        <XpictureShoot v-show="$route.query.type === '13'"/>
        <DoctorAnimationHelp v-show="$route.query.type === '21'"/>
        <DistinguishRestartStage v-show="$route.query.type === '22'"/>
      </div>
      <FullLoading v-show="isLoading"/>
    </div>
  </div>
</template>

<script>
import {extend} from 'lodash'
import FullLoading from 'components/full-loading/full-loading';
import {articleListDetail, articleDetail} from 'common/api/system';
import ModelMake from './components/model_make';
import PictureShoot from './components/picture_shoot';
import XpictureShoot from './components/x_picture_shoot';
import FromManagement from './components/from_sex_management';
import Inappropriate from './components/inappropriate';
import LedToSurface from './components/led_to_glaze_surface';
import mattersNeedAttention from './components/matters_need_attention';
import OperationTeaching from './components/operation_teaching';
import DoctorAnimationHelp from './components/doctor_animation_help.vue'
import DistinguishRestartStage from "./components/distinguish_restart_stage.vue";
import {mapMutations} from 'vuex';

export default {
  data() {
    return {
      isLoading: false,
      helpLeftList: [
        {
          id: '1',
          title: '临床操作',
          cList: [
            {
              id: '14',
              title: '初戴的注意事项'
            },
            {
              id: '15',
              title: '附件粘接操作教程'
            },
            {
              id: '16',
              title: '领面去釉操作教程'
            },
            {
              id: '17',
              title: '患者依从性管理'
            },
            {
              id: '11',
              title: '模型制取标准'
            },
            {
              id: '18',
              title: '医技沟通常见不恰当描述'
            },
            {
              id: '12',
              title: '照片拍摄标准'
            },
            {
              id: '13',
              title: 'X光片拍摄标准'
            },
            {
              id: '21',
              title: '3D动画医生修改操作帮助'
            },
            {
              id: '22',
              title: '如何区分重启和阶段调整'
            }
          ]
        }
      ],
      titleKey: '',
      articleObj: {
        content: '',
        title: ''
      }
    }
  },
  computed: {
    selectedArticle: {
      get() {
        return this.$route.query.type || '11'
      },
      set(val) {

      }
    }
  },
  methods: {
    ...mapMutations([
      'HANDLE_MSG',
    ]),
    upDown(key) {
      if (this.titleKey === key) {
        this.titleKey = '';
      } else {
        this.titleKey = key;
      }
    },
    clickArticle(ev) {
      const target = ev.target;
      const key = target.getAttribute('data-key');
      if (key) {
        this.selectedArticle = key;
        let route = this.$route;
        this.$router.push({
          name: route.name,
          query: extend({}, route.query, {type: key})
        });
      }
    },
  },
  components: {
    FullLoading,
    ModelMake,
    PictureShoot,
    XpictureShoot,
    FromManagement,
    Inappropriate,
    LedToSurface,
    mattersNeedAttention,
    OperationTeaching,
    DoctorAnimationHelp,
    DistinguishRestartStage
  },
  created() {
    this.HANDLE_MSG();
    this.titleKey = '1';
  },
  mounted() {
  },
}
</script>

<style lang="scss" scoped>
.wrap {
  //.help_center_top {
  //  width: 13.44rem;
  //  height: 25px;
  //  background: #EFEFF4;
  //}

  .help_center {
    margin: 0 auto 0;
    width: 16rem;
    background-color: #fff;
    display: flex;
    padding: 30px;

    .help_left {
      display: block;
      min-width: 20%;
      padding-bottom: 0.5rem;
      border-right: 0.01rem solid #F0F0F0;

      .left_single {
        .l_s_h5 {
          margin-bottom: 0.25rem;
          display: flex;
          align-items: center;
          cursor: pointer;
          font-size: 18px;
          color: $main_theme_color_333;

          span {
            display: inline-block;
            background-size: 100% 100%;
            margin-right: 0.11rem;
          }

          .l_s_cion {
            width: 0.08rem;
            height: 0.08rem;
            background-image: url("/static/images/home/web_doc_up.png");
          }

          .l_s_cion_down {
            width: 0.08rem;
            height: 0.08rem;
            background-image: url("/static/images/home/web_doc_down.png");
          }
        }

        .s_ul {
          padding-left: 0.13rem;
          color: $main_theme_color_333;
          font-size: 0.16rem;
          margin-top: -0.12rem;

          .s_u_single {
            padding-left: 0.17rem;
            height: 0.37rem;
            line-height: 0.37rem;
            margin-bottom: 0.05rem;
            border-right: 0.02rem solid transparent;
            cursor: pointer;

            &:last-child {
              margin-bottom: 0.2rem;
            }
          }

          .s_u_single_active {
            border-color: $main_theme_color;
            background-color: #F9F9F9;
            color: $main_theme_color;
          }
        }
      }
    }

    .help_right {
      padding: 0.1rem 0.1rem 0.1rem 0.39rem;
      min-width: 80%;
    }
  }
}
</style>
