
//治疗表详情
export const ACTION_GET_CASE_CURE_DETAIL = 'ACTION_GET_CASE_CURE_DETAIL';
//病例详情
export const ACTION_GET_COMMON_CASE_DETAIL = 'ACTION_GET_COMMON_CASE_DETAIL';
//治疗表详情
export const ACTION_GET_DIAGNOS_DETAIL = 'ACTION_GET_DIAGNOS_DETAIL';
//追加产品列表
export const ACTION_GET_ADD_PRODUCT_List = 'ACTION_GET_ADD_PRODUCT_List';
//追加历史列表
export const ACTION_GET_ADD_HISTROY_List = 'ACTION_GET_ADD_HISTROY_List';
//追加进度列表
export const ACTION_GET_CASE_PROGRESS_LIST = 'ACTION_GET_CASE_PROGRESS_LIST';
export const ACTION_GET_CITY_LIST = 'ACTION_GET_CITY_LIST';
