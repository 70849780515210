<template>
  <div class="more_msg">
    <back-step
      :goList="[{
        url: '/',
        name: `${$t('home.personal.personal.zy')}`
      }]"
      :currentTxt="`${$t('home.personal.moreHomePage.xx')}`"
    />
    <div class="more_box">
      <msg-page :msgList="masObj.list"/>
      <div class="pagination-box" v-if="masObj.total > 0">
        <el-pagination
          layout="total, sizes, prev, pager, next"
          :total="masObj.total"
          :current-page="queryParams.pageNum"
          :page-size="queryParams.pageSize"
          @size-change="changeRouteQuery({
            pageSize:arguments[0],
            pageNum:1
          })"
          @current-change="changeRouteQuery({
            pageNum:arguments[0]
          })">
        </el-pagination>
      </div>
    </div>
    <FullLoading v-show="isLoading"/>
  </div>
</template>

<script>
  import {caseMessagePage} from 'common/api/cases';
  import BackStep from '../../cases/cases-detail/childrenPublic/backStep';
  import FullLoading from 'components/full-loading/full-loading';
  import MsgPage from '../publicChildren/msgPage';
  import {getUserId} from 'common/js/util';
  import {mapState} from 'vuex';
  import {extend} from 'lodash';
  import {toNumber} from "../../../common/js/Fn";

  export default {
    data() {
      return {
        msgList: [],
        isLoading: true,
        masObj:{
          list: [],
          total: '',
        }
      }
    },
    computed: {
      ...mapState({
        isHandleMsg: state => state.isHandleMsg
      }),
      queryParams(){
        let query = this.$route.query;
        return {
          pageNum: toNumber(query.pageNum,1),
          pageSize: toNumber(query.pageSize,10),
          toUserId: getUserId(),
          sort:"-status,-id"
        }
      }
    },
    methods: {
      changeRouteQuery(args){
        let route = this.$route;
        this.$router.replace({
          name: route.name,
          query: extend({},route.query,args)
        });
        this.getList();
      },
      getList() {
        this.isLoading = true;
        caseMessagePage(this.queryParams).then(data => {
          this.masObj.total = data.total;
          this.isLoading = false;
          this.masObj.list = data.list || [];
        }).catch(() => {
          this.isLoading = false;
        });
      },
    },
    components: {
      BackStep,
      MsgPage,
      FullLoading
    },

    created() {
      document.title = this.$t('home.personal.moreHomePage.title');
      this.getList();
    },
    watch: {
      isHandleMsg() {
        this.getList();
      }
    }
  }
</script>

<style scoped lang="scss">
  .more_msg{
    color: $main_theme_color_333;
    width: 16rem;
    margin: 0 auto;
    //padding-top: 30px;
    .more_box{
      margin-top: 0.1rem;
    }
    .pagination-box {
      margin-top: 0.3rem;
      display: flex;
      justify-content: flex-end;
    }
  }
</style>
