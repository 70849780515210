var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"cases_create"},[_c('div',{staticClass:"create_head",class:{ top068: !_vm.isShowNews }},[_c('h5',{staticClass:"create_head_h5"},[_vm._v(_vm._s(_vm.$t("cases.casesCreate.xjbl")))]),_c('div',{staticClass:"create_head_ul"},[(_vm.clinicMsg.name)?_c('p',{staticClass:"h_line"}):_vm._e(),(_vm.clinicMsg.name)?_c('div',{staticClass:"h_single"},[_c('h5',{staticClass:"h_single_h5"},[_vm._v(" "+_vm._s(_vm.$t("cases.cases.zs"))+"："+_vm._s(_vm.clinicMsg.name)+" ")])]):_vm._e(),(_vm.productMsg.name)?_c('p',{staticClass:"h_line"}):_vm._e(),(_vm.productMsg.name)?_c('div',{staticClass:"h_single"},[_c('h5',{staticClass:"h_single_h5"},[_vm._v(" "+_vm._s(_vm.$t("cases.cases.cp"))+"："+_vm._s(_vm.productMsg.name)+" ")])]):_vm._e()])]),_c('div',{staticClass:"create_tabs",class:{ top18: !_vm.isShowNews }},[_c('ul',{staticClass:"tab_left"},[(
          _vm.details
            ? _vm.details.type === '0' &&
              _vm.details.nextStatus === 'to_submit' &&
              _vm.details.curePlanInfo.reopenNumber === 0
            : true
        )?_c('li',{staticClass:"tab_single",class:_vm.tabKey === '5' ? 'tab_active' : '',on:{"click":function () { return _vm.changeTabs('5'); }}},[_c('span',{staticClass:"product_icon",class:_vm.tabKey === '5' ? 'product_icon_active' : ''}),_vm._v("产品选择 ")]):_vm._e(),_c('li',{staticClass:"tab_single",class:_vm.tabKey === '0' ? 'tab_active' : '',on:{"click":function () { return _vm.changeTabs('0'); }}},[_c('span',{staticClass:"user_icon",class:_vm.tabKey === '0' ? 'user_icon_active' : ''}),_vm._v(_vm._s(_vm.$t("cases.casesCreate.jbxx"))+" ")]),_c('li',{staticClass:"tab_single",class:_vm.tabKey === '1' ? 'tab_active' : '',on:{"click":function () {
            _vm.changeTabs('1');
          }}},[_c('span',{staticClass:"diagnosis_icon",class:_vm.tabKey === '1' ? 'diagnosis_icon_active' : ''}),_vm._v(_vm._s(_vm.$t("cases.casesCreate.zdsm"))+" ")]),_c('li',{staticClass:"tab_single",class:_vm.tabKey === '2' ? 'tab_active' : '',on:{"click":function () {
            _vm.changeTabs('2');
          }}},[_c('span',{staticClass:"image_icon",class:_vm.tabKey === '2' ? 'image_icon_active' : ''}),_vm._v(_vm._s(_vm.$t("cases.casesCreate.yxsm"))+" ")]),_c('li',{staticClass:"tab_single",class:_vm.tabKey === '3' ? 'tab_active' : '',on:{"click":function () {
            _vm.changeTabs('3');
          }}},[_c('span',{staticClass:"preview_icon",class:_vm.tabKey === '3' ? 'preview_icon_active' : ''}),_vm._v(_vm._s(_vm.$t("cases.casesCreate.lltj"))+" ")])]),_c('div',{staticClass:"tab_right"},[_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.$route.query.submitTabKey !== '5' || _vm.tabKey !== '5'),expression:"$route.query.submitTabKey !== '5' || tabKey !== '5'"}],staticClass:"next_page flex-x-y-c curp mr10",class:_vm.tabKey !== '0' ? 'to_active' : '',style:(_vm.lang === 'en_US' ? 'width: 1.2rem' : ''),on:{"click":function () {
            _vm.toStep('0');
          }}},[_c('i',{staticClass:"el-icon-arrow-left mr4 mt2"}),_vm._v(" "+_vm._s(_vm.$t("cases.casesCreate.syy"))+" ")]),(_vm.tabKey !== '3')?_c('div',{staticClass:"next_page flex-x-y-c curp",class:_vm.tabKey !== '3' ? 'to_active' : '',on:{"click":function () {
            _vm.toStep('1');
          }}},[_vm._v(" "+_vm._s(_vm.$t("cases.casesCreate.xyy"))+" "),_c('i',{staticClass:"el-icon-arrow-right ml4 mt2"})]):_vm._e(),(_vm.isCurePlanSubmit && _vm.tabKey !== '5')?_c('div',{staticClass:"save_btn flex-x-y-c curp",on:{"click":_vm.saveMsg}},[_vm._v(" "+_vm._s(_vm.tabKey !== "3" ? _vm.$t("cases.casesCreate.zc") : _vm.$t("common.message.tj"))+" ")]):_vm._e()]),_c('div',{staticClass:"transverse_line"})]),_c('div',{staticClass:"create_con",class:{ mt28: !_vm.isShowNews }},[(_vm.tabKey === '5')?_c('create-product',{ref:"product",attrs:{"details":_vm.details}}):_vm._e(),(_vm.tabKey === '0')?_c('create-user',{ref:"user",attrs:{"productClear":_vm.prodcuct_clear,"tabIndex":_vm.tabKey},on:{"saveError":_vm.saveError,"getClinicMsg":_vm.getClinicMsg,"getProductMsg":_vm.getProductMsg}}):_vm._e(),(_vm.tabKey === '1')?[(_vm.productType === 'A')?_c('create-diagnosis',{ref:"diagnosis",on:{"saveError":_vm.saveError}}):(['B', 'G', 'H'].includes(_vm.productType))?_c('YouthTable',{ref:"youthDiagnosis"}):(_vm.productType === 'C')?_c('ChildrenTable',{ref:"childrenDiagnosis"}):(_vm.productType === 'D')?_c('BraceRetaining',{ref:"braceDiagnosis"}):(_vm.productType === 'E')?_c('VeneersTable',{ref:"vennersDiagnosis"}):(_vm.productType === 'F')?_c('YysdTable',{ref:"yysdDiagnosis"}):(_vm.productType === 'I')?_c('GapTable',{ref:"gapDiagnosis"}):_vm._e()]:_vm._e(),(_vm.tabKey === '2' && _vm.commonCaseDetail.state !== 'loading')?_c('create-iamge',{ref:"image",attrs:{"caseDetail":_vm.commonCaseDetail.detail},on:{"saveError":_vm.saveError}}):_vm._e(),(_vm.tabKey === '3')?_c('create-preview',{ref:"preview",attrs:{"isSubmit":_vm.isSubmit},on:{"resetSubStatus":_vm.resetSubStatus,"saveDiagnosis":_vm.saveDiagnosis,"submitCase":_vm.saveMsg,"oneJumpFour":_vm.oneJumpFour}}):_vm._e()],2),_c('confirm-modal',{directives:[{name:"show",rawName:"v-show",value:(_vm.isShowConfirm),expression:"isShowConfirm"}],attrs:{"confirmObj":{
      tipText: _vm.$t('cases.casesCreate.sftj'),
      backText: _vm.$t('casesDetail.casesChildren.common.qx'),
      okText: _vm.$t('cases.casesCreate.qdtj')
    }},on:{"confirmSubmit":function($event){return _vm.confirmSubmit()},"confirmHide":function($event){_vm.isShowConfirm = false}}}),_c('FullLoading',{directives:[{name:"show",rawName:"v-show",value:(_vm.isLoading),expression:"isLoading"}]})],1)}
var staticRenderFns = []

export { render, staticRenderFns }