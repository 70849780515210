var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"wrap"},[_c('back-step',{attrs:{"goList":[
      {
        url: '/',
        name: ("" + (_vm.$t('home.personal.personal.zy')))
      }
    ],"currentTxt":("" + (_vm.$t('home.home.xtgg')))}}),_c('div',{staticClass:"home_container"},[_c('div',{staticClass:"announcement_box"},[_c('div',{staticClass:"announcement_title"},[_c('p',{staticClass:"title_01"},[_vm._v(_vm._s(_vm.$t("home.home.ggbt")))]),_c('p',{staticClass:"title_02 tac"},[_vm._v(_vm._s(_vm.$t("home.home.sj")))])]),(_vm.announcement.list.length)?_c('ul',{staticClass:"announcement_ul",on:{"click":function($event){$event.stopPropagation();return _vm.announcementDetail($event)}}},_vm._l((_vm.announcement.list),function(item,index){return _c('li',{key:item.id,staticClass:"an_single"},[_c('div',{staticClass:"click_box",attrs:{"data-key":item.id}}),_c('p',{staticClass:"title_01 txt-ellipsis2"},[_vm._v(_vm._s(item.title))]),_c('p',{staticClass:"title_02 tac"},[_vm._v(_vm._s(item.updateDatetime))])])}),0):_c('p',{staticClass:"ann_no tac"},[_vm._v(_vm._s(_vm.$t("home.home.zwgg"))+"～")])])]),(!_vm.isLoading)?_c('el-pagination',{staticClass:"fr mb2rem",attrs:{"page-sizes":[10, 20, 30, 50, 100],"current-page":_vm.queryParams.pageNum,"page-size":_vm.queryParams.pageSize,"background":"","layout":"total, sizes, prev, pager, next","total":_vm.announcement.total},on:{"size-change":function($event){return _vm.changeRouteQuery({
        pageSize: arguments[0],
        pageNum: 1
      })},"current-change":function($event){return _vm.changeRouteQuery({
        pageNum: arguments[0]
      })}}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }