<template>
  <div class="exit_email">
    <back-step
      :goList="[{
        url: '/',
        name: `${$t('home.personal.personal.zy')}`
      }, {
        url: '/home/personal',
        name: `${$t('home.personal.personal.grzx')}`
      }]"
      :currentTxt="`${$t('home.personal.bandTradingPaw.zjmm')}`"
    />
    <div class="children_con" v-if="!isExitOk">
      <h5 class="c_con_title">{{$t('home.personal.bandTradingPaw.zjmm')}}</h5>
      <ul class="c_form">
        <li class="f_single">
          <span :class="language=== 'en_US' ? 'w28fr' : ''" class="s_label">{{$t('home.personal.bandTradingPaw.sjh')}}</span>
          <div class="iup_box">
            <input type="number" v-model="mobile" disabled>
          </div>
        </li>
        <li class="f_single">
          <span :class="language=== 'en_US' ? 'w28fr' : ''" class="s_label">{{$t('home.personal.bandTradingPaw.yzm')}}</span>
          <div class="iup_box">
            <input type="number" v-model="params.smsCaptcha">
            <span
              class="yzm_sp"
              :class="isYzm ? 'yzm_sp_active' : ''"
              @click="getYzm"
            >
              {{isYzm ? `${$t('home.personal.bandEmail.cxhq')} ${yzmTime}s` : `${$t('home.personal.bandEmail.hqyzm')}`}}
            </span>
          </div>
        </li>
        <li class="f_single">
          <span :class="language=== 'en_US' ? 'w28fr' : ''" class="s_label">{{$t('home.personal.bandTradingPaw.zjmm')}}</span>
          <div class="iup_box">
            <input type="password" autocomplete="new-password" v-model="params.tradePwd">
          </div>
        </li>
        <li class="f_single">
          <span :class="language=== 'en_US' ? 'w28fr' : ''" class="s_label">{{$t('home.personal.bandTradingPaw.zcqr')}}</span>
          <div class="iup_box">
            <input type="password" autocomplete="new-password" v-model="params.tradePwd01">
          </div>
        </li>
      </ul>
      <div class="c_foo">
        <div class="main_big_btn mr20 main_theme_color_btn_white" @click="goBack">{{$t('common.message.qx')}}</div>
        <div class="main_big_btn main_theme_color_btn" @click="submitForm">{{$t('common.message.qr')}}</div>
      </div>
    </div>
    <exit-success
      v-else
      :isExitOk="isExitOk"
      :successTit="`${$t('common.message.szcg')}`"
      :successTxt="`${$t('common.message.zjmmszcg')}`"
    />
    <full-loading v-show="isLoading"/>
  </div>
</template>

<script>
  import BackStep from '../../../cases/cases-detail/childrenPublic/backStep';
  import ExitSuccess from './exitSuccess';
  import {notifyMsg, getUserId} from 'common/js/util';
  import {bindTradePaw, modifyTradePaw, sendCaptcha} from 'common/api/user';
  import FullLoading from 'components/full-loading/full-loading';
  import {mapState} from 'vuex';

  export default {
    data() {
      return {
        isLoading: false,
        isExitOk: false,
        isYzm: false,
        yzmTime: 59,
        interTime: null,
        mobile: sessionStorage.getItem('userMobile') || '',
        params: {
          tradePwd: '',
          tradePwd01: '',
          smsCaptcha: ''
        },
        isExitType: '0'
      }
    },
    created() {
      this.isExitType = this.$route.query.type || '0';
    },
    methods: {
      getYzm() {
        if(this.isYzm) {
          return false;
        }
        const {mobile} = this;
        if(!mobile) {
          return notifyMsg(this, 'warning', this.$t('common.message.sjhbnwk'));
        }
        if(this.interTime) {
          clearInterval(this.interTime);
        }
        this.isLoading = true;
        sendCaptcha({
          mobile,
          bizType: this.isExitType === '0' ? 'BIND_TRADEPWD' : 'MODIFY_TRADE_PWD'
        }).then(() => {
          this.isLoading = false;
          this.isYzm = true;
          this.interTime = setInterval(() => {
            if(this.yzmTime < 1) {
              this.isYzm = false;
              this.yzmTime = 59;
              clearInterval(this.interTime);
            }
            this.yzmTime--;
          }, 1000);
        }).catch(() => {
          this.isLoading = false;
        });
      },
      submitForm() {
        const {tradePwd, tradePwd01, smsCaptcha} = this.params;
        if(!this.mobile) {
          return notifyMsg(this, 'warning', this.$t('common.message.sjhbnwk'));
        }else if(!tradePwd || !tradePwd01 || !smsCaptcha) {
          return notifyMsg(this, 'warning', this.$t('common.message.qtxwz'));
        }else if(tradePwd !== tradePwd01) {
          return notifyMsg(this, 'warning', this.$t('common.message.mmbyz'));
        }
        this.isLoading = true;
        const tradingApi = this.isExitType === '0' ? bindTradePaw : modifyTradePaw;
        const obj = {
          id: getUserId(),
          tradePwd,
          smsCaptcha
        };
        if(this.isExitType === '1') {
          obj.mobile = this.mobile;
        }
        tradingApi(obj).then(() => {
          this.isLoading = false;
          this.isExitOk = true;
        }).catch(() => {
          this.isLoading = false;
        });
      },
      goBack() {
        window.history.go(-1);
      }
    },
    computed:{
      ...mapState({
        language: state => state.language,
      }),
    },
    components: {
      BackStep,
      ExitSuccess,
      FullLoading
    },
    beforeDestroy() {
      if(this.interTime) {
        clearInterval(this.interTime);
      }
    }
  }
</script>

<style scoped lang="scss">
  .w28fr{
    width: 2.5rem;
    text-align: right;
  }
  .exit_email{
    width: 16rem;
    margin: 0 auto;
    color: $main_theme_color_333;
    font-size: 0.18rem;
    span{
      display: inline-block;
      cursor: pointer;
    }
    .children_con{
      margin-top: 0.1rem;
      box-shadow: 0 0 0.16rem 0 #ACACAC;
      border-radius: 0.1rem;
      background-color: #fff;
      display: flex;
      align-items: center;
      flex-direction: column;
      overflow: hidden;
      .c_con_title{
        background-color: #F4F4F4;
        font-size: 0.24rem;
        text-align: center;
        width: 100%;
        height: 0.8rem;
        line-height: 0.8rem;
      }
      .c_form{
        width: 6.5rem;
        margin-top: 0.33rem;
        margin-bottom: 0.26rem;
        .f_single{
          display: flex;
          align-items: center;
          margin-bottom: 0.3rem;
          .s_label{
            min-width: 0.65rem;
            margin-right: 0.14rem;
            white-space: nowrap;
          }
          .iup_box{
            flex: 1;
            display: flex;
            align-items: center;
            position: relative;
            input {
              flex: 1;
              background-color: #F7F7F7;
              border: 0.01rem solid #E6E6E6;
              border-radius: 0.6rem;
              height: 0.4rem;
              padding: 0.06rem 0.1rem;
            }
            .yzm_sp {
              margin-left: 0.1rem;
              width: 1.2rem;
              height: 0.4rem;
              line-height: 0.4rem;
              border-radius: 0.6rem;
              border: 0.01rem solid #FF8E30;
              color: #FF8E30;
              text-align: center;
            }
            .yzm_sp_active {
              border: none;
              background-color: #F4F4F4;
              color: #666;
              cursor: default;
            }
          }
        }
      }
    }
    .c_foo{
      display: flex;
      align-items: center;
      justify-content: center;
      padding-bottom: 0.64rem;
    }
  }
</style>
