<template>
  <div class="personal">
    <back-step
      :goList="[
        {
          url: '/',
          name: `${$t('home.personal.personal.zy')}`,
        },
      ]"
      :currentTxt="`${$t('home.personal.personal.grzx')}`"
    />
    <div class="personal_head">
      <div class="p_h_user">
        <h5 class="user_tit">
          <span class="tit_line"></span>{{ $t("home.personal.personal.grxx") }}
        </h5>
        <div class="p_h_user_box">
          <div class="p_h_u_pic">
            <el-upload
              :data="{ token: uploadToken, key: nanoid }"
              class="avatar-uploader"
              action="https://jsonplaceholder.typicode.com/posts/"
              :show-file-list="false"
              :on-success="handleAvatarSuccess"
              :before-upload="beforeAvatarUpload"
              :action="UPLOAD_URL"
            >
              <img
                class="avatar"
                :src="userPhoto ? $PicPrefix + userPhoto : defPhoto"
              />
              <div class="edit-photo">
                <img
                  class="edit-icon"
                  src="../../../common/imgs/personal/edit-photo.png"
                  alt=""
                />
              </div>
            </el-upload>
          </div>
          <div class="p_h_u_all">
            <h5 class="u_name">{{ userMsg.realName }}</h5>
            <p class="h__u_p">
              {{ $t("home.personal.personal.dlzh") }}：{{ userMsg.loginName }}
            </p>
            <p class="h__u_p">
              {{ $t("home.personal.personal.qyjg") }}：{{ userOrganization }}
            </p>
            <p class="h__u_p">
              {{ $t("home.personal.personal.jj") }}：{{ userMsg.description }}
            </p>
<!--
            <div class="icon_box">
              <div class="email_box">
                <div
                  class="email_pic"
                  :class="userMsg.email ? 'email_icon_ok' : 'email_icon'"
                  @click="toExitEmail"
                ></div>
                <p class="email_is_band">
                  {{
                    userMsg.email
                      ? this.$t("home.personal.personal.ybd")
                      : this.$t("home.personal.personal.wbd")
                  }}
                </p>
              </div>
              <div class="wx_box">
                <div
                  class="wx_pic"
                  :class="userMsg.wechatCode ? 'wx_icon_ok' : 'wx_icon'"
                  @click="goWeChatPage"
                ></div>
                <p class="wx_is_band fz16 main_theme_color_333">
                  {{
                    userMsg.wechatCode
                      ? this.$t("home.personal.personal.ybd")
                      : $t("home.personal.personal.wbd")
                  }}
                </p>
              </div>
            </div>
-->
          </div>
        </div>
      </div>
      <ul class="p_h_right">
        <li class="h_r_single">
          <div class="h_r_s_left">
            <div class="h_r_l_pic mobile_icon"></div>
            <div class="h_r_l_msg">
              <h5 class="h_r__msg_h5">
                {{ $t("home.personal.personal.sjh") }}
                <span class="h_r__msg_h5_sp" v-if="userMsg.mobile"
                  >({{ $t("home.personal.personal.ybd") }})</span
                >
              </h5>
              <p class="h_r__msg_p" v-if="userMsg.mobile">
                {{ userMsg.mobile }}
              </p>
            </div>
          </div>
          <div
            class="change_btn flex-x-y-c curp fz14 main_theme_color_333"
            @click="toExitPhone"
          >
            {{ $t("common.message.xg") }}
          </div>
        </li>
        <li class="h_r_single">
          <div class="h_r_s_left">
            <div class="h_r_l_pic login_paw_icon"></div>
            <div class="h_r_l_msg">
              <h5 class="h_r__msg_h5">
                {{ $t("home.personal.personal.dlmm") }}
              </h5>
              <p class="h_r__msg_p" v-if="userMsg.loginPwdFlag">********</p>
            </div>
          </div>
          <div
            class="change_btn flex-x-y-c curp fz14 main_theme_color_333"
            @click="toLoginPaw"
          >
            {{
              userMsg.loginPwdFlag
                ? this.$t("common.message.xg")
                : this.$t("common.message.sz")
            }}
          </div>
        </li>
      </ul>
    </div>
    <div class="doctor-address">
      <div class="address-title">
        <div class="title">医生地址管理</div>
        <div class="add-address" @click="addAddress">
          <img src="../../../common/imgs/personal/add.png" alt="" />
          添加地址
        </div>
      </div>
      <div class="address-content">
        <div
          class="item-content"
          v-if="addressList.length"
          v-for="(item, index) in addressList"
          :key="index"
        >
          <AddressCard
            :showDefault="true"
            :info="item"
            @confirm="editCurrent"
            @cancel="removeCurrent"
          />
        </div>
        <div v-if="!addressList.length" class="no-data">
          <img src="../../../common/imgs/personal/no-address.png" alt="" />
          <span>您暂未添加任何收货地址</span>
        </div>
      </div>
    </div>
    <scheduling
      v-show="isScheduling"
      @subScheduling="subScheduling"
      @removeScheduling="removeScheduling"
      :type="schedulingType"
      :selectedKey="radio"
    />
    <AddressEdit
      :visible="visible"
      @cancel="visible = false"
      @confirm="confirmAddress"
      :isEdit="isEdit"
      :editId="editId"
    />
    <confirm-modal
      :confirmObj="{
        tipText: `${$t('common.message.sfjx')}`,
        backText: `${$t('common.message.qx')}`,
        okText: `${$t('common.message.qd')}`,
      }"
      @confirmSubmit="confirmSubmit"
      @confirmHide="confirmHide"
      v-show="isShow"
    />
    <Confirm
        v-show="isShowRemove"
        :confirmObj="removeObj"
        @confirmHide="isShowRemove=false"
        @confirmSubmit="removeAddress"
    />
    <full-loading v-show="isLoading" />
  </div>
</template>

<script>
import { mapState, mapActions, mapMutations } from "vuex";
import { nanoid } from "nanoid";
import { getQiniuToken } from "common/api/public";
import { modifyUserInformation } from 'common/api/user'
import { PublicSendAddressList, removeSendAddress } from "common/api/cases";
import BackStep from "../../cases/cases-detail/childrenPublic/backStep";
import Scheduling from "./scheduling";
import FullLoading from "components/full-loading/full-loading";
import ConfirmModal from "components/confirm-modal/confirm";
import { defPhoto } from "common/js/requireImage";
import AddressCard from "../../../components/address-card/index.vue";
import AddressEdit from "../../../components/address-edit/index.vue";
import { UPLOAD_URL } from "common/js/config";
import {
  doctorClinic,
  doctorScheduleList,
  removeDoctorSchedule,
} from "common/api/user";
import { getUserId, notifyMsg, formatDate } from "common/js/util";
import $ from "jquery";
import Confirm from "components/confirm-modal/confirm.vue";

export default {
  data() {
    return {
      isShowRemove:false,
      removeObj: {
        tipText: "确定删除该信息?",
        backText: this.$t("common.message.qx"),
        okText: this.$t("common.message.qd")
      },
      addressInfo:'',
      UPLOAD_URL,
      defPhoto,
      isShow: false,
      isLoading: false,
      uploadToken: "",
      isScheduling: false,
      radio: "",
      schedulingList: [],
      schedulingType: "0",
      userOrganization: "",
      addressLoading: true,
      addressList: [],
      visible: false,
      isEdit: false,
      editId: "",
      userPhoto: "",
      nanoid: nanoid(),
    };
  },
  watch: {
    userMsg: function(nval) {
      this.userPhoto = nval["photo"];
    },
  },
  created() {
    this.getUserMsg();
    document.title = this.$t("home.personal.personal.title");
    sessionStorage.removeItem("userMobile");
    sessionStorage.removeItem("userEmail");
    sessionStorage.removeItem("wxObj");
    doctorClinic({
      doctorId: getUserId(),
    }).then((data) => {
      this.userOrganization = data || "-";
    });
    this.getSendAddressList();
    this.doctorScheduleList();
  },
  mounted() {
    getQiniuToken().then((data) => {
      this.uploadToken = data;
    });
  },
  methods: {
    ...mapActions(["getUserMsg"]),
    ...mapMutations(["MODIFY_USER_PHOTO"]),
    handleAvatarSuccess(res) {
      modifyUserInformation({
        id: localStorage.getItem("userId"),
        photo: res.key
      }).then(() => {
        this.userPhoto = res.key;
        this.MODIFY_USER_PHOTO();
        this.nanoid = nanoid();
      })
    },
    beforeAvatarUpload(file) {
      const isJPG = ["image/jpeg", "image/png"].includes(file.type);
      const isLt2M = file.size / 1024 / 1024 < 2;
      if (!isJPG) {
        notifyMsg(this, "warning", this.$t("common.message.supportedFormats"));
      }
      if (!isLt2M) {
        notifyMsg(this, "warning", this.$t("common.message.isLt20M"));
      }
      return isJPG && isLt2M;
    },
    editCurrent(info) {
      this.isEdit = true;
      this.editId = info.id;
      this.visible = true;
    },
    addAddress() {
      this.isEdit = false;
      this.editId = "";
      this.visible = true;
    },
    removeAddress(){
      const _this = this;
      removeSendAddress({
        idList: [this.addressInfo.id],
      }).then(() => {
        this.isShowRemove=false
        notifyMsg(_this, "success", "删除成功");
        _this.addressLoading = true;
        _this.getSendAddressList();
      });
    },
    removeCurrent(info) {
      this.isShowRemove=true
      this.addressInfo=info

      // const _this = this;
      // this.$confirm("确定删除该信息？", "提示", {
      //   confirmButtonText: "确定",
      //   cancelButtonText: "取消",
      //   type: "warning",
      // }).then(() => {
      //   removeSendAddress({
      //     idList: [info.id],
      //   }).then(() => {
      //     notifyMsg(_this, "success", "删除成功");
      //     _this.addressLoading = true;
      //     _this.getSendAddressList();
      //   });
      // });
    },
    getSendAddressList() {
      PublicSendAddressList({
        refCode: localStorage.getItem("userId"),
        refType: "DOCTOR",
      }).then((res) => {
        this.addressLoading = false;
        this.addressList = res;
      });
    },
    confirmAddress() {
      this.visible = false;
      this.addressLoading = true;
      this.getSendAddressList();
    },
    doctorScheduleList() {
      this.isLoading = true;
      doctorScheduleList({
        doctorId: getUserId(),
      })
        .then((data) => {
          this.isLoading = false;
          const weekObj = {
            "0": "周一",
            "1": "周二",
            "2": "周三",
            "3": "周四",
            "4": "周五",
            "5": "周六",
            "6": "周日",
          };
          this.schedulingList = data.map((item) => ({
            ...item,
            dayOfWeek: weekObj[item.dayOfWeek],
            workDate: formatDate(item.workDate, "yyyy-MM-dd"),
          }));
        })
        .catch(() => {
          this.isLoading = false;
        });
    },
    tableClick(ev) {
      const target = ev.target;
      const list = Array.from(target.classList);
      if (list.includes("click_box")) {
        this.radio = target.getAttribute("data-key");
      }
    },
    opScheduling(type) {
      $("html").css({
        overflowY: "hidden",
      });
      if (type === "0") {
        this.radio = "";
      } else if (!this.radio) {
        return notifyMsg(this, "warning", this.$t("common.message.qxzjl"));
      }
      this.schedulingType = type;
      this.isScheduling = true;
    },
    deleteScheduling() {
      if (!this.radio) {
        return notifyMsg(this, "warning", this.$t("common.message.qxzjl"));
      }
      $("html").css({
        overflowY: "hidden",
      });
      this.isShow = true;
    },
    confirmSubmit() {
      this.isLoading = true;
      removeDoctorSchedule(this.radio)
        .then(() => {
          $("html").css({
            overflowY: "scroll",
          });
          this.isLoading = false;
          this.isShow = false;
          this.radio = "";
          notifyMsg(this, "success", this.$t("common.message.czcg"));
          this.doctorScheduleList();
        })
        .catch(() => {
          $("html").css({
            overflowY: "scroll",
          });
          this.isShow = false;
          this.isLoading = false;
        });
    },
    confirmHide() {
      $("html").css({
        overflowY: "scroll",
      });
      this.isShow = false;
    },
    subScheduling() {
      $("html").css({
        overflowY: "scroll",
      });
      this.schedulingType = "";
      this.doctorScheduleList();
      this.isScheduling = false;
    },
    removeScheduling() {
      $("html").css({
        overflowY: "scroll",
      });
      this.schedulingType = "";
      this.isScheduling = false;
    },
    toExitEmail() {
      if (this.userMsg.email) {
        sessionStorage.setItem("userEmail", this.userMsg.email);
      }
      this.goPage(
        `${this.userMsg.email ? "/home/exitEmail" : "/home/bandEmail"}`
      );
    },
    toExitPhone() {
      sessionStorage.setItem("userMobile", this.userMsg.mobile);
      this.goPage("/home/editPhone");
    },
    toLoginPaw() {
      sessionStorage.setItem("userMobile", this.userMsg.mobile);
      this.goPage("/home/bandLoginPaw");
    },
    toTradePwd() {
      sessionStorage.setItem("userMobile", this.userMsg.mobile);
      const type = this.userMsg.tradepwdFlag ? "1" : "0";
      this.goPage(`/home/bandTradingPaw?type=${type}`);
    },
    goWeChatPage() {
      const { wechatQrCode, wechatCode } = this.userMsg;
      sessionStorage.setItem(
        "wxObj",
        JSON.stringify({ wechatQrCode, wechatCode })
      );
      this.goPage(`/home/bandWeChat`);
    },
    goPage(url) {
      if (url) {
        this.$router.push(url);
      }
    },
  },
  components: {
    Confirm,
    BackStep,
    Scheduling,
    FullLoading,
    ConfirmModal,
    AddressCard,
    AddressEdit,
  },
  computed: {
    ...mapState({
      userMsg: (state) => state.userMsg,
    }),
  },
};
</script>

<style scoped lang="scss">
.doctor-address {
  margin-bottom: 40px;
  max-height: 660px;
  display: flex;
  flex-direction: column;
  border-radius: 6px;
  margin-top: 20px;
  background-color: #ffffff;
  padding: 25px 0 5px;

  .address-title {
    padding-right: 30px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .title {
      padding: 0 30px;
      font-size: 18px;
      line-height: 26px;
      display: flex;
      align-items: center;

      &::before {
        content: "";
        width: 4px;
        height: 26px;
        background-color: #fcc80e;
        margin-right: 10px;
      }
    }

    .add-address {
      cursor: pointer;
      width: 116px;
      height: 36px;
      border-radius: 4px;
      border: 1px solid #bbbbbb;
      display: flex;
      align-items: center;
      justify-content: center;

      img {
        width: 16px;
        margin-right: 4px;
      }
    }
  }

  .address-content {
    margin-top: 15px;
    padding: 0 30px;
    flex: 1;
    overflow-y: auto;
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;

    .no-data {
      width: 100%;
      height: 500px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      img {
        width: 184px;
        margin-bottom: 24px;
      }

      span {
        font-size: 14px;
        line-height: 20px;
        color: #999999;
      }
    }

    .item-content {
      width: 414px;
      height: 124px;
      margin-bottom: 20px;
      margin-right: 20px;
      display: flex;
      align-items: center;

      &:nth-child(3n) {
        margin-right: 0px;
      }
    }
  }
}

.personal {
  width:16rem;
  margin: 0 auto;
  //padding-top: 50px;

  .personal_head {
    height: 360px;
    background-color: #fff;
    border-radius: 6px;
    padding: 0.3rem;
    display: flex;

    .p_h_user {
      margin-right: 0.6rem;
      flex: 1;

      .p_h_user_box {
        display: flex;
        margin-top: 0.2rem;

        .p_h_u_pic {
          min-width: 1.2rem;
          max-width: 1.2rem;
          height: 1.2rem;
          border-radius: 100%;
          margin-right: 0.2rem;
          border-radius: 50%;
          overflow: hidden;
          position: relative;

          :hover {
            .edit-photo {
              opacity: 1;
            }
          }

          .avatar-uploader {
            width: 100%;
            height: 100%; 

            /deep/ .el-upload {
              width: 100%;
              height: 100%;
            }
          }

          .edit-photo {
            position: absolute;
            bottom: 0;
            left: 0;
            right: 0;
            height: 40px;
            display: flex;
            justify-content: center;
            align-items: center;
            background-color: rgba($color: #000000, $alpha: .5);
            transition: 0.2s linear;
            opacity: 0;

            img {
              width: 24px;
              height: 24px;
            }
          }

          img {
            width: 100%;
            height: 100%;
          }
        }

        .p_h_u_all {
          .u_name {
            font-size: 0.16rem;
            color: $main_theme_color_333;
            margin-bottom: 0.1rem;
          }

          .h__u_p {
            max-width: 500px;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            font-size: 0.16rem;
            color: $main_theme_color_666;
            margin-bottom: 0.1rem;
            line-height: 18px;
          }

          .icon_box {
            margin-top: 0.3rem;
            display: flex;
            align-items: center;

            .email_pic,
            .wx_pic {
              width: 0.48rem;
              height: 0.48rem;
              background-size: 100% 100%;
              margin-bottom: 0.07rem;
              cursor: pointer;
            }

            .wx_box {
              display: flex;
              align-items: center;
              flex-direction: column;
            }

            .email_box {
              display: flex;
              align-items: center;
              flex-direction: column;
              margin-right: 0.3rem;

              .email_is_band {
                font-size: 16px;
                color: $main_theme_color_333;
              }

              .email_icon {
                background-image: url("../../../common/imgs/help_center/mailbox_gray.png");
              }

              .email_icon_ok {
                background-image: url("../../../common/imgs/help_center/mailbox_icon.png");
              }
            }

            .wx_icon {
              background-image: url("../../../common/imgs/help_center/weixin_gray.png");
            }

            .wx_icon_ok {
              background-image: url("../../../common/imgs/help_center/weixin_icon.png");
            }
          }
        }
      }
    }

    .p_h_right {
      width: 5rem;
      height: 240px;
      margin-top: 30px;
      padding-left: 0.6rem;
      border-left: 0.01rem solid #e5e5e5;
      font-size: 0.16rem;

      .h_r_single {
        display: flex;
        align-items: center;
        margin-bottom: 0.2rem;
        justify-content: space-between;
        padding-right: 70px;

        .change_btn {
          width: 96px;
          height: 36px;
          background: #fcc80e;
          border-radius: 4px;
        }

        &:last-child {
          margin-bottom: 0;
        }

        .h_r_s_left {
          display: flex;
          align-items: center;

          .h_r_l_pic {
            width: 0.48rem;
            height: 0.48rem;
            margin-right: 0.1rem;
            background-size: 100% 100%;
          }

          .mobile_icon {
            background-image: url("../../../common/imgs/help_center/iphone.png");
          }

          .login_paw_icon {
            background-image: url("../../../common/imgs/help_center/lock.png");
          }

          .trading_paw_icon {
            background-image: url("/static/images/home/web_doc_key.png");
          }

          .h_r_l_msg {
            .h_r__msg_h5 {
              font-size: 0.16rem;
              color: $main_theme_color_333;
              margin-bottom: 0.09rem;

              .h_r__msg_h5_sp {
                color: $main_theme_color_999;
              }
            }

            .h_r__msg_p {
              color: $main_theme_color_333;
              font-size: 0.16rem;
            }
          }
        }

        .h_r_s_right {
          width: 0.8rem;
          height: 0.32rem;
          line-height: 0.32rem;
          text-align: center;
          border-radius: 0.8rem;
          cursor: pointer;
        }
      }
    }
  }

  .user_tit {
    display: flex;
    align-items: center;
    font-size: 0.18rem !important;
    color: $main_theme_color_333;

    .tit_line {
      display: inline-block;
      width: 0.04rem;
      height: 0.26rem;
      background-color: $main_theme_color;
      margin-right: 0.1rem;
    }
  }
}
</style>
