<template>
  <div @click="clickText">test</div>
</template>

<script>
import TYPES from "store/mutationTypes";

export default {
  data() {
    return {
      test:'<p>以下是一段关于 CUE3 的描述，你可以根据它实际所指的对象进行调整，这里假定 CUE3 是一款电子产品以下是一段关于 CUE3 的描述，你可以根据它实际所指的对象进行调整，这里假定 CUE3 是一款电子产品</p>',
      showBtn: true, // 是否显示【展开/收起】按钮，按所需条件显示
      isSpread: false, // 是否展开文字动态判断
      cs_text:
        "以下是一段关于 CUE3 的描述，你可以根据它实际所指的对象进行调整，这里假定 CUE3 是一款电子产品以下是一段关于 CUE3 的描述，你可以根据它实际所指的对象进行调整，这里假定 CUE3 是一款电子产品"
    };
  },
  methods: {
    clickText() {
      this.$store.commit(TYPES.GET_IS_SHOW_NEWS, false);
    }
  },

};
</script>

<style lang="scss" scoped>
</style>
